import { ICellRendererParams } from "ag-grid-community"
import React, { ReactNode, SyntheticEvent } from "react"

import { ROUTE, formatRoutePath } from "../../../../../../routes"
import { Bind } from "../../../../../decorators/Bind.decorator"
import { encodeID } from "../../../../../utils/Url.util"
import ApiKeyCellRendererTemplate from "./ApiKeyCellRenderer.template"

export class ApiKeyCellRenderer extends React.Component<ApiKeyCellRendererProps, {}> {
    constructor(props: ApiKeyCellRendererProps) {
        super(props)
        const link = props.isMomConsole ? ROUTE.MOM_API_KEYS_DETAILS : ROUTE.API_KEYS_DETAILS
        try {
            this.link = formatRoutePath(link, { id: encodeID(props.data.id) })
        } catch {
            this.link = ""
        }
    }
    public render(): ReactNode {
        return ApiKeyCellRendererTemplate.call(this)
    }

    @Bind
    private clickHandler(event: SyntheticEvent): void {
        event.preventDefault()
        this.props.context.history.push(this.link)
    }

    private link: string
}

interface ApiKeyCellRendererProps extends ICellRendererParams {
    context: any
    isMomConsole?: boolean
}
