import { History } from "history"
import React from "react"
import { useHistory } from "react-router-dom"

import { queryClient } from "../../queryClient"
import { ROUTE } from "../../routes"
import {
    BroadcastChannelService,
    useBroadcastChannelService,
} from "../../v3/services/BroadcastChannel.service"
import { Singleton } from "../decorators/Singleton.decorator"
import { AuthStore } from "./Auth.store"
import { MspOrgApi } from "../../v3/api/MspOrgManagement.api"
import { AppConsole } from "../../utils/consoles.utils"
import { replacePathname } from "../../utils/url.utils"

@Singleton("AuthService")
class AuthServiceClass {
    public static readonly JWT_KEY: string = "__AUTH/jwtToken"
    public static readonly IMPERSONATE_JWT_KEY = "__IMPERSONATE/AUTH/jwtToken"
    public static readonly PARENT_CONSOLE = "parent_console"
    public static readonly MSW_CSC_URL = "msw_csc_url"
    public static readonly CSC_JWT = "csc_jwt"

    constructor(broadcastChannelService: BroadcastChannelService) {
        this.broadcastChannelService = broadcastChannelService
        const originalJwtToken: string | null = window.localStorage.getItem(
            AuthServiceClass.JWT_KEY
        )
        const impersonateJwtToken: string | null = window.localStorage.getItem(
            AuthServiceClass.IMPERSONATE_JWT_KEY
        )
        const jwtToken: string | null = impersonateJwtToken || originalJwtToken
        if (jwtToken) {
            this.setTokenConfig(jwtToken)
        }

        const cscJwtToken = window.localStorage.getItem(AuthServiceClass.CSC_JWT)
        if (cscJwtToken) this.setCscTokenConfig(cscJwtToken)

        const mswUrl = window.localStorage.getItem(AuthServiceClass.MSW_CSC_URL)
        if (mswUrl) this.authStore.setMswUrl(mswUrl)
    }

    public setHistory(history: History): void {
        this.history = history
    }

    public setLogin(jwtToken: string): void {
        if (!jwtToken) return

        window.localStorage.setItem(AuthServiceClass.JWT_KEY, jwtToken)
        this.setTokenConfig(jwtToken)
    }

    public setCscLogin(jwtToken: string): void {
        if (!jwtToken) return

        window.localStorage.setItem(AuthServiceClass.CSC_JWT, jwtToken)
        this.setCscTokenConfig(jwtToken)
    }

    public setImpersonateLogin(jwtToken: string) {
        if (!jwtToken) return

        window.localStorage.setItem(AuthServiceClass.IMPERSONATE_JWT_KEY, jwtToken)
        this.setTokenConfig(jwtToken)
        queryClient.clear()
    }

    public logout(inactive?: boolean): void {
        window.localStorage.clear()
        queryClient.clear()
        this.authStore.clear()

        const params = new URLSearchParams()

        if (inactive) params.set("inactive", "true")

        this.history.push(`${ROUTE.LOGIN}?${params.toString()}`)
    }

    public logoutOfAllTabs(inactive?: boolean): void {
        // Note: This will logout the current tab
        this.logout(inactive)
        //Note: This will broadcast a message to all other open tabs of banyan app to logout.
        this.broadcastChannelService.broadcastMessage({ type: "logout", payload: inactive })
    }

    public setTokenConfig(jwtToken: string): void {
        try {
            JSON.parse(atob(jwtToken.split(".")[1]))
            this.authStore.setJwtToken(jwtToken)
        } catch {
            this.logoutOfAllTabs()
        }
    }

    public setCscTokenConfig(jwtToken: string): void {
        try {
            JSON.parse(atob(jwtToken.split(".")[1]))
            this.authStore.setCscJwtToken(jwtToken)
        } catch (error) {
            console.error(error)
        }
    }

    public getLoginUrl(): string {
        return this.loginUrl
    }

    public setLoginUrl(url: string): void {
        this.loginUrl = url
    }

    public impersonateFromMomConsole(jwtToken: string): void {
        this.setImpersonateLogin(jwtToken)
        window.localStorage.setItem(AuthServiceClass.PARENT_CONSOLE, AppConsole.MOM)
    }

    public isImpersonatedActive() {
        return !!window.localStorage.getItem(AuthServiceClass.IMPERSONATE_JWT_KEY)
    }

    public unImpersonate() {
        window.localStorage.removeItem(AuthServiceClass.IMPERSONATE_JWT_KEY)
        window.localStorage.removeItem(AuthServiceClass.PARENT_CONSOLE)

        const jwtToken = window.localStorage.getItem(AuthServiceClass.JWT_KEY)
        if (jwtToken) {
            this.setTokenConfig(jwtToken)
        } else {
            this.logoutOfAllTabs()
        }
    }

    public masqueradeMspAdmin(orgId: string): Promise<void> {
        return this.mspOrgApi.masqueradeMspAdmin(orgId).then((res) => {
            this.setImpersonateLogin(res)
            window.localStorage.setItem(AuthServiceClass.PARENT_CONSOLE, AppConsole.MSP)
        })
    }

    public getParentConsole() {
        return (
            (window.localStorage.getItem(AuthServiceClass.PARENT_CONSOLE) as AppConsole | null) ||
            undefined
        )
    }

    public setMswLogin(jwtToken: string, cscAuthToken: string, redirectUrl?: string) {
        this.setLogin(jwtToken)
        this.setCscLogin(cscAuthToken)
        if (redirectUrl) this.setMswUrl(redirectUrl)
    }

    public setMswImpersonatedLogin(
        jwtToken: string,
        cscAuthToken: string,
        childOrgId: string,
        redirectUrl?: string
    ): Promise<void> {
        this.setMswLogin(jwtToken, cscAuthToken, redirectUrl)
        return this.masqueradeMspAdmin(childOrgId)
    }

    private setMswUrl(url: string) {
        const updatedUrl = replacePathname(url, "/muir/spog/login")
        const mswUrl = this.authStore.setMswUrl(updatedUrl)
        if (!mswUrl) return
        window.localStorage.setItem(AuthServiceClass.MSW_CSC_URL, mswUrl)
    }

    public getMswCscUrl() {
        return this.authStore.getMswUrl()
    }

    public getCscAccessToken(): string {
        return this.authStore.getCscJwtToken()
    }

    private authStore: AuthStore = new AuthStore()
    private broadcastChannelService: BroadcastChannelService
    private history: History
    private mspOrgApi: MspOrgApi = new MspOrgApi()

    private loginUrl: string = ROUTE.ROOT
}

export type AuthService = AuthServiceClass

export function useAuthService(): AuthService {
    const broadcastChannelService = useBroadcastChannelService()
    const history = useHistory()
    const authService = React.useMemo(() => new AuthServiceClass(broadcastChannelService), [])

    React.useEffect(() => {
        authService.setHistory(history)
    }, [history])

    return authService
}
