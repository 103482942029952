import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import * as qs from "query-string"

export function isSonicWallDomain(url: string) {
    let domain = new URL(url).hostname.split(".").slice(-2).join(".")
    return domain === getSonicWallDomain()
}

export function getSonicWallDomain() {
    return process.env.SONIC_WALL_DOMAIN || "sonicwall.com"
}

export function setQueryParmaToUrl(url: string, key: string, value: string): string {
    const Url = new URL(url)
    Url.searchParams.set(key, value)
    return Url.toString()
}

export function replacePathname(url: string, newPathname: string) {
    const Url = new URL(url)
    Url.pathname = newPathname
    return Url.toString()
}

export function base64Decode(base64: string): string | undefined {
    try {
        return atob(base64)
    } catch (error) {
        console.error(error)
    }
}

export function getUrlDomain(url: string): string | undefined {
    try {
        const Url = new URL(url)
        return Url.hostname
    } catch (error) {
        console.error(error, { url })
    }
}

export function useGetRouterSearchParams<T = Record<string, string>>(): T {
    const { search } = useLocation()
    return useMemo(() => qs.parse(search) as T, [search])
}
