import React, { useEffect, ReactNode } from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../../../components/button/Button.component"
import { LanguageKey } from "../../../../../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../../../../../pre-v3/services/localization/Localization.service"
import ActionBarService, { IconType } from "../../../../../../../pre-v3/services/ActionBar.service"
import {
    NewTrustProfile,
    NewTrustProfileDetail,
} from "../../../../../../services/TrustProfile.service"
import { noDeviceOwnership } from "../../../../../../services/shared/DeviceOwnership"
import { noPlatforms } from "../../../../../../services/shared/Platform"
import { Accordion } from "../../../../../../components/accordion/Accordion.component"
import { ErrorBanners } from "../../../../../../components/banner/Banner.component"
import { Form } from "../../../../../../components/form/Form.component"
import { FormButtons } from "../../../../../../components/form/form-buttons/FormButtons.component"
import { DescriptionInput } from "./DescriptionInput.component"
import { DeviceOwnershipInput } from "./DeviceOwnershipInput.component"
import { ManagedByMdmToggle } from "./ManagedByMdmToggle.component"
import { NameInput } from "./NameInput.component"
import { OperatingSystemsInput } from "./OperatingSystemsInput.component"
import { SerialNumbersInput } from "./SerialNumbersInput.component"
import { UserGroupsInput } from "./UserGroupsInput.component"
import styles from "./DetailsAndAssignmentForm.module.scss"

interface Props<TrustProfileDetail extends NewTrustProfileDetail> {
    initialValue?: TrustProfileDetail
    disabled?: boolean
    enableReset?: boolean
    onSubmit(formValues: NewTrustProfile): void
    onCancel(): void
    errors?: ReactNode[]
    submitText?: string
}

export function DetailsAndAssignmentForm<TrustProfileDetail extends NewTrustProfileDetail>(
    props: Props<TrustProfileDetail>
): JSX.Element {
    const { initialValue, disabled, enableReset, errors = [], submitText } = props

    const actionBarService = new ActionBarService()
    const localization = useServiceLocalization()

    const initialValues = {
        name: initialValue?.name ?? "",
        description: initialValue?.description ?? "",
        userGroups: initialValue?.assignment.userGroups ?? [],
        serialNumbers: initialValue?.assignment.serialNumbers ?? [],
        appliedPlatform: initialValue?.appliedPlatform ?? noPlatforms,
        isManagedByMdm: initialValue?.assignment.isManagedByMdm ?? false,
        deviceOwnership: initialValue?.assignment.deviceOwnership ?? noDeviceOwnership,
    }

    const [formError, setFormError] = React.useState("")

    const [name, setName] = React.useState(initialValues.name)
    const [description, setDescription] = React.useState(initialValues.description)
    const [userGroups, setUserGroups] = React.useState(initialValues.userGroups)
    const [serialNumbers, setSerialNumbers] = React.useState(initialValues.serialNumbers)
    const [appliedPlatform, setAppliedOs] = React.useState(initialValues.appliedPlatform)
    const [isManagedByMdm, setIsManagedByMdm] = React.useState(initialValues.isManagedByMdm)
    const [deviceOwnership, setDeviceOwnership] = React.useState(initialValues.deviceOwnership)

    const onChangeName: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        setName(event.target.value)
    }
    const onChangeDescription: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        setDescription(event.target.value)
    }
    const onManagedByMdmToggle = () => setIsManagedByMdm((isManagedByMdm) => !isManagedByMdm)

    useEffect(() => {
        if (enableReset) {
            actionBarService.setActions([
                {
                    icon: IconType.REDO,
                    tooltip: localization.getString("refresh"),
                    onClick: reset,
                },
            ])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enableReset])

    function reset() {
        setName(initialValues.name)
        setDescription(initialValues.description)
        setUserGroups(initialValues.userGroups)
        setSerialNumbers(initialValues.serialNumbers)
        setAppliedOs(initialValues.appliedPlatform)
        setIsManagedByMdm(initialValues.isManagedByMdm)
        setDeviceOwnership(initialValues.deviceOwnership)
    }

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        setFormError("")

        const formValues: NewTrustProfile = {
            name,
            description,
            appliedPlatform,
            assignment: { userGroups, serialNumbers, isManagedByMdm, deviceOwnership },
        }

        if (!hasAssignment(formValues)) {
            const assignments = assignmentLabels.map((key) => localization.getString(key))
            const formatter = new Intl.ListFormat(localization.getLocale(), { type: "disjunction" })
            return setFormError(
                localization.getString("chooseAtLeastOneAssignment", formatter.format(assignments))
            )
        }

        props.onSubmit(formValues)
    }

    function onCancel(event: React.MouseEvent): void {
        event.preventDefault()
        reset()

        props.onCancel()
    }

    return (
        <Form className={styles.form} onSubmit={onSubmit}>
            <Accordion label={localization.getString("generalInformation")} type="plain" open>
                <div className={styles.accordionContent}>
                    <NameInput
                        id="trustProfileName"
                        disabled={disabled}
                        value={name}
                        onChange={onChangeName}
                    />
                    <DescriptionInput
                        id="trustProfileDescription"
                        disabled={disabled}
                        value={description}
                        onChange={onChangeDescription}
                    />
                </div>
            </Accordion>
            <Accordion label={localization.getString("deviceDetails")} type="plain" open>
                <div className={styles.accordionContent}>
                    <UserGroupsInput
                        values={userGroups}
                        disabled={disabled}
                        onChange={setUserGroups}
                        caseInsensitiveDuplicateMatch={false}
                    />
                    <SerialNumbersInput
                        values={serialNumbers}
                        disabled={disabled}
                        onChange={setSerialNumbers}
                        caseInsensitiveDuplicateMatch={false}
                    />
                    <OperatingSystemsInput
                        disabled={disabled}
                        appliedPlatform={appliedPlatform}
                        onAppliedOsChange={setAppliedOs}
                    />
                    <ManagedByMdmToggle
                        disabled={disabled}
                        isManagedByMdm={isManagedByMdm}
                        onManagedByMdmToggle={onManagedByMdmToggle}
                    />
                    <DeviceOwnershipInput
                        disabled={disabled}
                        deviceOwnership={deviceOwnership}
                        onDeviceOwnershipChange={setDeviceOwnership}
                    />
                </div>
            </Accordion>
            {!disabled && (
                <FormButtons
                    leftButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            onClick={onCancel}
                        >
                            {localization.getString("cancel")}
                        </Button>
                    }
                    rightButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            type="submit"
                        >
                            {submitText || localization.getString("saveChanges")}
                        </Button>
                    }
                >
                    <ErrorBanners errors={[formError, ...errors]} />
                </FormButtons>
            )}
        </Form>
    )
}

const assignmentLabels: LanguageKey[] = [
    "userGroups",
    "serialNumbers",
    "operatingSystems",
    "mdmManagedDevices",
    "deviceOwnership",
]

function hasAssignment({ appliedPlatform, assignment }: NewTrustProfile): boolean {
    return (
        assignment.isManagedByMdm ||
        assignment.userGroups.length > 0 ||
        assignment.serialNumbers.length > 0 ||
        Object.values(assignment.deviceOwnership).some(identity) ||
        Object.values(appliedPlatform).some(identity)
    )
}

function identity<T>(value: T): T {
    return value
}
