import { FileInfo } from "../v3/services/MomOrgManagement.service"

export function downloadLink(url: string): void {
    const anchorElement = document.createElement("a")
    document.body.appendChild(anchorElement)
    anchorElement.href = url
    anchorElement.download = ""
    anchorElement.click()
    document.body.removeChild(anchorElement)
}

export const downloadMultipleFile = (files: FileInfo[]) => {
    function downloadNext(current: number) {
        if (current >= files.length) {
            return
        }

        const anchor = document.createElement("a")
        anchor.href = files[current].url
        anchor.download = files[current].filename
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)

        setTimeout(
            function () {
                downloadNext(current + 1)
            },
            (current + 1) * 1000
        )
    }

    downloadNext(0)
}
