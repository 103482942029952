import React, { ReactNode, useEffect, useState } from "react"
import { SuccessBanner } from "../../../../pre-v3/components/banner/Banner.component"
import { LocalizationService } from "../../../../pre-v3/services"
import ActionBarService from "../../../../pre-v3/services/ActionBar.service"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { downloadLink } from "../../../../utils/Download.utils"
import { AppText } from "../../../components/app-text/AppText.component"
import {
    ErrorBanner,
    ErrorBanners,
    WarningBanner,
} from "../../../components/banner/Banner.component"
import { Button } from "../../../components/button/Button.component"
import { Container } from "../../../components/container/Container.component"
import { Loader } from "../../../components/loader/Loader.component"
import {
    DnsReport as DnsReportType,
    DnsReportStatus as DnsReportStatusType,
    useGetDnsReport,
    useGenerateDnsReport,
} from "../../../services/ItpPolicy.service"
import styles from "./DnsReportTab.module.scss"

interface Props {
    canGenerateReport?: boolean
}
export function DnsReport(props: Props) {
    const actionBarService = new ActionBarService()

    const {
        isFetching: isDnsReportLoading,
        data: dnsReport,
        error: dnsReportError,
        isError: isDnsReportError,
        refetch: refetchDnsReport,
    } = useGetDnsReport()

    const {
        isLoading: isGenerateDnsReportLoading,
        error: generateDnsReportError,
        isError: isGenerateDnsReportError,
        mutateAsync: generateDnsReport,
    } = useGenerateDnsReport()

    const isLoading = isDnsReportLoading || isGenerateDnsReportLoading
    const isError = isDnsReportError || isGenerateDnsReportError

    useEffect(() => {
        actionBarService.registerRefreshFn(refetchDnsReport)

        return () => {
            actionBarService.unregisterRefreshFn(refetchDnsReport)
        }
    }, [])
    return (
        <Loader isLoading={isLoading} medium center>
            <Container className={styles.container}>
                <AppText className={styles.title} ls={"itpReport"} />
                <ErrorBanners
                    className={styles.errorBanners}
                    errors={[
                        dnsReportError ? String(dnsReportError) : null,
                        generateDnsReportError ? String(generateDnsReportError) : null,
                    ]}
                />
                {!isError && (
                    <DnsReportStatus
                        dnsReport={dnsReport!}
                        generateDnsReport={() => generateDnsReport()}
                        canGenerateReport={props.canGenerateReport}
                    />
                )}
            </Container>
        </Loader>
    )
}

interface DnsReportStatusProps {
    dnsReport: DnsReportType
    generateDnsReport: () => void
    canGenerateReport?: boolean
}

function DnsReportStatus(props: DnsReportStatusProps) {
    const localization = new LocalizationService()

    const { dnsReport, generateDnsReport } = props

    const [status, setStatus] = useState<DnsReportStatusTypeWithExpired>(getStatus)

    function downloadDnsFilter(): void {
        downloadLink(dnsReport.url)
    }

    function getStatus(): DnsReportStatusTypeWithExpired {
        if (dnsReport.status === "success") {
            if (isReportExpired().expired) {
                return "expired"
            }
        }

        return dnsReport.status
    }

    function isReportExpired(): { expired: boolean; msToExpiry: number } {
        const requestTime = new Date(DateUtil.convertLargeTimestamp(dnsReport.requestTimestamp))
        const expiryTime = new Date(new Date(requestTime).setDate(requestTime.getDate() + 14))

        const expired = DateUtil.isExpired(expiryTime.getTime())
        const msToExpiry = expired ? 0 : DateUtil.timeUntil(expiryTime.getTime())

        return { expired, msToExpiry }
    }

    useEffect(() => {
        const { expired, msToExpiry } = isReportExpired()

        if (!expired) {
            const expirSetTimeout = setTimeout(() => {
                setStatus("expired")
            }, msToExpiry)

            return () => {
                return clearTimeout(expirSetTimeout)
            }
        }
    }, [])

    switch (status) {
        case "success": {
            return (
                <>
                    <DNSReportRow label={localization.getString("status")}>
                        <SuccessBanner>
                            {localization.getString("theItpReportHasBeenGeneratedSuccessfully")}
                        </SuccessBanner>
                    </DNSReportRow>
                    <DNSReportRow label={localization.getString("requestedAt")}>
                        <p className={styles.time}>
                            {DateUtil.formatLargeTimestamp(dnsReport.requestTimestamp)}
                        </p>
                    </DNSReportRow>
                    <DNSReportRow label={localization.getString("generatedAt")}>
                        <p className={styles.time}>
                            {DateUtil.formatLargeTimestamp(dnsReport.successTimestamp)}
                        </p>
                    </DNSReportRow>
                    <div className={styles.buttonGroup}>
                        <Button onClick={downloadDnsFilter}>
                            {localization.getString("download")}
                        </Button>
                        <Button
                            brand
                            onClick={generateDnsReport}
                            disabled={!props.canGenerateReport}
                        >
                            {localization.getString("regenerate")}
                        </Button>
                    </div>
                </>
            )
        }

        case "error": {
            return (
                <>
                    <DNSReportRow label="status">
                        <ErrorBanner>
                            {localization.getString("failedToGenerateItpReport")}
                        </ErrorBanner>
                    </DNSReportRow>
                    <div className={styles.buttonGroup}>
                        <Button
                            className={styles.button}
                            brand
                            onClick={generateDnsReport}
                            disabled={!props.canGenerateReport}
                        >
                            {localization.getString("regenerate")}
                        </Button>
                    </div>
                </>
            )
        }

        case "no_status":
        case "expired": {
            return (
                <div className={styles.buttonGroup}>
                    <Button brand onClick={generateDnsReport} disabled={!props.canGenerateReport}>
                        {localization.getString("generate")}
                    </Button>
                </div>
            )
        }

        case "pending": {
            return (
                <>
                    <DNSReportRow label="status">
                        <WarningBanner>
                            {localization.getString("generatingItpReport")}
                        </WarningBanner>
                    </DNSReportRow>
                    <DNSReportRow label={localization.getString("requestedAt")}>
                        <p className={styles.time}>
                            {DateUtil.formatLargeTimestamp(dnsReport.requestTimestamp)}
                        </p>
                    </DNSReportRow>
                </>
            )
        }

        default: {
            console.error("Error: DnsReport, this status is not found", status)
            return <></>
        }
    }
}

interface DNSReportRowProps {
    children: ReactNode
    label: string
}

function DNSReportRow(props: DNSReportRowProps) {
    const { children, label } = props

    return (
        <div>
            <p className={styles.headerText}>{label}</p>
            {children}
        </div>
    )
}

type DnsReportStatusTypeWithExpired = DnsReportStatusType | "expired"
