import classNames from "classnames/bind"
import React from "react"

import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { useServiceLinks } from "../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { UrlUtil, encodeID } from "../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../routes"
import { AppText } from "../../../v3/components/app-text/AppText.component"
import { Badge } from "../../../v3/components/badge/Badge.component"
import { FormButtons } from "../../../v3/components/form/form-buttons/FormButtons.component"
import { Form } from "../../../v3/components/form/Form.component"
import { Loader } from "../../../v3/components/loader/Loader.component"
import { Organization } from "../../../v3/services/shared/Organization"
import {
    AccessTierDetails,
    InstallationMethod,
    Status,
    useGetAccessTierStatus,
    useGetLatestNetagentVersion,
} from "../../../v3/services/AccessTier.service"
import { CloudFormationInstaller } from "../../../v3/views/access-tiers/create/installation-methods/CloudFormationInstaller.component"
import { DebianInstaller } from "../../../v3/views/access-tiers/create/installation-methods/DebianInstaller.component"
import { DockerInstaller } from "../../../v3/views/access-tiers/create/installation-methods/DockerInstaller.component"
import { TarballInstaller } from "../../../v3/views/access-tiers/create/installation-methods/TarballInstaller.component"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { ErrorToast, ToastApi } from "../../../components/toast/Toast.components"
import { AccessTierInstallationMethod } from "../shared/AccessTierInstallationMethod.component"
import { AccessTierInstallationInstruction } from "../shared/AccessTierInstallationInstruction.component"
import styles from "./AccessTierInstall.module.scss"

interface Props {
    accessTier: AccessTierDetails
    organization: Organization
    onContinue(): void
    onBack(): void
    onChange(accessTier: AccessTierDetails): void
    canAccessAccessTierInstallStep: boolean
}

export function AccessTierInstallTab(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const errorToastRef = React.useRef<ToastApi>(null)

    const {
        data: latestNetagentVersion,
        status: latestVersionStatus,
        error: latestVersionError,
    } = useGetLatestNetagentVersion()

    const isAccessTierReporting = props.accessTier.status === Status.REPORTING

    const { isFetching: isAccessTierLoading } = useGetAccessTierStatus(props.accessTier, {
        enabled: !isAccessTierReporting,
        refetchInterval: 10_000,
        onSuccess: (status) => props.onChange({ ...props.accessTier, status }),
        onError: (error) =>
            typeof error === "string"
                ? errorToastRef.current?.openToast(error)
                : console.error(error),
    })

    if (latestVersionStatus === "loading") {
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("netagentDetails")
                )}
            />
        )
    }

    if (latestVersionStatus === "error") {
        return <Banner label={String(latestVersionError)} variant={Variant.ERROR} />
    }

    const onInstallationMethodChange = (installationMethod: InstallationMethod) =>
        props.onChange({
            ...props.accessTier,
            installationMethod,
        })

    const onBack: React.MouseEventHandler = (event) => {
        event.preventDefault()
        props.onBack()
    }

    const onSubmit: React.FormEventHandler = (event) => {
        event.preventDefault()
        props.onContinue()
    }

    return (
        <Form className={styles.form} onSubmit={onSubmit}>
            <AccessTierInstallationMethod
                installationMethod={props.accessTier.installationMethod}
                onInstallationMethodChange={onInstallationMethodChange}
            />
            <InstallationInstructions
                accessTier={props.accessTier}
                organization={props.organization}
                latestNetagentVersion={latestNetagentVersion}
                canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
            />
            <FormButtons
                leftButtons={
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        onClick={onBack}
                    >
                        {localization.getString("back")}
                    </Button>
                }
                rightButtons={
                    <>
                        <Button
                            asElement={ButtonElement.LINK}
                            to={formatRoutePath(ROUTE.ACCESS_TIERS_DETAILS, {
                                id: encodeID(props.accessTier.id),
                            })}
                            buttonType={ButtonType.SECONDARY}
                        >
                            {localization.getString("saveAndExit")}
                        </Button>
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            type="submit"
                            loading={!isAccessTierReporting || isAccessTierLoading}
                        >
                            {localization.getString("continue")}
                        </Button>
                    </>
                }
            >
                <ErrorToast ref={errorToastRef} />
                {isAccessTierReporting ? (
                    <Banner
                        label={
                            <AppText
                                ls={"accessTierSuccessfullyInstalledMessage"}
                                className={styles.banner}
                            />
                        }
                        variant={Variant.SUCCESS}
                    />
                ) : (
                    <Banner
                        label={
                            <AppText
                                ls={"continueWhenAccessTierIsReportingMessage"}
                                className={styles.banner}
                            />
                        }
                        variant={Variant.WARNING}
                    />
                )}
            </FormButtons>
        </Form>
    )
}

interface InstallationInstructionsProps {
    accessTier: AccessTierDetails
    organization: Organization
    latestNetagentVersion: string
    canAccessAccessTierInstallStep: boolean
}

function InstallationInstructions(props: InstallationInstructionsProps): JSX.Element {
    const linkService = useServiceLinks()
    const localization = useServiceLocalization()

    const bulletList: string = localization.getString(
        "serverOutBoundConnectionList",
        props.accessTier.cluster.shieldAddress,
        props.organization.name,
        linkService.getLink("cseCluster"),
        linkService.getLink("cseCommandCenter"),
        linkService.getLink("cseComponents"),
        UrlUtil.getBackendUrl().origin
    )

    switch (props.accessTier.installationMethod) {
        case InstallationMethod.DOCKER_CONTAINER:
            return (
                <AccessTierInstallationInstruction
                    title={localization.getString(
                        "installingAccessTierUsing",
                        localization.getString("dockerContainer")
                    )}
                >
                    <GenericPreReqCheckList
                        bulletList={bulletList}
                        udpPortNumber={props.accessTier.udpPortNumber}
                    />
                    <DockerInstaller
                        apiSecretKey={props.accessTier.apiKey.secret}
                        accessTierName={props.accessTier.name}
                        hideDoc
                        netagentVersion={props.latestNetagentVersion}
                        udpPortNumber={props.accessTier.udpPortNumber}
                        canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
                    />
                </AccessTierInstallationInstruction>
            )

        case InstallationMethod.TARBALL_INSTALLER:
            return (
                <AccessTierInstallationInstruction
                    title={localization.getString(
                        "installingAccessTierUsing",
                        localization.getString("tarballInstaller")
                    )}
                >
                    <GenericPreReqCheckList
                        bulletList={bulletList}
                        udpPortNumber={props.accessTier.udpPortNumber}
                    />
                    <TarballInstaller
                        apiSecretKey={props.accessTier.apiKey.secret}
                        accessTierName={props.accessTier.name}
                        hideDoc
                        netagentVersion={props.latestNetagentVersion}
                        canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
                    />
                </AccessTierInstallationInstruction>
            )

        case InstallationMethod.DEBIAN_PACKAGE:
            return (
                <AccessTierInstallationInstruction
                    title={localization.getString(
                        "installingAccessTierUsing",
                        localization.getString("debianPackage")
                    )}
                >
                    <GenericPreReqCheckList
                        bulletList={bulletList}
                        udpPortNumber={props.accessTier.udpPortNumber}
                    />
                    <DebianInstaller
                        apiSecretKey={props.accessTier.apiKey.secret}
                        accessTierName={props.accessTier.name}
                        hideDoc
                        canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
                    />
                </AccessTierInstallationInstruction>
            )

        case InstallationMethod.CLOUD_FORMATION:
            return (
                <AccessTierInstallationInstruction
                    title={localization.getString(
                        "installingAccessTierUsing",
                        localization.getString("cloudFormation")
                    )}
                >
                    <AwsPreReqCheckList
                        bulletList={bulletList}
                        udpPortNumber={props.accessTier.udpPortNumber}
                    />
                    <CloudFormationInstaller
                        apiSecretKey={props.accessTier.apiKey.secret}
                        accessTierName={props.accessTier.name}
                        hideDoc
                        canAccessAccessTierInstallStep={props.canAccessAccessTierInstallStep}
                    />
                </AccessTierInstallationInstruction>
            )
    }
}

interface PreReqCheckListProps {
    bulletList: string
    udpPortNumber: number
}

function GenericPreReqCheckList(props: PreReqCheckListProps) {
    const localization = useServiceLocalization()

    const genericCheckList: string[] = [
        localization.getString("adminAccessToServerDesc"),
        localization.getString("serverOutboundConnectionDesc"),
        localization.getString("serverInboundConnectionDesc"),
    ]

    return (
        <div className={styles.preReqCheckList}>
            <p className={styles.subTitle}>{localization.getString("preRequirementChecklist")}</p>
            <ul className={styles.list}>
                {genericCheckList.map((item, index) => (
                    <li key={item}>
                        <div className={styles.checkList}>
                            <Badge number={index + 1} warning />
                            <AppText className={styles.preReqLabel}>{item}</AppText>
                        </div>
                        {item === localization.getString("serverOutboundConnectionDesc") && (
                            <AppText className={styles.bulletList}>{props.bulletList}</AppText>
                        )}
                    </li>
                ))}
                <InboundConnectionTable udpPortNumber={props.udpPortNumber} />
            </ul>
        </div>
    )
}

function AwsPreReqCheckList(props: PreReqCheckListProps) {
    const linkService = useServiceLinks()
    const localization = useServiceLocalization()

    const awsCheckList: string[] = [
        localization.getString("iHaveAwsAccountAndPermissionDesc"),
        localization.getString(
            "iHaveAccessToSonicWallCseInstallerDesc",
            linkService.getLink("cseInstallerGithubRepo")
        ),
        localization.getString("serverOutboundConnectionDesc"),
        localization.getString("securityGroupInboundConnectionDesc"),
    ]

    return (
        <div className={styles.preReqCheckList}>
            <p className={styles.subTitle}>{localization.getString("preRequirementChecklist")}</p>
            <ul className={styles.list}>
                {awsCheckList.map((item, index) => (
                    <li key={item}>
                        <div className={styles.checkList}>
                            <Badge number={index + 1} warning />
                            <AppText className={styles.preReqLabel}>{item}</AppText>
                        </div>
                        {item === localization.getString("serverOutboundConnectionDesc") && (
                            <AppText className={styles.bulletList}>{props.bulletList}</AppText>
                        )}
                    </li>
                ))}
                <InboundConnectionTable udpPortNumber={props.udpPortNumber} />
            </ul>
        </div>
    )
}

interface InboundConnectionTableProps {
    udpPortNumber: number
}

function InboundConnectionTable(props: InboundConnectionTableProps): JSX.Element {
    const localization = useServiceLocalization()

    const tcpProtocol = localization.getString("tcp")

    return (
        <div>
            <table className={styles.table}>
                <thead>
                    <tr className={styles.tr}>
                        <th className={classNames(styles.text, styles.nameColumn)}>
                            {localization.getString("ingressPort")}
                        </th>
                        <th className={classNames(styles.text, styles.nameColumn)}>
                            {localization.getString("protocol")}
                        </th>
                        <th className={classNames(styles.text, styles.nameColumn)}>
                            {localization.getString("traffic")}
                        </th>
                        <th className={classNames(styles.text, styles.nameColumn)}>
                            {localization.getString(
                                "usedByFollowingTypesOfServicesViaSonicWallCse"
                            )}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <TableRow
                        ingressPort={80}
                        protocol={tcpProtocol}
                        traffic={localization.getString("http")}
                        serviceType={localization.getString("notApplicableWillBeRedirectedToHTTPS")}
                    />
                    <TableRow
                        ingressPort={443}
                        protocol={tcpProtocol}
                        traffic={localization.getString("https")}
                        serviceType={localization.getString("hostedWebsites")}
                    />
                    <TableRow
                        ingressPort={8443}
                        protocol={tcpProtocol}
                        traffic={localization.getString("mTls")}
                        serviceType={localization.getString("infrastructureServicesWithTypes")}
                    />
                    <TableRow
                        ingressPort={props.udpPortNumber}
                        protocol={localization.getString("udp")}
                        traffic={localization.getString("wireguard")}
                        serviceType={localization.getString("serviceTunnel")}
                    />
                </tbody>
            </table>
        </div>
    )
}

interface TableRowProps {
    ingressPort: number
    protocol: string
    traffic: string
    serviceType: string
}

function TableRow(props: TableRowProps): JSX.Element {
    return (
        <tr className={styles.tr}>
            <td className={styles.columnColor}>{props.ingressPort}</td>
            <td>{props.protocol}</td>
            <td>{props.traffic}</td>
            <td>{props.serviceType}</td>
        </tr>
    )
}
