import {
    QueryKey,
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"

import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import {
    LicenseUsageRes,
    OrgApi,
    OrgDetailsRes,
    LicenseInformationRes,
    LicenseTypeRes,
    OrgTypeRes,
} from "../api/Org.api"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import { Edition, editionResMap } from "./shared/Edition"
import { LicenseLevel } from "./shared/LicenseInformation"

@Singleton("OrgService")
export class OrgService {
    public async getOrgInfo(): Promise<OrgInfo> {
        const [userOrgDetails, orgDetails, orgLicenses] = await Promise.all([
            this.orgApi.getUserOrgDetails(),
            this.orgApi.getOrgDetails(),
            this.orgApi.getLicenseInformation(),
        ])

        let licenseCounts: LicenseUsageRes | null | undefined
        if (orgDetails) licenseCounts = await this.orgApi.getOrgLicensesUsage()

        return {
            ...getLicenseEdition(orgDetails, orgLicenses, licenseCounts ?? undefined),
            id: orgDetails.OrgID,
            lastUpdatedAt: userOrgDetails.LastUpdatedAt,
            lastUpdatedBy: userOrgDetails.LastUpdatedBy,
            orgName: orgDetails.OrgName,
            isBanyanIdp: orgDetails.IDPName === "BANYAN",
            isDnsFilterEnabled: orgDetails.IsDNSFilterEnabled,
            isGranularTrustEnabled: orgDetails.IsGranularTrustEnabled,
            createdAt: DateUtil.convertLargeTimestamp(orgDetails.CreatedAt),
            orgType: orgTypeDic[orgDetails.OrgType],
            isAiAssistedAdminSearchEnabled: userOrgDetails.IsAIAssistEnabled,
            isMspOrg: orgDetails.IsMSPOrg,
            isScimEnabled: userOrgDetails.IsSCIMEnabled ?? false,
            isGeolocationEnabled: orgDetails.IsGeoLocationEnabled ?? false,
        }
    }

    public getAdminInfo(): Promise<AdminInfo> {
        return this.orgApi.getUserOrgDetails().then((userOrgDetails) => {
            return {
                id: userOrgDetails.ID,
                firstName: userOrgDetails.First,
                lastName: userOrgDetails.Last,
                email: userOrgDetails.Email,
                canWriteAll: ["Admin", "Owner", "SuperAdmin"].includes(userOrgDetails.Profile),
                canWritePolicies: ["Admin", "Owner", "SuperAdmin", "PolicyAuthor"].includes(
                    userOrgDetails.Profile
                ),
                canWriteServices: ["Admin", "Owner", "SuperAdmin", "ServiceAuthor"].includes(
                    userOrgDetails.Profile
                ),
                canWriteRoles: ["Admin", "Owner", "SuperAdmin", "PolicyAuthor"].includes(
                    userOrgDetails.Profile
                ),
            }
        })
    }

    public isBanyanIdp(): Promise<boolean> {
        return this.orgApi.getOrgDetails().then((res) => {
            return res.IDPName === "BANYAN"
        })
    }

    public async hasGranularTrustMigrationOcurred(): Promise<boolean> {
        const { IsGranularTrustEnabled } = await this.orgApi.getOrgDetails()
        return IsGranularTrustEnabled
    }

    public migrateToGranularTrust(): Promise<void> {
        return this.orgApi.patchOrgSettings({ IsGranularTrustEnabled: "TRUE" })
    }

    private orgApi: OrgApi = new OrgApi()
}

enum OrgHookKey {
    GET_CUSTOMIZATION = "orgService.getCustomization",
}

const getCustomizationKey: QueryKey = [OrgHookKey.GET_CUSTOMIZATION]

export function useGetCustomization(
    options?: QueryOptions<Customization>
): UseQueryResult<Customization> {
    const orgApi = new OrgApi()

    return useQuery({
        ...options,
        queryKey: getCustomizationKey,
        queryFn: async (): Promise<Customization> => {
            const orgCustomizations = await orgApi.getOrgCustomizations()

            return {
                logoFile: orgCustomizations.branding_logo || undefined,
                primaryColor: orgCustomizations.branding_background_color || undefined,
                appSupportMessage: orgCustomizations.support_message || undefined,
                appSupportLink: orgCustomizations.support_link_url || undefined,
                appSupportTitle: orgCustomizations.support_link_title || undefined,
                errorMessage: orgCustomizations.error_support_message || undefined,
                errorLink: orgCustomizations.error_support_link_url || undefined,
                errorTitle: orgCustomizations.error_support_link_title || undefined,
            }
        },
    })
}

export function useSetCustomization(
    options?: QueryOptions<void, unknown, Customization>
): UseMutationResult<void, unknown, Customization> {
    const orgApi = new OrgApi()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: (customization: Customization): Promise<void> =>
            orgApi.patchOrgSettings({
                BrandingLogo: customization.logoFile ?? "",
                BrandingBackgroundColor: customization.primaryColor ?? "",
                SupportMessage: customization.appSupportMessage ?? "",
                SupportLinkURL: customization.appSupportLink ?? "",
                ErrorSupportMessage: customization.errorMessage ?? "",
                ErrorSupportLinkURL: customization.errorLink ?? "",
            }),
        onSuccess: (_data, customization) => {
            options?.onSuccess?.()
            queryClient.setQueryData(getCustomizationKey, customization)
        },
    })
}

export type Status = "success" | "partialSuccess" | "error" | "inProgress" | "unknown"

export interface OrgInfoBase {
    id: string
    lastUpdatedAt: number
    lastUpdatedBy: string
    orgName: string
    isBanyanIdp?: boolean
    isDnsFilterEnabled?: boolean
    isGranularTrustEnabled?: boolean
    createdAt?: number
    orgType: OrgType
    isAiAssistedAdminSearchEnabled: boolean
    isMspOrg: boolean
    isScimEnabled?: boolean
    isGeolocationEnabled?: boolean
}

interface OrgInfoWithEdition extends OrgInfoBase {
    licenseType: "edition"
    edition: Edition
    license: undefined
}

export interface OrgInfoWithLicense extends OrgInfoBase {
    licenseType: "license"
    license: LicenseInformation
    edition: undefined
}

export type OrgInfo = OrgInfoWithEdition | OrgInfoWithLicense

export interface AdminInfo {
    id: string
    firstName: string
    lastName: string
    email: string
    canWriteAll: boolean
    canWriteServices: boolean
    canWritePolicies: boolean
    canWriteRoles: boolean
}

export interface Customization {
    logoFile?: string
    primaryColor?: string
    appSupportMessage?: string
    appSupportLink?: string
    appSupportTitle?: string
    errorMessage?: string
    errorLink?: string
    errorTitle?: string
}

export interface LicenseInformation {
    spa: LicenseLevel
    sia: LicenseLevel
    spaCount: number
    siaCount: number
    spaUsageCount: number
    siaUsageCount: number
}

export const licenseTypeMap: Record<LicenseTypeRes, LicenseLevel> = {
    None: LicenseLevel.NONE,
    Basic: LicenseLevel.BASIC,
    Advanced: LicenseLevel.ADVANCED,
}

export enum OrgType {
    PRODUCTION = "production",
    TRIAL = "trial",
    STAGING = "staging",
    INTERNAL_TEMPORARY = "internalTemporary",
    INTERNAL_PERSISTENT = "internalPersistent",
    INTERNAL = "internal",
    TESTING = "testing",
}

const orgTypeDic: Record<OrgTypeRes, OrgType> = {
    Production: OrgType.PRODUCTION,
    Trial: OrgType.TRIAL,
    Staging: OrgType.INTERNAL,
    InternalPersistent: OrgType.INTERNAL_PERSISTENT,
    InternalTemporary: OrgType.INTERNAL_TEMPORARY,
    Internal: OrgType.INTERNAL,
    Testing: OrgType.TESTING,
}

function mapLicenseInformation(
    res: LicenseInformationRes,
    usageRes?: LicenseUsageRes
): LicenseInformation {
    return {
        spa: licenseTypeMap[res.spa],
        sia: licenseTypeMap[res.sia],
        spaCount: res.spa_users,
        siaCount: res.sia_users,
        spaUsageCount: usageRes?.spa_license_count ?? 0,
        siaUsageCount: usageRes?.sia_license_count ?? 0,
    }
}

export function useGetOrgInfo(options?: QueryOptions<OrgInfo>) {
    const orgService = new OrgService()
    return useQuery<OrgInfo, string>({
        ...options,
        queryKey: ["orgService.getOrgInfo"],
        queryFn: orgService.getOrgInfo.bind(orgService),
    })
}

export function useGetAdminInfo(options?: QueryOptions<AdminInfo>) {
    const orgService = new OrgService()
    return useQuery<AdminInfo, string>({
        ...options,
        queryKey: ["orgService.getAdminInfo"],
        queryFn: orgService.getAdminInfo.bind(orgService),
    })
}

export function useIsProfileAdmin() {
    const adminInfo = useGetAdminInfo()
    return {
        ...adminInfo,
        data: adminInfo.data?.canWriteAll,
    }
}

export function useIsTeamEdition() {
    const orgInfo = useGetOrgInfo()
    return {
        ...orgInfo,
        data: orgInfo.data?.edition === Edition.TEAM,
    }
}

export function useHasGranularTrustMigrationOccurred(options?: QueryOptions<OrgInfo>) {
    const orgInfo = useGetOrgInfo(options)
    return {
        ...orgInfo,
        data: !!orgInfo.data?.isGranularTrustEnabled,
    }
}

export function useMigrateToGranularTrust() {
    const orgService = new OrgService()
    const queryClient = useQueryClient()

    return useMutation<void, string, void>({
        mutationFn: () => orgService.migrateToGranularTrust(),
        onSuccess: () => {
            queryClient.removeQueries(["orgService.getOrgInfo"])
        },
    })
}

function getLicenseEdition(
    org: OrgDetailsRes,
    licenseInformationRes: LicenseInformationRes | null,
    licenseUsageRes?: LicenseUsageRes
):
    | Pick<OrgInfoWithLicense, "licenseType" | "license" | "edition">
    | Pick<OrgInfoWithEdition, "licenseType" | "license" | "edition"> {
    if (licenseInformationRes)
        return {
            licenseType: "license",
            license: mapLicenseInformation(licenseInformationRes, licenseUsageRes),
            edition: undefined,
        }

    return {
        licenseType: "edition",
        license: undefined,
        edition: editionResMap[org.Edition],
    }
}
