import React from "react"
import { AccessType } from "./AccessPolicy.types"
import styles from "./PolicyDestinationList.module.scss"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { PolicyDestinationRow } from "./PolicyDestinationRow.component"
import { CollectionUtil } from "../../../pre-v3/utils/Collection.util"
import { emptyDestination } from "./helper"
import { v4 as uuidv4 } from "uuid"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import { L4Rule } from "../../../v3/services/Policy.service"

export interface Props {
    policyDestinations: L4Rule[]
    disabled?: boolean
    disableDelete: boolean
    onDestinationsChange: (destinations: L4Rule[]) => void
    enableRequiredCheck?: boolean
}

export function PolicyDestinationList(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const headersKeys: LanguageKey[] = ["selectType", "protocols", "ports", "notes"]
    const headersLocalization = React.useMemo(
        () => headersKeys.map((header) => localization.getString(header)),
        headersKeys
    )

    // BUG: when changing the access type, with multiple rows, the list is being reset to load until the row that was changed
    // the toggle button for ip ranges / fqdns
    const onDestinationRowChange = (destination: L4Rule, index: number) => {
        const updatedDestinations = [...props.policyDestinations]
        updatedDestinations[index] = destination
        props.onDestinationsChange(updatedDestinations)
    }

    const onDeleteRow = (index: number) => {
        props.onDestinationsChange(
            CollectionUtil.removeValueByIndex(props.policyDestinations, index)
        )
    }

    const onAddRow = () => {
        props.onDestinationsChange(
            CollectionUtil.addValue(props.policyDestinations, {
                ...emptyDestination,
                id: uuidv4(),
            })
        )
    }

    const selectType = (policyDestination: L4Rule) => {
        if (policyDestination.fqdnList && policyDestination.ipRanges) {
            return AccessType.IP_RANGES
        }

        if (policyDestination.ipRanges) {
            return AccessType.IP_RANGES
        } else {
            return AccessType.FQDNS
        }
    }

    return (
        <div className={styles.destinationList}>
            <table className={styles.destinationTable}>
                <PolicyDestinationListHeader headers={headersLocalization} />

                <tbody>
                    {props.policyDestinations.map((destination, index) => {
                        return (
                            <PolicyDestinationRow
                                key={destination.id}
                                policyDestination={destination}
                                disabled={props.disabled}
                                disableDelete={props.disableDelete}
                                onDestinationChange={(destination) =>
                                    onDestinationRowChange(destination, index)
                                }
                                onDeleteRow={() => onDeleteRow(index)}
                                type={selectType(destination)}
                                enableRequiredCheck={props.enableRequiredCheck}
                            />
                        )
                    })}
                </tbody>
            </table>
            {!props.disabled && (
                <div className={styles.footer}>
                    <Button
                        icon={IconType.PLUS}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.PRIMARY}
                        onClick={onAddRow}
                        type="button"
                    >
                        {localization.getString("addAnotherRow")}
                    </Button>
                </div>
            )}
        </div>
    )
}

interface ListHeaderProps {
    headers: string[]
}

function PolicyDestinationListHeader(props: ListHeaderProps) {
    return (
        <thead>
            <tr className={styles.destinationListHeader}>
                {props.headers.map((header) => (
                    <th key={header}>{header}</th>
                ))}
            </tr>
        </thead>
    )
}
