import React from "react"

import { FormLabel } from "../../../pre-v3/components/form-label/FormLabel"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Checkbox } from "../../../v3/components/checkbox/Checkbox.component"

interface Props {
    usesGlobalEdge: boolean
    usesPrivateEdge: boolean
    usesGlobalEdgeDisabled?: boolean
    usesPrivateEdgeDisabled?: boolean
    showPrivateEdge: boolean
    onToggleGlobalEdge?(checked: boolean): void
    onTogglePrivateEdge?(checked: boolean): void
}

export function NetworkConfigurationCheckbox(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <FormLabel inline title={localization.getString("networkConfiguration")}>
            <Checkbox
                checked={props.usesGlobalEdge}
                onChange={props.onToggleGlobalEdge}
                disabled={props.usesGlobalEdgeDisabled}
            >
                {localization.getString("globalEdge")}
            </Checkbox>
            {props.showPrivateEdge && (
                <Checkbox
                    checked={props.usesPrivateEdge}
                    onChange={props.onTogglePrivateEdge}
                    disabled={props.usesPrivateEdgeDisabled}
                >
                    {localization.getString("privateEdge")}
                </Checkbox>
            )}
        </FormLabel>
    )
}
