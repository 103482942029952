import React from "react"
import { Redirect, Route, Switch, matchPath, useHistory, useLocation } from "react-router-dom"

import { useAuthService } from "../../pre-v3/services/Auth.service"
import { LoginReset } from "../../pre-v3/views/login/reset/LoginReset"
import { ROUTE } from "../../routes"
import { Login } from "../../v3/views/login/Login.view"
import { MswSso } from "../../v3/views/msw-sso/MswSso.view"

export function Unauthenticated(): JSX.Element {
    useSaml()

    return (
        <Switch>
            <Route path={ROUTE.LOGIN} component={Login} />
            <Route path={ROUTE.MSW_SSO} component={MswSso} />
            <Route path={ROUTE.LOGIN_RESET} component={LoginReset} />
            {/* Prevent redirecting to the Login page when the user logins as SAML */}
            <Route path={ROUTE.SAML} />
            <Redirect to={ROUTE.LOGIN} />
        </Switch>
    )
}

function useSaml() {
    const location = useLocation()
    const history = useHistory()

    const authService = useAuthService()

    React.useEffect(() => {
        const match = matchPath(location.pathname, { path: ROUTE.SAML })
        if (!match) return

        const token = new URLSearchParams(location.search).get("token")

        if (token) {
            authService.setLogin(token)
            history.replace(ROUTE.ROOT)
        } else {
            history.replace(ROUTE.LOGIN)
        }
    }, [location])
}
