import { EditionRes } from "../../api/Org.api"

export enum Edition {
    TEAM = "team",
    ENTERPRISE = "enterprise",
    UNLIMITED = "unlimited",
}

export const allEditions = Object.values(Edition)

export const editionResMap: Record<EditionRes, Edition> = {
    Team: Edition.TEAM,
    Enterprise: Edition.ENTERPRISE,
    Unlimited: Edition.UNLIMITED,
}

export const editionToResMap: Record<Edition, EditionRes> = {
    [Edition.TEAM]: "Team",
    [Edition.ENTERPRISE]: "Enterprise",
    [Edition.UNLIMITED]: "Unlimited",
}
