import React from "react"
import styles from "./TrustProviderDownloadApp.module.scss"
import classNames from "classnames/bind"
import { AppText } from "../../../../../../v3/components/app-text/AppText.component"

export default function () {
    return (
        <div>
            <AppText
                ls={{
                    key: "useTheCseDesktopDescription",
                    replaceVals: [this.linkService.getLink("appInstallDocs")],
                }}
            />
            <div className={styles.cardContainer}>
                <ul className={styles.cardList}>
                    <li className={classNames(styles.cardListItem, styles.macOs)}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className={styles.cardLink}
                            href={this.linkService.getLink("downloadCSEAppForMacOs")}
                        >
                            {" "}
                        </a>
                    </li>
                    <li className={classNames(styles.cardListItem, styles.windows)}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className={styles.cardLink}
                            href={this.linkService.getLink("downloadCSEAppForWindows")}
                        >
                            {" "}
                        </a>
                    </li>
                    <li className={classNames(styles.cardListItem, styles.linux)}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className={styles.cardLink}
                            href={this.linkService.getLink("downloadCSEAppForLinux")}
                        >
                            {" "}
                        </a>
                    </li>
                    <li className={classNames(styles.cardListItem, styles.ios)}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className={styles.cardLink}
                            href={this.linkService.getLink("downloadCSEAppForIOs")}
                        >
                            {" "}
                        </a>
                    </li>
                    <li className={classNames(styles.cardListItem, styles.android)}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            className={styles.cardLink}
                            href={this.linkService.getLink("downloadCSEAppForAndroid")}
                        >
                            {" "}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
