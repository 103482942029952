import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../../components/button/Button.component"
import { ROUTE } from "../../../../../routes"
import { Grid } from "../../../../components/grid/Grid.component"
import { Toolbar } from "../../../../components/toolbar/Toolbar.component"
import styles from "./ApiKeysSettings.module.scss"
import { ApiKeyCellRenderer } from "./cell-renderer/ApiKeyCellRenderer.component"

export default function () {
    const addLink = this.props.isMomConsole ? ROUTE.MOM_API_KEYS_ADD : ROUTE.API_KEYS_ADD
    return (
        <div className={styles.gridWithToolbar}>
            <Toolbar onSearchChange={this.onFilter}>
                <span></span>
                {this.props.canAccessApiKey && (
                    <Button
                        buttonType={ButtonType.PRIMARY}
                        asElement={ButtonElement.LINK}
                        to={addLink}
                        icon={IconType.PLUS}
                    >
                        {this.ls.getString("addApiKey")}
                    </Button>
                )}
            </Toolbar>
            <Grid
                onGridReady={this.onGridReady}
                columnDefs={this.columns}
                context={{ history: this.props.history }}
                pagination
                onRowDoubleClicked={this.onRowDoubleClicked}
                components={{
                    apiKeyCellRenderer: (c) => (
                        <ApiKeyCellRenderer isMomConsole={this.props.isMomConsole} {...c} />
                    ),
                }}
            />
        </div>
    )
}
