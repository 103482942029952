import React, { useRef, useState, useEffect, ReactNode } from "react"
import styles from "./MspOrgList.module.scss"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../pre-v3/utils/AgGrid.util"
import { generatePath } from "react-router-dom"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { GridReadyEvent, GridApi, ColDef, ITooltipParams } from "ag-grid-community"
import { useActionBar } from "../../../../pre-v3/services/ActionBar.service"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { Loader } from "../../../components/loader/Loader.component"
import { Status } from "../../../components/status/Status.component"

// Note: Old components, move them to v3
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import {
    MspOrgInfo,
    mspOrgLabelMap,
    orgStatusToStatusTypeMap,
    useGetMspOrgs,
} from "../../../services/MspOrgManagement.service"
import { Link } from "../../../components/link/Link.component"
import { ROUTE } from "../../../../routes"
import { RoundButton } from "../../../components/round-button/RoundButton.component"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import { CreateMspOrgModal } from "../modals/CreateMspOrgModal.component"
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags.hook"

export function MspOrgList() {
    const localization = useServiceLocalization()
    const { data: featureFlags } = useFeatureFlags()
    const grid = useRef<GridApi>()
    const modalService = useServiceModal()

    const [search, setSearch] = useState<string>("")

    const {
        data,
        refetch: fetchMspOrgs,
        isFetching: isMspOrgsLoading,
        isError: isMspOrgError,
        error: mspOrgsError,
    } = useGetMspOrgs()

    useEffect(() => {
        grid.current?.setQuickFilter?.(search)
    }, [search])

    useActionBar({
        title: localization.getString("orgManagement"),
        fetchData: () => {
            fetchMspOrgs()
        },
    })

    function onGridReady(event: GridReadyEvent) {
        grid.current = event.api
    }

    const errors: ReactNode[] = [typeof mspOrgsError === "string" && mspOrgsError]

    const columns: (ColDef & { field: keyof MspOrgInfo })[] = [
        {
            headerName: localization.getString("status"),
            field: "status",
            flex: 65,
            cellRenderer: ({ value }: { value: MspOrgInfo["status"] }) => {
                return value ? (
                    <Status
                        type={orgStatusToStatusTypeMap[value]}
                        label={localization.getString(mspOrgLabelMap[value])}
                    />
                ) : (
                    ""
                )
            },
            tooltipValueGetter: (tooltipProps: ITooltipParams) => {
                const value = tooltipProps.value as MspOrgInfo["status"]

                return value ? localization.getString(mspOrgLabelMap[value]) : ""
            },
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: localization.getString("orgName"),
            field: "orgName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: NameCellRenderer,
            comparator: AgGridUtil.alphaBetComparator,
            flex: 150,
        },
        {
            headerName: localization.getString("edition"),
            flex: 100,
            field: "edition",
            cellRenderer: (cellParams: { value: MspOrgInfo["edition"] }) => {
                return localization.getString(cellParams.value)
            },
            sortable: false,
        },
        {
            headerName: localization.getString("type"),
            field: "type",
            flex: 90,
            comparator: AgGridUtil.alphaBetComparator,
            valueFormatter: AgGridUtil.nullableStringFormatter,
        },
        {
            headerName: localization.getString("licenseUsage"),
            field: "licenseCount",
            flex: 100,
        },
    ]

    function addOrg() {
        modalService.open(localization.getString("addAnOrganization"), {
            component: CreateMspOrgModal,
            maxWidth: "lg",
        })
    }

    return (
        <div className={styles.outerContainer}>
            <div className={styles.container}>
                <SearchInput
                    className={styles.search}
                    value={search}
                    onChangeValue={setSearch}
                    placeholder={localization.getString("search")}
                />
                {featureFlags?.mspConsole.canAddOrganization && (
                    <RoundButton
                        icon={faPlus}
                        onClick={addOrg}
                        rightLabel={localization.getString("addOrg")}
                        brand
                        className={styles.iconButton}
                    />
                )}
            </div>
            <Loader isLoading={isMspOrgsLoading} center medium>
                <ErrorBanners className={styles.errorBanner} errors={errors} />
                {!isMspOrgError && (
                    <Grid
                        onGridReady={onGridReady}
                        columnDefs={columns}
                        rowData={data}
                        pagination
                    />
                )}
            </Loader>
        </div>
    )
}

interface NameCellRendererProps {
    value: string
    data: MspOrgInfo
}

function NameCellRenderer(props: NameCellRendererProps) {
    return (
        <Link to={generatePath(ROUTE.MSP_ORG_DETAILS, { id: encodeID(props.data.id) })}>
            {props.value}
        </Link>
    )
}
