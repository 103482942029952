import {
    faExclamation,
    faCheckCircle,
    faMinusCircle,
    faTimesCircle,
    faPauseCircle,
    IconDefinition,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames/bind"
import React from "react"
import { RouteComponentProps, useHistory, useRouteMatch } from "react-router"
import { Link } from "react-router-dom"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { UserOrgDetails } from "../../../api/User.api"
import { SimpleTableItem } from "../../../components/simple-table/SimpleTable.component"
import { IconType, useServiceActionBar } from "../../../services/ActionBar.service"
import { ServiceManage, ServiceManageStatus, ServiceType } from "../../../services/Manage.service"
import { useServiceTunnelService } from "../../../services/ServiceTunnel.service"
import { DateUtil } from "../../../utils/Date.util"
import { decodeID, encodeID } from "../../../utils/Url.util"
import {
    useServiceLocalization,
    useServiceManage,
    useServiceUser,
    useServiceModal,
    useServiceInventory,
} from "../../../services"
import { ServiceInfoTestConnectionModal } from "./ServiceInfoTestConnectionModal"
import { BannerType, DoneActions, OkCancelActions, Tooltip } from "../../../components"
import styles from "./ServicesOverview.module.scss"
import { LoadMask } from "../../../components/load-mask/LoadMask.component"
import { LargeMessage } from "../../../components/large-message/LargeMessage.component"
import { SimpleTable } from "../../../components/simple-table/SimpleTable.component"
import { TabBar } from "../../../components/tab-bar/TabBar.component"
import { ServiceActivityList } from "../activity-list/ServiceActivityList.component"
import { ServicesInfo } from "../info/ServicesInfo.component"
import { ServiceMetadata, ServiceSpec } from "../../../api/Manage.api"
import { ConvertToCustomServiceModal } from "./ConvertToCustomServiceModal"
import { CreateServiceCloneModal } from "./CreateServiceCloneModal"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { Policy } from "../../../../v3/services/Policy.service"

interface Props {
    id: string
}

export function ServicesOverview(props: RouteComponentProps<Props>) {
    // grab the services we need
    const localization = useServiceLocalization()
    const history = useHistory()

    // some state
    const [tab, setTab] = React.useState<number>(1)

    // grab the data we need
    const {
        fetchData,
        service,
        error,
        policyData,
        policyRawData,
        serviceData,
        serviceIcon,
        serviceStatus,
        canCreateServices,
        serviceID,
        loading,
        linkedResource,
    } = useOverviewData()

    // the functions to call to refresh the view
    const isBackendDomainEmailVariableAvailable = doBackendDomainUseOnlyEmailVariables(service)
    const [openTestConnectionModal, testable] = useOpenTestConnectionModal(
        service,
        isBackendDomainEmailVariableAvailable
    )
    const openConvertServiceToJsonModal = useConvertServiceToJsonModal(service, fetchData)
    const openCloneServiceModal = useOpenCloneServiceModal(service)

    return (
        <div className={"pre-v3"}>
            {loading && !error && <LoadMask />}
            {error && (
                <LargeMessage icon={faExclamation} className={styles.missingMessage}>
                    {error}
                </LargeMessage>
            )}
            {service && !loading && serviceIcon && (
                <div className={styles.containerWithHeading}>
                    <header className={styles.header}>
                        <PageHeading>{service.id}</PageHeading>
                        <div className={styles.actionButtons}>
                            {canCreateServices && (
                                <>
                                    {policyRawData?.id === "" && serviceID && (
                                        <Button
                                            asElement={ButtonElement.LINK}
                                            buttonType={ButtonType.SECONDARY}
                                            icon={IconType.PLUS}
                                            to={formatRoutePath(ROUTE.INFRASTRUCTURE_EDIT, {
                                                id: encodeID(serviceID),
                                            })}
                                        >
                                            {localization.getString("attachAPolicy")}
                                        </Button>
                                    )}
                                    {service.spec?.metadata.tags.template !==
                                        ServiceType.CUSTOM && (
                                        <Tooltip
                                            title={localization.getString(
                                                "convertServiceToCustomJson"
                                            )}
                                        >
                                            <Button
                                                icon={IconType.RANDOM}
                                                onClick={openConvertServiceToJsonModal}
                                                asElement={ButtonElement.BUTTON}
                                                buttonType={ButtonType.SECONDARY}
                                                aria-label={localization.getString(
                                                    "convertServiceToCustomJson"
                                                )}
                                            />
                                        </Tooltip>
                                    )}
                                    <Tooltip title={localization.getString("cloneService")}>
                                        <Button
                                            icon={IconType.COPY}
                                            onClick={openCloneServiceModal}
                                            asElement={ButtonElement.BUTTON}
                                            buttonType={ButtonType.SECONDARY}
                                            aria-label={localization.getString("cloneService")}
                                        />
                                    </Tooltip>
                                    {testable &&
                                        (isBackendDomainEmailVariableAvailable ? (
                                            <Tooltip
                                                title={localization.getString("testConnection")}
                                            >
                                                <span>
                                                    <Button
                                                        icon={IconType.CHECK}
                                                        onClick={openTestConnectionModal}
                                                        asElement={ButtonElement.BUTTON}
                                                        buttonType={ButtonType.SECONDARY}
                                                        aria-label={localization.getString(
                                                            "testConnection"
                                                        )}
                                                    />
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip
                                                title={localization.getString("testConnection")}
                                            >
                                                <span>
                                                    <Button
                                                        icon={IconType.CHECK}
                                                        onClick={openTestConnectionModal}
                                                        asElement={ButtonElement.BUTTON}
                                                        buttonType={ButtonType.SECONDARY}
                                                        aria-label={localization.getString(
                                                            "testConnection"
                                                        )}
                                                        disabled={doBackendDomainUseVariables(
                                                            service
                                                        )}
                                                    />
                                                </span>
                                            </Tooltip>
                                        ))}
                                    <Tooltip title={localization.getString("edit")}>
                                        <Button
                                            icon={IconType.PEN}
                                            onClick={() => {
                                                serviceID &&
                                                    history.push(
                                                        formatRoutePath(ROUTE.INFRASTRUCTURE_EDIT, {
                                                            id: encodeID(serviceID),
                                                        })
                                                    )
                                            }}
                                            asElement={ButtonElement.BUTTON}
                                            buttonType={ButtonType.SECONDARY}
                                            aria-label={localization.getString("edit")}
                                        />
                                    </Tooltip>
                                </>
                            )}
                            <Tooltip title={localization.getString("refresh")}>
                                <Button
                                    icon={IconType.REDO}
                                    onClick={fetchData}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                />
                            </Tooltip>
                        </div>
                    </header>
                    <div className={styles.overviewContainer}>
                        <div className={styles.overviewLeftContainer}>
                            <div
                                className={classNames(styles.policy, {
                                    [styles.policyEnforcing]:
                                        service.status === ServiceManageStatus.POLICY_ENFORCING,
                                    [styles.policyPermissive]:
                                        service.status === ServiceManageStatus.POLICY_PERMISSIVE,
                                    [styles.noPolicy]:
                                        service.status === ServiceManageStatus.NO_POLICY,
                                })}
                            >
                                <p className={styles.statusHeader}>
                                    {localization.getString("serviceStatus")}
                                </p>
                                <p>
                                    <FontAwesomeIcon
                                        className={styles.statusIcon}
                                        icon={serviceIcon!}
                                    />
                                    <span className={styles.statusText}>{serviceStatus}</span>
                                </p>
                                <SimpleTable className={styles.policyTable} items={policyData} />
                            </div>
                            <SimpleTable items={serviceData} />
                        </div>
                        <div className={styles.overviewRightContainer}>
                            <TabBar
                                tabs={[
                                    {
                                        label: localization.getString("specification"),
                                        value: 1,
                                        active: true,
                                    },
                                    {
                                        label: localization.getString("accessActivity"),
                                        value: 2,
                                    },
                                ]}
                                onChange={setTab}
                            ></TabBar>
                            <div className={styles.overviewRightGridContainer}>
                                {tab === 1 && (
                                    <ServicesInfo
                                        className={styles.info}
                                        service={service}
                                        linkedResource={linkedResource}
                                    />
                                )}
                                {tab === 2 && (
                                    <ServiceActivityList
                                        serviceId={serviceID!}
                                        history={props.history}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

function useOverviewData(): {
    serviceID: string | null
    fetchData: () => Promise<void>
    service: ServiceManage | null
    policyData: SimpleTableItem[]
    policyRawData: Partial<Policy>
    serviceIcon: IconDefinition | null
    serviceStatus: string | null
    serviceData: SimpleTableItem[]
    canCreateServices: boolean
    linkedResource: boolean
    error: string
    loading: boolean
} {
    const match = useRouteMatch<Props>()
    const history = useHistory()

    // pull out the services we need
    const manageService = useServiceManage()
    const localization = useServiceLocalization()
    const userService = useServiceUser()
    const inventoryService = useServiceInventory()
    const tunnelService = useServiceTunnelService()
    const actionBarService = useServiceActionBar()

    // and the utility functions
    const getServiceStatus = useComputeServiceStatus()
    const getServiceIcon = useComputeServiceIcon()

    // set up the state
    const [loading, setLoading] = React.useState(true)
    const [service, setService] = React.useState<ServiceManage | null>(null)
    const [policyData, setPolicyData] = React.useState<SimpleTableItem[]>([])
    const [policyRawData, setPolicyRawData] = React.useState<Partial<Policy & { id: string }>>({})
    const [serviceData, setServiceData] = React.useState<SimpleTableItem[]>([])
    const [serviceIcon, setServiceIcon] = React.useState<IconDefinition | null>(null)
    const [serviceStatus, setServiceStatus] = React.useState<string | null>(null)
    const [canCreateServices, setCanCreateServices] = React.useState(false)
    const [linkedResource, setLinkedResource] = React.useState(false)
    const [error, setError] = React.useState("")
    const [serviceID, setServiceID] = React.useState<string | null>(null)

    const fetchData = React.useCallback(
        // we'll invoke this immediately and then add a finally clause to clean up loading
        async () => {
            // when we first mount, we need to figure out the correct id
            try {
                setServiceID(decodeID(match.params.id))
            } catch {
                setError(localization.getString("serviceNotFound"))
                setLoading(false)
                return
            }

            setLoading(true)

            if (!serviceID) {
                return
            }

            // look up the service
            let remoteService: ServiceManage | null = null

            try {
                remoteService = await manageService.getRegisteredService(serviceID)
                actionBarService.setTitle(remoteService.id)
                setPolicyRawData({
                    id: remoteService.policyId,
                })
            } catch {
                setError(localization.getString("serviceNotFound"))
                return
            }

            // there's no remote service or saas service, its an error
            if (!remoteService) {
                return
            }

            let linkedCloudResource: React.ReactNode
            try {
                //get cloud resource linked to a service
                const [cloudResource] = await inventoryService.getResourceAndServiceDetails({
                    service_id: serviceID,
                })

                if (cloudResource) {
                    setLinkedResource(true)
                    linkedCloudResource = (
                        <Link
                            to={formatRoutePath(ROUTE.IAAS_DISCOVERY_DETAILS, {
                                id: encodeID(cloudResource.cloudResourceId),
                            })}
                        >
                            {cloudResource.resourceName}
                        </Link>
                    )
                } else {
                    linkedCloudResource = <>{" - "}</>
                }
            } catch {
                linkedCloudResource = <>{" - "}</>
            }

            // figure out the policy information
            const attachedPolicy: React.ReactNode = remoteService.policyName ? (
                <Link
                    to={formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, {
                        id: remoteService.policyId!,
                    })}
                >
                    {remoteService.policyName}
                </Link>
            ) : (
                <span>{`${localization.getString("none")}`}</span>
            )

            // update the state
            setPolicyData([
                {
                    label: localization.getString("policyAttached"),
                    value: attachedPolicy,
                },
                {
                    label: localization.getString("attachedOn"),
                    value: DateUtil.format(remoteService.policyAttachedAt),
                },
            ])
            setServiceData([
                {
                    label: localization.getString("linkedCloudResource"),
                    value: linkedCloudResource,
                },
                {
                    label: localization.getString("serviceName"),
                    value: remoteService.name,
                },
                {
                    label: localization.getString("description"),
                    value: remoteService.description,
                },
                {
                    label: localization.getString("clusterName"),
                    value: remoteService.clusterName,
                },
                {
                    label: localization.getString("version"),
                    value: remoteService.version,
                },
                {
                    label: localization.getString("createdAt"),
                    value: DateUtil.format(remoteService.createdAt),
                },
                {
                    label: localization.getString("createdBy"),
                    value: remoteService.createdBy,
                },
                {
                    label: localization.getString("lastUpdated"),
                    value: DateUtil.format(remoteService.lastUpdatedAt),
                },
                {
                    label: localization.getString("lastUpdatedBy"),
                    value: remoteService.lastUpdatedBy,
                },
            ])

            // update the service meta data
            setServiceStatus(getServiceStatus(remoteService))
            setServiceIcon(getServiceIcon(remoteService))

            // look up the user details
            let details: UserOrgDetails
            try {
                details = await userService.getUserOrgDetails()
            } catch (e) {
                setError((e as Error).message || (e as string))
                return
            }

            if (userService.canCreateServices(details.Profile)) {
                setCanCreateServices(true)
            }

            // set the service state last so that the UI doesn't try to render with half of its data
            setService(remoteService)
            setLoading(false)
        },
        [
            serviceID,
            getServiceIcon,
            getServiceStatus,
            localization,
            tunnelService,
            manageService,
            setLoading,
            userService,
            match.params.id,
            inventoryService,
            history,
            match.path,
            actionBarService,
        ]
    )

    // fetch the data
    React.useEffect(() => {
        fetchData()
    }, [fetchData])

    return {
        fetchData,
        service,
        policyData,
        policyRawData,
        serviceIcon,
        serviceStatus,
        serviceData,
        canCreateServices,
        error,
        serviceID,
        loading,
        linkedResource,
    }
}

function useOpenCloneServiceModal(service: ServiceManage | null) {
    const modalService = useServiceModal()
    const localization = useServiceLocalization()

    return () => {
        if (!service) {
            return null
        }

        modalService.open(localization.getString("cloneService"), {
            component: CreateServiceCloneModal,
            props: {
                service,
            },
            maxWidth: "lg",
        })
    }
}

function useOpenTestConnectionModal(
    service: ServiceManage | null,
    isEmailDomainVariable: boolean
): [() => void, boolean] {
    const modalService = useServiceModal()
    const localization = useServiceLocalization()

    const testable =
        !!service &&
        !service.spec?.spec.backend.http_connect &&
        !service.spec?.metadata.tags.domain?.includes("*")

    return [
        () => {
            if (!service) {
                return null
            }

            modalService.open(
                localization.getString("testConnection"),
                {
                    component: ServiceInfoTestConnectionModal,
                    props: {
                        service,
                        isEmailDomainVariable,
                    },
                    maxWidth: "lg",
                },
                DoneActions
            )
        },
        testable,
    ]
}

function useConvertServiceToJsonModal(service: ServiceManage | null, fetchData: Function) {
    const modalService = useServiceModal()
    const localization = useServiceLocalization()
    const manageService = useServiceManage()
    const match = useRouteMatch()
    const history = useHistory()

    return () => {
        if (!service) {
            return null
        }

        modalService
            .open(
                localization.getString("convertServiceToCustomJson"),
                {
                    component: ConvertToCustomServiceModal,
                    props: {
                        text: localization.getString("areYouSureToConvertServiceToJson"),
                        bannerType: BannerType.WARNING,
                        bannerText: localization.getString("warningThisCannotBeReversed"),
                    },
                },
                {
                    component: OkCancelActions,
                    props: {
                        okString: localization.getString("continue"),
                    },
                }
            )
            .onClose(() => {
                const serviceSpec: ServiceSpec | undefined = service.spec

                if (!serviceSpec) {
                    return
                }

                const metadata: ServiceMetadata = serviceSpec.metadata
                metadata.tags.template = ServiceType.CUSTOM

                manageService
                    .editRegisteredService({
                        metadata,
                        id: service.id,
                        type: serviceSpec.kind,
                        attributes: serviceSpec.spec,
                        policyId: service.policyId ? service.policyId : "",
                        enabled: service.policyEnabled,
                        nameId: service.nameId as string,
                    })
                    .then(() => {
                        const targetPath =
                            match.path === ROUTE.INFRASTRUCTURE_DETAILS
                                ? ROUTE.INFRASTRUCTURE_DETAILS
                                : ROUTE.HOSTED_WEBSITES_DETAILS

                        history.push(formatRoutePath(targetPath, { id: encodeID(service.id) }))
                        fetchData()
                    })
            })
    }
}

function useComputeServiceStatus(): (service: ServiceManage) => string {
    const localization = useServiceLocalization()

    return React.useCallback(
        (service: ServiceManage): string => {
            switch (service.status) {
                case ServiceManageStatus.POLICY_ENFORCING:
                    return localization.getString("policyEnforcing")
                case ServiceManageStatus.POLICY_PERMISSIVE:
                    return localization.getString("policyPermissive")
                case ServiceManageStatus.NO_POLICY:
                    return localization.getString("noPolicy")
                default:
                    return localization.getString("inactive")
            }
        },
        [localization]
    )
}

function useComputeServiceIcon(): (service: ServiceManage) => IconDefinition {
    return React.useCallback((service: ServiceManage): IconDefinition => {
        switch (service.status) {
            case ServiceManageStatus.POLICY_ENFORCING:
                return faCheckCircle
            case ServiceManageStatus.POLICY_PERMISSIVE:
                return faMinusCircle
            case ServiceManageStatus.NO_POLICY:
                return faTimesCircle
            default:
                return faPauseCircle
        }
    }, [])
}

function doBackendDomainUseVariables(service: ServiceManage | null): boolean {
    const domain = service?.spec?.spec.backend.target.name.toLowerCase() ?? ""
    return domain.includes("{{.domain") || domain.includes("name") || domain.includes("index")
}

function doBackendDomainUseOnlyEmailVariables(service: ServiceManage | null): boolean {
    return (
        (service?.spec?.spec.backend.target.name?.toLowerCase().includes("email") &&
            !doBackendDomainUseVariables(service)) ??
        false
    )
}
