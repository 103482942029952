import React from "react"
import styles from "./TrustProviderIdentitySettings.module.scss"
import { Select } from "../../../../components/select/Select.component"
import { InfoBanner, ErrorBanner, SectionScreen } from "../../../../components"
import { InputWithHide } from "../../../../components/input-with-hide/InputWithHide.component"
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"
import {
    Button,
    ButtonElement,
    ButtonType,
} from "../../../../../components/button/Button.component"
import { ConfirmationDialog } from "../../../../components/dialog/confirmation/ConfirmationDialog.component"
import { IDPProtocol, IDPName } from "../../../../api/User.api"
import { AppText } from "../../../../../v3/components/app-text/AppText.component"
import { Loader } from "../../../../../v3/components/loader/Loader.component"
import { Input } from "../../../../../v3/components/input/Input.component"
import { Form } from "../../../../../components/form/Form.component"
import { FormRow } from "../../../../../components/form/FormRow.component"
import { PasswordInput } from "../../../../../v3/components/password-input/PasswordInput.component"
import { Accordion } from "../../../../../v3/components/accordion/Accordion.component"
import { ScimProvisioning } from "../../../../../pages/settings/identity-access/enduser/ScimProvisioning"
import { Tooltip } from "../../../../../v3/components/tooltip/Tooltip.component"

export default function () {
    return (
        <Loader isLoading={this.state.isLoading} center medium>
            <div className={styles.mainContainer}>
                {this.state.isBanyanIDP ? (
                    <>
                        <FormRow>
                            {this.state.configureError && (
                                <ErrorBanner className={styles.error}>
                                    {this.state.configureError}
                                </ErrorBanner>
                            )}
                        </FormRow>
                        <SectionScreen
                            title={this.localizationService.getString("userIdentityProvider")}
                            description={<AppText ls="userIdentityProviderUpgradeDescription" />}
                            onConfigureButtonClick={this.onConfigure}
                            disableButton={!this.props.canConfigureEndUserSettings}
                        />
                    </>
                ) : (
                    <>
                        <Accordion
                            defaultOpen
                            type="plain"
                            label={
                                <h3>
                                    {this.localizationService.getString("userIdentityProvider")}
                                </h3>
                            }
                            description={
                                <AppText
                                    ls={{
                                        key: "userIdentityProviderExplanation",
                                        replaceVals: [this.linkService.getLink("userIdentityDocs")],
                                    }}
                                />
                            }
                            titleChildren={
                                this.props.canConfigureEndUserSettings && (
                                    <div className={styles.userHeader}>
                                        <Tooltip
                                            title={
                                                this.state.isScimEnabled
                                                    ? this.localizationService.getString(
                                                          "toModifyIdpConfigurationDisableScim"
                                                      )
                                                    : ""
                                            }
                                        >
                                            <span>
                                                <Button
                                                    buttonType={ButtonType.PRIMARY}
                                                    asElement={ButtonElement.BUTTON}
                                                    loading={this.state.userIdpLoading}
                                                    type="submit"
                                                    onClick={this.onSubmitUserConfig}
                                                    disabled={this.state.isScimEnabled}
                                                >
                                                    {this.localizationService.getString(
                                                        "saveUpdates"
                                                    )}
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                )
                            }
                        >
                            <Form
                                onSubmit={this.onSubmitUserConfig}
                                display="grid"
                                ref={this.userFormRef}
                            >
                                <FormRow>
                                    {this.state.error && (
                                        <ErrorBanner>{this.state.error}</ErrorBanner>
                                    )}
                                    {this.state.success && (
                                        <InfoBanner type="info" icon={faExclamationCircle}>
                                            {this.state.success}
                                        </InfoBanner>
                                    )}
                                </FormRow>
                                <FormRow
                                    label={this.localizationService.getString(
                                        "identityProviderProtocol"
                                    )}
                                    htmlFor="identityProviderProtocol"
                                >
                                    <Select
                                        required
                                        value={this.state.idpProtocol}
                                        options={this.idpProtocolOptions}
                                        onChange={this.onIdpProtocolChange}
                                        aria-label={this.localizationService.getString(
                                            "identityProviderProtocol"
                                        )}
                                        id="identityProviderProtocol"
                                        disabled={
                                            this.state.isScimEnabled ||
                                            !this.props.canConfigureEndUserSettings
                                        }
                                    />
                                </FormRow>
                                <FormRow
                                    label={this.localizationService.getString(
                                        "identityProviderName"
                                    )}
                                    htmlFor="identityProviderName"
                                >
                                    <Select
                                        id="identityProviderName"
                                        name="identityProviderName"
                                        required
                                        value={this.state.selectedIdpName}
                                        options={this.knownIdpNames}
                                        onChange={this.onSelectedIdpNameChange}
                                        disabled={
                                            this.state.isScimEnabled ||
                                            !this.props.canConfigureEndUserSettings
                                        }
                                    />
                                </FormRow>
                                {this.state.selectedIdpName === IDPName.OTHER && (
                                    <FormRow>
                                        <Input
                                            id="idpName"
                                            disabled={
                                                this.state.selectedIdpName !== IDPName.OTHER ||
                                                this.state.isScimEnabled ||
                                                !this.props.canConfigureEndUserSettings
                                            }
                                            defaultValue={this.state.idpName}
                                            className={styles.formInput}
                                            required
                                            placeholder={this.localizationService.getString(
                                                "identityProviderName"
                                            )}
                                            onChange={this.onIdpNameChange}
                                        />
                                    </FormRow>
                                )}

                                {this.state.idpProtocol === IDPProtocol.OIDC && (
                                    <>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "appClientName"
                                            )}
                                            htmlFor="appClientName"
                                        >
                                            <Input
                                                id="appClientName"
                                                name="appClientName"
                                                className={styles.formInput}
                                                disabled
                                                value={this.appClientName}
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString("issuerUrl")}
                                            htmlFor="issuerUrl"
                                        >
                                            <Input
                                                id="issuerUrl"
                                                name="issuerUrl"
                                                defaultValue={this.idpConfig.IssuerURL}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "issuerUrl"
                                                )}
                                                onChange={this.onIssuerUrlChange}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString("clientId")}
                                            htmlFor="clientId"
                                        >
                                            <Input
                                                id="clientId"
                                                name="clientId"
                                                defaultValue={this.idpConfig.ClientID}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "clientId"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "clientSecret"
                                            )}
                                            htmlFor="clientSecret"
                                        >
                                            <PasswordInput
                                                id="clientSecret"
                                                aria-label={this.localizationService.getString(
                                                    "clientSecret"
                                                )}
                                                value={this.state.idpConfig.ClientSecret}
                                                onChange={this.onClientSecretChange}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "clientSecret"
                                                )}
                                                className={styles.passwordInput}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "redirectUri"
                                            )}
                                            htmlFor="redirectUri"
                                        >
                                            <Input
                                                id="redirectUri"
                                                name="redirectUri"
                                                value={this.state.redirectUri}
                                                onChange={this.onRedirectUriChange}
                                                className={styles.formInput}
                                                placeholder={this.localizationService.getString(
                                                    "redirectUri"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                    </>
                                )}
                                {this.state.idpProtocol === IDPProtocol.OIDC &&
                                    this.state.idpName === IDPName.COGNITO && (
                                        <>
                                            <FormRow
                                                label={this.localizationService.getString(
                                                    "accessKey"
                                                )}
                                                htmlFor="accessKey"
                                            >
                                                <Input
                                                    id="accessKey"
                                                    name="accessKey"
                                                    defaultValue={this.idpConfig.AccessKeyId}
                                                    className={styles.formInput}
                                                    required
                                                    placeholder={this.localizationService.getString(
                                                        "internalOnly"
                                                    )}
                                                    disabled={
                                                        this.state.isScimEnabled ||
                                                        !this.props.canConfigureEndUserSettings
                                                    }
                                                />
                                            </FormRow>
                                            <FormRow
                                                label={this.localizationService.getString(
                                                    "secretKey"
                                                )}
                                                htmlFor="secretKey"
                                            >
                                                <InputWithHide
                                                    id="secretKey"
                                                    name="secretKey"
                                                    value={this.idpConfig.SecretAccessKey}
                                                    required
                                                    placeholder={this.localizationService.getString(
                                                        "internalOnly"
                                                    )}
                                                    disabled={
                                                        !this.props.canConfigureEndUserSettings
                                                    }
                                                    hideEyeIcon={
                                                        !this.props.canConfigureEndUserSettings
                                                    }
                                                />
                                            </FormRow>
                                            <FormRow
                                                label={this.localizationService.getString(
                                                    "userPoolId"
                                                )}
                                                htmlFor="userPoolId"
                                            >
                                                <Input
                                                    id="userPoolId"
                                                    name="userPoolId"
                                                    defaultValue={this.idpConfig.UserPoolId}
                                                    className={styles.formInput}
                                                    required
                                                    placeholder={this.localizationService.getString(
                                                        "internalOnly"
                                                    )}
                                                    disabled={
                                                        this.state.isScimEnabled ||
                                                        !this.props.canConfigureEndUserSettings
                                                    }
                                                />
                                            </FormRow>
                                            <FormRow
                                                label={this.localizationService.getString(
                                                    "authDomain"
                                                )}
                                                htmlFor="authDomain"
                                            >
                                                <Input
                                                    id="authDomain"
                                                    name="authDomain"
                                                    defaultValue={this.idpConfig.UserPoolDomain}
                                                    className={styles.formInput}
                                                    required
                                                    placeholder={this.localizationService.getString(
                                                        "internalOnly"
                                                    )}
                                                    disabled={
                                                        this.state.isScimEnabled ||
                                                        !this.props.canConfigureEndUserSettings
                                                    }
                                                />
                                            </FormRow>
                                        </>
                                    )}

                                {this.state.idpProtocol === IDPProtocol.SAML && (
                                    <>
                                        <FormRow
                                            label={this.localizationService.getString("ssoUrl")}
                                            htmlFor="ssoUrl"
                                        >
                                            <Input
                                                id="ssoUrl"
                                                name="ssoUrl"
                                                defaultValue={this.idpConfig.SSOURL}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "ssoUrl"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "entityIssuer"
                                            )}
                                            htmlFor="entityIssuer"
                                        >
                                            <Input
                                                id="entityIssuer"
                                                name="entityIssuer"
                                                defaultValue={this.idpConfig.EntityIssuer}
                                                className={styles.formInput}
                                                placeholder={this.localizationService.getString(
                                                    "entityIssuerPlaceholder"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString("caData")}
                                            htmlFor="caData"
                                        >
                                            <textarea
                                                id="caData"
                                                defaultValue={this.idpConfig.CAData}
                                                className={styles.formTextArea}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "caDataPlaceholder"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "usernameAttr"
                                            )}
                                            htmlFor="usernameAttr"
                                        >
                                            <Input
                                                id="usernameAttr"
                                                name="usernameAttr"
                                                defaultValue={this.idpConfig.UsernameAttr}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "usernameAttr"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString("emailAttr")}
                                            htmlFor="emailAttr"
                                        >
                                            <Input
                                                id="emailAttr"
                                                name="emailAttr"
                                                defaultValue={this.idpConfig.EmailAttr}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "emailAttr"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString("groupsAttr")}
                                            htmlFor="groupsAttr"
                                        >
                                            <Input
                                                id="groupsAttr"
                                                name="groupsAttr"
                                                defaultValue={this.idpConfig.GroupsAttr}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "groupsAttr"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "groupsDelimiter"
                                            )}
                                            htmlFor="groupsDelimiter"
                                        >
                                            <Input
                                                id="groupsDelimiter"
                                                name="groupsDelimiter"
                                                defaultValue={this.idpConfig.GroupsDelim}
                                                onChange={this.onGroupsDelimChange}
                                                className={styles.formInput}
                                                placeholder={this.localizationService.getString(
                                                    "groupsDelimAttr"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "redirectUrlSamlAcs"
                                            )}
                                            htmlFor="redirectUrl"
                                        >
                                            <Input
                                                id="redirectUrl"
                                                name="redirectUrl"
                                                onChange={this.onRedirectUriChange}
                                                value={this.state.redirectUri}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "redirectUrlSamlAcs"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                    </>
                                )}
                                <ConfirmationDialog
                                    open={this.state.showConfirmDialog}
                                    title={this.localizationService.getString(
                                        "identityProviderConfig"
                                    )}
                                    confirmLabel={this.localizationService.getString("continue")}
                                    onClose={this.onHideConfirmDialog}
                                    onConfirm={this.onUpdateUserIdp}
                                >
                                    <p>
                                        {this.localizationService.getString(
                                            "idpConfigConfirmDescription"
                                        )}
                                    </p>
                                </ConfirmationDialog>
                            </Form>
                        </Accordion>
                        <Accordion
                            type="plain"
                            defaultOpen
                            label={
                                <h3>
                                    {this.localizationService.getString(
                                        "deviceRegistrationProviderConfig"
                                    )}
                                </h3>
                            }
                            description={<AppText ls="deviceRegistrationProviderDescription" />}
                        >
                            <Form onSubmit={this.onSubmitDeviceConfig} display="grid">
                                <FormRow>
                                    {this.state.deviceError && (
                                        <ErrorBanner>{this.state.deviceError}</ErrorBanner>
                                    )}
                                    {this.state.deviceSuccess && (
                                        <InfoBanner>{this.state.deviceSuccess}</InfoBanner>
                                    )}
                                </FormRow>
                                <FormRow
                                    label={this.localizationService.getString("deviceIdpProtocol")}
                                    htmlFor="deviceIdpProtocol"
                                >
                                    <Select
                                        id="deviceIdpProtocol"
                                        name="deviceIdpProtocol"
                                        value={this.state.deviceManagerProtocol}
                                        options={this.idpProtocolOptions}
                                        onChange={this.onDeviceManagerProtocolChange}
                                        disabled={
                                            this.state.isScimEnabled ||
                                            !this.props.canConfigureEndUserSettings
                                        }
                                    />
                                </FormRow>
                                {this.state.deviceManagerProtocol && (
                                    <>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "deviceIdpName"
                                            )}
                                            htmlFor="deviceIdpName"
                                        >
                                            <Select
                                                id="deviceIdpName"
                                                name="deviceIdpName"
                                                value={this.state.selectedDeviceManagerName}
                                                options={this.knownIdpNames}
                                                onChange={this.onSelectedDeviceManagerNameChange}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                    </>
                                )}
                                {this.state.selectedDeviceManagerName === IDPName.OTHER && (
                                    <FormRow>
                                        <Input
                                            id="deviceManagerName"
                                            disabled={
                                                this.state.selectedDeviceManagerName !==
                                                    IDPName.OTHER ||
                                                this.state.isScimEnabled ||
                                                !this.props.canConfigureEndUserSettings
                                            }
                                            defaultValue={this.state.deviceManagerName}
                                            className={styles.formInput}
                                            required
                                            placeholder={this.localizationService.getString(
                                                "deviceIdpName"
                                            )}
                                            onChange={this.onDeviceManagerNameChange}
                                        />
                                    </FormRow>
                                )}

                                {this.state.deviceManagerProtocol === IDPProtocol.OIDC && (
                                    <>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "appClientName"
                                            )}
                                            htmlFor="deviceAppClientName"
                                        >
                                            <Input
                                                id="deviceAppClientName"
                                                name="deviceAppClientName"
                                                className={styles.formInput}
                                                disabled
                                                value={this.deviceAppClientName}
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString("issuerUrl")}
                                            htmlFor="deviceIssuerUrl"
                                        >
                                            <Input
                                                id="deviceIssuerUrl"
                                                name="deviceIssuerUrl"
                                                className={styles.formInput}
                                                defaultValue={this.deviceManagerConfig.IssuerURL}
                                                placeholder={this.localizationService.getString(
                                                    "issuerUrl"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString("clientId")}
                                            htmlFor="deviceClientId"
                                        >
                                            <Input
                                                id="deviceClientId"
                                                name="deviceClientId"
                                                defaultValue={
                                                    this.deviceManagerConfig.ClientID ||
                                                    this.idpConfig.DeviceRegClientID
                                                }
                                                className={styles.formInput}
                                                placeholder={this.localizationService.getString(
                                                    "clientId"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "clientSecret"
                                            )}
                                            htmlFor="deviceClientSecret"
                                        >
                                            <PasswordInput
                                                id="deviceClientSecret"
                                                aria-label={this.localizationService.getString(
                                                    "deviceClientSecret"
                                                )}
                                                value={
                                                    this.state.deviceManagerConfig.ClientSecret ||
                                                    this.state.idpConfig.DeviceRegClientSecret
                                                }
                                                placeholder={this.localizationService.getString(
                                                    "clientSecret"
                                                )}
                                                onChange={this.onDeviceClientSecretChange}
                                                className={styles.passwordInput}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "redirectUri"
                                            )}
                                            htmlFor="deviceRedirectUrl"
                                        >
                                            <Input
                                                id="deviceRedirectUrl"
                                                name="deviceRedirectUrl"
                                                onChange={this.onDeviceRedirectUriChange}
                                                defaultValue={this.state.deviceRedirectUrl}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "redirectUrlSamlAcs"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                    </>
                                )}
                                {this.state.deviceManagerProtocol === IDPProtocol.OIDC &&
                                    this.state.deviceManagerName === IDPName.COGNITO && (
                                        <>
                                            <FormRow
                                                label={this.localizationService.getString(
                                                    "accessKey"
                                                )}
                                                htmlFor="deviceAccessKey"
                                            >
                                                <Input
                                                    id="deviceAccessKey"
                                                    name="deviceAccessKey"
                                                    defaultValue={
                                                        this.deviceManagerConfig.AccessKeyId
                                                    }
                                                    className={styles.formInput}
                                                    required
                                                    placeholder={this.localizationService.getString(
                                                        "internalOnly"
                                                    )}
                                                    disabled={
                                                        this.state.isScimEnabled ||
                                                        !this.props.canConfigureEndUserSettings
                                                    }
                                                />
                                            </FormRow>
                                            <FormRow
                                                label={this.localizationService.getString(
                                                    "secretKey"
                                                )}
                                                htmlFor="deviceSecretKey"
                                            >
                                                <InputWithHide
                                                    defaultValue={
                                                        this.deviceManagerConfig.SecretAccessKey
                                                    }
                                                    required
                                                    placeholder={this.localizationService.getString(
                                                        "internalOnly"
                                                    )}
                                                    disabled={
                                                        this.state.isScimEnabled ||
                                                        !this.props.canConfigureEndUserSettings
                                                    }
                                                    hideEyeIcon={
                                                        !this.props.canConfigureEndUserSettings
                                                    }
                                                />
                                            </FormRow>
                                            <FormRow
                                                label={this.localizationService.getString(
                                                    "userPoolId"
                                                )}
                                                htmlFor="deviceUserPoolId"
                                            >
                                                <Input
                                                    id="deviceUserPoolId"
                                                    name="deviceUserPoolId"
                                                    defaultValue={
                                                        this.deviceManagerConfig.UserPoolId
                                                    }
                                                    className={styles.formInput}
                                                    required
                                                    placeholder={this.localizationService.getString(
                                                        "internalOnly"
                                                    )}
                                                    disabled={
                                                        this.state.isScimEnabled ||
                                                        !this.props.canConfigureEndUserSettings
                                                    }
                                                />
                                            </FormRow>
                                            <FormRow
                                                label={this.localizationService.getString(
                                                    "authDomain"
                                                )}
                                                htmlFor="deviceAuthDomain"
                                            >
                                                <Input
                                                    id="deviceAuthDomain"
                                                    name="deviceAuthDomain"
                                                    defaultValue={
                                                        this.deviceManagerConfig.UserPoolDomain
                                                    }
                                                    className={styles.formInput}
                                                    required
                                                    placeholder={this.localizationService.getString(
                                                        "internalOnly"
                                                    )}
                                                    disabled={
                                                        this.state.isScimEnabled ||
                                                        !this.props.canConfigureEndUserSettings
                                                    }
                                                />
                                            </FormRow>
                                        </>
                                    )}

                                {this.state.deviceManagerProtocol === IDPProtocol.SAML && (
                                    <>
                                        <FormRow
                                            label={this.localizationService.getString("ssoUrl")}
                                            htmlFor="deviceSsoUrl"
                                        >
                                            <Input
                                                id="deviceSsoUrl"
                                                name="deviceSsoUrl"
                                                defaultValue={this.deviceManagerConfig.SSOURL}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "ssoUrl"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "entityIssuer"
                                            )}
                                            htmlFor="deviceEntityIssuer"
                                        >
                                            <Input
                                                id="deviceEntityIssuer"
                                                name="deviceEntityIssuer"
                                                defaultValue={this.deviceManagerConfig.EntityIssuer}
                                                className={styles.formInput}
                                                placeholder={this.localizationService.getString(
                                                    "entityIssuerIdentifierMustBeUniqueForYourIdp"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString("caData")}
                                            htmlFor="deviceCaData"
                                        >
                                            <textarea
                                                id="deviceCaData"
                                                defaultValue={this.deviceManagerConfig.CAData}
                                                className={styles.formTextArea}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "caDataPlaceholder"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "usernameAttr"
                                            )}
                                            htmlFor="deviceUsernameAttr"
                                        >
                                            <Input
                                                id="deviceUsernameAttr"
                                                name="deviceUsernameAttr"
                                                defaultValue={this.deviceManagerConfig.UsernameAttr}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "usernameAttr"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString("emailAttr")}
                                            htmlFor="deviceEmailAttr"
                                        >
                                            <Input
                                                id="deviceEmailAttr"
                                                name="deviceEmailAttr"
                                                defaultValue={this.deviceManagerConfig.EmailAttr}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "emailAttr"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString("groupsAttr")}
                                            htmlFor="deviceGroupsAttr"
                                        >
                                            <Input
                                                id="deviceGroupsAttr"
                                                name="deviceGroupsAttr"
                                                defaultValue={this.deviceManagerConfig.GroupsAttr}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "groupsAttr"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "groupsDelimiter"
                                            )}
                                            htmlFor="deviceGroupsDelimiter"
                                        >
                                            <Input
                                                id="deviceGroupsDelimiter"
                                                name="deviceGroupsDelimiter"
                                                defaultValue={this.deviceManagerConfig.GroupsDelim}
                                                onChange={this.onGroupsDelimChange}
                                                className={styles.formInput}
                                                placeholder={this.localizationService.getString(
                                                    "groupsDelimAttr"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                        <FormRow
                                            label={this.localizationService.getString(
                                                "redirectUrlSamlAcs"
                                            )}
                                            htmlFor="deviceRedirectUrl"
                                        >
                                            <Input
                                                id="deviceRedirectUrl"
                                                name="deviceRedirectUrl"
                                                onChange={this.onDeviceRedirectUriChange}
                                                defaultValue={this.state.deviceRedirectUrl}
                                                className={styles.formInput}
                                                required
                                                placeholder={this.localizationService.getString(
                                                    "redirectUrlSamlAcs"
                                                )}
                                                disabled={
                                                    this.state.isScimEnabled ||
                                                    !this.props.canConfigureEndUserSettings
                                                }
                                            />
                                        </FormRow>
                                    </>
                                )}
                                {this.props.canConfigureEndUserSettings && (
                                    <div className={styles.buttons}>
                                        {this.state.deviceManagerProtocol && (
                                            <Tooltip
                                                title={
                                                    this.state.isScimEnabled
                                                        ? this.localizationService.getString(
                                                              "toModifyIdpConfigurationDisableScim"
                                                          )
                                                        : ""
                                                }
                                            >
                                                <span>
                                                    <Button
                                                        onClick={this.onDeleteDeviceReg}
                                                        loading={this.state.deviceIdpLoading}
                                                        buttonType={ButtonType.DESTRUCTIVE}
                                                        asElement={ButtonElement.BUTTON}
                                                        disabled={this.state.isScimEnabled}
                                                    >
                                                        {" "}
                                                        {this.localizationService.getString(
                                                            "deleteDeviceConfig"
                                                        )}{" "}
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        )}
                                        <Tooltip
                                            title={
                                                this.state.isScimEnabled
                                                    ? this.localizationService.getString(
                                                          "toModifyIdpConfigurationDisableScim"
                                                      )
                                                    : ""
                                            }
                                        >
                                            <span>
                                                <Button
                                                    buttonType={ButtonType.PRIMARY}
                                                    asElement={ButtonElement.BUTTON}
                                                    loading={this.state.deviceIdpLoading}
                                                    className={styles.updateButton}
                                                    type="submit"
                                                    disabled={this.state.isScimEnabled}
                                                >
                                                    {this.localizationService.getString(
                                                        "updateDeviceRegistrationProviderConfig"
                                                    )}
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                )}
                                <ConfirmationDialog
                                    open={this.state.showDeviceConfirmDialog}
                                    title={this.localizationService.getString(
                                        "identityProviderConfig"
                                    )}
                                    confirmLabel={this.localizationService.getString("continue")}
                                    onClose={this.onHideDeviceConfirmDialog}
                                    onConfirm={this.onUpdateDeviceIdp}
                                >
                                    <p>
                                        {this.localizationService.getString(
                                            "idpConfigConfirmDescription"
                                        )}
                                    </p>
                                </ConfirmationDialog>
                            </Form>
                        </Accordion>
                        <ScimProvisioning
                            isScimEnabled={this.state.isScimEnabled}
                            enableScimToggle={
                                this.state.enableScimToggle ||
                                !this.props.canConfigureEndUserSettings
                            }
                            onChange={(state) => this.onScimProvisioningStatusChange(state)}
                        />
                    </>
                )}
            </div>
        </Loader>
    )
}
