import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { PatternUtil } from "../../../pre-v3/utils/Pattern.util"
import { Accordion } from "../../../v3/components/accordion/Accordion.component"
import { AccessTierGroup } from "../../../v3/services/AccessTier.service"
import { MultiInput } from "../../../components/multi-input/MultiInput.component"
import { FormRow } from "../../../components/form/FormRow.component"
import styles from "./AccessTierNetworkSettings.module.scss"

export interface NetworkSettingsValues {
    cidrs?: string[]
    domains?: string[]
}

interface Props {
    values?: NetworkSettingsValues
    accessTierGroup?: AccessTierGroup
    isReadMode?: boolean
    onChange(values: NetworkSettingsValues): void
}

export function AccessTierNetworkSettings(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const onChangeCidrs = (cidrs: string[]) => props.onChange({ ...props.values, cidrs })
    const onChangeDomains = (domains: string[]) => props.onChange({ ...props.values, domains })

    return (
        <Accordion
            label={localization.getString("networkSettingOptional")}
            defaultOpen
            type="plain"
        >
            <div className={styles.accordionContent}>
                <FormRow
                    label={localization.getString("whatNetworkWouldYouLikeToMakeAvailable")}
                    description={localization.getString(
                        "thisCanBeCidrRangeOrIPAddressInCidrFormat"
                    )}
                    className={styles.multiInput}
                >
                    <MultiInput
                        label={localization.getString("cidrRange")}
                        onChange={onChangeCidrs}
                        values={props.accessTierGroup?.cidrRanges ?? props.values?.cidrs}
                        placeholder={localization.getString("cidrAddressExample")}
                        disabled={props.isReadMode || !!props.accessTierGroup}
                        patternProps={{
                            pattern: PatternUtil.CIDR_REGEX.source,
                            errorMessage: localization.getString("cidrPlaceholder"),
                        }}
                    />
                </FormRow>
                <FormRow
                    label={localization.getString(
                        "somethingOptional",
                        localization.getString("whatDomainsExistOnNetworkProvidedAbove")
                    )}
                    description={localization.getString(
                        "thisAllowsEndUsersToContinueTheirUseOfFQDNWithinTheServiceTunnel"
                    )}
                    className={styles.multiInput}
                >
                    <MultiInput
                        label={localization.getString("privateDomain")}
                        onChange={onChangeDomains}
                        values={props.accessTierGroup?.privateDomains ?? props.values?.domains}
                        placeholder={localization.getString("egDomainNameDotCom")}
                        disabled={props.isReadMode || !!props.accessTierGroup}
                    />
                </FormRow>
            </div>
        </Accordion>
    )
}
