import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import {
    PolicyApi,
    PolicyRes,
    PolicySearchParams,
    PolicyAttachmentRes,
    PolicyTypeReq,
    PolicySpecReq,
    AccessReq,
    L4RuleReq,
    PolicySpecRes,
    L4RuleRes,
    PolicyAccessRes,
    AttachedToTypeRes,
} from "../api/Policy.api"
import { queryClient } from "../../queryClient"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import JsonStableStringify from "json-stable-stringify"
import { StatusType } from "../components/status/Status.component"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { SecureService } from "../../pre-v3/services/Secure.service"
import { PolicyMetadata as OldPolicyMetaData, PolicyAttr } from "../../pre-v3/api/Secure.api"
import { emptyDestination } from "../../pages/access-policies/shared/helper"
import { v4 as uuidv4 } from "uuid"
import { RoleApi } from "../api/Role.api"
import { StringUtil } from "../../pre-v3/utils/String.util"
import { getPolicyType, OtherPolicyTypes, PolicyStatus, PolicyType } from "./shared/Policy"

const serviceKeys = {
    GET_POLICIES: (searchParams?: PolicySearchParams) => [
        "policyService.getPolicies",
        ...(searchParams ? [JsonStableStringify(searchParams)] : []),
    ],
}

async function helperGetPolicies(
    searchParams?: PolicySearchParams,
    type?: PolicyType
): Promise<Policy[]> {
    const policyApi = new PolicyApi()

    const [policiesRes, policyAttachmentsRes] = await Promise.all([
        policyApi.getAccessPolicies(searchParams),
        policyApi.getPolicyAttachments(),
    ])

    const policyAttachmentsMap = policyAttachmentsRes.reduce<{
        [key: string]: PolicyAttachmentRes[]
    }>((acc, policyAttachmentRes) => {
        const policy = acc[policyAttachmentRes.PolicyID] || []
        return { ...acc, [policyAttachmentRes.PolicyID]: [...policy, policyAttachmentRes] }
    }, {})

    return policiesRes.reduce<Policy[]>((acc, res) => {
        const policy = mapPolicyResToPolicy(res, policyAttachmentsMap)
        if (!policy) return acc

        if (type) {
            return policy.type === type ? [...acc, policy] : acc
        }

        return [...acc, policy]
    }, [])
}

export function useGetPolicies(type?: PolicyType) {
    const queryClient = useQueryClient()
    const query = useQuery<Policy[], string>({
        queryKey: serviceKeys.GET_POLICIES(),
        queryFn: () => helperGetPolicies({}, type),
    })
    return {
        ...query,
        refetch: () => {
            queryClient.removeQueries(serviceKeys.GET_POLICIES())
            query.refetch()
        },
    }
}

export function useGetPoliciesByRoleId(
    roleID: string,
    options?: QueryOptions<Policy[]>
): UseQueryResult<Policy[]> {
    const query = useQuery<Policy[], string>({
        ...options,
        queryKey: serviceKeys.GET_POLICIES({ roleID }),
        queryFn: () => helperGetPolicies({ roleID }),
    })

    return {
        ...query,
        refetch: () => {
            queryClient.removeQueries(serviceKeys.GET_POLICIES({ roleID }))
            return query.refetch()
        },
    }
}

export function useGetPolicyById(id: string, options?: QueryOptions<Policy>) {
    const policyApi = new PolicyApi()
    const localization = useServiceLocalization()
    return useQuery<Policy, string>({
        ...options,
        queryKey: ["policyService.getPolicy", id],
        queryFn: async (): Promise<Policy> => {
            const [[policyRes], policyAttachmentsRes] = await Promise.all([
                policyApi.getAccessPolicies({ PolicyID: id }),
                policyApi.getPolicyAttachments(),
            ])

            const policyAttachmentsMap = policyAttachmentsRes.reduce<{
                [key: string]: PolicyAttachmentRes[]
            }>((acc, policyAttachmentRes) => {
                const policy = acc[policyAttachmentRes.PolicyID] || []
                return { ...acc, [policyAttachmentRes.PolicyID]: [...policy, policyAttachmentRes] }
            }, {})

            const policy = policyRes && mapPolicyResToPolicy(policyRes, policyAttachmentsMap)
            if (!policy) throw localization.getString("policyNotFound")

            return policy
        },
    })
}

export function useGetPoliciesByName(
    name: string,
    options?: QueryOptions<Policy[]>
): UseQueryResult<Policy[]> {
    const queryClient = useQueryClient()
    const query = useQuery<Policy[], string>({
        ...options,
        queryKey: ["policyService.getPolicies", name],
        queryFn: async () => {
            const policies = await helperGetPolicies()

            return policies.filter((policy) => policy.name === name)
        },
    })

    return {
        ...query,
        refetch: () => {
            queryClient.removeQueries(serviceKeys.GET_POLICIES())
            return query.refetch()
        },
    }
}

interface PolicyArgs {
    metadata: PolicyMetadata
    policyType: PolicyType
    spec: TunnelPolicySpec
}

interface OldPolicyArgs {
    metadata: OldPolicyReqMetadata
    type: "USER" | "WORKLOAD" | "CUSTOM"
    attr: OldPolicyReqAttr
}

export function useCreatePolicy(options?: QueryOptions<Policy, string, PolicyArgs>) {
    const ls = useServiceLocalization()
    const policyApi = new PolicyApi()
    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: async (args: PolicyArgs) => {
            const { metadata, policyType, spec } = args
            const existing = (await policyApi.getAccessPolicies()).find(
                (policy) => policy.PolicyName === metadata.name
            )
            if (existing) {
                const error: Error = new Error(
                    ls.getString(
                        "somethingNamedAlreadyExists",
                        ls.getString("policy"),
                        metadata.name
                    )
                )
                throw error.message
            }
            const response = await policyApi.insertPolicy({
                kind: "BanyanPolicy",
                apiVersion: "rbac.banyanops.com/v1",
                metadata: {
                    name: metadata.name,
                    description: metadata.description,
                    tags: {
                        template: getPolicyReqType(policyType),
                    },
                },
                type: getPolicyReqType(policyType),
                spec: mapPolicySpecToPolicySpecReq(spec),
            })
            return mapPolicyResToPolicy(response)
        },
        onSuccess: (newPolicy) => {
            options?.onSuccess?.(newPolicy as Policy)
            if (newPolicy) {
                queryClient.setQueryData(["policyService.getPolicy", newPolicy.id], newPolicy)
            }
            queryClient.invalidateQueries({ queryKey: serviceKeys.GET_POLICIES() })
        },
    })
}

export function useEditPolicy(id: string, options?: QueryOptions<void, string, Policy>) {
    const policyApi = new PolicyApi()

    return useMutation({
        ...options,
        mutationFn: async (args: PolicyArgs) => {
            const { metadata, policyType, spec } = args
            const response = await policyApi.insertPolicy({
                kind: "BanyanPolicy",
                apiVersion: "rbac.banyanops.com/v1",
                metadata: {
                    name: metadata.name,
                    description: metadata.description,
                    tags: {
                        template: getPolicyReqType(policyType),
                    },
                },
                type: getPolicyReqType(policyType),
                spec: mapPolicySpecToPolicySpecReq(spec),
            })
            const policyAttachmentsRes = await policyApi.getPolicyAttachments()

            const policyAttachmentsMap = policyAttachmentsRes.reduce<{
                [key: string]: PolicyAttachmentRes[]
            }>((acc, policyAttachmentRes) => {
                const policy = acc[policyAttachmentRes.PolicyID] || []
                return { ...acc, [policyAttachmentRes.PolicyID]: [...policy, policyAttachmentRes] }
            }, {})
            return mapPolicyResToPolicy(response, policyAttachmentsMap)
        },
        onSuccess: (updatedPolicy) => {
            options?.onSuccess?.()
            queryClient.setQueryData(["policyService.getPolicy", id], updatedPolicy)
        },
    })
}

export function useOldEditPolicy(id: string, options?: QueryOptions<void, string, Policy>) {
    const policyApi = new PolicyApi()

    return useMutation({
        ...options,
        mutationFn: async (args: OldPolicyArgs) => {
            const { metadata, type, attr } = args
            const policy = await policyApi.insertPolicy({
                kind: "BanyanPolicy",
                apiVersion: "rbac.banyanops.com/v1",
                metadata: metadata,
                type: type,
                spec: attr,
            })

            const policyAttachmentsRes = await policyApi.getPolicyAttachments()

            const policyAttachmentsMap = policyAttachmentsRes.reduce<{
                [key: string]: PolicyAttachmentRes[]
            }>((acc, policyAttachmentRes) => {
                const policy = acc[policyAttachmentRes.PolicyID] || []
                return { ...acc, [policyAttachmentRes.PolicyID]: [...policy, policyAttachmentRes] }
            }, {})
            return mapPolicyResToPolicy(policy, policyAttachmentsMap)
        },
        onSuccess: (updatedPolicy) => {
            options?.onSuccess?.()
            queryClient.setQueryData(["policyService.getPolicy", id], updatedPolicy)
        },
    })
}

export function useGetRoles(options?: QueryOptions<string[]>): UseQueryResult<string[]> {
    const roleApi = new RoleApi()
    const query = useQuery<string[], string>({
        ...options,
        queryKey: ["policyService.getRoles"],
        queryFn: async () => {
            const response = await roleApi.getRoles()
            return response.map((role) => role.RoleName)
        },
    })

    return {
        ...query,
    }
}

interface DeleteArgs {
    id: string
}
export function useDeletePolicy() {
    const policyApi = new PolicyApi()
    const queryClient = useQueryClient()

    return useMutation<void, Error, DeleteArgs>({
        mutationFn: (args: DeleteArgs) => {
            const { id } = args
            return policyApi.deletePolicy(id)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: serviceKeys.GET_POLICIES() })
        },
    })
}

//used in Old policy views.
interface CreateArgs {
    metadata: OldPolicyMetaData
    type: string
    attr: PolicyAttr
}
export function useCreatePolicyOld() {
    const secureService = new SecureService()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (args: CreateArgs) => {
            const { metadata, type, attr } = args
            return secureService.createPolicy(metadata, type, attr)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: serviceKeys.GET_POLICIES() })
        },
    })
}

export interface TunnelPolicy {
    id: string
    name: string
    type: PolicyType.TUNNEL
    status: PolicyStatus
    lastUpdatedAt: number
    noOfAttachments: number
    description: string
    spec: TunnelPolicySpec
    version: string
    lastUpdatedBy: string
    createdAt: number
    createdBy: string
    attachedServices?: TunnelAttachedService[]
}

export interface OtherPolicy {
    id: string
    name: string
    type: OtherPolicyTypes
    description: string
    spec: string
    version: number
    lastUpdatedAt: number
    lastUpdatedBy: string
    createdAt: number
    createdBy: string
    attachments: OtherPolicyAttachment[]
    status: PolicyStatus
}

export interface OtherPolicyAttachment {
    policyId: string
    policyName: string
    serviceId: string
    serviceName: string
    serviceFriendlyName: string
    attachedAt: number
    attachedToType?: PolicyAttachmentType
    enabled: boolean
}

export enum PolicyAttachmentType {
    SAAS_APP = "saasapp",
    SERVICE = "service",
}

const policyAttachmentResToPolicyAttachmentTypeMap: Record<
    AttachedToTypeRes,
    PolicyAttachmentType
> = {
    saasapp: PolicyAttachmentType.SAAS_APP,
    service: PolicyAttachmentType.SERVICE,
}

export type Policy = OtherPolicy | TunnelPolicy

export interface PolicyAttachment {
    serviceId: string
    serviceName: string
    policyId: string
    policyName: string
    enabled: boolean
    attachedAt?: number
    type?: PolicyType
}

export interface TunnelAttachedService {
    serviceId: string
    serviceName: string
}

export function isAnyIpRange(data: L4Rule): boolean {
    if (!data.fqdnList && !data.ipRanges && data.protocols.length > 0) {
        return true
    } else if (data.ipRanges === "ANY") {
        return true
    } else {
        return false
    }
}

export function isAnyPort(data: L4Rule): boolean {
    if (data.protocols?.length === 1 && data.protocols[0] === L4Protocol.ICMP) {
        return false
    }
    return data.ports === "ANY"
}

export function isDenyListEmpty(data: L4Rule[]): boolean {
    return !data.some(
        (d) =>
            d.description !== "" ||
            d.fqdnList !== "" ||
            d.ipRanges !== "" ||
            d.ports.length > 0 ||
            d.protocols.length > 0
    )
}

function mapL4Access(access: L4Rule[]): L4RuleReq[] {
    if (access.length === 0) {
        return []
    }
    return access
        .filter((a) => a.protocols.length !== 0)
        .map((access) => {
            return {
                cidrs: getCidrs(access),
                protocols: access.protocols,
                ports:
                    access.ports === "ANY"
                        ? ["*"]
                        : StringUtil.stringListToArrayList(access.ports.toString()),
                fqdns: StringUtil.stringListToArrayList(access.fqdnList),
                description: access.description,
            }
        })
}

function getCidrs(access: L4Rule): string[] {
    if (!access.fqdnList) {
        return access.ipRanges === "ANY" ? ["*"] : StringUtil.stringListToArrayList(access.ipRanges)
    } else {
        return []
    }
}

function mapPolicySpecToPolicySpecReq(policySpec: TunnelPolicySpec): PolicySpecReq {
    const access: AccessReq[] = policySpec.access.map((access) => {
        return {
            name: access.name,
            description: access.description,
            roles: access.roles,
            rules: {
                l4_access: {
                    allow: mapL4Access(access.rules.l4_access?.allow || []),
                    deny: mapL4Access(access.rules.l4_access?.deny || []),
                },
                conditions: {
                    trust_level:
                        access.rules.conditions.trust_level === TrustLevel.NONE
                            ? ""
                            : access.rules.conditions.trust_level,
                },
            },
        }
    })
    return {
        access,
        options: policySpec.options,
    }
}

function mapPolicyResToPolicy(
    res: PolicyRes,
    policyAttachmentsMap?: { [key: string]: PolicyAttachmentRes[] }
): Policy | undefined {
    const type = getPolicyType(res)

    if (type === PolicyType.TUNNEL)
        return mapPolicyResToTunnelPolicy(res, type, policyAttachmentsMap)
    return mapPolicyResToOtherPolicy(res, type, policyAttachmentsMap)
}

function mapPolicyResToTunnelPolicy(
    res: PolicyRes,
    type: PolicyType.TUNNEL,
    policyAttachmentsMap?: { [key: string]: PolicyAttachmentRes[] }
): TunnelPolicy | undefined {
    const spec = getPolicySpec(res)
    const attachedServices: TunnelAttachedService[] =
        policyAttachmentsMap?.[res.PolicyID]?.map((policy) => ({
            serviceId: policy.AttachedToID,
            serviceName: policy.AttachedToName,
        })) || []
    const noOfAttachments = attachedServices.length

    return (
        spec && {
            id: res.PolicyID,
            name: res.PolicyName,
            type,
            status: noOfAttachments > 0 ? PolicyStatus.ACTIVE : PolicyStatus.INACTIVE,
            lastUpdatedAt: DateUtil.convertLargeTimestamp(res.LastUpdatedAt),
            noOfAttachments,
            lastUpdatedBy: res.LastUpdatedBy,
            description: res.Description,
            spec: mapTunnelPolicySpecFromRes(spec),
            version: "v" + res.PolicyVersion,
            createdAt: DateUtil.convertLargeTimestamp(res.CreatedAt),
            createdBy: res.CreatedBy,
            attachedServices,
        }
    )
}
function mapPolicyResToOtherPolicy(
    res: PolicyRes,
    type: OtherPolicyTypes,
    policyAttachmentsMap?: { [key: string]: PolicyAttachmentRes[] }
): OtherPolicy | undefined {
    const attachments: OtherPolicyAttachment[] =
        policyAttachmentsMap && policyAttachmentsMap[res.PolicyID]
            ? policyAttachmentsMap[res.PolicyID].map((sp) => ({
                  policyId: sp.PolicyID,
                  policyName: sp.PolicyName,
                  serviceId: sp.AttachedToID,
                  serviceName: sp.AttachedToName,
                  serviceFriendlyName: sp.AttachedToFriendlyName,
                  attachedAt: DateUtil.convertLargeTimestamp(sp.AttachedAt),
                  attachedToType:
                      sp.AttachedToType &&
                      policyAttachmentResToPolicyAttachmentTypeMap[sp.AttachedToType],
                  enabled: sp.Enabled === "TRUE",
              }))
            : []

    return {
        id: res.PolicyID,
        name: res.PolicyName,
        type,
        description: res.Description,
        spec: res.PolicySpec,
        version: res.PolicyVersion,
        lastUpdatedAt: DateUtil.convertLargeTimestamp(res.LastUpdatedAt),
        lastUpdatedBy: res.LastUpdatedBy,
        createdAt: DateUtil.convertLargeTimestamp(res.CreatedAt),
        createdBy: res.CreatedBy,
        attachments,
        status: attachments.length > 0 ? PolicyStatus.ACTIVE : PolicyStatus.INACTIVE,
    }
}

function getPolicySpec(res: PolicyRes): PolicySpecRes | undefined {
    try {
        return JSON.parse(res.PolicySpec).spec
    } catch (error) {
        console.error(`Policy spec could not be parsed for ${res.PolicyID}.`)
        return
    }
}

function mapTunnelPolicySpecFromRes(specRes: PolicySpecRes): TunnelPolicySpec {
    return {
        ...specRes,
        access: specRes.access.map(mapPolicyAccessFromRes),
    }
}

function mapPolicyAccessFromRes(res: PolicyAccessRes): PolicyAccess {
    return {
        ...res,
        id: uuidv4(),
        rules: {
            ...res.rules,
            l4_access: {
                ...res.rules.l4_access,
                allow: res.rules.l4_access?.allow?.map(mapL4RuleFromRes) ?? [],
                deny: res.rules.l4_access?.deny?.map(mapL4RuleFromRes),
            },
        },
    }
}

function mapL4RuleFromRes(res: L4RuleRes): L4Rule {
    const { cidrs: ipRanges, fqdns: fqdnList, ...rest } = res
    return {
        ...res,
        protocols: res.protocols ?? [],
        cidrs: res.cidrs ?? [],
        id: uuidv4(),
        ipRanges: ipRanges?.includes("*") ? "ANY" : ipRanges ? ipRanges.toString() : "",
        fqdnList: fqdnList ? fqdnList.toString() : "",
        ports: rest.ports?.includes("*") ? "ANY" : rest.ports ?? [],
    }
}

export function getNullPolicySpecRes(includeL4Access?: boolean): TunnelPolicySpec {
    const nullPolicySpecRes: TunnelPolicySpec = {
        access: [
            {
                roles: [],
                id: uuidv4(),
                rules: {
                    conditions: {
                        trust_level: "",
                    },
                },
                name: "",
                description: "",
            },
        ],
    }

    if (includeL4Access) {
        nullPolicySpecRes.access[0].rules.l4_access = {
            allow: [emptyDestination],
            deny: [emptyDestination],
        }
    }

    return nullPolicySpecRes
}

function getPolicyReqType(type: PolicyType): PolicyTypeReq {
    if (type === PolicyType.CUSTOM) {
        return "CUSTOM"
    } else {
        return "USER"
    }
}

export const statusMap: Record<PolicyStatus, StatusType> = {
    inactive: "disabled",
    active: "success",
}

export const labelMap: Record<PolicyStatus, LanguageKey> = {
    active: "active",
    inactive: "inactive",
}

export interface PolicyMetadata {
    id?: string
    name: string
    description: string
}

export interface PolicyL4Access {
    deny?: L4Rule[]
    allow: L4Rule[]
}

export interface PolicyAccess {
    name?: string
    id: string
    description?: string
    roles: string[]
    rules: PolicyRules
}

export interface PolicyRules {
    l4_access?: PolicyL4Access
    conditions: PolicyConditions
}

export interface PolicyL4Access {
    deny?: L4Rule[]
    allow: L4Rule[]
}

export interface TunnelPolicySpec {
    access: PolicyAccess[]
    options?: {
        l7_protocol: string
    }
}

export interface L4Rule {
    id: string
    ipRanges: string | "ANY"
    fqdnList: string
    protocols: L4Protocol[]
    ports: string[] | "ANY"
    description: string
    fqdns: string[]
    cidrs: string[]
}

export enum L4Protocol {
    ALL = "ALL",
    TCP = "TCP",
    UDP = "UDP",
    ICMP = "ICMP",
}

export const l4ProtocolLabels: Record<L4Protocol, LanguageKey> = {
    ALL: "all",
    TCP: "tcp",
    UDP: "udp",
    ICMP: "icmp",
}

export enum TrustLevel {
    NONE = "None",
    LOW = "Low",
    MID = "Medium",
    HIGH = "High",
}

export enum PolicyServiceType {
    WEB = "WEB",
    TCP = "TCP",
    TUNNEL = "TUNNEL",
    CUSTOM = "CUSTOM",
}

export interface PolicyConditions {
    trust_level?: string
}

interface OldPolicyReqMetadata {
    id?: string
    name: string
    description: string
    tags: StringMap
}

interface OldPolicyReqAttr {
    access: OldPolicyAccess[]
    exception?: OldPolicyException
    options?: OldPolicyOptions
}
interface OldPolicyAccess {
    roles: string[]
    rules: OldPolicyRules
}

interface OldPolicyRules {
    l7_access?: OldPolicyL7Access[]
    l4_access?: OldPolicyL4Access
    conditions: OldPolicyConditions
}
interface OldPolicyL7Access {
    resources: string[]
    actions: OldPolicyL7Action[]
}

interface OldPolicyL4Access {
    deny: OldL4Rule[]
    allow: OldL4Rule[]
}

interface OldPolicyConditions {
    start_time?: string
    end_time?: string
    trust_level?: string
}

interface OldL4Rule {
    cidrs: string[]
    protocols: L4Protocol[]
    ports: string[]
    fqdns: string[]
}

enum OldPolicyL7Action {
    "CREATE" = "CREATE",
    "READ" = "READ",
    "UPDATE" = "UPDATE",
    "DELETE" = "DELETE",
    // TODO: Find a name for this option
    "*" = "*",
}

interface OldPolicyException {
    src_addr: string[]
}
interface OldPolicyOptions {
    disable_tls_client_authentication: boolean
    l7_protocol: OldPolicyL7Protocol
}
type OldPolicyL7Protocol = "http" | "kafka" | "mysql" | ""

export { PolicyType }
