import React from "react"
import useTitle from "../../hooks/useTitle.hook"
import { useServiceLocalization } from "../../pre-v3/services"
import { Route, RouteComponentProps, Switch, useHistory } from "react-router-dom"
import { Banner, Variant } from "../../components/banner/Banner.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { Loader } from "../../v3/components/loader/Loader.component"
import { ROUTE } from "../../routes"
import { ApiKeysAdd } from "../../pre-v3/views/settings/org/api-keys/add/ApiKeysAdd.component"
import { ApiKeysDetail } from "../../pre-v3/views/settings/org/api-keys/detail/ApiKeysDetail.component"
import { ApiKeysSettings } from "../../pre-v3/views/settings/org/api-keys/ApiKeysSettings.component"
import { ActionBar } from "../../components/action-bar/ActionBar.component"
import styles from "./MomApiKeys.module.scss"

export const MomApiKeys = () => {
    useTitle(["apiKeys", "momConsole"])

    const featureFlagsQuery = useFeatureFlags()
    const localization = useServiceLocalization()
    const history = useHistory()

    if (featureFlagsQuery.status === "loading") {
        return <Loader center medium title={localization.getString("loading")} />
    }

    if (featureFlagsQuery.status === "error") {
        return <Banner label={String(featureFlagsQuery.error)} variant={Variant.ERROR} />
    }
    return (
        <div className={styles.container}>
            <ActionBar
                title={localization.getString("apiKeys")}
                breadcrumbs={[
                    { label: localization.getString("superAdmin") },
                    { label: localization.getString("apiKeys"), href: ROUTE.MOM_API_KEYS },
                ]}
            />
            <div className={styles.page}>
                <Switch>
                    <Route
                        path={ROUTE.MOM_API_KEYS_ADD}
                        component={(props: RouteComponentProps) => (
                            <ApiKeysAdd isMomConsole {...props} />
                        )}
                    ></Route>
                    <Route
                        path={ROUTE.MOM_API_KEYS_DETAILS}
                        component={(props: RouteComponentProps<{ id: string }>) => (
                            <ApiKeysDetail
                                isMomConsole
                                canAccessApiKey={
                                    featureFlagsQuery.data.adminConsole.canAccessApiKey
                                }
                                {...props}
                            />
                        )}
                    />
                    <Route path={ROUTE.MOM_API_KEYS}>
                        <ApiKeysSettings
                            history={history}
                            canAccessApiKey={featureFlagsQuery.data.adminConsole.canAccessApiKey}
                            isMomConsole
                        />
                    </Route>
                </Switch>
            </div>
        </div>
    )
}
