import { ROUTE } from "../../routes"
import { BaseApi } from "./Base.api"
import { Singleton } from "../decorators/Singleton.decorator"

@Singleton("AuthApi")
export class AuthApi extends BaseApi {
    public checkOrg(orgName: string): Promise<AuthRes> {
        return this.get("/api/v1/org_status?OrgName=" + orgName)
    }

    public login(auth: LoginReq): Promise<LoginRes> {
        return this.postForm<LoginReq, LoginRes>("/api/v1/authorize", auth)
    }

    public samlLogin(orgName: string): Promise<AuthRes> {
        return this.get("/api/v1/saml_auth?OrgName=" + orgName)
    }

    public resetPassword(
        orgName: string,
        email: string,
        password: string,
        uuid: string
    ): Promise<AuthRes> {
        return this.postForm("/api/v1/reset_password", {
            UUID: uuid,
            OrgName: orgName,
            Email: email,
            Password: password,
            Password2: password,
        })
    }

    public forgotPassword(orgName: string, email: string): Promise<AuthRes> {
        const params = new URLSearchParams({
            OrgName: orgName,
            Email: email,
            APIServerLocation: window.location.host + ROUTE.LOGIN_RESET,
        })

        return this.get(`/api/v1/forgot_password?${params.toString()}`)
    }
}

interface LoginReq {
    Password: string
    Email: string
    OrgName: string
}

export interface LoginRes {
    Message: string
    mfa_configured: boolean
    mfa_required: boolean
}

export interface AuthRes {
    Message: OrgStatus
    AdminIDPType: AdminIDPType | ""
    IsAdminBanyanIDP: boolean
    RefreshToken?: string
    mfa_configured?: boolean
    mfa_required?: boolean
}

export enum OrgStatus {
    SAML = "SAML",
    TEAM = "TEAM",
    BANYAN = "BANYAN",
}

export enum AdminIDPType {
    LOCAL = "LOCAL",
    SAML = "SAML",
}
