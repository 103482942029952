import classNames from "classnames/bind"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import { Tooltip } from "../tooltip/Tooltip.component"
import { FormLabel, Props as FormLabelProps } from "./FormLabel.component"
import indentedStyles from "./Indented.module.scss"
import styles from "./FormRow.module.scss"

interface Props extends FormLabelProps {
    indented?: boolean
    labelClassName?: string
    childrenClassName?: string
    children?: React.ReactNode
    removeLabel?: string
    onRemove?: () => void
    removeButtonAlign?: RemoveButtonAlign
}

export enum RemoveButtonAlign {
    CENTER = "center",
    TOP = "top",
    BOTTOM = "bottom",
}

export function FormRow(props: Props): JSX.Element {
    const removeButtonClassName = classNames({
        [styles.removeButton]:
            !props.removeButtonAlign || props.removeButtonAlign === RemoveButtonAlign.CENTER,
        [styles.removeButtonBottom]: props.removeButtonAlign === RemoveButtonAlign.BOTTOM,
        [styles.removeButtonTop]: props.removeButtonAlign === RemoveButtonAlign.TOP,
    })
    return (
        <div
            role="group"
            aria-labelledby={props.id}
            className={classNames(styles.container, props.className)}
        >
            <FormLabel
                id={props.id}
                label={props.label}
                description={props.description}
                htmlFor={props.htmlFor}
                className={classNames(styles.formLabel, props.className, {
                    [indentedStyles.indented]: props.indented,
                })}
                labelClassName={props.labelClassName}
            />
            <div className={classNames(styles.children, props.childrenClassName)}>
                {props.children}
            </div>
            {props.removeLabel && (
                <Tooltip title={props.removeLabel}>
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        className={removeButtonClassName}
                        icon={IconType.TRASH}
                        onClick={props.onRemove}
                    />
                </Tooltip>
            )}
        </div>
    )
}
