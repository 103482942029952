import React from "react"

import { useServiceLocalization, useServiceLinks, LinkKey } from "../../../../../pre-v3/services"
import { AppText } from "../../../../components/app-text/AppText.component"
import { ErrorBanners } from "../../../../components/banner/Banner.component"
import { Loader } from "../../../../components/loader/Loader.component"
import { ApiKey, useGetApiKey } from "../../../../services/ApiKey.service"
import {
    Connector,
    getConfigYamlParameters,
    getConnectionTestCommand,
    getConnectivityParameters,
    getDockerConnectivityCheckCommand,
    getDockerInstallCommand,
    getDownloadBinaryCommand,
    getTarballInstallCommand,
    LinuxInstallationMethod,
    ServerEnvironments,
    useGetLatestConnectorVersion,
    VirtualApplianceInstallationMethod,
    WindowsInstallationMethod,
} from "../../../../services/Connector.service"
import styles from "./ConnectorInstallForm.module.scss"
import { FormRow } from "../../../../../components/form/FormRow.component"
import { ToggleButton } from "../../../../../components/toggle-buttons/ToggleButtons.component"
import { CodeWithCopy } from "../../../../../components/code-with-copy/CodeWithCopy"
import {
    useGetLinuxInstallationMethod,
    useGetServerEnvironmentsOptions,
} from "./ConnectorInstallFrom.hooks"
import { WindowsInstall } from "./WindowsInstall.component"
import { VirtualApplianceInstall } from "./VirtualApplianceInstall.component"

export function ConnectorInstallForm(props: ConnectorInstallFormProps) {
    const links = useServiceLinks()
    const localization = useServiceLocalization()

    const [serverEnvironment, setServerEnvironment] = React.useState<ServerEnvironments>(
        props.connector.deployment?.platform ?? ServerEnvironments.WINDOWS
    )
    const [installationMethod, setInstallationMethod] = React.useState<LinuxInstallationMethod>(
        props.connector.deployment?.platform === ServerEnvironments.LINUX
            ? props.connector.deployment.method
            : LinuxInstallationMethod.TARBALL
    )

    const { getServerEnvironmentsOptions, data: serverEnvironmentArr } =
        useGetServerEnvironmentsOptions()
    const { getLinuxInstallationMethodOptions, data: installationMethodArr } =
        useGetLinuxInstallationMethod()

    const onServerEnvironmentToggle = (serverEnvironment: ServerEnvironments) => {
        setServerEnvironment(serverEnvironment)
        props.onDeploymentChange?.(getDeployment(serverEnvironment, installationMethod))
    }
    const onInstallationMethodToggle = (method: LinuxInstallationMethod) => {
        setInstallationMethod(method)
        props.onDeploymentChange?.(getDeployment(serverEnvironment, method))
    }

    const url = window.location.origin
    const { error: apiKeyError, data: apiKey } = useGetApiKey(props.connector.apiKeyId)

    const { error: latestVersionError, data: latestVersion } = useGetLatestConnectorVersion()
    if (apiKeyError || latestVersionError) {
        return <ErrorBanners errors={[String(apiKeyError), String(latestVersionError)]} />
    }

    if (!apiKey || !latestVersion) {
        return (
            <Loader
                children
                isLoading
                medium
                center
                title={localization.getString("loadingAPIKeysToAddConnectors")}
            />
        )
    }

    return (
        <div className={styles.container}>
            <FormRow
                label={localization.getString("whereWillYouInstallThisConnector")}
                description={localization.getString("whereWillYouInstallThisConnectorDescription")}
                className={styles.methodSelect}
            >
                <ToggleButton
                    data={serverEnvironmentArr}
                    getOptions={getServerEnvironmentsOptions}
                    onChange={onServerEnvironmentToggle}
                    selectedValue={serverEnvironment}
                />
            </FormRow>
            {serverEnvironment === ServerEnvironments.LINUX && (
                <FormRow
                    label={localization.getString("selectAnInstallationMethod")}
                    className={styles.methodSelect}
                >
                    <ToggleButton
                        data={installationMethodArr}
                        getOptions={getLinuxInstallationMethodOptions}
                        onChange={onInstallationMethodToggle}
                        selectedValue={installationMethod}
                    />
                </FormRow>
            )}
            <FormRow
                label={localization.getString("networkRequirementsForConnectorInstall")}
                description={
                    <AppText
                        ls={{
                            key: "pleaseCheckDocumentForPreReqAndMoreDetails",
                            replaceVals: [
                                links.getLink(getDocLink(serverEnvironment, installationMethod)),
                            ],
                        }}
                    />
                }
            />
            <h3 className={styles.title}>
                {localization.getString(
                    isDockerInstall(serverEnvironment, installationMethod)
                        ? "installationInstructions"
                        : "connectivityCheckAndInstallationInstructions"
                )}
            </h3>
            {serverEnvironment === ServerEnvironments.LINUX && (
                <LinuxCommands
                    installationMethod={installationMethod}
                    connector={props.connector}
                    apiKey={apiKey}
                    url={url}
                    latestVersion={latestVersion}
                    canAccessConnectorInstallStep={props.canAccessConnectorInstallStep}
                />
            )}
            {serverEnvironment === ServerEnvironments.WINDOWS && (
                <WindowsInstall
                    ApiKey={apiKey.secret}
                    connectorName={props.connector.name}
                    fqdn={url}
                    canAccessConnectorInstallStep={props.canAccessConnectorInstallStep}
                    latestVersion={latestVersion}
                />
            )}
            {serverEnvironment === ServerEnvironments.VIRTUAL_APPLIANCE && (
                <VirtualApplianceInstall
                    ApiKey={apiKey.secret}
                    connectorName={props.connector.name}
                    fqdn={url}
                    canAccessConnectorInstallStep={props.canAccessConnectorInstallStep}
                    latestVersion={latestVersion}
                />
            )}
        </div>
    )
}

interface LinuxCommandsPros {
    installationMethod: LinuxInstallationMethod
    connector: Connector
    apiKey: ApiKey
    url: string
    latestVersion: string
    canAccessConnectorInstallStep?: boolean
}
function LinuxCommands({
    installationMethod,
    connector,
    apiKey,
    url,
    latestVersion,
    canAccessConnectorInstallStep,
}: LinuxCommandsPros) {
    if (installationMethod === LinuxInstallationMethod.DOCKER)
        return (
            <DockerCommands
                connector={connector}
                apiKey={apiKey}
                commandCenterUrl={url}
                latestVersion={latestVersion}
                canAccessConnectorInstallStep={canAccessConnectorInstallStep}
            />
        )
    return (
        <TarballCommands
            connector={connector}
            apiKey={apiKey}
            commandCenterUrl={url}
            latestVersion={latestVersion}
            canAccessConnectorInstallStep={canAccessConnectorInstallStep}
        />
    )
}

interface ConnectorInstallFormProps {
    connector: Connector
    onDeploymentChange?: (deployment: Connector["deployment"]) => void
    canAccessConnectorInstallStep?: boolean
}

interface CommandsProps {
    connector: Connector
    apiKey: ApiKey
    commandCenterUrl: string
    latestVersion: string
    canAccessConnectorInstallStep?: boolean
}

function DockerCommands(props: CommandsProps): JSX.Element {
    const localization = useServiceLocalization()
    const links = useServiceLinks()

    return (
        <React.Fragment>
            <div className={styles.step}>
                <label>{localization.getString("dockerConnectivityDescription")}</label>
                <CodeWithCopy
                    title={localization.getString("step", "1")}
                    text={getConnectivityParameters(
                        props.connector,
                        props.commandCenterUrl,
                        props.apiKey.secret || ""
                    )}
                    hideCopyButton={!props.canAccessConnectorInstallStep}
                />
            </div>
            <div className={styles.step}>
                <label>{localization.getString("dockerInstallDescription")}</label>

                <CodeWithCopy
                    title={localization.getString("step", "2")}
                    text={getDockerInstallCommand(props.connector, props.latestVersion)}
                    hideCopyButton={!props.canAccessConnectorInstallStep}
                />
            </div>
            <div className={styles.step}>
                <label>
                    {
                        <AppText
                            ls={{
                                key: "dockerChecks",
                                replaceVals: [
                                    links.getLink(
                                        "dockerInstallDocForConnectorPreInstallCheckList"
                                    ),
                                ],
                            }}
                        />
                    }
                </label>

                <CodeWithCopy
                    title={localization.getString("step", "3")}
                    text={getDockerConnectivityCheckCommand(props.connector)}
                    hideCopyButton={!props.canAccessConnectorInstallStep}
                />
            </div>
        </React.Fragment>
    )
}

function TarballCommands(props: CommandsProps): JSX.Element {
    const localization = useServiceLocalization()
    const links = useServiceLinks()

    return (
        <React.Fragment>
            <div className={styles.step}>
                <label>{localization.getString("downloadAndExtractTheBinary")}</label>
                <CodeWithCopy
                    title={localization.getString("step", "1")}
                    text={getDownloadBinaryCommand(props.latestVersion)}
                    hideCopyButton={!props.canAccessConnectorInstallStep}
                />
            </div>
            <div className={styles.step}>
                <label>
                    <AppText
                        ls={{
                            key: "moveToPackageDirectoryAndRunNetworkConnectivityScript",
                            replaceVals: [
                                links.getLink("tarballInstallDocForConnectorPreInstallCheckList"),
                            ],
                        }}
                    />
                </label>
                <CodeWithCopy
                    title={localization.getString("step", "2")}
                    text={getConnectionTestCommand(props.commandCenterUrl, props.latestVersion)}
                    hideCopyButton={!props.canAccessConnectorInstallStep}
                />
            </div>
            <div className={styles.step}>
                <label>
                    {localization.getString("updateConnectorConfigYamlWithSonicWallCseVariables")}
                </label>
                <CodeWithCopy
                    title={localization.getString("step", "3")}
                    text={getConfigYamlParameters(
                        props.connector,
                        props.commandCenterUrl,
                        props.apiKey.secret || ""
                    )}
                    hideCopyButton={!props.canAccessConnectorInstallStep}
                />
            </div>
            <div className={styles.step}>
                <label>{localization.getString("runNetagentInstallScript")}</label>
                <CodeWithCopy
                    title={localization.getString("step", "4")}
                    text={getTarballInstallCommand()}
                    hideCopyButton={!props.canAccessConnectorInstallStep}
                />
            </div>
        </React.Fragment>
    )
}

function getDeployment(
    serverEnvironment: ServerEnvironments,
    installationMethod: LinuxInstallationMethod
): Connector["deployment"] {
    if (serverEnvironment === ServerEnvironments.WINDOWS)
        return {
            platform: serverEnvironment,
            method: WindowsInstallationMethod.EXECUTABLE,
        }
    if (serverEnvironment === ServerEnvironments.VIRTUAL_APPLIANCE)
        return {
            platform: serverEnvironment,
            method: VirtualApplianceInstallationMethod.EXECUTABLE,
        }
    return {
        platform: serverEnvironment,
        method: installationMethod,
    }
}

function getDocLink(
    serverEnvironment: ServerEnvironments,
    installationMethod: LinuxInstallationMethod
): LinkKey {
    if (serverEnvironment === ServerEnvironments.WINDOWS) return "windowsInstallForConnector"
    if (installationMethod === LinuxInstallationMethod.DOCKER) return "dockerInstallDocForConnector"
    return "tarballInstallDocForConnector"
}

function isDockerInstall(
    serverEnvironment: ServerEnvironments,
    linuxInstallationMethod: LinuxInstallationMethod
): boolean {
    return (
        serverEnvironment === ServerEnvironments.LINUX &&
        linuxInstallationMethod === LinuxInstallationMethod.DOCKER
    )
}
