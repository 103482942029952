import React from "react"

import cloudformationImage from "../../../images/cloudformation.svg"
import dockerImage from "../../../images/docker2.svg"
import debPkgImage from "../../../images/debPkg.svg"
import tarballImage from "../../../images/tarball2.svg"
import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { FormColumn } from "../../../v3/components/form/FormColumn.component"
import { ToggleButton, ToggleButtonItem } from "../../../v3/components/toggle-button/ToggleButton"
import { InstallationMethod } from "../../../v3/services/AccessTier.service"
import styles from "./AccessTierInstallationMethod.module.scss"

interface Props {
    installationMethod: InstallationMethod
    onInstallationMethodChange(installationMethod: InstallationMethod): void
}

export function AccessTierInstallationMethod(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const installationMethodOptions = React.useMemo(
        () =>
            Object.values(InstallationMethod).map<ToggleButtonItem>((installationMethod) => ({
                label: localization.getString(installationMethodLabelKeys[installationMethod]),
                onClick: () => props.onInstallationMethodChange(installationMethod),
                image: installationMethodImage[installationMethod],
                altText: localization.getString(installationMethodAltTextKeys[installationMethod]),
                selected: props.installationMethod === installationMethod,
            })),
        [localization, props]
    )

    return (
        <FormColumn
            label={localization.getString("selectAnInstallationMethod")}
            className={styles.formColumn}
        >
            <ToggleButton items={installationMethodOptions} />
        </FormColumn>
    )
}

const installationMethodLabelKeys: Record<InstallationMethod, LanguageKey> = {
    [InstallationMethod.DOCKER_CONTAINER]: "dockerContainer",
    [InstallationMethod.TARBALL_INSTALLER]: "tarballInstaller",
    [InstallationMethod.DEBIAN_PACKAGE]: "debianPackage",
    [InstallationMethod.CLOUD_FORMATION]: "cloudFormation",
}

const installationMethodAltTextKeys: Record<InstallationMethod, LanguageKey> = {
    [InstallationMethod.DOCKER_CONTAINER]: "docker",
    [InstallationMethod.TARBALL_INSTALLER]: "tarball",
    [InstallationMethod.DEBIAN_PACKAGE]: "debianPackage",
    [InstallationMethod.CLOUD_FORMATION]: "cloudFormation",
}

const installationMethodImage: Record<InstallationMethod, string> = {
    [InstallationMethod.DOCKER_CONTAINER]: dockerImage,
    [InstallationMethod.TARBALL_INSTALLER]: tarballImage,
    [InstallationMethod.DEBIAN_PACKAGE]: debPkgImage,
    [InstallationMethod.CLOUD_FORMATION]: cloudformationImage,
}
