import React from "react"
import { ConfigureAccessGroup } from "./ConfigureAccessGroup.component"
import styles from "./AccessGroup.module.scss"
import { FormLabel } from "../../../components/form/FormLabel.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import { emptyPolicyAccess } from "./helper"
import { v4 as uuidV4 } from "uuid"
import { PolicyAccessExtendedWithId, PolicySpecExtendedWithId } from "./PolicyAccess.type"

export interface Props {
    disabled?: boolean
    policySpec: PolicySpecExtendedWithId
    onPolicySpecChange: (policySpec: PolicySpecExtendedWithId) => void
}

export function AccessGroup(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const onAccessGroupChange = (policyAccess: PolicyAccessExtendedWithId, index: number) => {
        const updatedPolicyAccess: PolicyAccessExtendedWithId[] = [...props.policySpec.access]
        updatedPolicyAccess[index] = policyAccess

        props.onPolicySpecChange({
            ...props.policySpec,
            access: updatedPolicyAccess,
        })
    }

    const onDeleteAccessGroup = (index: number) => {
        props.onPolicySpecChange({
            ...props.policySpec,
            access: props.policySpec.access.filter((_, i) => i !== index),
        })
    }

    const onAddAccessGroup = () => {
        props.onPolicySpecChange({
            ...props.policySpec,
            access: [...props.policySpec.access, { ...emptyPolicyAccess, id: uuidV4() }],
        })
    }

    return (
        <div className={styles.accessGroup}>
            <div className={styles.labelContainer}>
                <FormLabel
                    labelClassName={styles.labelClassName}
                    label={localization.getString("accessGroups")}
                ></FormLabel>
                {!props.disabled && (
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        onClick={onAddAccessGroup}
                        icon={IconType.PLUS}
                        className={styles.addButton}
                        type="button"
                    >
                        {localization.getString("addAccessGroup")}
                    </Button>
                )}
            </div>

            {props.policySpec.access.map(
                (policyAccess: PolicyAccessExtendedWithId, index: number) => (
                    <ConfigureAccessGroup
                        policyAccess={policyAccess}
                        disabled={props.disabled}
                        onAccessGroupChange={(policyAccess) =>
                            onAccessGroupChange(policyAccess, index)
                        }
                        onDeleteAccessGroup={() => onDeleteAccessGroup(index)}
                        newGroupNumber={index + 1}
                        key={policyAccess.id}
                    />
                )
            )}
        </div>
    )
}
