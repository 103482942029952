import React, { FC } from "react"
import { useParams } from "react-router-dom"
import { PolicyType, useGetPolicyById } from "../../../v3/services/Policy.service"
import { decodeID } from "../../../pre-v3/utils/Url.util"
import { Loader } from "../../../v3/components/loader/Loader.component"
import { ErrorBanner } from "../../../pre-v3/components"
import { useServiceLocalization } from "../../../pre-v3/services"
import { TunnelPolicyEdit } from "../tunnel-policy/edit/TunnelPolicyEdit.component"
import { PolicyDetail } from "../../../pre-v3/views/policy/detail/PolicyDetail.page"

interface PageParams {
    id: string
}

interface Props {
    canEditPolicy: boolean
    canDeletePolicy: boolean
    canCratePolices: boolean
}

export const AccessPolicyDetails: FC<Props> = ({
    canDeletePolicy,
    canEditPolicy,
    canCratePolices,
}) => {
    const localization = useServiceLocalization()
    const { id } = useParams<PageParams>()
    const {
        data: policy,
        status: policyStatus,
        error: policyError,
        refetch: refetchPolicy,
    } = useGetPolicyById(decodeID(id))

    if (policyStatus === "loading") return <Loader medium center />
    if (policyStatus === "error") return <ErrorBanner>{policyError}</ErrorBanner>
    if (!policy) return <ErrorBanner>{localization.getString("policyNotFound")}</ErrorBanner>

    if (policy.type === PolicyType.TUNNEL)
        return (
            <TunnelPolicyEdit
                canDeletePolicy={canDeletePolicy}
                canEditPolicy={canEditPolicy}
                policy={policy}
                refetch={refetchPolicy}
            />
        )
    return (
        <PolicyDetail canCratePolices={canCratePolices} policy={policy} refetch={refetchPolicy} />
    )
}
