import * as React from "react"
import { TrustLevel } from "../../../api/Entity.api"
import { L4Protocol, PolicyAttr, PolicySpec } from "../../../api/Secure.api"
import { Checkbox, FormLabel, Select, Button, FormSection, Tooltip } from "../../../components"
import styles from "./PolicyForm.module.scss"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { PolicySecure, useServiceLocalization, useServiceManage } from "../../../services"
import { MultiInput } from "../../../../v3/components/multi-input/MultiInput.component"

interface Props {
    setAttr: (attr: PolicyAttr) => void
    roles: string[]
    disabled?: boolean
    edit?: PolicySecure
}

export function TunnelPolicyForm({ setAttr, roles, edit, disabled }: Props) {
    const localization = useServiceLocalization()

    // hold onto the list of access groups
    const [accessGroups, setAccessGroups] = React.useState<AccessGroup[]>(
        !edit
            ? [defaultAccessGroup()]
            : (JSON.parse(edit.spec) as PolicySpec).spec.access.map((aGroup, i) => ({
                  id: i,
                  roles: aGroup.roles,
                  trustLevel: aGroup.rules.conditions.trust_level as TrustLevel,
                  allowProtocols: aGroup.rules.l4_access?.allow?.[0]?.protocols || [],
                  denyProtocols: aGroup.rules.l4_access?.deny?.[0]?.protocols || [],
                  allowCidrs: aGroup.rules.l4_access?.allow?.[0]?.cidrs || [],
                  denyCidrs: aGroup.rules.l4_access?.deny?.[0]?.cidrs || [],
                  allowPorts: aGroup.rules.l4_access?.allow?.[0]?.ports || [],
                  denyPorts: aGroup.rules.l4_access?.deny?.[0]?.ports || [],
                  allowFqdns: aGroup.rules.l4_access?.allow?.[0]?.fqdns || [],
                  denyFqdns: aGroup.rules.l4_access?.deny?.[0]?.fqdns || [],
                  showDeny:
                      (aGroup.rules.l4_access?.deny?.[0]?.cidrs?.length || 0) +
                          (aGroup.rules.l4_access?.deny?.[0]?.fqdns?.length || 0) +
                          (aGroup.rules.l4_access?.deny?.[0]?.ports?.length || 0) +
                          (aGroup.rules.l4_access?.deny?.[0]?.protocols?.length || 0) >
                      0,
              }))
    )

    // we need to control the visibility of the access groups
    const visibility = Object.fromEntries(
        Object.keys(accessGroups).map((key) => [key, Object.keys(accessGroups).length < 3])
    )

    const newAccessGroup = () => {
        const newGroup = defaultAccessGroup()
        // add the group to the list
        setAccessGroups([...accessGroups, newGroup])
    }

    const deleteGroup = (id: number) => {
        delete visibility[id]
        setAccessGroups((groups) => {
            return groups.filter((target) => target.id !== id)
        })
    }

    // keep the parents state in sync with this form
    React.useEffect(() => {
        setAttr({
            access: accessGroups.map((group) => ({
                roles: group.roles,
                rules: {
                    conditions: {
                        trust_level: group.trustLevel,
                    },
                    l4_access: {
                        allow: [
                            {
                                cidrs: group.allowCidrs?.filter(Boolean) || [],
                                ports: group.allowPorts?.filter(Boolean) || [],
                                protocols: group.allowProtocols?.filter(Boolean) || [],
                                fqdns: group.allowFqdns?.filter(Boolean) || [],
                            },
                        ],
                        // ignore the deny value if they are meant to be hidden
                        deny: !group.showDeny
                            ? []
                            : [
                                  {
                                      cidrs: group.denyCidrs?.filter(Boolean) || [],
                                      ports: group.denyPorts?.filter(Boolean) || [],
                                      protocols: group.denyProtocols?.filter(Boolean) || [],
                                      fqdns: group.denyFqdns?.filter(Boolean) || [],
                                  },
                              ],
                    },
                },
            })),
        })
    }, [accessGroups, setAttr])

    return (
        <>
            {accessGroups.map((group, i) => (
                <AccessGroupInput
                    i={i + 1}
                    group={group}
                    roles={roles}
                    key={group.id}
                    setAccessGroups={setAccessGroups}
                    collapsible={accessGroups.length >= 3}
                    disabled={disabled}
                    onDelete={() => deleteGroup(group.id)}
                    allowDelete={accessGroups.length > 1}
                />
            ))}
            {!disabled && (
                <Button icon={faPlus} onClick={newAccessGroup}>
                    {localization.getString("newAccessGroup")}
                </Button>
            )}
        </>
    )
}

function AccessGroupInput({
    i,
    group,
    roles,
    setAccessGroups,
    collapsible,
    disabled,
    onDelete,
    allowDelete,
}: {
    i: number
    group: AccessGroup
    roles: string[]
    setAccessGroups: React.Dispatch<React.SetStateAction<AccessGroup[]>>
    collapsible: boolean
    disabled?: boolean
    onDelete: () => void
    allowDelete: boolean
}) {
    const manage = useServiceManage()
    const localization = useServiceLocalization()

    const updateGroup = <Key extends keyof AccessGroup>(which: Key, value: AccessGroup[Key]) => {
        setAccessGroups((groups) => {
            const newGroups = [...groups]

            for (const target of groups) {
                if (target.id !== group.id) {
                    continue
                }

                target[which] = value
            }

            return newGroups
        })
    }

    // we want to show the split if the group's flag is enabled or
    // the inputs are disabled and there is a deny rule
    const showDeny = Boolean(
        group.showDeny ||
            (disabled &&
                group.denyCidrs.length +
                    group.denyFqdns?.length +
                    group.denyPorts.length +
                    group.denyProtocols.length >
                    0)
    )

    return (
        <FormSection
            title={`${localization.getString("accessGroup#")}${i}`}
            className={styles.accessGroupContainer}
            collapsible={collapsible}
            hideLine
            startOpen
        >
            {!disabled && allowDelete && (
                <Tooltip title={localization.getString("deleteAccessGroup#") + i} placement="top">
                    <button className={styles.closeButton} onClick={onDelete}>
                        X
                    </button>
                </Tooltip>
            )}
            <FormLabel
                title={localization.getString(
                    "onlyAllowUsersAndDevicesWIthTheFollowingTrustLevels"
                )}
                inline={false}
                htmlFor={`group-${group.id}-trustlevel`}
            >
                <Select
                    options={[
                        {
                            displayName: localization.getString("noTrustLevelIgnoreTrustScore"),
                            value: TrustLevel.NONE,
                        },
                        {
                            displayName: localization.getString("highTrustLevelsOnly"),
                            value: TrustLevel.HIGH,
                        },
                        {
                            displayName: localization.getString("mediumOrHighTrustLevelsOnly"),
                            value: TrustLevel.MID,
                        },
                        {
                            displayName: localization.getString("anyTrustLevelExceptAlwaysDeny"),
                            value: TrustLevel.LOW,
                        },
                    ]}
                    value={group.trustLevel! || TrustLevel.NONE}
                    onChange={(value) => updateGroup("trustLevel", value as TrustLevel)}
                    disabled={disabled}
                />
            </FormLabel>
            <FormLabel
                title={localization.getString("onlyAllowTheFollowingRoles")}
                inline={false}
                htmlFor={`group-${group.id}-roles`}
            >
                <Select
                    multiple
                    options={roles}
                    value={group.roles}
                    onChange={(roles) => updateGroup("roles", roles as string[])}
                    disabled={disabled}
                    required
                />
            </FormLabel>
            <SplitInput
                group={group}
                which={localization.getString("protocol")}
                split={showDeny}
                left={
                    <Select
                        multiple
                        options={Object.keys(L4Protocol)}
                        value={group.allowProtocols}
                        onChange={(value) => updateGroup("allowProtocols", value as L4Protocol[])}
                        disabled={disabled}
                        required
                    />
                }
                right={
                    <Select
                        multiple
                        options={Object.keys(L4Protocol)}
                        value={group.denyProtocols}
                        onChange={(value) => updateGroup("denyProtocols", value as L4Protocol[])}
                        disabled={disabled}
                        required
                    />
                }
            />
            <SplitInput
                group={group}
                which={localization.getString("port")}
                split={showDeny}
                left={
                    <MultiInput
                        label={localization.getString("accessGroupPortPlaceholder")}
                        onChange={(value) => updateGroup("allowPorts", value as string[])}
                        values={group.allowPorts}
                        placeholder={localization.getString("accessGroupPortPlaceholder")}
                        disabled={disabled}
                        required={
                            group.allowProtocols.includes(L4Protocol.TCP) ||
                            group.allowProtocols.includes(L4Protocol.UDP)
                        }
                        patternProps={{
                            pattern: manage.PORT_REGEX,
                            errorMessage: localization.getString("accessGroupPortPlaceholder"),
                        }}
                        caseInsensitiveDuplicateMatch={false}
                    />
                }
                right={
                    <MultiInput
                        label={localization.getString("accessGroupPortPlaceholder")}
                        onChange={(value) => updateGroup("denyPorts", value as string[])}
                        values={group.denyPorts}
                        placeholder={localization.getString("accessGroupPortPlaceholder")}
                        disabled={disabled}
                        patternProps={{
                            pattern: manage.PORT_REGEX,
                            errorMessage: localization.getString("accessGroupPortPlaceholder"),
                        }}
                        required={
                            group.denyProtocols.includes(L4Protocol.TCP) ||
                            group.denyProtocols.includes(L4Protocol.UDP)
                        }
                        caseInsensitiveDuplicateMatch={false}
                    />
                }
            />
            <div>{localization.getString("pleaseChooseBetweenOneOrBothOfFollowingOptions")}</div>
            <br />
            <SplitInput
                group={group}
                which={localization.getString("cidrOrIp")}
                split={showDeny}
                left={
                    <MultiInput
                        label={localization.getString("accessGroupCidrPlaceholder")}
                        onChange={(value) => updateGroup("allowCidrs", value as string[])}
                        values={group.allowCidrs}
                        placeholder={localization.getString("accessGroupCidrPlaceholder")}
                        disabled={disabled}
                        patternProps={{
                            pattern: `\\*|(${manage.CIDR_OR_IP_REGEX})`,
                            errorMessage: localization.getString("cidrPlaceholder"),
                        }}
                        caseInsensitiveDuplicateMatch={false}
                    />
                }
                right={
                    <MultiInput
                        label={localization.getString("accessGroupCidrPlaceholder")}
                        onChange={(value) => updateGroup("denyCidrs", value as string[])}
                        values={group.denyCidrs}
                        placeholder={localization.getString("accessGroupCidrPlaceholderNoWildcard")}
                        disabled={disabled}
                        patternProps={{
                            pattern: manage.CIDR_OR_IP_REGEX,
                            errorMessage: localization.getString(
                                "accessGroupCidrPlaceholderNoWildcard"
                            ),
                        }}
                        caseInsensitiveDuplicateMatch={false}
                    />
                }
            />
            <SplitInput
                group={group}
                which={localization.getString("fqdn")}
                split={showDeny}
                tooltip={localization.getString("thisFieldRepresentsFullyQualifiedDomainNameDesc")}
                left={
                    <MultiInput
                        label={localization.getString("fqdn")}
                        onChange={(value) => updateGroup("allowFqdns", value as string[])}
                        values={group.allowFqdns}
                        placeholder={localization.getString("egWwwExampleDotCom")}
                        disabled={disabled}
                        required={group.allowCidrs.length + group.allowFqdns.length === 0}
                        newValueInputProps={{
                            title: localization.getString("pleaseFillOutOneOrBothOfTheseFields"),
                        }}
                        caseInsensitiveDuplicateMatch={false}
                    />
                }
                right={
                    <MultiInput
                        label={localization.getString("fqdn")}
                        onChange={(value) => updateGroup("denyFqdns", value as string[])}
                        values={group.denyFqdns}
                        placeholder={localization.getString("egWwwExampleDotCom")}
                        disabled={disabled}
                        required={group.denyCidrs.length + group.denyFqdns.length === 0}
                        newValueInputProps={{
                            title: localization.getString("pleaseFillOutOneOrBothOfTheseFields"),
                        }}
                        caseInsensitiveDuplicateMatch={false}
                    />
                }
            />
            {!disabled && (
                <FormLabel title="" inline={false}>
                    <div className={styles.inlineLabel}>
                        <Checkbox
                            checked={group.showDeny}
                            onChange={(e) => {
                                // find the group with the right id
                                setAccessGroups((groups) => {
                                    const newGroups = [...groups]
                                    for (const inner of newGroups) {
                                        if (inner.id === group.id) {
                                            inner.showDeny = e.target.checked
                                        }
                                    }

                                    return newGroups
                                })
                            }}
                            name={`show-deny-${group.id}`}
                            id={`show-deny-${group.id}`}
                        />
                        <FormLabel
                            title={
                                <>
                                    {localization.getString("addExceptions(")}
                                    <Bold>{localization.getString("DENY")}</Bold>{" "}
                                    {localization.getString("rules)ForProtocolsCIDRsAndPorts")}
                                </>
                            }
                            inline={false}
                            slim
                            className={styles.checkboxLabel}
                            htmlFor={`show-deny-${group.id}`}
                        />
                    </div>
                </FormLabel>
            )}
        </FormSection>
    )
}

function SplitInput({
    split,
    left,
    right,
    which,
    group,
    tooltip,
}: {
    split: boolean
    left: React.ReactNode
    right: React.ReactNode
    which: string
    group: AccessGroup
    tooltip?: string
}) {
    return (
        <FormLabel title="" inline={false}>
            {split ? (
                <div className={styles.splitColumn}>
                    <FormLabel
                        inline={false}
                        slim
                        className={styles.splitLabelContainer}
                        labelClassName={styles.splitLabel}
                        htmlFor={`only-allow-${which}-${group.id}`}
                        title={
                            <>
                                <Bold>Only allow</Bold> the following {which}(s)
                            </>
                        }
                        tooltip={tooltip}
                    >
                        {left}
                    </FormLabel>
                    <FormLabel
                        inline={false}
                        slim
                        className={styles.splitLabelContainer}
                        labelClassName={styles.splitLabel}
                        title={
                            <>
                                <Bold>Except</Bold> the following {which}(s)
                            </>
                        }
                        htmlFor={`only-deny-${which}-${group.id}`}
                    >
                        {right}
                    </FormLabel>
                </div>
            ) : (
                <FormLabel
                    inline={false}
                    slim
                    title={
                        <>
                            <Bold>Only allow</Bold> the following {which}(s)
                        </>
                    }
                    tooltip={tooltip}
                    className={styles.nonSplitInput}
                    htmlFor={`only-allow-${which}-${group.id}`}
                >
                    {left}
                </FormLabel>
            )}
        </FormLabel>
    )
}

function Bold({ children }: { children: React.ReactNode }) {
    return <span className={styles.boldText}>{children}</span>
}

function defaultAccessGroup(): AccessGroup {
    return {
        id: new Date().getTime(),
        roles: [],
        allowProtocols: [],
        denyProtocols: [],
        allowCidrs: [],
        denyCidrs: [],
        allowPorts: [],
        denyPorts: [],
        showDeny: false,
        trustLevel: TrustLevel.NONE,
        allowFqdns: [],
        denyFqdns: [],
    }
}

type AccessGroup = {
    id: number
    roles: string[]
    trustLevel?: TrustLevel
    allowProtocols: L4Protocol[]
    denyProtocols: L4Protocol[]
    allowCidrs: string[]
    denyCidrs: string[]
    allowPorts: string[]
    denyPorts: string[]
    allowFqdns: string[]
    denyFqdns: string[]
    showDeny: boolean
}
