import React, { ReactNode, useMemo } from "react"
import styles from "./ToggleButtons.module.scss"
import classNames from "classnames/bind"
import { Button, ButtonElement, ButtonType } from "../button/Button.component"

export interface Option<T> {
    label: ReactNode
    value: T
    id: string
    ariaLabel?: string
    disabled?: boolean
}

interface Props<T, D> {
    data: D[]
    getOptions: (data: D) => Option<T>
    onChange?: (value: T) => void
    selectedValue: D
    disabled?: boolean
}

export function ToggleButton<T, D>(props: Props<T, D>) {
    const { data, getOptions, onChange, selectedValue, disabled } = props

    const selectedId = getOptions(selectedValue).id
    const options = useMemo(() => data.map(getOptions), [data])

    return (
        <div className={styles.buttonsContainer} role="listbox">
            {options.map((option) => (
                <Button
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.PRIMARY}
                    key={option.id}
                    className={classNames(styles.button, {
                        [styles.selected]: option.id === selectedId,
                    })}
                    onClick={() => onChange?.(option.value)}
                    aria-label={option.ariaLabel}
                    disabled={disabled || option.disabled}
                    aria-selected={option.id === selectedId}
                    role="option"
                >
                    {option.label}
                </Button>
            ))}
        </div>
    )
}
