import React, { ReactNode } from "react"
import { LocalizationService } from "../../../../services/localization/Localization.service"
import {
    PolicyStatusAttachedTemplate,
    PolicyStatusInactiveTemplate,
} from "./PolicyStatusCellRenderer.template"
import { PolicyStatus } from "../../../../../v3/services/shared/Policy"
import { OtherPolicy } from "../../../../../v3/services/Policy.service"

export class PolicyStatusCellRenderer extends React.Component<PolicyStatusCellRendererProps, {}> {
    public render(): ReactNode {
        switch (this.props.data.status) {
            case PolicyStatus.ACTIVE:
                return PolicyStatusAttachedTemplate.call(this)
            default:
                return PolicyStatusInactiveTemplate.call(this)
        }
    }

    private localizationService: LocalizationService = new LocalizationService()
}

interface PolicyStatusCellRendererProps {
    data: OtherPolicy
}
