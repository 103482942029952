import React from "react"
import { BaseInputProps, BasicInput } from "./BasicInput.component"
import { VarInput } from "./VarInput.component"

export interface InputProps extends BaseInputProps {
    variables?: string[]
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    if (props.variables) return <VarInput {...props} variables={props.variables} ref={ref} />
    return <BasicInput {...props} ref={ref} />
})
