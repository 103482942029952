import React from "react"
import { useHistory, useParams } from "react-router-dom"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { decodeID, encodeID } from "../../../pre-v3/utils/Url.util"
import { Loader } from "../../../v3/components/loader/Loader.component"
import {
    FeatureModelType,
    MspOrg,
    Org,
    useAssignMspOrg,
    useGetMspOrgs,
    useGetOrgById,
    useRemoveMspOrgAssignment,
    useEditOrg,
    KnownOrgType,
} from "../../../v3/services/MomOrgManagement.service"
import { OrgIdInput } from "../shared/OrgId.component"
import { OrgNameInput } from "../shared/OrgName.component"
import { InternalOwnerInput } from "../shared/InternalOwner.component"
import { SonicWallCseIdpCheckbox } from "../shared/SonicWallCseIdp.component"
import { Edition } from "../../../v3/services/shared/Edition"
import { ParentMspInput } from "../shared/ParentMsp.component"
import {
    ConfirmationModalWithoutRef,
    ModalAction,
    ModalApi,
    ModalProps,
} from "../../../components/modal/ConfirmationModal.component"
import {
    Api as ConfirmMspAssignmentModalApi,
    ConfirmMspAssignmentModal,
} from "./ConfirmMspAssignmentModal.component"
import { ApplicationDiscoveryCheckbox } from "../shared/ApplicationDiscovery.component"
import { NetworkConfigurationCheckbox } from "../shared/NetworkConfiguration.component"
import { AiAssistedAdminSearchCheckbox } from "../shared/AiAssistedAdminSearch.component"
import { CustomerIdInput } from "../shared/CustomerId.component"
import { FeatureModelRow } from "../shared/FeatureModel.component"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { FormSection } from "../../../pre-v3/components/form-section/FormSection"
import { Form } from "../../../pre-v3/components/form/Form"
import { ActionBar, BreadcrumbProps } from "../../../components/action-bar/ActionBar.component"
import { ROUTE, formatRoutePath } from "../../../routes"
import styles from "./EditOrg.module.scss"
import { PageBreak } from "../../../pre-v3/components/page-break/PageBreak.component"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { OrgTypeInput } from "../shared/OrgType.component"
import { ErrorToast, SuccessToast, ToastApi } from "../../../components/toast/Toast.components"

export function EditOrg(): JSX.Element {
    const params = useParams<{ id: string }>()

    const { data: org, status: orgStatus, error: orgError } = useGetOrgById(decodeID(params.id))

    const { data: mspOrgs, status: mspOrgsStatus } = useGetMspOrgs()

    if (orgStatus === "loading" || mspOrgsStatus === "loading") {
        return <Loader center medium />
    }

    if (orgStatus === "error") {
        return <Banner variant={Variant.ERROR} label={String(orgError)} />
    }

    return <AfterLoading org={org} mspOrgs={mspOrgs ?? []} />
}

interface AfterLoadingProps {
    org: Org
    mspOrgs: MspOrg[]
}

function AfterLoading(props: AfterLoadingProps): JSX.Element {
    const history = useHistory()
    const localization = useServiceLocalization()

    const confirmMspAssignmentModalRef = React.useRef<ConfirmMspAssignmentModalApi>(null)
    const confirmMspRemovalModalRef = React.useRef<ModalApi<void>>(null)
    const successToastRef = React.useRef<ToastApi>(null)
    const errorToastRef = React.useRef<ToastApi>(null)

    const [editableOrg, setEditableOrg] = React.useState(props.org)

    const { mutate: editOrg, isLoading: isEditingOrg } = useEditOrg({
        onSuccess: (updatedOrg) => {
            successToastRef.current?.openToast(
                localization.getString(
                    "somethingHasBeenSuccessfullyAdded",
                    localization.getString("organization"),
                    updatedOrg.name
                )
            )
            history.push(
                formatRoutePath(ROUTE.ORG_MANAGEMENT_DETAILS, { id: encodeID(updatedOrg.id) })
            )
        },
        onError: (error) =>
            typeof error === "string"
                ? errorToastRef.current?.openToast(error)
                : console.error(error),
    })

    const { mutateAsync: assignMspOrg } = useAssignMspOrg(props.org)
    const { mutateAsync: removeMspOrgAssignment } = useRemoveMspOrgAssignment(props.org)

    const onInternalOwnerChange = (internalOwnerEmailAddress: string) =>
        setEditableOrg({ ...editableOrg, internalOwnerEmailAddress })

    const onEditionChange = (edition: Edition) =>
        editableOrg.featureModel.type === FeatureModelType.EDITION_BASED &&
        setEditableOrg({
            ...editableOrg,
            featureModel: {
                ...editableOrg.featureModel,
                edition,
            },
            networkConfiguration: {
                ...editableOrg.networkConfiguration,
                usesGlobalEdge:
                    edition === Edition.TEAM
                        ? true
                        : editableOrg.networkConfiguration.usesGlobalEdge,
            },
        })

    const onToggleApplicationDiscovery = (enableApplicationDiscovery: boolean) =>
        setEditableOrg({
            ...editableOrg,
            featureFlags: { ...editableOrg.featureFlags, enableApplicationDiscovery },
        })

    const onToggleGlobalEdge = (usesGlobalEdge: boolean) =>
        setEditableOrg({
            ...editableOrg,
            networkConfiguration: { ...editableOrg.networkConfiguration, usesGlobalEdge },
        })

    const onTogglePrivateEdge = (usesPrivateEdge: boolean) =>
        setEditableOrg({
            ...editableOrg,
            networkConfiguration: { ...editableOrg.networkConfiguration, usesPrivateEdge },
        })

    const onToggleAiAssistedAdminSearch = (enableAiAssistedAdminSearch: boolean) =>
        setEditableOrg({
            ...editableOrg,
            featureFlags: { ...editableOrg.featureFlags, enableAiAssistedAdminSearch },
        })

    const onOrgTypeChange = (type: KnownOrgType) =>
        setEditableOrg({ ...editableOrg, typeInformation: { type } })

    const onCustomerIdChange = (customerId: string) =>
        setEditableOrg({ ...editableOrg, customerId })

    const onParentMspChange = async (parentMspOrg?: MspOrg) => {
        if (parentMspOrg?.id === editableOrg.parentMspOrg?.id) return

        const response = await (parentMspOrg
            ? confirmMspAssignmentModalRef.current?.open(parentMspOrg)
            : confirmMspRemovalModalRef.current?.open())

        if (response?.action === ModalAction.COMPLETE) {
            setEditableOrg({ ...editableOrg, parentMspOrg })
        }
    }

    const onSubmit: React.FormEventHandler = (event) => {
        event.preventDefault()
        editOrg(editableOrg)
    }

    const orgDetailsRoute = formatRoutePath(ROUTE.ORG_MANAGEMENT_DETAILS, {
        id: encodeID(props.org.id),
    })

    const breadcrumbs: BreadcrumbProps[] = [
        {
            label: localization.getString("superAdmin"),
        },
        {
            label: localization.getString("orgManagement"),
            href: ROUTE.ORG_MANAGEMENT,
        },
        {
            label: props.org.name,
            href: orgDetailsRoute,
        },
    ]

    const isTeamEdition =
        editableOrg.featureModel.type === FeatureModelType.EDITION_BASED &&
        editableOrg.featureModel.edition === Edition.TEAM

    return (
        <React.Fragment>
            <ActionBar title={localization.getString("editOrg")} breadcrumbs={breadcrumbs} />
            <Form className={styles.form} onSubmit={onSubmit} labelWidth={177} display="grid">
                <FormSection title={localization.getString("orgDetails")}>
                    <OrgIdInput id={Id.ORG_ID} value={editableOrg.id} />
                    <OrgNameInput id={Id.NAME} value={editableOrg.name} disabled />
                    <InternalOwnerInput
                        id={Id.INTERNAL_OWNER}
                        value={editableOrg.internalOwnerEmailAddress}
                        onChange={onInternalOwnerChange}
                    />
                    <FeatureModelRow
                        org={editableOrg}
                        editionInputId={Id.EDITION}
                        editionInputDisabled={
                            editableOrg.featureFlags.isMspOrg ||
                            editableOrg.featureFlags.isSonicWallProvisioned
                        }
                        onEditionChange={onEditionChange}
                    />
                    {!isTeamEdition && !editableOrg.featureFlags.isMspOrg && (
                        <React.Fragment>
                            <SonicWallCseIdpCheckbox
                                id={Id.SONIC_WALL_CSE_IDP}
                                value={editableOrg.featureFlags.usesSonicWallCseIdp}
                                disabled
                            />
                            <ApplicationDiscoveryCheckbox
                                id={Id.APPLICATION_DISCOVERY}
                                value={editableOrg.featureFlags.enableApplicationDiscovery}
                                onChange={onToggleApplicationDiscovery}
                            />
                        </React.Fragment>
                    )}
                    {!editableOrg.featureFlags.isMspOrg && (
                        <React.Fragment>
                            <NetworkConfigurationCheckbox
                                usesGlobalEdge={editableOrg.networkConfiguration.usesGlobalEdge}
                                usesPrivateEdge={editableOrg.networkConfiguration.usesPrivateEdge}
                                usesGlobalEdgeDisabled={isTeamEdition}
                                showPrivateEdge={!isTeamEdition}
                                onToggleGlobalEdge={onToggleGlobalEdge}
                                onTogglePrivateEdge={onTogglePrivateEdge}
                            />
                            <AiAssistedAdminSearchCheckbox
                                id={Id.AI_ASSISTED_ADMIN_SEARCH}
                                value={editableOrg.featureFlags.enableAiAssistedAdminSearch}
                                onChange={onToggleAiAssistedAdminSearch}
                            />
                        </React.Fragment>
                    )}
                    <OrgTypeInput
                        id={Id.ORG_TYPE}
                        value={editableOrg.typeInformation.type}
                        typeInformation={editableOrg.typeInformation}
                        onChange={onOrgTypeChange}
                    />
                    <CustomerIdInput
                        id={Id.CUSTOMER_ID}
                        value={editableOrg.customerId}
                        disabled={editableOrg.featureFlags.isSonicWallProvisioned}
                        onChange={onCustomerIdChange}
                    />
                    {!editableOrg.featureFlags.isMspOrg && (
                        <ParentMspInput
                            id={Id.PARENT_MSP}
                            value={editableOrg.parentMspOrg}
                            mspOrgs={props.mspOrgs}
                            disabled={editableOrg.featureFlags.isSonicWallProvisioned}
                            onChange={onParentMspChange}
                        />
                    )}
                    <ConfirmMspAssignmentModal
                        id={Id.CONFIRM_MSP_ASSIGNMENT_MODAL}
                        onMspAssignmentConfirmed={async (mspOrg) => await assignMspOrg(mspOrg)}
                        ref={confirmMspAssignmentModalRef}
                    />
                    <ConfirmationModal
                        id={Id.CONFIRM_MSP_REMOVAL_MODAL}
                        title={localization.getString("confirmRemovalOfMspOrganizationAssignment")}
                        children={localization.getString("removeOrgFromMspConfirmationMessage")}
                        onConfirm={async () => {
                            await removeMspOrgAssignment()
                            confirmMspRemovalModalRef.current?.complete()
                        }}
                        ref={confirmMspRemovalModalRef}
                    />
                </FormSection>
                <PageBreak />
                <section className={styles.formButtons}>
                    <Button
                        asElement={ButtonElement.LINK}
                        buttonType={ButtonType.SECONDARY}
                        to={orgDetailsRoute}
                    >
                        {localization.getString("cancel")}
                    </Button>
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.PRIMARY}
                        type="submit"
                        loading={isEditingOrg}
                    >
                        {localization.getString("editOrg")}
                    </Button>
                </section>
            </Form>
            <SuccessToast ref={successToastRef} />
            <ErrorToast ref={errorToastRef} />
        </React.Fragment>
    )
}

enum Id {
    ORG_ID = "orgId",
    NAME = "name",
    INTERNAL_OWNER = "internalOwner",
    MSP_ORG = "mspOrg",
    EDITION = "edition",
    SONIC_WALL_CSE_IDP = "sonicWallCseIdp",
    APPLICATION_DISCOVERY = "applicationDiscovery",
    AI_ASSISTED_ADMIN_SEARCH = "aiAssistedAdminSearch",
    ORG_TYPE = "orgType",
    CUSTOMER_ID = "customerId",
    PARENT_MSP = "parentMsp",
    CONFIRM_MSP_ASSIGNMENT_MODAL = "confirmMspAssignmentModal",
    CONFIRM_MSP_REMOVAL_MODAL = "confirmMspRemovalModal",
}

const ConfirmationModal = React.forwardRef<ModalApi<void>, ModalProps>(ConfirmationModalWithoutRef)
