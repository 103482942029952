import React from "react"
import { useServiceLinks, useServiceLocalization } from "../../../../../pre-v3/services"
import { AppText } from "../../../../components/app-text/AppText.component"
import { CodeWithCopy } from "../../../../components/code-with-copy/CodeWithCopy"
import { getConnectivityParameters } from "../../../../services/AccessTier.service"

import styles from "../AccessTiersCreate.module.scss"

export function DebianInstaller({
    apiSecretKey,
    accessTierName,
    hideDoc,
    canAccessAccessTierInstallStep,
}: Props) {
    const ls = useServiceLocalization()
    const links = useServiceLinks()

    return (
        <>
            <p className={styles.title}>{ls.getString("installationSteps")}</p>
            <div className={styles.row}>
                <label className={styles.label}>{ls.getString("debianUbuntu")}</label>
                <CodeWithCopy
                    className={styles.textBox}
                    title={ls.getString("step", "1")}
                    text={linuxPackageDownloadCommand}
                    hideCopyButton={!canAccessAccessTierInstallStep}
                />
            </div>
            <br />
            <div className={styles.row}>
                <label className={styles.label}>
                    {ls.getString("dockerConnectivityDescription")}
                </label>
                <CodeWithCopy
                    className={styles.textBox}
                    title={ls.getString("step", "2")}
                    text={getConnectivityParameters(apiSecretKey, accessTierName)}
                    hideCopyButton={!canAccessAccessTierInstallStep}
                />
            </div>
            <br />
            <div className={styles.row}>
                <label className={styles.label}>{ls.getString("runNetagentInstallScript")}</label>
                <CodeWithCopy
                    className={styles.textBox}
                    title={ls.getString("step", "3")}
                    text={linuxPackageInstallCommand}
                    hideCopyButton={!canAccessAccessTierInstallStep}
                />
            </div>
            {!hideDoc && (
                <AppText
                    className={styles.info}
                    ls={{
                        key: "pleaseCheckDocumentForPreReqAndMoreDetails",
                        replaceVals: [links.getLink("debInstallDoc")],
                    }}
                />
            )}
        </>
    )
}

interface Props {
    apiSecretKey?: string
    accessTierName?: string
    hideDoc?: boolean
    canAccessAccessTierInstallStep?: boolean
}

const linuxPackageDownloadCommand = `curl https://www.banyanops.com/onramp/deb-repo/banyan.key | sudo apt-key add -
sudo apt-add-repository "deb [arch=amd64] https://www.banyanops.com/onramp/deb-repo xenial main"
sudo apt install banyan-netagent2`

const linuxPackageInstallCommand = [`cd /opt/banyan-packages/`, `sudo -E ./install`].join("\n")
