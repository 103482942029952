import React from "react"

import { useServiceActionBar } from "../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { ErrorBanner } from "../../components/banner/Banner.component"
import { Loader } from "../../components/loader/Loader.component"
import { SearchInput } from "../../components/search-input/SearchInput.component"
import { useGetAnswers } from "../../services/SmartSearch.service"
import { SmartSearchGrid } from "./SmartSearchGrid.component"
import styles from "./SmartSearchHistoryPanel.module.scss"
import useTitle from "../../../hooks/useTitle.hook"

interface Props {
    id?: string
}

export function SmartSearchHistoryPanel(props: Props): JSX.Element {
    useTitle(["smartSearchHistory", "getHelp", "adminConsole"])
    const actionBarService = useServiceActionBar()
    const localization = useServiceLocalization()
    const getAnswersQuery = useGetAnswers()

    const [search, setSearch] = React.useState("")

    React.useEffect(() => {
        actionBarService.registerRefreshFn(getAnswersQuery.refetch)
        return () => {
            actionBarService.unregisterRefreshFn(getAnswersQuery.refetch)
        }
    }, [actionBarService, getAnswersQuery.refetch])

    if (getAnswersQuery.status === "error") {
        return <ErrorBanner children={String(getAnswersQuery.error)} />
    }

    if (getAnswersQuery.status === "loading") {
        return (
            <Loader
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("smartSearchHistory")
                )}
                center
                medium
            />
        )
    }

    return (
        <div id={props.id} className={styles.container}>
            <div className={styles.titleContainer}>
                <h3>{localization.getString("yourPreviousSmartSearches")}</h3>
                <SearchInput value={search} onChangeValue={setSearch} />
            </div>
            <SmartSearchGrid answers={getAnswersQuery.data} search={search} />
        </div>
    )
}
