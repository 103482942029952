import { faTachometerAltFast } from "@fortawesome/pro-regular-svg-icons"
import { faFillDrip, faSignIn, faUserShield } from "@fortawesome/pro-solid-svg-icons"
import React from "react"

import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../routes"
import { AppNavLink } from "../../pre-v3/services/shared/AppNavLink"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import {
    useGetCustomization,
    useHasGranularTrustMigrationOccurred,
} from "../../v3/services/Org.service"
import styles from "./Settings.module.scss"
import { LeftNav } from "../../pre-v3/components/left-nav/LeftNav.component"
import { ActionBar } from "../../pre-v3/components/action-bar/ActionBar.component"
import classNames from "classnames"
import { Redirect, Route, Switch } from "react-router"
import { TrustProviderIdentitySettings } from "../../pre-v3/views/settings/trust-provider/identity/TrustProviderIdentitySettings.component"
import { Customization } from "../../v3/views/customization/Customization.view"
import { TrustScoreSettings } from "../../v3/views/app/settings/trustScoreSettings/TrustScoreSettings.view"
import { OrgSignOnSettings } from "../../pre-v3/views/settings/org/sign-on/OrgSignOnSettings.component"
import { MspManageAdmins } from "../msp-manage-admins/MspManageAdmins.page"
import { Loader } from "../../v3/components/loader/Loader.component"
import { Container } from "../../v3/components/container/Container.component"
import { ErrorBanner } from "../../v3/components/banner/Banner.component"

interface Props {
    canAccessSignOn: boolean
    canConfigureAdminSignOnSettings: boolean
    canUpdateCustomization: boolean
    canConfigureEndUserSettings: boolean
}

export function Settings(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const { data: hasMigrated } = useHasGranularTrustMigrationOccurred()

    const {
        data: customization,
        status: customizationStatus,
        error: customizationError,
        refetch: refetchCustomization,
    } = useGetCustomization()

    function getDivider(label: LanguageKey): AppNavLink {
        return {
            divider: true,
            label: localization.getString(label),
        }
    }

    const sharedLinks = {
        adminSignOn: {
            label: localization.getString("adminSignOn"),
            href: ROUTE.MSP_SIGN_ON,
            icon: faSignIn,
        },
        manageAdmins: {
            label: localization.getString("manageAdmins"),
            href: ROUTE.MSP_MANAGE_ADMIN,
            icon: faUserShield,
        },
        customization: {
            label: localization.getString("customization"),
            href: ROUTE.MSP_CUSTOMIZATION,
            icon: faFillDrip,
        },
        trustFactors: {
            label: localization.getString("trustFactors"),
            href: ROUTE.MSP_TRUST_FACTOR_SETTINGS,
            icon: faTachometerAltFast,
        },
    }

    const leftNavLinks = React.useMemo(() => {
        return getNavLinks(hasMigrated ?? false)
    }, [hasMigrated])

    function getNavLinks(hasMigrated: boolean): AppNavLink[] {
        return [
            getDivider("adminSettings"),
            sharedLinks.adminSignOn,
            sharedLinks.manageAdmins,
            getDivider("desktopAndMobile"),
            sharedLinks.customization,
            ...(hasMigrated ? [] : [getDivider("trustScoreSettings"), sharedLinks.trustFactors]),
        ].filter((link) => {
            if (!props.canAccessSignOn && link.label === localization.getString("adminSignOn")) {
                return false
            }
            return true
        })
    }

    if (customizationStatus === "loading") {
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("customization")
                )}
            />
        )
    }

    return (
        <div className={styles.pageWithLeftNav}>
            <LeftNav links={leftNavLinks} />
            <div className={styles.pageRightWithActionBar}>
                <ActionBar />
                <div className={classNames(styles.pageContent, styles.settingsContainer)}>
                    <Switch>
                        <Route path={ROUTE.MSP_MANAGE_ADMIN} component={MspManageAdmins} />
                        <Route path={ROUTE.MSP_CUSTOMIZATION}>
                            {customizationStatus === "error" ? (
                                <Container>
                                    <ErrorBanner children={String(customizationError)} />
                                </Container>
                            ) : (
                                <Customization
                                    customization={customization}
                                    refetchCustomization={refetchCustomization}
                                    canUpdateCustomization={props.canUpdateCustomization}
                                    isMspOrg
                                />
                            )}
                        </Route>

                        <Route path={ROUTE.MSP_IDENTITY_PROVIDER_SETTINGS}>
                            <TrustProviderIdentitySettings
                                canConfigureEndUserSettings={props.canConfigureEndUserSettings}
                            />
                        </Route>
                        <Route
                            path={ROUTE.MSP_TRUST_FACTOR_SETTINGS}
                            component={TrustScoreSettings}
                        />
                        {props.canAccessSignOn && (
                            <Route path={[ROUTE.MSP_SIGN_ON]}>
                                <OrgSignOnSettings
                                    canConfigureAdminSignOnSettings={
                                        props.canConfigureAdminSignOnSettings
                                    }
                                />
                            </Route>
                        )}
                        <Redirect to={ROUTE.MSP_MANAGE_ADMIN} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}
