import { faStars } from "@fortawesome/pro-regular-svg-icons"
import React from "react"

import { useSmartSearch } from "../../../context/SmartSearch.context"
import { useServiceActionBar } from "../../../pre-v3/services/ActionBar.service"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { decodeID, useUrlSearch } from "../../../pre-v3/utils/Url.util"
import { ErrorBanner } from "../../components/banner/Banner.component"
import { ButtonAsLink } from "../../components/button/ButtonAsLink.component"
import { Icon } from "../../components/Icon/Icon.component"
import { Loader } from "../../components/loader/Loader.component"
import {
    AnswerDetail,
    Feedback,
    Source,
    useGetAnswer,
    useProvideFeedback,
} from "../../services/SmartSearch.service"
import styles from "./SearchResultPanel.module.scss"
import { Link } from "../../components/link/Link.component"
import Markdown from "react-markdown"
import useTitle from "../../../hooks/useTitle.hook"

interface Props {
    id?: string
}

export function SearchResultPanel(props: Props): JSX.Element {
    useTitle(["searchResult", "getHelp", "adminConsole"])
    const { answer } = useUrlSearch("answer")

    const actionBarService = useServiceActionBar()
    const localization = useServiceLocalization()
    const getAnswerQuery = useGetAnswer(answer && decodeID(answer))

    React.useEffect(() => {
        actionBarService.registerRefreshFn(getAnswerQuery.refetch)
        return () => {
            actionBarService.unregisterRefreshFn(getAnswerQuery.refetch)
        }
    }, [actionBarService, getAnswerQuery.refetch])

    if (getAnswerQuery.status === "error") {
        return <ErrorBanner id={props.id} children={String(getAnswerQuery.error)} />
    }

    if (getAnswerQuery.status === "loading") {
        return (
            <Loader
                id={props.id}
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("searchResult")
                )}
                center
                medium
            />
        )
    }

    return <WithSearchResult id={props.id} answer={getAnswerQuery.data} />
}

interface WithSearchResultProps {
    id?: string
    answer?: AnswerDetail
}

function WithSearchResult(props: WithSearchResultProps): JSX.Element {
    const localization = useServiceLocalization()
    const onClickCannedPrompt = useSmartSearch()

    const cannedPrompts = [
        localization.getString("explain"),
        localization.getString("showMeHowTo"),
        localization.getString("whatIsTheBestWayTo"),
        localization.getString("whyDoINeedTo"),
    ]

    return (
        <div id={props.id} className={styles.container}>
            <Icon icon={faStars} className={styles.starsIcon} />
            {props.answer ? (
                <React.Fragment>
                    <h3>{localization.getString("youAskedSomething", props.answer.prompt)}</h3>
                    <span className={styles.smartSearchResults}>
                        {localization.getString("smartSearchResults")}
                    </span>
                    <Markdown className={styles.markdownResult}>{props.answer.response}</Markdown>
                    <h4>{localization.getString("relevantHelpDocs")}</h4>
                    <ul className={styles.sources}>{props.answer.sources.map(renderSource)}</ul>
                    {!props.answer.hasProvidedFeedback && (
                        <WasThisResponseHelpful answer={props.answer} />
                    )}
                    {props.answer.hasProvidedFeedback && (
                        <div className={styles.wasThisResponseHelpful}>
                            {localization.getString("thanksForTheFeedback")}
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <h3>{localization.getString("welcomeToSmartSearch")}</h3>
                    <span>
                        {localization.getString(
                            "toGetStartedTypeAQuestionInTheSearchBarAtTheTopOfThePage"
                        )}
                    </span>
                    <h4>
                        {localization.getString(
                            "doNotKnowHowToFrameYourQuestionTryWritingTheseStatements"
                        )}
                    </h4>
                    <div className={styles.cannedResponsesContainer}>
                        {onClickCannedPrompt &&
                            cannedPrompts.map((prompt) => {
                                const onClick: React.MouseEventHandler = (event) => {
                                    event.preventDefault()
                                    onClickCannedPrompt(`${prompt} `)
                                }

                                return (
                                    <button
                                        key={prompt}
                                        onClick={onClick}
                                        className={styles.cannedResponseButton}
                                    >
                                        {localization.getString("somethingEllipsis", prompt)}
                                    </button>
                                )
                            })}
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

function renderSource(source: Source): JSX.Element {
    return (
        <li key={source.label}>
            <Link to={source.url}>{source.label}</Link>
        </li>
    )
}

interface FeedbackProps {
    answer: AnswerDetail
}

function WasThisResponseHelpful(props: FeedbackProps): JSX.Element {
    const localization = useServiceLocalization()

    const provideFeedbackMutation = useProvideFeedback()

    if (provideFeedbackMutation.status === "error")
        return <ErrorBanner children={String(provideFeedbackMutation.error)} />

    if (provideFeedbackMutation.status === "loading") return <Loader center medium />

    const onClickYes: React.MouseEventHandler = (event) => {
        event.preventDefault()
        provideFeedbackMutation.mutate([props.answer, Feedback.HELPFUL])
    }

    const onClickNo: React.MouseEventHandler = (event) => {
        event.preventDefault()
        provideFeedbackMutation.mutate([props.answer, Feedback.NOT_HELPFUL])
    }

    return (
        <div className={styles.wasThisResponseHelpful}>
            <span>{localization.getString("wasThisResponseHelpful")}</span>
            <ButtonAsLink onClick={onClickYes} brand>
                {localization.getString("yes")}
            </ButtonAsLink>
            <ButtonAsLink onClick={onClickNo} brand>
                {localization.getString("no")}
            </ButtonAsLink>
        </div>
    )
}
