export function convertFromIsoDate(date: string): Date {
    return new Date(date)
}

export function convertFromServerTimestamp(timestamp: number): Date {
    return new Date(timestamp / 1_000_000)
}

export function convertToServerTimestamp(timestamp: Date): number {
    return timestamp.getTime() * 1_000_000
}

export function formatToLocalDateStr(date: Date, locale: string): string {
    return date.toLocaleDateString(locale)
}

export function formatToLocalDateTimeStr(date: Date, locale: string): string {
    const datetimeFormat = new Intl.DateTimeFormat(locale, {
        timeStyle: "medium",
        dateStyle: "short",
    })
    return datetimeFormat.format(date)
}
