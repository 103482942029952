import React, { useMemo } from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import {
    NavigationTabBar,
    TabProps,
} from "../../components/navigation-tab-bar/NavigationTabBar.component"
import useTitle from "../../hooks/useTitle.hook"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { OidcSettings as Passwordless } from "../../pre-v3/views/settings/oidc/OidcSettings.component"
import { CloakExceptionSettings as UnregisteredDevices } from "../../pre-v3/views/settings/org/cloak-exceptions/CloakExceptionSettings.component"
import { ServiceTunnelConfigurationSettings } from "../service-tunnnel-configuration-settings/ServiceTunnelConfigurationSettings.component"
import { TrustProviderAdvancedSettings as Advanced } from "../../pre-v3/views/settings/trust-provider/advanced-settings/TrustProviderAdvancedSettings.component"
import { doesRouteMatch, ROUTE } from "../../routes"
import { Licensing } from "../../v3/views/licensing/Licensing.view"
import styles from "./Configuration.module.scss"
import { useGetOrgInfo } from "../../v3/services/Org.service"
import { Loader } from "../../v3/components/loader/Loader.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { Upsell } from "../../shared/upsell/Upsell.component"
import { Banner, Variant } from "../../components/banner/Banner.component"
import { OrgCustomization } from "./OrgCustomization"

export function Configuration(): JSX.Element {
    useTitle(["configuration", "settings", "adminConsole"])

    const {
        data: featureFlags,
        status: featureFlagsStatus,
        error: featureFlagsError,
    } = useFeatureFlags()
    const localization = useServiceLocalization()
    const location = useLocation()
    const { data: orgInfo, status: orgInfoStatus, error: orgInfoError } = useGetOrgInfo()

    const getTabProps = (route: ThirdLevelRoute): TabProps => ({
        key: route,
        label: localization.getString(thirdLevelLabels[route]),
        to: route,
        active: doesRouteMatch<ThirdLevelRoute>(route, location),
    })

    const tabs = useMemo(() => {
        if (orgInfo?.licenseType === "license")
            return thirdLevelRoutes.filter((route) => route !== ROUTE.LICENSING)
        return thirdLevelRoutes
    }, [orgInfo])

    if (orgInfoStatus === "loading" || featureFlagsStatus === "loading")
        return <Loader medium center />
    if (orgInfoStatus === "error" || featureFlagsStatus === "error")
        return (
            <Banner
                variant={Variant.ERROR}
                label={String(orgInfoError) || String(featureFlagsError)}
            />
        )

    const serviceTunnelConfigView = featureFlags.adminConsole.doShowUpsellForServiceTunnelConfig ? (
        <Upsell />
    ) : (
        <ServiceTunnelConfigurationSettings
            canUpdateNrptSetting={featureFlags.adminConsole.canUpdateNrptSetting}
        />
    )

    return (
        <div className={styles.container}>
            <NavigationTabBar tabs={tabs} getTabProps={getTabProps} />
            <Switch>
                <Route path={ROUTE.PASSWORDLESS}>
                    {featureFlags?.adminConsole.doShowUpsellForConfigurationPasswordless ? (
                        <Upsell />
                    ) : (
                        <Passwordless
                            canCreatePasswordLessAuth={
                                featureFlags?.adminConsole.canCreatePasswordLessAuth
                            }
                        />
                    )}
                </Route>
                <Route path={ROUTE.UNREGISTERED_DEVICES_CONFIGURATION}>
                    {featureFlags?.adminConsole.doShowUpsellForConfigurationUnregisteredDevices ? (
                        <Upsell />
                    ) : (
                        <UnregisteredDevices
                            canUpdateUnregisteredDeviceConfig={
                                featureFlags?.adminConsole.canUpdateUnregisteredDeviceConfig
                            }
                            canUpdateDeviceTrustVerfication={
                                featureFlags?.adminConsole.canUpdateDeviceTrustVerfication
                            }
                        />
                    )}
                </Route>
                <Route path={ROUTE.CUSTOMIZATION}>
                    <OrgCustomization
                        canUpdateCustomization={featureFlags?.adminConsole.canUpdateCustomization}
                    />
                </Route>
                <Route path={ROUTE.ADVANCED}>
                    <Advanced
                        canAccessTrustProviderCerts={
                            featureFlags?.adminConsole.canAccessTrustProviderCerts
                        }
                        canUpdateGeoLocationSetting={
                            featureFlags?.adminConsole.canUpdateGeoLocationSetting
                        }
                        canUpdateTokenExpiry={featureFlags?.adminConsole.canUpdateTokenExpiry}
                        canUpdateUserExpiry={featureFlags?.adminConsole.canUpdateUserExpiry}
                    />
                </Route>
                <Route path={ROUTE.SERVICE_TUNNELS_CONFIGURATION}>{serviceTunnelConfigView}</Route>
                {orgInfo?.licenseType === "edition" ? (
                    <Route path={ROUTE.LICENSING} component={Licensing} />
                ) : (
                    <Redirect path={ROUTE.LICENSING} to={ROUTE.LICENSES} />
                )}
                <Redirect to={ROUTE.PASSWORDLESS} />
            </Switch>
        </div>
    )
}

const thirdLevelRoutes = [
    ROUTE.PASSWORDLESS,
    ROUTE.UNREGISTERED_DEVICES_CONFIGURATION,
    ROUTE.CUSTOMIZATION,
    ROUTE.ADVANCED,
    ROUTE.SERVICE_TUNNELS_CONFIGURATION,
    ROUTE.LICENSING,
] satisfies ROUTE[]

type ThirdLevelRoute = (typeof thirdLevelRoutes)[number]

const thirdLevelLabels: Record<ThirdLevelRoute, LanguageKey> = {
    [ROUTE.PASSWORDLESS]: "passwordless",
    [ROUTE.UNREGISTERED_DEVICES_CONFIGURATION]: "unregisteredDevices",
    [ROUTE.CUSTOMIZATION]: "customization",
    [ROUTE.ADVANCED]: "advanced",
    [ROUTE.SERVICE_TUNNELS_CONFIGURATION]: "serviceTunnel",
    [ROUTE.LICENSING]: "licensing",
}
