import React from "react"
import { useHistory } from "react-router-dom"

import { EdgeClusters } from "../../../v3/services/shared/Cluster"
import {
    AccessPolicy,
    emptyGlobalEdgeNetworkSetting,
    NetworkSetting,
    useGetApplicationListForServiceTunnel,
    useGetAccessPolicies,
} from "../../../v3/services/ServiceTunnelV2.service"
import {
    Application,
    Network,
    isValidNetworkSetting,
    ServiceTunnelDetail,
    useCreateServiceTunnel,
    useGetNetworks,
    useGetEdgeClusters,
} from "../../../v3/services/ServiceTunnelV2.service"
import { GeneralInfo, ServiceTunnelForm } from "../shared/ServiceTunnelForm.component"
import { PolicyAttachment } from "../../../v3/services/Policy.service"
import { formatRoutePath, ROUTE } from "../../../routes"
import { encodeID } from "../../../pre-v3/utils/Url.util"
import styles from "./ServiceTunnelAdd.module.scss"
import { AssignmentCard } from "../shared/AssignmentCard.component"
import { Form } from "../../../components/form/Form.component"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Loader } from "../../../v3/components/loader/Loader.component"
import { useAdminInfo } from "../../../hooks/useAdminInfo.hook"
import { RegisteredServiceStatus } from "../../../v3/services/shared/RegisteredService"
import { Banner, Variant } from "../../../components/banner/Banner.component"
import { PageHeading } from "../../../components/page-heading/PageHeading.component"
import { RefreshButton } from "../edit/ServiceTunnelEdit.component"
import { ErrorToast, ToastApi } from "../../../components/toast/Toast.components"

const emptyServiceTunnelDetail: ServiceTunnelDetail = {
    networkSettings: [],
    name: "",
    status: RegisteredServiceStatus.POLICY_ENFORCING,
    extra: {},
}

interface Props {
    enableATG: boolean
    hideApplication: boolean
    hideAccessTier: boolean
}

export function ServiceTunnelAdd(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const { data: adminInfo, status: adminInfoStatus } = useAdminInfo()

    const {
        data: applications,
        status: applicationsStatus,
        error: applicationsError,
        refetch: refetchApplications,
    } = useGetApplicationListForServiceTunnel(props.enableATG)

    const {
        status: networksStatus,
        data: networks,
        error: NetworksError,
        refetch: refetchNetworks,
    } = useGetNetworks(props.enableATG)

    const {
        status: edgeClustersStatus,
        data: edgeClusters,
        error: edgeClustersError,
        refetch: refetchEdgeClusters,
    } = useGetEdgeClusters()

    const {
        data: policies,
        error: policyError,
        status: policiesStatus,
        refetch: refetchPolicy,
    } = useGetAccessPolicies()

    const refetchData = () => {
        refetchNetworks()
        refetchEdgeClusters()
        refetchPolicy()
        refetchApplications()
    }

    if (
        adminInfoStatus === "error" ||
        networksStatus === "error" ||
        edgeClustersStatus === "error" ||
        policiesStatus === "error" ||
        applicationsStatus === "error"
    ) {
        return (
            <div className={styles.container}>
                <header className={styles.header}>
                    <PageHeading id={Id.HEADING}>
                        {localization.getString("serviceTunnel")}
                    </PageHeading>
                    <RefreshButton onRefresh={refetchData} />
                </header>
                <Banner
                    label={
                        String(NetworksError) ||
                        String(edgeClustersError) ||
                        String(policyError) ||
                        String(applicationsError)
                    }
                    variant={Variant.ERROR}
                />
            </div>
        )
    }

    if (
        adminInfoStatus === "loading" ||
        networksStatus === "loading" ||
        edgeClustersStatus === "loading" ||
        policiesStatus === "loading" ||
        applicationsStatus === "loading"
    ) {
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("networkSettings")
                )}
            />
        )
    }

    return (
        <AfterLoading
            applications={applications}
            networks={networks}
            edgeClusters={edgeClusters}
            policies={policies}
            hasGlobalEdge={adminInfo.isGlobalEdge}
            enableATG={props.enableATG}
            hideApplication={props.hideApplication}
            hideAccessTier={props.hideAccessTier}
        />
    )
}

interface AfterLoadingProps {
    applications: Application[]
    networks: Network[]
    edgeClusters: EdgeClusters
    policies: AccessPolicy[]
    hasGlobalEdge: boolean
    enableATG: boolean
    hideApplication: boolean
    hideAccessTier: boolean
}

function AfterLoading(props: AfterLoadingProps): JSX.Element {
    const toastRef = React.useRef<ToastApi>(null)
    const history = useHistory()
    const localization = useServiceLocalization()

    const saveButtonRef = React.useRef<HTMLButtonElement & HTMLAnchorElement>(null)

    const [serviceTunnel, setServiceTunnel] = React.useState(() =>
        props.hasGlobalEdge
            ? {
                  ...emptyServiceTunnelDetail,
                  networkSettings: [emptyGlobalEdgeNetworkSetting],
              }
            : emptyServiceTunnelDetail
    )

    React.useEffect(() => {
        const validityMessage = serviceTunnel.networkSettings.some(isValidNetworkSetting)
            ? ""
            : "Network settings are required"

        saveButtonRef.current?.setCustomValidity(validityMessage)
    }, [serviceTunnel.networkSettings])

    const { mutate: onCreateServiceTunnel, isLoading: isCreatingTunnel } = useCreateServiceTunnel(
        props.enableATG,
        {
            onSuccess: (serviceTunnel) => {
                history.push(
                    formatRoutePath(ROUTE.SERVICE_TUNNELS_DETAILS, {
                        id: encodeID(serviceTunnel.id!),
                    })
                )
            },
            onError: (error) =>
                typeof error === "string"
                    ? toastRef.current?.openToast(error)
                    : console.error(error),
        }
    )

    const onNetworkSettingsChange = (networkSettings: NetworkSetting[]) => {
        setServiceTunnel((prev) => ({
            ...prev,
            networkSettings: networkSettings,
        }))
    }

    const onGeneralInfoChange = (generalInfo: GeneralInfo) => {
        setServiceTunnel((prev) => ({
            ...prev,
            name: generalInfo.name,
            description: generalInfo.description,
        }))
    }

    const onSearchDomainsChange = (domains: string[]) => {
        setServiceTunnel((prev) => ({
            ...prev,
            dnsSearchDomains: domains,
        }))
    }

    const onAutoStartOnLoginChange = () => {
        setServiceTunnel((prev) => ({
            ...prev,
            autorun: !prev.autorun,
        }))
    }

    const onUserControlStartOnLoginChange = () => {
        setServiceTunnel((prev) => ({
            ...prev,
            isAutorunLocked: !prev.autorun ? false : !prev.isAutorunLocked,
        }))
    }

    const onPolicyAttachmentChange = (policyAttachment: PolicyAttachment | undefined) => {
        setServiceTunnel((prev) => ({
            ...prev,
            policyAttachment,
        }))
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        onCreateServiceTunnel({
            ...serviceTunnel,
            globalEdgeClusterName: props.edgeClusters.global?.name,
        })
    }

    return (
        <div className={styles.container}>
            <Form onSubmit={onSubmit}>
                <ServiceTunnelForm
                    serviceTunnel={serviceTunnel}
                    onNetworkSettingsChange={onNetworkSettingsChange}
                    onGeneralInfoChange={onGeneralInfoChange}
                    applications={props.applications}
                    networks={props.networks}
                    policies={props.policies}
                    hideApplication={props.hideApplication}
                    hideAccessTier={props.hideAccessTier}
                />

                <AssignmentCard
                    serviceTunnel={serviceTunnel}
                    policies={props.policies}
                    onPolicyAttachmentChange={onPolicyAttachmentChange}
                    onAutoStartOnLoginChange={onAutoStartOnLoginChange}
                    onUserControlStartOnLoginChange={onUserControlStartOnLoginChange}
                    onPrivateSearchDomainsChange={onSearchDomainsChange}
                />

                <div className={styles.actionButtons}>
                    <Button
                        asElement={ButtonElement.LINK}
                        buttonType={ButtonType.SECONDARY}
                        to={ROUTE.SERVICE_TUNNELS}
                    >
                        {localization.getString("cancel")}
                    </Button>

                    <Button
                        ref={saveButtonRef}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.PRIMARY}
                        type="submit"
                        disabled={serviceTunnel.networkSettings.length === 0}
                        loading={isCreatingTunnel}
                    >
                        {localization.getString("save")}
                    </Button>
                </div>
                <ErrorToast ref={toastRef} />
            </Form>
        </div>
    )
}

enum Id {
    HEADING = "heading",
}
