import classnames from "classnames/bind"
import React from "react"

import { Icon, IconSize, IconType } from "../icon/Icon.component"
import styles from "./ActionButton.module.scss"

export { IconType } from "../icon/Icon.component"

export const ActionButton = React.forwardRef<HTMLButtonElement, ActionButtonProps>((props, ref) => {
    const { icon, buttonType, className, size, ...restProps } = props

    const classes = classnames(props.className, className, styles.actionButton, {
        [styles.default]: buttonType === ActionButtonType.DEFAULT,
        [styles.primary]: buttonType === ActionButtonType.PRIMARY,
        [styles.secondary]: buttonType === ActionButtonType.SECONDARY,
        [styles.secondarySolid]: buttonType === ActionButtonType.SECONDARY_SOLID,
        [styles.destructive]: buttonType === ActionButtonType.DESTRUCTIVE,
        [styles.destructiveSolid]: buttonType === ActionButtonType.DESTRUCTIVE_SOLID,
        [styles.disabled]: restProps.disabled,
    })

    return (
        <button {...restProps} className={classes} type="button" ref={ref}>
            {<Icon icon={icon} size={props.size} />}
        </button>
    )
})

ActionButton.displayName = "ActionButton"

export type ActionButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
    CustomActionButtonProps

interface CustomActionButtonProps {
    icon: IconType
    buttonType: ActionButtonType
    size: IconSize
}

export enum ActionButtonType {
    DEFAULT = "default",
    PRIMARY = "primary",
    SECONDARY = "secondary",
    SECONDARY_SOLID = "secondary-solid",
    DESTRUCTIVE = "destructive",
    DESTRUCTIVE_SOLID = "destructive-solid",
}
