import React from "react"

import { Input } from "../../../components/input/Input.component"
import { FormLabel } from "../../../pre-v3/components/form-label/FormLabel"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"

interface Props {
    id: string
    value?: string
    disabled?: boolean
    onChange?(value?: string): void
}

export function InternalOwnerInput(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <FormLabel inline title={localization.getString("internalOwner")} htmlFor={props.id}>
            <Input
                type="email"
                id={props.id}
                value={props.value ?? ""}
                onChangeValue={(value) => props.onChange?.(value || undefined)}
                disabled={props.disabled}
            />
        </FormLabel>
    )
}
