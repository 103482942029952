import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { ROUTE } from "../../../routes"
import { PrivateResourceList } from "./list/PrivateResourceList.view"
import { PrivateResourceOverview } from "./overview/PrivateResourceOverview.view"
import useTitle from "../../../hooks/useTitle.hook"

export function Discovery(): JSX.Element {
    useTitle(["discovery", "privateAccess", "adminConsole"])
    return (
        <Switch>
            <Route path={ROUTE.DISCOVERY_DETAILS} component={PrivateResourceOverview} />
            <Route path={ROUTE.DISCOVERY} component={PrivateResourceList} />
            <Redirect to={ROUTE.DISCOVERY} />
        </Switch>
    )
}
