import { BaseApi, PaginatedResponse, PaginationOptions } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"

@Singleton("ConnectorApi")
export class ConnectorApi extends BaseApi {
    public getConnectors(
        search?: Partial<ConnectorSearch>
    ): Promise<PaginatedResponse<"satellites", ConnectorRes>> {
        const urlParams: URLSearchParams = new URLSearchParams(search as any)
        return this.get(`/api/experimental/v2/satellite?` + urlParams.toString(), {
            default: this.localization.getString("failedToFetchConnectors"),
        })
    }

    public getConnectorsStats(): Promise<ConnectorStats> {
        return this.get("/api/v2/connector/stats", {
            default: this.localization.getString("failedToFetchConnectorsStats"),
        })
    }

    public getConnectorById(id: string): Promise<ConnectorRes> {
        return this.get(`/api/v2/satellite/${encodeURIComponent(id)}`, {
            default: this.localization.getString("connectorNotFound"),
        })
    }

    public createConnector(
        metadata: ConnectorMetadataReq,
        spec: ConnectorSpecReq
    ): Promise<ConnectorRes> {
        const connector: ConnectorReq = {
            kind: "BanyanConnector",
            api_version: "rbac.banyanops.com/v1",
            type: "attribute-based",
            metadata: metadata,
            spec: spec,
        }
        return this.post(`/api/v2/satellite`, connector, {
            409: this.localization.getString(
                "somethingNamedAlreadyExists",
                this.localization.getString("connector"),
                connector.metadata.display_name
            ),
            default: this.localization.getString("failedToCreateConnector"),
        })
    }

    public updateConnector(
        id: string,
        metadata: ConnectorMetadataReq,
        spec: ConnectorSpecReq
    ): Promise<ConnectorRes> {
        return this.put(
            `/api/experimental/v2/satellite/${id}`,
            {
                kind: "BanyanConnector",
                api_version: "rbac.banyanops.com/v1",
                type: "attribute-based",
                metadata: metadata,
                spec: spec,
            },
            {
                default: this.localization.getString("failedToUpdateConnector"),
            }
        )
    }

    public deleteConnector(id: string): Promise<void> {
        return this.delete(
            `/api/v2/satellite/${id}`,
            {},
            {
                default: this.localization.getString("failedToDeleteConnector"),
            }
        )
    }
}

export interface ConnectorSearch extends PaginationOptions {
    name: string
    display_name: string
}

export interface ConnectorsListRes {
    count: number
    satellites: ConnectorRes[]
}

export interface ConnectorRes {
    id: string
    org_id: string
    name: string
    description?: string
    display_name: string
    access_tiers: ConnectorAccessTierRes[]
    cidrs: string[]
    domains: string[]
    tunnel_ip_address: string
    keepalive: number
    wireguard_public_key: string
    status: Status
    created_at: number
    created_by: string
    updated_at: number
    updated_by: string
    api_key_id: string
    connector_version?: string
    host_info?: HostInfo
    spec: string
    last_status_updated_at?: number
}

type Status = "Reporting" | "PartiallyReporting" | "Pending" | "Terminated"

export interface ConnectorAccessTierRes {
    satellite_tunnel_peer_id: string
    access_tier_id: string
    access_tier_name: string
    healthy: boolean
}

export interface ConnectorReq {
    kind: "BanyanConnector"
    api_version: "rbac.banyanops.com/v1"
    type: "attribute-based"
    metadata: ConnectorMetadataReq
    spec: ConnectorSpecReq
}

export interface ConnectorMetadataReq {
    name: string
    display_name: string
    description?: string
}

export interface ConnectorSpecReq {
    api_key_id: string
    keepalive: number
    cidrs: string[]
    domains: string[]
    peer_access_tiers: ConnectorClusterReq[]
    disable_snat: boolean
    deployment?: LinuxDeployment | WindowDeployment | SonicOsDeployment | VirtualApplianceDeployment
}

export interface ConnectorSpecRes {
    spec: ConnectorSpecReq
}

export interface WindowDeployment {
    platform: "Windows"
    method: "Installer"
}

export interface VirtualApplianceDeployment {
    platform: "Virtual_Appliance"
    method: "Executable"
}

export interface LinuxDeployment {
    platform: "Linux"
    method: "Docker" | "Tarball"
}

export interface SonicOsDeployment {
    platform: "SonicOS"
    method: undefined
}

export interface ConnectorClusterReq {
    cluster: string
    access_tiers: string[]
    connectors?: string[]
}

export interface HostInfo {
    name: string
    ip_addresses: string[]
}

export interface ConnectorStats {
    total: number
    status: ConnectorStatus
}

export interface ConnectorStatus {
    healthy: number
    pending: number
    partially_healthy: number
    terminated: number
}
