import {
    useMutation,
    useQuery,
    useQueryClient,
    QueryClient,
    UseQueryResult,
} from "@tanstack/react-query"
import {
    CloudResource,
    CloudResourceService,
    InventoryService,
    LocalizationService,
} from "../../pre-v3/services"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { CollectionUtil } from "../../pre-v3/utils/Collection.util"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import { StringUtil } from "../../pre-v3/utils/String.util"
import {
    HostedServiceApi,
    HostedServiceMetadataReq,
    HostedServiceReq,
    HostedServiceRes,
    HostedServiceSpecReq,
    SameSiteReq,
} from "../api/HostedService.api"
import { PolicyApi } from "../api/Policy.api"
import { RegisteredServiceApi, RegisteredServicesRes } from "../api/RegisteredService.api"
import { PillMultiSelectOption } from "../components/pill-multi-select/PillMultiSelect.component"
import { AccessTier } from "./AccessTier.service"
import { AccessTierGroup, Cluster, helperGetClusters } from "./Cluster.service"
import { Connector } from "./Connector.service"
import { RegisteredService, RegisteredServiceStatus } from "./shared/RegisteredService"
import { getPolicyType, PolicyType } from "./shared/Policy"
import { ApiFunction } from "./shared/QueryKey"

const serviceKey = "hostedWebsites"

export function useCreateHostedService<HS extends HostedService = HostedService>(
    enableATG: boolean,
    options?: QueryOptions<HostedService, string, ServiceForceParams<HS>>
) {
    const queryClient = useQueryClient()
    const hostedServiceApi: HostedServiceApi = new HostedServiceApi()
    const policyApi: PolicyApi = new PolicyApi()
    const localization: LocalizationService = new LocalizationService()

    return useMutation<HostedService, string, ServiceForceParams<HS>>({
        ...options,
        mutationFn: async (params: ServiceForceParams<HS>) => {
            const service = params.service
            // Need to check if service with the same name already exists
            // due to how our api is implemented, doing this naively may
            // unintentionally overwrite an existing service
            let existing: HostedService | undefined = await helperGetHostedServiceByName(
                queryClient,
                service.name
            )
            if (existing && !params.force) {
                return Promise.reject(
                    localization.getString(
                        "somethingNamedAlreadyExists",
                        localization.getString("service"),
                        service.name
                    )
                )
            }
            const req: HostedServiceReq = mapHostedServiceToHostedServiceReq(service)
            const res: HostedServiceRes = await hostedServiceApi.insertHostedService(req)
            if (service.policyAttachment && service.policyAttachment.policyId) {
                await policyApi.setPolicyAttachment(
                    service.policyAttachment.policyId,
                    res.ServiceID,
                    service.policyAttachment.enabled
                )
            }
            const clusters: Cluster[] = await helperGetClusters(enableATG, queryClient)
            const newService: HostedService | undefined = mapHostedServiceResToHostedService(
                res,
                clusters
            )
            if (!newService) {
                return Promise.reject(localization.getString("failedToCreateService"))
            }
            return newService
        },
        onSuccess: (params) => {
            queryClient.removeQueries(["hostedService.getHostedServices"])
            queryClient.removeQueries(["hostedService.getHostedServiceById"])
            options?.onSuccess?.(params)
        },
    })
}

// TODO: Turn this into a hook
export function getParamsToCloneHostedWebsite(
    originalHostedWebsite: WebService,
    newName: string
): ServiceForceParams<WebService> {
    const { existingHostname, newPublicUrl, newDomainName } = getValuesForCloning(
        originalHostedWebsite,
        newName
    )

    return {
        service: {
            ...originalHostedWebsite,
            name: newName,
            domain: newPublicUrl,
            dnsNames: originalHostedWebsite.dnsNames.map((dnsName) =>
                dnsName === existingHostname ? newDomainName : dnsName
            ),
            tlsSni: originalHostedWebsite.tlsSni.map((tlsSni) =>
                tlsSni === originalHostedWebsite.domain ? newPublicUrl : tlsSni
            ),
        },
    }
}

export interface ConfigurationValues {
    description: string
    infra: HostedServiceInfra[]
    backendDomain: string
    backendPort: number
    isTls: boolean
    isTlsInsecure: boolean
    hasClientCertificate: boolean
    domain: string
    port: number
    certificate: Certificate
    registeredDomainId?: string
}

// TODO: Move to a hook
export function updateHostedWebsiteConfiguration(
    hostedWebsite: WebService,
    configuration: ConfigurationValues
): WebService {
    const existingDomainName = getDomainName(hostedWebsite)
    const newDomainName = getDomainName(configuration)

    return {
        ...hostedWebsite,
        ...configuration,
        tls: configuration.isTls,
        tlsInsecure: configuration.isTlsInsecure,
        clientCertificate: configuration.hasClientCertificate,
        dnsNames: hostedWebsite.dnsNames.map((dnsName) =>
            dnsName === existingDomainName ? newDomainName : dnsName
        ),
        tlsSni: hostedWebsite.tlsSni.map((item) =>
            item === hostedWebsite.domain ? configuration.domain : item
        ),
    }
}

function helperGetHostedServices(
    queryClient: QueryClient,
    type?: HostedServiceType,
    enableATG?: boolean
): Promise<HostedService[]> {
    const hostedServiceApi: HostedServiceApi = new HostedServiceApi()
    return queryClient.ensureQueryData({
        queryKey: ["hostedService.getHostedServices", type],
        queryFn: () => {
            return Promise.all([
                hostedServiceApi.getHostedServices(),
                helperGetClusters(enableATG || false, queryClient),
            ]).then(([services, clusters]) => {
                let hostedServices: HostedService[] = []
                for (const s of services) {
                    const hostedService: HostedService | undefined =
                        mapHostedServiceResToHostedService(s, clusters)
                    if (hostedService) {
                        hostedServices.push(hostedService)
                    }
                }

                if (type) {
                    hostedServices = hostedServices.filter((s) => s.type === type)
                }

                return hostedServices
            })
        },
    })
}

export function useGetHostedServices(enableATG: boolean, type?: HostedServiceType) {
    const queryClient = useQueryClient()
    const query = useQuery<HostedService[], string>({
        queryKey: ["hostedService.getHostedServices", type],
        queryFn: () => helperGetHostedServices(queryClient, type, enableATG),
    })
    return {
        ...query,
        refetch: () => {
            queryClient.removeQueries(["hostedService.getHostedServices", type])
            query.refetch()
        },
    }
}

function helperGetHostedServiceById(
    queryClient: QueryClient,
    id: string,
    enableATG: boolean
): Promise<HostedService> {
    const registeredServiceApi = new RegisteredServiceApi()
    const ls: LocalizationService = new LocalizationService()

    return queryClient.ensureQueryData({
        queryKey: ["hostedService.getHostedServiceById", id],
        queryFn: () => {
            return Promise.all([
                registeredServiceApi.getRegisteredServices({ ServiceID: id }),
                helperGetClusters(enableATG, queryClient),
            ]).then(([services, clusters]) => {
                if (services && services.length > 0) {
                    const service: HostedService | undefined = mapHostedServiceResToHostedService(
                        services[0],
                        clusters
                    )
                    if (service) {
                        return service
                    } else {
                        return Promise.reject(ls.getString("serviceSpecCouldNotBeParsed"))
                    }
                } else {
                    return Promise.reject(ls.getString("serviceNotFound"))
                }
            })
        },
    })
}

export function useGetHostedServiceById(
    id: string,
    enableATG: boolean,
    options?: QueryOptions<HostedService>
) {
    const queryClient = useQueryClient()
    return useQuery<HostedService, string>({
        ...options,
        queryKey: ["hostedService.getHostedServiceById", id],
        queryFn: () => helperGetHostedServiceById(queryClient, id, enableATG),
    })
}

async function helperGetHostedServiceByName(
    queryClient: QueryClient,
    name: string
): Promise<HostedService | undefined> {
    const services: HostedService[] = await helperGetHostedServices(queryClient)
    return services.find((s) => s.name === name)
}

export function useUpdateHostedService(
    enableATG: boolean,
    options?: QueryOptions<HostedService, string, HostedService>
) {
    const queryClient = useQueryClient()
    const policyApi: PolicyApi = new PolicyApi()
    const localization: LocalizationService = new LocalizationService()

    const createHostedService = useCreateHostedService(enableATG)
    const deleteHostedService = useDeleteHostedService()

    return useMutation<HostedService, string, HostedService>({
        ...options,
        mutationFn: async (service: HostedService) => {
            if (!service.id) {
                return Promise.reject(localization.getString("serviceMustHaveAnId"))
            }
            const existing: HostedService = await helperGetHostedServiceById(
                queryClient,
                service.id,
                enableATG
            )
            if (existing.name !== service.name) {
                // User has changed the name of the service.
                // Delete the old service and create a new one.
                await deleteHostedService.mutateAsync({
                    service,
                    force: true,
                })
                return createHostedService.mutateAsync({
                    service,
                })
            } else {
                if (
                    existing.policyAttachment?.policyId &&
                    existing.policyAttachment?.policyId !== service.policyAttachment?.policyId
                ) {
                    await policyApi.deletePolicyAttachment(
                        existing.policyAttachment.policyId,
                        service.id
                    )
                }
                return createHostedService.mutateAsync({
                    service,
                    force: true,
                })
            }
        },
        onSuccess: (service: HostedService) => {
            queryClient.removeQueries(["hostedService.getHostedServices"])
            queryClient.removeQueries(["hostedService.getHostedServiceById", service.id])
            options?.onSuccess?.(service)
        },
    })
}

export function mapCloudResourceToWebService(resource: CloudResource): WebService {
    const ls = new LocalizationService()
    //add random string to service name to be able to avoid overwriting of services with same name
    var randomString = (Math.floor(Math.random() * (100 - 1 + 1)) + 1).toString()

    var serviceName = (resource.resourceName || resource.resourceId).concat("-", randomString)
    var descriptionString = ls.getString(
        "publishedFromResource",
        resource.resourceName || resource.resourceId
    )

    const data = {} as WebService

    return {
        ...data,
        icon: "cloud",
        id: resource.id,
        name: serviceName,
        createdAt: resource.createdAt,
        description: descriptionString,
    }
}

export function useDeleteHostedService(options?: QueryOptions<void, string, ServiceForceParams>) {
    const queryClient = useQueryClient()
    const hostedServiceApi: HostedServiceApi = new HostedServiceApi()
    const policyApi: PolicyApi = new PolicyApi()
    const localization: LocalizationService = new LocalizationService()

    return useMutation<void, string, ServiceForceParams>({
        ...options,
        mutationFn: async (params: ServiceForceParams) => {
            const service = params.service
            if (!service.id) {
                return Promise.reject(localization.getString("serviceMustHaveAnId"))
            }
            if (!params.force) {
                if (service.enabled || service.policyAttachment?.policyId) {
                    return Promise.reject(
                        localization.getString("serviceDeleteDisabledDescription")
                    )
                }
            } else {
                if (service.enabled) {
                    await hostedServiceApi.disableHostedService(service.id)
                }
                if (service.policyAttachment?.policyId) {
                    await policyApi.deletePolicyAttachment(
                        service.policyAttachment.policyId,
                        service.id
                    )
                }
            }

            return hostedServiceApi.deleteHostedService(service.id)
        },
        onSuccess: (_data) => {
            queryClient.removeQueries(["hostedService.getHostedServices"])
            options?.onSuccess?.()
        },
    })
}

export function useGetCloudResourceByServiceId(
    id: string = "",
    options?: QueryOptions<CloudResourceService[]>
) {
    const inventoryService = new InventoryService()
    return useQuery<CloudResourceService[], string>({
        ...options,
        queryKey: ["inventoryService.getCloudResourceByServiceId", id],
        queryFn: () => inventoryService.getResourceAndServiceDetails({ service_id: id }),
        enabled: Boolean(id),
    })
}

export function useEnableService(options?: QueryOptions<void, string, string>) {
    const queryClient = useQueryClient()
    const hostedServiceApi: HostedServiceApi = new HostedServiceApi()

    return useMutation<void, string, string>({
        ...options,
        mutationFn: (id: string) => hostedServiceApi.enableHostedService(id),
        onSuccess: (_data, id) => {
            queryClient.removeQueries(["hostedService.getHostedServiceById", id])
            queryClient.removeQueries(["hostedService.getHostedServices"])
            options?.onSuccess?.()
        },
    })
}

export function useDisableService(options?: QueryOptions<void, string, string>) {
    const queryClient = useQueryClient()
    const hostedServiceApi: HostedServiceApi = new HostedServiceApi()

    return useMutation<void, string, string>({
        ...options,
        mutationFn: (id: string) => hostedServiceApi.disableHostedService(id),
        onSuccess: (_data, id) => {
            queryClient.removeQueries(["hostedService.getHostedServiceById", id])
            queryClient.removeQueries(["hostedService.getHostedServices"])
            options?.onSuccess?.()
        },
    })
}

export interface HostedService extends RegisteredService {
    type: HostedServiceType
    userFacing: boolean
    enabled: boolean
    infra: HostedServiceInfra[]
    dnsNames: string[]

    domain: string
    port: number
    raw?: string
}

export type HostedServiceType = "web" | "ssh" | "rdp" | "kube" | "database" | "tcp"

export function getServiceAccountTypes(): ServiceAccountSelectList[] {
    const ls = new LocalizationService()
    return [
        { displayName: ls.getString("authorizationHeader"), value: "authorization" },
        { displayName: ls.getString("queryParameter"), value: "query" },
        { displayName: ls.getString("customHeader"), value: "custom" },
    ]
}

export type ServiceAccountType = "authorization" | "custom" | "query"

interface ServiceAccountSelectList {
    displayName: string
    value: ServiceAccountType
}

export interface HostedServiceInfra {
    id: string
    name: string
    // need the AT info even when type=connector to map registered domains
    networkIds: string[]
    clusterName: string
    type: NetworkType
}

export enum NetworkType {
    ACCESS_TIER = "accessTier",
    CONNECTOR = "connector",
    ACCESS_TIER_GROUP = "accessTierGroup",
}

export interface WebService extends HostedService {
    backendDomain: string
    backendPort: number
    tls: boolean
    tlsInsecure: boolean
    clientCertificate: boolean
    registeredDomainId?: string

    serviceAccountAccess: ServiceAccountAccess

    exemptions: ServiceExemption[]
    customHeaders: StringMap

    suppressDtv: boolean
    disablePrivateDns: boolean
    isServiceTestable?: boolean
    trustCookieSameSitePolicy?: SameSite
    trustCookiePath?: string
    certificate: Certificate
    postAuthRedirectPath: string
    tlsSni: string[]
}

export enum SameSite {
    STRICT = "strict",
    LAX = "lax",
    NONE = "none",
}

export enum CertificateType {
    LETS_ENCRYPT = "LETS_ENCRYPT",
    BANYAN_PKI = "BANYAN_PKI",
    CUSTOM = "CUSTOM",
}

export const certificateTypeLabelDict: Record<CertificateType, LanguageKey> = {
    [CertificateType.LETS_ENCRYPT]: "letsEncrypt",
    [CertificateType.BANYAN_PKI]: "sonicWallCsePki",
    [CertificateType.CUSTOM]: "custom",
}

interface LetsEncryptCertificate {
    type: CertificateType.LETS_ENCRYPT
}

export const letsEncryptCertificate: LetsEncryptCertificate = {
    type: CertificateType.LETS_ENCRYPT,
}

interface BanyanPkiCertificate {
    type: CertificateType.BANYAN_PKI
}

export const banyanPkiCertificate: BanyanPkiCertificate = {
    type: CertificateType.BANYAN_PKI,
}

interface CustomCertificate {
    type: CertificateType.CUSTOM
    certificateFilePath: string
    privateKeyFilePath: string
}

export type Certificate = LetsEncryptCertificate | BanyanPkiCertificate | CustomCertificate

export interface SshService extends HostedService {
    backendProxyMode: BackendProxyMode
    backendDomain: string
    backendPort: number
    backendHostnames: string[]
    backendCidrRanges: string[]
    backendDnsOverride: string

    appSettings: {
        hostDirective: string
        sshConnectMode: SshConnectMode
        allowWrite: boolean
    }
}

export enum SshConnectMode {
    TRUST_CERT = "TRUSTCERT",
    TRUST_AND_SSH_CERT = "BOTH",
}

export enum BackendProxyMode {
    FIXED,
    CLIENT_SPECIFIED,
}

export interface ServiceAccountAccess {
    enabled: boolean
    type: ServiceAccountType
    value?: string
}

export interface ServiceExemption {
    origin: string[]
    target: string[]
    methods: string[]
    mandatoryHeaders: string[]
    paths: string[]
    sourceCidrs: string[]
}

export enum ExemptedMethods {
    OPTIONS = "OPTIONS",
    GET = "GET",
    POST = "POST",
    HEAD = "HEAD",
    PUT = "PUT",
    DELETE = "DELETE",
}

export function getExemptionMethods(): PillMultiSelectOption[] {
    return [
        { label: "HEAD", value: ExemptedMethods.HEAD },
        { label: "GET", value: ExemptedMethods.GET },
        { label: "POST", value: ExemptedMethods.POST },
        { label: "PUT", value: ExemptedMethods.PUT },
        { label: "DELETE", value: ExemptedMethods.DELETE },
        { label: "OPTIONS", value: ExemptedMethods.OPTIONS },
    ]
}

function mapHostedServiceResToHostedService(
    svc: RegisteredServicesRes,
    clusters: Cluster[]
): HostedService | undefined {
    let json: HostedServiceReq
    try {
        json = JSON.parse(svc.ServiceSpec)
    } catch {
        console.error(`Service spec could not be parsed for ${svc.ServiceID}`)
        return
    }

    let status: RegisteredServiceStatus = RegisteredServiceStatus.NO_POLICY
    if (svc.AttachedPolicy && svc.AttachedPolicy.PolicyID) {
        if (svc.AttachedPolicy.PolicyStatus) {
            status = RegisteredServiceStatus.POLICY_ENFORCING
        } else {
            status = RegisteredServiceStatus.POLICY_PERMISSIVE
        }
    }
    if (svc.Enabled !== "TRUE") {
        status = RegisteredServiceStatus.INACTIVE
    }

    const domain = json.metadata?.tags?.domain ?? ""

    const service: HostedService = {
        id: svc.ServiceID,
        name: json.metadata?.name || "",
        description: json.metadata?.description || "",
        descriptionLink: json.metadata?.tags?.description_link || "",
        icon: json.metadata?.tags?.icon || "",
        type: mapServiceAppTypeToServiceType(json.metadata?.tags?.service_app_type),
        userFacing: StringUtil.isTrue(json.metadata?.tags.user_facing),
        enabled: StringUtil.isTrue(svc.Enabled),
        infra: getHostedServiceInfra(json, clusters),
        status,
        domain,
        port: StringUtil.getNumber(json.metadata.tags.port),
        dnsNames: json.spec?.cert_settings?.dns_names ?? [],

        createdAt: DateUtil.convertLargeTimestamp(svc.CreatedAt),
        createdBy: svc.CreatedBy,
        updatedAt: DateUtil.convertLargeTimestamp(svc.LastUpdatedAt),
        updatedBy: svc.LastUpdatedBy,
        raw: svc.ServiceSpec,
    }

    if (svc.AttachedPolicy) {
        service.policyAttachment = {
            serviceId: service.id || "",
            serviceName: service.name,
            policyId: svc.AttachedPolicy.PolicyID || "",
            policyName: svc.AttachedPolicy.PolicyName || "",
            enabled: StringUtil.isTrue(svc.AttachedPolicy.PolicyStatus),
            attachedAt: DateUtil.convertLargeTimestamp(svc.AttachedPolicy.AttachedAt),
        }
    }

    if (service.type === "web") {
        const webService: WebService = {
            ...service,
            backendDomain: json.spec?.backend?.target?.name || "",
            backendPort: StringUtil.getNumber(json.spec?.backend?.target?.port),
            tls: json.spec?.backend?.target?.tls,
            tlsInsecure: json.spec?.backend?.target?.tls_insecure,
            clientCertificate: json.spec?.backend?.target?.client_certificate,
            registeredDomainId: json.metadata?.tags?.registered_domain_id,

            serviceAccountAccess: getServiceAccountAccess(json.spec),

            exemptions: getExemptions(json.spec),
            customHeaders: json.spec?.http_settings?.headers || {},

            suppressDtv:
                json.spec?.http_settings?.oidc_settings?.suppress_device_trust_verification,
            disablePrivateDns: json.spec?.attributes?.disable_private_dns,
            isServiceTestable:
                !json.spec?.backend.http_connect && !json.metadata.tags.domain!.includes("*"),

            trustCookieSameSitePolicy:
                typeof json.spec?.http_settings?.custom_trust_cookie?.same_site === "string"
                    ? sameSiteMap[json.spec.http_settings.custom_trust_cookie.same_site]
                    : undefined,
            trustCookiePath: json.spec?.http_settings?.custom_trust_cookie?.path,
            certificate: getCertificateFromSpec(json.spec),
            postAuthRedirectPath:
                json.spec?.http_settings?.oidc_settings?.post_auth_redirect_path ?? "/",
            tlsSni: json.spec?.attributes?.tls_sni ?? [domain],
        }

        return webService
    } else if (service.type === "ssh") {
        const allowPatterns:
            | {
                  hostnames: string[]
                  cidrs: string[]
              }
            | undefined = CollectionUtil.safeGetIndex(json.spec?.backend?.allow_patterns, 0)
        const sshService: SshService = {
            ...service,
            backendProxyMode: json.metadata?.tags?.ssh_chain_mode
                ? BackendProxyMode.FIXED
                : BackendProxyMode.CLIENT_SPECIFIED,
            backendDomain: json.spec?.backend?.target?.name || "",
            backendPort: StringUtil.getNumber(json.spec?.backend?.target?.port),
            backendHostnames: allowPatterns?.hostnames || [],
            backendCidrRanges: allowPatterns?.cidrs || [],
            backendDnsOverride:
                CollectionUtil.safeGetProperty(json.spec?.backend?.dns_overrides, service.domain) ||
                "",

            appSettings: {
                hostDirective: json.metadata?.tags?.ssh_host_directive || "",
                sshConnectMode:
                    json.metadata?.tags?.ssh_service_type === "TRUSTCERT"
                        ? SshConnectMode.TRUST_CERT
                        : SshConnectMode.TRUST_AND_SSH_CERT,
                allowWrite: !!json.metadata?.tags?.write_ssh_config,
            },
        }

        return sshService
    }

    return service
}

const sameSiteMap: Record<SameSiteReq, SameSite | undefined> = {
    "": undefined,
    default: undefined,
    lax: SameSite.LAX,
    strict: SameSite.STRICT,
    none: SameSite.NONE,
}

function getCertificateFromSpec(spec?: HostedServiceSpecReq): Certificate {
    if (spec?.cert_settings.custom_tls_cert.enabled) {
        return {
            type: CertificateType.CUSTOM,
            certificateFilePath: spec.cert_settings.custom_tls_cert.cert_file,
            privateKeyFilePath: spec.cert_settings.custom_tls_cert.key_file,
        }
    }

    if (spec?.cert_settings.letsencrypt) return { type: CertificateType.LETS_ENCRYPT }

    return { type: CertificateType.BANYAN_PKI }
}

function mapServiceAppTypeToServiceType(
    serviceAppType: "WEB" | "SSH" | "RDP" | "K8S" | "GENERIC" | "DATABASE"
): HostedServiceType {
    switch (serviceAppType) {
        case "WEB":
            return "web"
        case "SSH":
            return "ssh"
        case "RDP":
            return "rdp"
        case "K8S":
            return "kube"
        case "DATABASE":
            return "database"
        default:
            return "tcp"
    }
}

function mapHostedServiceToHostedServiceReq(service: HostedService): HostedServiceReq {
    const metadata: HostedServiceMetadataReq = {
        cluster: service.infra[0]?.clusterName ?? "",
        description: service.description || "",
        name: service.name,
        tags: {
            description_link: service.descriptionLink || "",
            domain: service.domain,
            icon: service.icon || "",
            port: service.port.toString(),
            protocol: service.type === "web" ? "https" : "tcp",
            template: mapServiceTypeToTemplate(service.type),
            service_app_type: mapServiceTypeToServiceAppType(service.type),
            user_facing: StringUtil.fromBoolean(service.userFacing),
        },
    }

    const spec: HostedServiceSpecReq = {
        attributes: {
            frontend_addresses: [
                {
                    cidr: "", // TODO
                    port: service.port.toString(),
                },
            ],
            host_tag_selector: [
                {
                    "com.banyanops.hosttag.site_name":
                        StringUtil.serializeArray(
                            service.infra.reduce<string[]>(
                                (acc, i) => (i.type === "accessTier" ? [...acc, i.name] : acc),
                                []
                            )
                        ) ||
                        StringUtil.serializeArray(
                            service.infra.map((i) => (i.type === "accessTierGroup" ? "" : "*"))
                        ),
                    "com.banyanops.hosttag.access_tier_group": StringUtil.serializeArray(
                        service.infra.reduce<string[]>(
                            (acc, i) => (i.type === "accessTierGroup" ? [...acc, i.name] : acc),
                            []
                        )
                    ),
                },
            ],
            tls_sni: [service.domain],
            disable_private_dns: false,
        },
        backend: {
            connector_name: service.infra.find((i) => i.type === "connector")?.name ?? "",
            dns_overrides: {}, // TODO
            http_connect: false, // TODO
            target: {
                // below
                name: "",
                port: "",
                tls: false,
                tls_insecure: false,
                client_certificate: false,
            },
            whitelist: [], // TODO
        },
        cert_settings: {
            dns_names: service.dnsNames ?? [getDomainName(service)],
            custom_tls_cert: {
                enabled: false,
                cert_file: "",
                key_file: "",
            }, // below
            letsencrypt: false, // below
        },
        client_cidrs: [], // TODO
        http_settings: {
            // below
            enabled: false,
            exempted_paths: {
                enabled: false,
                paths: [],
                patterns: [],
            },
            headers: {},
            http_health_check: {
                enabled: false,
                from_address: [],
                method: "",
                path: "",
                user_agent: "",
            },
            oidc_settings: {
                api_path: "",
                enabled: false,
                post_auth_redirect_path: "",
                service_domain_name: "",
                suppress_device_trust_verification: false,
            },
        },
    }

    if (service.type === "web") {
        const webService: WebService = service as WebService
        metadata.tags.registered_domain_id = webService.registeredDomainId
        spec.attributes.disable_private_dns = webService.disablePrivateDns
        spec.backend.target = {
            name: webService.backendDomain,
            port: webService.backendPort.toString(),
            tls: webService.tls,
            tls_insecure: webService.tlsInsecure,
            client_certificate: webService.clientCertificate,
        }

        spec.cert_settings.letsencrypt =
            webService.certificate.type === CertificateType.LETS_ENCRYPT

        if (webService.certificate.type === CertificateType.CUSTOM) {
            spec.cert_settings.custom_tls_cert.enabled = true
            spec.cert_settings.custom_tls_cert.cert_file =
                webService.certificate.certificateFilePath
            spec.cert_settings.custom_tls_cert.key_file = webService.certificate.privateKeyFilePath
        }

        spec.http_settings.enabled = true
        spec.attributes.tls_sni = webService.tlsSni
        spec.http_settings.oidc_settings = {
            enabled: true,
            service_domain_name: getFullyQualifiedDomain(service),
            post_auth_redirect_path: webService.postAuthRedirectPath,
            api_path: "",
            suppress_device_trust_verification: false,
        }
        if (webService.exemptions?.length > 0) {
            spec.http_settings.exempted_paths.enabled = true
            for (const e of webService.exemptions) {
                spec.http_settings.exempted_paths.patterns.push({
                    hosts: [
                        {
                            origin_header: e.origin,
                            target: e.target,
                        },
                    ],
                    methods: e.methods,
                    mandatory_headers: e.mandatoryHeaders,
                    paths: e.paths,
                    source_cidrs: e.sourceCidrs,
                    template: "CORS", // This field is no longer used.
                })
            }
        }
        spec.http_settings.headers = webService.customHeaders
        spec.http_settings.oidc_settings.suppress_device_trust_verification = webService.suppressDtv
        if (webService.serviceAccountAccess.enabled) {
            spec.http_settings.token_loc = {
                authorization_header: webService.serviceAccountAccess.type === "authorization",
                custom_header:
                    webService.serviceAccountAccess.type === "custom"
                        ? webService.serviceAccountAccess.value || ""
                        : "",
                query_param:
                    webService.serviceAccountAccess.type === "query"
                        ? webService.serviceAccountAccess.value || ""
                        : "",
            }
        }

        if (webService.trustCookieSameSitePolicy || webService.trustCookiePath) {
            spec.http_settings.custom_trust_cookie = {
                path: webService.trustCookiePath,
                same_site: webService.trustCookieSameSitePolicy,
            }
        }
    } else if (service.type === "ssh") {
        const sshService: SshService = service as SshService
        if (sshService.backendProxyMode === BackendProxyMode.FIXED) {
            spec.backend.target.name = sshService.backendDomain
            spec.backend.target.port = sshService.backendPort.toString()
        } else {
            spec.backend.allow_patterns = [
                {
                    hostnames: sshService.backendHostnames,
                    cidrs: sshService.backendCidrRanges,
                },
            ]
            spec.backend.http_connect = true
        }
        if (sshService.backendDnsOverride) {
            spec.backend.dns_overrides = { [sshService.domain]: sshService.backendDnsOverride }
        }
        metadata.tags.ssh_host_directive = sshService.appSettings.hostDirective
        metadata.tags.ssh_service_type = sshService.appSettings.sshConnectMode
        metadata.tags.ssh_chain_mode =
            sshService.backendProxyMode === BackendProxyMode.CLIENT_SPECIFIED
        metadata.tags.write_ssh_config = sshService.appSettings.allowWrite
    }

    return {
        apiVersion: "rbac.banyanops.com/v1",
        kind: "BanyanService",
        metadata: metadata,
        spec: spec,
        type: "origin",
    }
}

function mapServiceTypeToServiceAppType(
    serviceType: HostedServiceType
): "WEB" | "SSH" | "RDP" | "K8S" | "GENERIC" | "DATABASE" {
    switch (serviceType) {
        case "web":
            return "WEB"
        case "ssh":
            return "SSH"
        case "rdp":
            return "RDP"
        case "kube":
            return "K8S"
        case "database":
            return "DATABASE"
        default:
            return "GENERIC"
    }
}

function mapServiceTypeToTemplate(serviceType: HostedServiceType): "WEB_USER" | "TCP_USER" {
    switch (serviceType) {
        case "web":
            return "WEB_USER"
        default:
            return "TCP_USER"
    }
}

function getFullyQualifiedDomain(service: HostedService): string {
    if (service.type === "web") {
        return `https://${service.domain}`
    }
    return ""
}

function getServiceAccountAccess(spec: HostedServiceSpecReq): ServiceAccountAccess {
    const tokenLoc = spec?.http_settings?.token_loc
    if (!tokenLoc) {
        return {
            enabled: false,
            type: "authorization",
        }
    }

    let enabled: boolean = false
    let type: "authorization" | "custom" | "query" = "authorization"
    let value: string = ""
    if (tokenLoc.authorization_header) {
        enabled = true
        type = "authorization"
    } else if (tokenLoc.custom_header) {
        enabled = true
        type = "custom"
        value = tokenLoc.custom_header
    } else if (tokenLoc.query_param) {
        enabled = true
        type = "query"
        value = tokenLoc.query_param
    }

    return {
        enabled,
        type,
        value,
    }
}

function getExemptions(spec: HostedServiceSpecReq): ServiceExemption[] {
    const enabled: boolean =
        spec?.http_settings?.enabled && spec?.http_settings?.exempted_paths?.enabled
    if (!enabled) {
        return []
    }
    const patterns = spec?.http_settings?.exempted_paths?.patterns
    const exemptions: ServiceExemption[] = patterns.map((p) => {
        const hosts = p.hosts?.[0]
        return {
            origin: hosts?.origin_header || [],
            target: hosts?.target || [],
            methods: p.methods || [],
            mandatoryHeaders: p.mandatory_headers || [],
            paths: p.paths || [],
            sourceCidrs: p.source_cidrs || [],
        }
    })
    return exemptions
}

function getHostedServiceInfra(
    hostedServiceReq: HostedServiceReq,
    clusters: Cluster[]
): HostedServiceInfra[] {
    const connectorName: string = hostedServiceReq.spec?.backend?.connector_name || ""
    const accessTierNames: string[] = getAccessTierNames(hostedServiceReq)
    const accessTierGroupName =
        hostedServiceReq.spec.attributes.host_tag_selector[0][
            "com.banyanops.hosttag.access_tier_group"
        ]

    const connectors = clusters.reduce<Connector[]>((acc, v) => {
        return acc.concat(v.connectors || [])
    }, [])
    const accessTiers = clusters.reduce<AccessTier[]>((acc, v) => {
        return acc.concat(v.accessTiers || [])
    }, [])
    const accessTierGroups = clusters.reduce<AccessTierGroup[]>((acc, v) => {
        return acc.concat(v.accessTierGroups || [])
    }, [])

    if (connectorName) {
        const exists = connectors.find((c) => c.name === connectorName)
        if (exists) {
            return [
                {
                    id: exists.id || "",
                    name: exists.name,
                    clusterName: exists.clusterName,
                    networkIds: exists.accessTierIds,
                    type: NetworkType.CONNECTOR,
                },
            ]
        }
    } else if (accessTierNames.length > 0) {
        const infra: HostedServiceInfra[] = []
        for (const name of accessTierNames) {
            if (name !== "*") {
                const exists = accessTiers.find((a) => a.name === name)
                if (exists) {
                    infra.push({
                        id: exists.id || "",
                        name: exists.name,
                        clusterName: exists.clusterName,
                        networkIds: [exists.id || ""],
                        type: NetworkType.ACCESS_TIER,
                    })
                }
            }
        }
        return infra
    } else if (accessTierGroupName) {
        const exists = accessTierGroups.find((c) => c.name === accessTierGroupName)
        if (exists) {
            return [
                {
                    id: exists.id || "",
                    name: exists.name,
                    clusterName: exists.clusterName,
                    networkIds: [exists.id],
                    type: NetworkType.ACCESS_TIER_GROUP,
                },
            ]
        }
    }

    return []
}

function getAccessTierNames(json: HostedServiceReq): string[] {
    if (
        json.spec?.attributes?.host_tag_selector &&
        json.spec?.attributes?.host_tag_selector.length > 0
    ) {
        return StringUtil.deserializeArray(
            json.spec.attributes.host_tag_selector[0]["com.banyanops.hosttag.site_name"] ?? ""
        )
    } else {
        return []
    }
}

interface ServiceForceParams<HS extends HostedService = HostedService> {
    service: HS
    force?: boolean
}

interface ValuesForCloning {
    existingHostname: string
    newDomainName: string
    newPublicUrl: string
}

function getValuesForCloning(originalHostedWebsite: WebService, newName: string): ValuesForCloning {
    const existingUrl = getUrl(originalHostedWebsite.domain)

    if (!existingUrl) {
        const newDomainName = getNewDomainName(originalHostedWebsite.domain, newName)

        return {
            existingHostname: originalHostedWebsite.domain,
            newDomainName,
            newPublicUrl: newDomainName,
        }
    }

    const newDomainName = getNewDomainName(existingUrl.hostname, newName)

    const newUrl = new URL(existingUrl.href)
    newUrl.hostname = newDomainName

    return {
        existingHostname: existingUrl.hostname,
        newDomainName,
        newPublicUrl: newUrl.toString(),
    }
}

function getUrl(maybeUrl: string): URL | undefined {
    try {
        return new URL(maybeUrl)
    } catch (error) {}
}

function getDomainName(hasDomain: { domain: string }): string {
    return getUrl(hasDomain.domain)?.hostname ?? hasDomain.domain
}

function getNewDomainName(existingHostname: string, newName: string): string {
    const hostnameParts = existingHostname.split(".")
    if (hostnameParts.length <= 2) return `${newName}.${existingHostname}`
    return `${newName}.${hostnameParts.slice(1).join(".")}`
}

interface Variables {
    backendDomain: string[]
    clusterHeaders: string[]
}

export function useGetVariables() {
    const registeredServiceApi = new RegisteredServiceApi()

    return useQuery<Variables, string>({
        queryKey: ["hostedService.variables"],
        queryFn: async () => {
            const { web } = await registeredServiceApi.getTemplates()
            return {
                backendDomain: web.backend_target,
                clusterHeaders: web.header,
            }
        },
    })
}

export function useGetPolices(): UseQueryResult<AccessPolicy[]> {
    const policyApi = new PolicyApi()
    return useQuery({
        queryKey: [ApiFunction.GET_POLICIES, serviceKey],
        queryFn: async () => {
            const policiesRes = await policyApi.getAccessPolicies()
            return policiesRes
                .filter((p) => getPolicyType(p) === PolicyType.WEB)
                .map((p) => ({
                    id: p.PolicyID,
                    name: p.PolicyName,
                }))
        },
    })
}

export interface AccessPolicy {
    id: string
    name: string
}
