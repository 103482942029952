import { History } from "history"
import React from "react"

import { ROUTE } from "../../routes"
import { AuthApi, AuthRes } from "../api/Auth.api"
import {
    OIDCApi,
    SignInReq,
    SignInRes,
    ChangePasswordReq,
    ForgotPasswordReq,
    ResendCodeReq,
    ConfirmForgotPasswordReq,
    NewPasswordReq,
} from "../api/OIDC.api"
import { Singleton } from "../decorators/Singleton.decorator"
import { AuthService, useAuthService } from "./Auth.service"
import { LinkService } from "./link/Link.service"

@Singleton("OIDCService")
export class OIDCService {
    public static readonly ORG_KEY: string = "__OIDC/orgName"
    public static readonly QUERY_STATE_KEY: string = "--OIDC/queryState"
    public static readonly USER_KEY: string = "__OIDC/user"
    public static readonly EMAIL_KEY: string = "__OIDC/email"

    constructor(authService: AuthService) {
        this.authService = authService
    }

    /**
     * This is the silent login which is used when the session state is already available
     * @param orgName
     * @param state
     * @returns RedirectURL if successful, throws on error
     */
    public authorize(orgName: string, state: string): Promise<string> {
        return this.oidcApi.authorize(orgName, state).then((data) => {
            if (data) {
                this.setUser(data)
            }

            // If there is a RedirectURL, always follow the RedirectURL
            if (data?.RedirectURL && data.RedirectURL !== "") {
                return data.RedirectURL
            }

            // Otherwise, if Admin, redirect to SAML login
            // this kicks off a "unique" flow which will send the user
            // to TrustProvider - which then injects a RedirectURL and sends
            // them back here. At that point, since their login is cached
            // it will immediately follow the above logic, and then they
            // will get redirected to console
            if (data.IsAdmin) {
                return this.authApi.samlLogin(orgName).then((res: AuthRes) => {
                    return res?.Message || ""
                })
            }

            // None of the above is true, cannot silent login
            throw new Error("Non-admin user without RedirectURL")
        })
    }

    /**
     * Used to trigger the login flow.
     * @param payload
     * @returns RedirectURL on success, throws on error
     */
    public signIn(payload: SignInReq): Promise<string> {
        return this.oidcApi.signIn(payload).then((data) => {
            if (data) {
                this.setUser(data)
            }

            // If there is a RedirectURL, always follow it
            if (data?.RedirectURL) {
                return data.RedirectURL
            }

            // Handle Challenges
            if (data?.ChallengeName === "NEW_PASSWORD_REQUIRED") {
                return ROUTE.OIDC_NEW_PASSWORD
            }
            // MFA Challenges not implemented, default to main login
            if (data?.ChallengeName === "SMS_MFA" || data?.ChallengeName === "SOFTWARE_TOKEN_MFA") {
                return ROUTE.OIDC_LOGIN
            }

            // Otherwise, if Admin, redirect to SAML login
            // this kicks off a "unique" flow which will send the user
            // to TrustProvider - which then injects a RedirectURL and sends
            // them back here. At that point, since their login is cached
            // it will immediately follow the above logic, and then they
            // will get redirected to console
            if (data?.IsAdmin && data?.OrgName) {
                return this.authApi.samlLogin(data.OrgName).then((res: AuthRes) => {
                    return res?.Message || ""
                })
            }

            // If enduser, download the app
            return this.LinkService.getLink("getCSEAppNoDownload")
        })
    }

    public changePassword(payload: ChangePasswordReq) {
        return this.oidcApi.changePassword(payload).then(() => {
            window.location.href = ROUTE.OIDC_LOGIN
        })
    }

    public forgotPassword(payload: ForgotPasswordReq) {
        return this.oidcApi.forgotPassword(payload).then(() => {
            this.setEmail(payload.username)
            window.location.href = ROUTE.OIDC_CONFIRM_PASSWORD
        })
    }

    public resendCode(payload: ResendCodeReq) {
        return this.oidcApi.resendCode(payload)
    }

    public confirmForgotPassword(payload: ConfirmForgotPasswordReq) {
        return this.oidcApi.confirmForgotPassword(payload).then(() => {
            window.location.href = ROUTE.OIDC_LOGIN
        })
    }

    public newPassword(payload: NewPasswordReq) {
        return this.oidcApi.newPassword(payload).then((data) => {
            if (this.getQueryState()) {
                if (data?.RedirectURL && data.RedirectURL !== "") {
                    window.location.href = data.RedirectURL
                    return
                }
            }
            if (data) {
                window.location.href = ROUTE.OIDC_LOGIN
            }
        })
    }

    public navigateTo(route: string, history: History): void {
        if (route.startsWith("http")) {
            window.location.href = route
        } else {
            history.push(route)
        }
    }

    public getUser() {
        try {
            return JSON.parse(sessionStorage.getItem(OIDCService.USER_KEY) || "")
        } catch {
            this.authService.logoutOfAllTabs()
        }
    }

    public getOrgName(): string {
        return sessionStorage.getItem(OIDCService.ORG_KEY) || ""
    }

    public getQueryState(): string {
        return sessionStorage.getItem(OIDCService.QUERY_STATE_KEY) || ""
    }

    public getEmail(): string {
        return sessionStorage.getItem(OIDCService.EMAIL_KEY) || ""
    }

    public setOrgName(orgName: string): void {
        sessionStorage.setItem(OIDCService.ORG_KEY, orgName)
    }

    public setQueryState(queryState: string): void {
        sessionStorage.setItem(OIDCService.QUERY_STATE_KEY, queryState)
    }

    public setUser(user: SignInRes): void {
        sessionStorage.setItem(OIDCService.USER_KEY, JSON.stringify(user))
    }

    public setEmail(email?: string): void {
        if (email) {
            sessionStorage.setItem(OIDCService.EMAIL_KEY, email)
        } else {
            sessionStorage.removeItem(OIDCService.EMAIL_KEY)
        }
    }

    private oidcApi = new OIDCApi()
    private authApi = new AuthApi()
    private authService: AuthService
    private LinkService: LinkService = new LinkService()
}

export function useServiceOIDC(): OIDCService {
    const authService = useAuthService()

    return React.useMemo(() => new OIDCService(authService), [])
}
