import React, {
    FocusEvent,
    KeyboardEvent,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react"
import { BaseInputProps, BasicInput } from "./BasicInput.component"
import { Content, Item, Portal, Root, Trigger } from "@radix-ui/react-dropdown-menu"
import styles from "./VarInput.module.scss"
import classNames from "classnames"
import { PatternUtil } from "../../pre-v3/utils/Pattern.util"

interface VarInputProps extends BaseInputProps {
    variables: string[]
}

export const VarInput = React.forwardRef<HTMLInputElement, VarInputProps>((props, ref) => {
    const { variables, className, onBlur: originalOnBlur, ...otherProps } = props
    const inputRef = useRef<HTMLInputElement>(null)
    const textRef = useRef<HTMLSpanElement>(null)
    const listRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)
    const [options, setOption] = useState(variables)
    const value = props.value ? String(props.value) : ""

    const openVarRegExp = PatternUtil.OPEN_VARIABLE

    const { output } = useParseValue(value, variables)

    useImperativeHandle(ref, () => inputRef.current!)

    const getCursorPosition = () => {
        const input = inputRef.current
        if (!input) return 0
        return input.selectionStart ?? 0
    }

    const close = () => {
        setOpen(false)
    }

    const onOpen = () => {
        setOpen(true)
        setTimeout(() => {
            inputRef.current?.focus()
        }, 0)
    }

    const setScroll = () => {
        if (textRef.current && inputRef.current)
            textRef.current.scrollLeft = inputRef.current.scrollLeft
    }

    const simulateOnChange = (newValue: string) => {
        const input = inputRef.current
        if (!input) return
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            "value"
        )?.set
        nativeInputValueSetter?.call(input, newValue)
        const event = new Event("input", { bubbles: true })
        input.dispatchEvent(event)
    }

    const onSelect = (newValue: string) => {
        const activeText = value.replace(openVarRegExp, newValue)
        const restText = value.slice(getCursorPosition())
        const updatedValue = activeText + restText
        props.onChangeValue?.(updatedValue)
        simulateOnChange(updatedValue)
        close()
        inputRef.current?.focus()
    }

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "ArrowDown") {
            listRef.current?.focus()
        }
    }

    const onEscape = () => {
        close()
        inputRef.current?.focus()
    }

    const onInteractOutsideSelect = (e: CustomEvent) => {
        if (e.target === inputRef.current) return
        close()
    }

    const onBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
        if (listRef.current?.focus) return
        originalOnBlur?.(e)
    }

    useEffect(() => {
        const filterOptions = () => {
            const activeText = value.slice(0, getCursorPosition())
            const searchArr = activeText.match(openVarRegExp)
            if (!searchArr) return
            const search = pruneVarSearch(searchArr[0])
            const newOptions = props.variables.filter((val) => new RegExp(search, "ig").test(val))
            setOption(newOptions)
        }

        if (openVarRegExp.test(value)) {
            onOpen()
            filterOptions()
        } else {
            close()
        }
    }, [value])

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.inputContainer}>
                <BasicInput
                    {...otherProps}
                    ref={inputRef}
                    className={styles.input}
                    onScroll={setScroll}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                />
                <span
                    className={classNames(styles.text, { [styles.disablesText]: props.disabled })}
                    ref={textRef}
                >
                    {output}
                </span>
            </div>
            <Root open={open} modal={false}>
                <Trigger className={styles.trigger}></Trigger>

                <Portal>
                    <Content
                        onEscapeKeyDown={onEscape}
                        onInteractOutside={onInteractOutsideSelect}
                        className={styles.content}
                        style={{ width: `${inputRef.current?.getBoundingClientRect().width}px` }}
                        ref={listRef}
                        avoidCollisions={false}
                        sideOffset={1}
                    >
                        {options.map((variable) => (
                            <Item
                                onSelect={() => onSelect(variable)}
                                className={styles.item}
                                key={variable}
                            >
                                {variable}
                            </Item>
                        ))}
                    </Content>
                </Portal>
            </Root>
            {/*
            TODO - re add when this is defined 
            {invalidVariable && (
                <div className={styles.banner}>
                    <Banner
                        variant={Variant.ERROR}
                        label={localization.getString("attributeDoesNotExist", invalidVariable)}
                    />
                </div>
            )} */}
        </div>
    )
})

function splitVariables(value: string): string[] {
    return value.replaceAll("{{", "\u0000{{").replaceAll("}}", "}}\u0000").split("\u0000")
}

function isVariable(value: string) {
    return PatternUtil.VARIABLE.test(value)
}

function useParseValue(value: string, variables: string[]) {
    const [invalidVariable, setInvalidVariable] = useState<string>()

    const output = useMemo(() => {
        setInvalidVariable(undefined)
        return splitVariables(value).map((chunk, i) => {
            if (variables.includes(chunk))
                return (
                    <span className={styles.variable} key={i}>
                        {chunk}
                    </span>
                )
            if (isVariable(chunk)) setInvalidVariable(chunk)
            return <span key={i}>{chunk}</span>
        })
    }, [variables, value])

    return {
        output,
        invalidVariable,
    }
}

function pruneVarSearch(search: string) {
    return search.replaceAll("{", "").replaceAll(/[.*+?^${}()|[\]\\]/g, "\\$&")
}
