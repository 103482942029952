import React, { FC, useMemo } from "react"
import { OrgInfoWithLicense } from "../../v3/services/Org.service"
import { useServiceLinks, useServiceLocalization } from "../../pre-v3/services"
import styles from "./Licenses.module.scss"
import { LicensesCard } from "./licensesCard/LicensesCard.component"
import { AppText } from "../../v3/components/app-text/AppText.component"
import { Banner, Variant } from "../../components/banner/Banner.component"
import { LicenseLevel } from "../../v3/services/shared/LicenseInformation"
import { LicenseType } from "../../v3/services/User.service"
import { Card } from "../../components/card/Card.component"

interface Props {
    orgInfo: OrgInfoWithLicense
}

export const Licenses: FC<Props> = ({ orgInfo }) => {
    const localization = useServiceLocalization()
    const links = useServiceLinks()

    const needMoreLicenses = useMemo(() => {
        const { spaCount, spaUsageCount, siaCount, siaUsageCount, sia, spa } = orgInfo.license
        const needMoreSpa = spaUsageCount >= spaCount && spa !== LicenseLevel.NONE
        const needMoreSia = siaUsageCount >= siaCount && sia !== LicenseLevel.NONE

        return needMoreSpa || needMoreSia
    }, [orgInfo])

    return (
        <div className={styles.container}>
            <Card className={styles.topContainer}>
                <p>{localization.getString("organization")}</p>
                <h2>{orgInfo.orgName}</h2>
                <p>
                    {localization.getString(
                        "someEnvironment",
                        localization.getString(orgInfo.orgType)
                    )}
                </p>
            </Card>
            <Card className={styles.licensesContainer}>
                <div className={styles.titleDescription}>
                    <h2>{localization.getString("licenses")}</h2>
                    <div className={styles.description}>
                        <AppText
                            ls={{
                                key: "licensesDescription",
                                replaceVals: [links.getLink("licensingDoc")],
                            }}
                        />
                        {needMoreLicenses && (
                            <Banner
                                label={
                                    <AppText
                                        className={styles.errorBanner}
                                        ls={{
                                            key: "licensesWarning",
                                            replaceVals: [links.getLink("salesContact")],
                                        }}
                                    />
                                }
                                variant={Variant.ERROR}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.cards}>
                    <LicensesCard
                        licenseName={LicenseType.SIA}
                        licenseType={orgInfo.license.sia}
                        licensesCount={orgInfo.license.siaCount}
                        usageCount={orgInfo.license.siaUsageCount}
                    />
                    <LicensesCard
                        licenseName={LicenseType.SPA}
                        licenseType={orgInfo.license.spa}
                        licensesCount={orgInfo.license.spaCount}
                        usageCount={orgInfo.license.spaUsageCount}
                    />
                </div>
            </Card>
        </div>
    )
}
