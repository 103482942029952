// externals
import * as React from "react"
// locals
import {
    ServiceFormProps,
    useCluster,
    useServiceType,
    useSetDNSOverride,
    useSetErrors,
    useSetShowError,
    useUpdateAttributes,
    useUpdateTags,
} from "../../service-form"
import {
    Connector,
    ServiceType,
    useServiceInfra,
    useServiceLocalization,
} from "../../../../services"
import styles from "./ServiceAttributeForm.module.scss"
import { Select, FormSection, FormLabel, Checkbox, PortInput } from "../../../../components"
import { SelectItem } from "../../../../utils/SelectValue.util"
import BackendToggle from "./BackendToggle"
import { ClusterGroupType } from "../../../../api/Infra.api"
import { useServiceCerts } from "../../../../services"
import { matchesWildcard } from "../../../../utils"
import { Input } from "../../../../../v3/components/input/Input.component"
import { useFeatureFlags } from "../../../../../hooks/useFeatureFlags.hook"

type Props = ServiceFormProps & {
    hideBackend?: boolean
    hideDnsOverride?: boolean
    hideBackendDomain?: boolean
    showTls?: boolean
    defaultPort?: string
    allowLetsEncrypt?: boolean
    showAllowPatterns?: boolean
    dnsOverrideRequired?: boolean
}

export default function ServiceAttributeForm({
    edit = null,
    defaultPort,
    hideBackend = false,
    hideDnsOverride,
    hideBackendDomain,
    showTls = false,
    showAllowPatterns = true,
    dnsOverrideRequired,
    allowLetsEncrypt,
}: Props) {
    // grab the services we need
    const localization = useServiceLocalization()
    const infraService = useServiceInfra()
    const certsService = useServiceCerts()

    // pull the service type out of the spec
    const serviceType = useServiceType()
    const spec = edit?.spec

    // the selected access tiers are listed as the value of an object
    // inside of the host_tag_selector list
    let existingAccessTiers: string[] = []
    let existingAccessTierGroups: string[] = []
    if ((spec?.spec.attributes.host_tag_selector || []).length > 0) {
        spec?.spec.attributes.host_tag_selector.forEach((tag) => {
            const tiersString = tag["com.banyanops.hosttag.site_name"]
            const groupsString = tag["com.banyanops.hosttag.access_tier_group"]

            if (tiersString && tiersString.indexOf("|") !== -1) {
                const tiers = tiersString.split("|")
                existingAccessTiers = existingAccessTiers.concat(tiers)
            } else if (tiersString) {
                existingAccessTiers.push(tiersString)
            }
            if (groupsString && groupsString?.indexOf("|") !== -1) {
                const groups = groupsString.split("|")
                existingAccessTierGroups = existingAccessTierGroups.concat(groups)
            } else if (groupsString) {
                existingAccessTierGroups.push(groupsString)
            }
        })
    }

    const accessTiersDivider = {
        displayName: localization.getString("accessTier"),
        value: "accessTier",
        divisor: true,
    }
    const groupDivider = {
        displayName: localization.getString("accessTierGroups"),
        value: "accessTierGroup",
        divisor: true,
    }

    // some state to track the form
    const [domain, setDomain] = React.useState<string>(spec?.metadata.tags.domain || "")
    const [port, setPort] = React.useState(spec?.metadata.tags.port || defaultPort)
    const [accessTiers, setAccessTiers] = React.useState<string[]>(existingAccessTiers || [])
    const [accessTierGroups, setAccessTierGroups] = React.useState<string[]>(
        existingAccessTierGroups || []
    )
    const [selectedSites, setSelectedSites] = React.useState<string[]>([])
    const [accessTierOptions, setAccessTierOptions] = React.useState<SelectItem[]>([])
    const [accessTierGroupsOptions, setAccessTierGroupsOptions] = React.useState<SelectItem[]>([])
    const [accessTierDisplayOptions, setAccessTierDisplayOptions] = React.useState<SelectItem[]>([])
    const [emptyAccessTier, setEmptyAccessTier] = React.useState<boolean>(false)

    const [connector, setConnector] = React.useState(spec?.spec.backend?.connector_name || "")
    const [connectorOptions, setConnectorOptions] = React.useState<Connector[]>([])
    const [updatedConnector, setUpdatedConnector] = React.useState(false)

    const [updatedDomain, setUpdatedDomain] = React.useState(false)
    const [updatedAccessTier, setUpdatedAccessTier] = React.useState(false)

    const [dnsOverride, setDnsOverride] = React.useState(
        spec?.spec.backend.dns_overrides ? Object.values(spec.spec.backend.dns_overrides)[0] : ""
    )

    const [letsEncrypt, setLetsEncrypt] = React.useState(
        spec?.spec.cert_settings.letsencrypt || false
    )
    // if letsencrypt is enabled we need to validate the domain name against the
    // list of registered domains
    const [registeredDomains, setRegisteredDomains] = React.useState<string[] | null>(null)

    // when either value changes, tell the parent
    const updateTags = useUpdateTags()
    const updateAttributes = useUpdateAttributes()
    const updateOverride = useSetDNSOverride()
    const setErrors = useSetErrors()
    const setShowError = useSetShowError()
    const cluster = useCluster()

    const { data: featureFlags } = useFeatureFlags()
    const isDomainRegistered = React.useMemo(() => {
        // if we haven't loaded the list of registered domains yet, we can't let the user pass
        if (!registeredDomains) {
            return false
        }

        // a domain is considered registered if it matches ANY of the known patterns
        return Boolean(
            registeredDomains.find((registeredDomain) => matchesWildcard(registeredDomain, domain))
        )
    }, [domain, registeredDomains])

    React.useEffect(() => {
        setSelectedSites([
            ...accessTiers.map((a) => `accessTier:${a}`),
            ...accessTierGroups.map((g) => `group:${g}`),
        ])
    }, [])

    React.useEffect(() => {
        setAccessTierDisplayOptions(() => {
            if (accessTierGroups.length && featureFlags?.adminConsole.enableAccessTierGroups)
                return [{ value: `group:${accessTierGroups[0]}`, displayName: accessTierGroups[0] }]
            if (accessTiers.length) return [accessTiersDivider, ...accessTierOptions]
            return [
                accessTiersDivider,
                ...accessTierOptions,
                ...(featureFlags?.adminConsole.enableAccessTierGroups ? [groupDivider] : []),
                ...accessTierGroupsOptions,
            ]
        })
    }, [accessTiers, accessTierGroups, accessTierOptions, accessTierGroupsOptions])

    React.useEffect(() => {
        updateTags((tags) => ({
            ...tags,
            domain,
            port,
        }))

        updateAttributes((attr) => {
            const newAttributes = {
                ...attr,
                attributes: {
                    ...attr.attributes,
                    host_tag_selector: [
                        {
                            "com.banyanops.hosttag.site_name":
                                cluster?.group === ClusterGroupType.EDGE
                                    ? // if the user chose a managed cluster, set the access tier to *
                                      "*"
                                    : // otherwise join all of the access tiers with |
                                      accessTiers.join("|"),
                            "com.banyanops.hosttag.access_tier_group": accessTierGroups.join("|"),
                        },
                    ],
                },
            }

            // if we're allowed to track the lets encrypt state, assign it
            if (allowLetsEncrypt) {
                newAttributes.cert_settings = {
                    ...newAttributes.cert_settings,
                    letsencrypt: letsEncrypt,
                }
            }

            return newAttributes
        })

        updateOverride(dnsOverride)
        setErrors((old) => ({
            ...old,
            NO_DOMAIN_PORT: !domain && localization.getString("aDomainNameAndPortAreRequired"),
            NO_ACCESS_TIERS:
                !!cluster &&
                cluster.group !== ClusterGroupType.EDGE &&
                accessTiers.length < 1 &&
                accessTierGroups.length < 1 &&
                localization.getString("aSiteAccessTierIsRequired"),
            LETS_ENCRYPT_WILDCARD:
                letsEncrypt &&
                domain.includes("*") &&
                localization.getString("aServiceWithLetsEncryptEnabledCannotHaveWildcards"),
            UNREGISTERED_DOMAIN:
                registeredDomains &&
                letsEncrypt &&
                !isDomainRegistered &&
                localization.getString("domainMustBeRegisteredWithYourOrganization"),
            NO_CONNECTOR:
                cluster?.group === ClusterGroupType.EDGE &&
                !connector &&
                localization.getString("aConnectorIsRequired"),
        }))

        setShowError((prev) => ({
            ...prev,
            NO_DOMAIN_PORT: updatedDomain,
            NO_ACCESS_TIERS:
                !!cluster && cluster.group !== ClusterGroupType.EDGE && updatedAccessTier,
            NO_CONNECTOR: cluster?.group === ClusterGroupType.EDGE && updatedConnector,
            LETS_ENCRYPT_WILDCARD: letsEncrypt && updatedDomain,
            UNREGISTERED_DOMAIN: letsEncrypt && updatedDomain,
        }))
    }, [
        port,
        domain,
        updatedDomain,
        updatedAccessTier,
        dnsOverride,
        letsEncrypt,
        registeredDomains,
        accessTiers,
        accessTierGroups,
        localization,
        serviceType,
        updateAttributes,
        setErrors,
        setShowError,
        updateOverride,
        updateTags,
        cluster,
        connector,
        updatedConnector,
        allowLetsEncrypt,
        isDomainRegistered,
    ])

    React.useEffect(() => {
        if (!cluster) {
            setEmptyAccessTier(true)
            return
        }

        certsService
            .getRegisteredDomains(cluster.name)
            .then((domains) => setRegisteredDomains(domains.map(({ name }) => name)))

        if (cluster.group === ClusterGroupType.EDGE) {
            infraService.getConnectors().then(({ data: connectors }) => {
                setConnectorOptions(connectors)
                if (connectors.length === 1) {
                    setConnector(connectors[0].name)
                }
            })
            return
        }

        Promise.all([
            infraService.getAccessTierNames(cluster.name),
            infraService.getAccessTierGroupNames(),
        ]).then(([tiers, groups]) => {
            if (tiers.length === 0 && groups.length === 0) {
                setEmptyAccessTier(true)
            } else {
                const tiersOptions = tiers.map((a) => ({
                    displayName: a,
                    value: `accessTier:${a}`,
                }))
                const groupsOptions = groups.map((a) => ({
                    displayName: a,
                    value: `group:${a}`,
                }))

                setAccessTierOptions(tiersOptions)
                setAccessTierGroupsOptions(groupsOptions)

                if (!groups.length && tiers.length === 1) {
                    setAccessTiers([tiers[0]])
                }
                if (!tiers.length && groups.length) {
                    setAccessTierGroups([groups[0]])
                }
            }
        })
    }, [cluster, infraService, localization, certsService])

    const onWhichSelectOptionsChange = (values: string[] | string): void => {
        if (typeof values === "string") return
        const tiers: string[] = []
        const groups: string[] = []
        const selects: string[] = []
        values.forEach((v) => {
            const [type, name] = v.split(":")
            if (type === "accessTier") {
                tiers.push(name)
            } else {
                groups.push(name)
            }
            selects.push(v)
        })
        setAccessTiers(tiers)
        setAccessTierGroups(groups)
        setSelectedSites(selects)

        setUpdatedAccessTier(true)
    }

    return (
        <FormSection title={localization.getString("serviceAttributes")}>
            {(!cluster || (cluster && cluster.group !== ClusterGroupType.EDGE)) && (
                <FormLabel
                    title={localization.getString(
                        featureFlags?.adminConsole.enableAccessTierGroups
                            ? "selectAccessTiersOrAnAccessTierGroup"
                            : "selectAnAccessTier"
                    )}
                    htmlFor="accessTier"
                >
                    {!emptyAccessTier ? (
                        <input
                            id="accessTier"
                            className={styles.formInput}
                            type="text"
                            disabled
                            value={localization.getString(
                                "noAccessTierFoundSelectADifferentCluster"
                            )}
                        />
                    ) : (
                        <Select
                            multiple
                            placeholder={localization.getString(
                                featureFlags?.adminConsole.enableAccessTierGroups
                                    ? "accessTiersOrAccessTierGroup"
                                    : "accessTier"
                            )}
                            options={accessTierDisplayOptions}
                            value={selectedSites}
                            onChange={onWhichSelectOptionsChange}
                        />
                    )}
                </FormLabel>
            )}

            {cluster?.group === ClusterGroupType.EDGE && (
                <FormLabel title={localization.getString("whichConnector")} htmlFor="connector">
                    <div className={styles.selectContainer}>
                        <Select
                            id="connector"
                            name="connector"
                            placeholder={localization.getString("connector")}
                            options={connectorOptions.map((connector) => {
                                return { displayName: connector.displayName, value: connector.name }
                            })}
                            value={connector}
                            onChange={(val) => {
                                setConnector(val as string)
                                setUpdatedConnector(true)
                            }}
                        />
                    </div>
                </FormLabel>
            )}

            <FormLabel
                title={localization.getString("serviceDomainName")}
                htmlFor="serviceDomainName"
                tooltip={
                    serviceType === ServiceType.WEB_USER
                        ? localization.getString("sniDescription")
                        : localization.getString("serviceDomainNameDescription")
                }
            >
                <Input
                    id="serviceDomainName"
                    className={styles.domainInput}
                    required
                    defaultValue={domain}
                    onChange={(e) => {
                        setDomain(e.target.value)
                        setUpdatedDomain(true)
                    }}
                    placeholder={localization.getString("domainNamePlaceholder")}
                    aria-label={localization.getString("domainName")}
                />
                <PortInput
                    required
                    className={styles.portInput}
                    disabled={serviceType === ServiceType.WEB_USER}
                    onChange={(value) => {
                        setPort(value)
                    }}
                    value={port}
                    id="port"
                />
                {allowLetsEncrypt && (
                    <>
                        <div>
                            <Checkbox
                                id="letsEncrypt"
                                className={styles.checkbox}
                                checked={letsEncrypt}
                                onChange={(e) => setLetsEncrypt(e.target.checked)}
                            />
                            <label htmlFor="letsEncrypt" className={styles.checkboxLabel}>
                                {localization.getString("letsEncrypt")}
                            </label>
                        </div>
                        {letsEncrypt && registeredDomains && registeredDomains.length > 0 && (
                            <>
                                <FormLabel
                                    title={localization.getString(
                                        "theServiceDomainMustMatchOneOfTheFollowing"
                                    )}
                                    className={styles.domainListHeader}
                                    htmlFor="theServiceDomainMustMatchOneOfTheFollowing"
                                />
                                <ul className={styles.domainList}>
                                    {registeredDomains.map((domain) => (
                                        <li className={styles.registeredDomain} key={domain}>
                                            {domain}
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </>
                )}
            </FormLabel>

            <div className={styles.selectContainer}>
                <BackendToggle
                    edit={edit}
                    showTls={showTls}
                    showAllowPatterns={showAllowPatterns}
                    hideBackendDomain={hideBackendDomain}
                    connector={connector}
                    hideToggle={hideBackend}
                />
            </div>

            {!hideDnsOverride && (
                <FormLabel
                    title={
                        dnsOverrideRequired
                            ? localization.getString("backendDNSOverrideForServiceDomainName")
                            : localization.getString(
                                  "backendDNSOverrideForServiceDomainNameOptional"
                              )
                    }
                    htmlFor="backendDNSOverrideForServiceDomainName"
                >
                    <Input
                        id="backendDNSOverrideForServiceDomainName"
                        className={styles.formInput}
                        placeholder={localization.getString("domainNamePlaceholder")}
                        value={dnsOverride}
                        onChange={(e) => setDnsOverride(e.target.value)}
                    />
                </FormLabel>
            )}
        </FormSection>
    )
}

//TODO - check this
interface WhichSiteSelectValue {
    type: "accessTier" | "group"
    name: string
}

interface WhichSiteSelectDivisor {
    displayName: string
    value: string
    divisor: true
}

interface WhichSiteSelect extends Omit<SelectItem, "value"> {
    value: WhichSiteSelectValue
}

type WhichSelectOptions = (WhichSiteSelect | WhichSiteSelectDivisor)[]
