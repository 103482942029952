// external imports
import * as React from "react"
// local imports
import {
    ServiceFormProps,
    useCluster,
    useDNSOverride,
    useMetadata,
    useSetErrors,
    useSetShowError,
    useUpdateAttributes,
} from "../../service-form"
import styles from "./ServiceAttributeForm.module.scss"
import { useServiceInventory, useServiceLocalization, useServiceManage } from "../../../../services"
import { ToggleButton, InfoTip, MultiInput, FormLabel, Checkbox } from "../../../../components"
import { BackendType } from "../../info/backend/ServicesAddBackend.component"
import { ClusterGroupType } from "../../../../api/Infra.api"
import { Input } from "../../../../../components/input/Input.component"
import { PortInput } from "../../../../../components/port-input/PortInput.component"
import { useGetVariables } from "../../../../../v3/services/Infrastructure.service"

export type BackendToggleProps = ServiceFormProps & {
    showTls: boolean
    showAllowPatterns: boolean
    hideToggle?: boolean | undefined
    hideBackendDomain?: boolean | undefined
    connector: string
}

export default function BackendToggle({
    edit,
    showTls,
    showAllowPatterns,
    hideToggle,
    hideBackendDomain,
    connector,
}: BackendToggleProps) {
    // grab the services we need
    const localization = useServiceLocalization()
    const inventoryService = useServiceInventory()
    const manageService = useServiceManage()
    // and the form info
    const dnsOverride = useDNSOverride()
    const metadata = useMetadata()

    const { data: variables } = useGetVariables()

    // look for an initial backend
    const backendAttr = React.useMemo(
        () =>
            edit?.spec?.spec.backend || {
                target: {
                    name: "",
                    port: "",
                    tls: false,
                    tls_insecure: false,
                    client_certificate: false,
                },
                dns_overrides: {},
                whitelist: [],
                http_connect: false,
                allow_patterns: [],
            },
        [edit]
    )

    const [backendType, setBackendType] = React.useState<BackendType>(
        backendAttr.http_connect ? BackendType.HTTP_CONNECT : BackendType.FIXED
    )

    const [allowedHostnames, setAllowedHostnames] = React.useState<string[]>(
        backendAttr.allow_patterns?.[0]?.hostnames || []
    )
    const [cidrs, setCidrs] = React.useState<string[]>(backendAttr.allow_patterns?.[0]?.cidrs || [])

    // some more state for the form
    const [targetDomain, setTargetDomain] = React.useState(backendAttr.target.name)
    const [targetPort, setTargetPort] = React.useState(backendAttr.target.port)
    const [targetTls, setTargetTls] = React.useState(backendAttr.target.tls)
    const [targetTlsInsecure, setTargetTlsInsecure] = React.useState(
        backendAttr.target.tls_insecure
    )
    const [targetClientCert, setTargetClientCert] = React.useState(
        backendAttr.target.client_certificate
    )

    const [updatedName, setUpdatedName] = React.useState(false)
    const [updatedPort, setUpdatedPort] = React.useState(false)

    //state for checking is service has a linked cloud resource
    const [linkedResource, setLinkedResource] = React.useState(false)

    // update the backend attr object when something changes
    const updateAttributes = useUpdateAttributes()
    const setErrors = useSetErrors()
    const showError = useSetShowError()
    const cluster = useCluster()

    React.useEffect(() => {
        //get cloudresource linked to a service
        if (edit) {
            inventoryService
                .getResourceAndServiceDetails({
                    service_id: edit.id,
                })
                .then((cloudResource) => {
                    if (cloudResource.length > 0) {
                        setLinkedResource(true)
                    }
                })
        }
    }, [])

    React.useEffect(() => {
        // update the backend attr
        const newBackend = {
            ...backendAttr,
            http_connect: backendType === BackendType.HTTP_CONNECT,
        }

        // only assign the target key if the backend is fixed
        if (backendType === BackendType.FIXED) {
            newBackend.target = {
                name: targetDomain,
                port: targetPort,
                tls: targetTls,
                tls_insecure: targetTlsInsecure,
                client_certificate: targetClientCert,
            }
            newBackend.allow_patterns = []
        }

        // if we have a connector to assign
        if (cluster?.group === ClusterGroupType.EDGE && connector) {
            newBackend.connector_name = connector
        } else {
            // not sure if api would accept null here, just set to undefined explicitly
            // so that the spread over the previous value will clear it
            newBackend.connector_name = undefined
        }

        // only assign the patterns if we let the user decide
        if (backendType === BackendType.HTTP_CONNECT) {
            //remove backend name and port from spec if backend is http connect
            newBackend.target.name = ""
            newBackend.target.port = ""

            // make sure the list of cidrs is unique
            const uniqueCidrs = [...new Set(cidrs)]
            // make sure the list of hostnames is unique
            const uniqueAllowedHostnames = [...new Set(allowedHostnames)]

            let allow_patterns = backendAttr.allow_patterns || []
            if (allow_patterns.length > 0) {
                allow_patterns = [
                    { ...allow_patterns[0], cidrs: uniqueCidrs, hostnames: uniqueAllowedHostnames },
                ]
            } else {
                allow_patterns = [{ cidrs: uniqueCidrs, hostnames: uniqueAllowedHostnames }]
            }

            // assign the list
            newBackend.allow_patterns = allow_patterns
        }

        // if there is a domain override set
        // if there is no override set, make sure we clear any existing values
        newBackend.dns_overrides =
            dnsOverride && metadata.tags.domain ? { [metadata.tags.domain]: dnsOverride } : {}

        // update the parent
        updateAttributes((attr) => ({
            ...attr,
            backend: newBackend,
        }))

        setErrors((errs) => ({
            ...errs,
            BACKEND_DOMAIN:
                !hideBackendDomain &&
                backendType === BackendType.FIXED &&
                !targetDomain &&
                localization.getString("aBackendDomainNameIsRequired"),
            BACKEND_PORT:
                !hideBackendDomain &&
                backendType === BackendType.FIXED &&
                !targetPort &&
                localization.getString("aBackendPortIsRequired"),
            CIDRS:
                cidrs.length > 0 &&
                cidrs.filter((cidr) => cidr.match(manageService.CIDR_REGEX)).length !==
                    cidrs.length &&
                localization.getString("invalidCidr"),
        }))

        showError((prev) => ({
            ...prev,
            BACKEND_DOMAIN: updatedName,
            BACKEND_PORT: updatedPort,
            CIDRS: cidrs.length > 0,
        }))
    }, [
        backendType,
        targetDomain,
        targetPort,
        targetTls,
        targetTlsInsecure,
        targetClientCert,
        dnsOverride,
        metadata.tags.domain,
        updatedName,
        updatedPort,
        allowedHostnames,
        backendAttr,
        cidrs,
        localization,
        setErrors,
        showError,
        updateAttributes,
        connector,
        hideBackendDomain,
        cluster,
        edit,
        manageService,
    ])

    return (
        <>
            {/* hide backend toggle if service has linked resource and hidetoggle prop is enabled */}
            {!hideToggle && !linkedResource && (
                <FormLabel
                    title={localization.getString(
                        "howShouldIncomingConnectionsBeProxiedToTheBackend"
                    )}
                    htmlFor="howShouldIncomingConnectionsBeProxiedToTheBackend"
                >
                    <ToggleButton
                        className={styles.toggleButton}
                        items={[
                            {
                                label: localization.getString("fixedBackendDomain"),
                                value: BackendType.FIXED,
                                onClick: () => {
                                    setBackendType(BackendType.FIXED)
                                },
                                selected: backendType === BackendType.FIXED,
                            },
                            {
                                label: localization.getString("cliendSpecifiedUsingHttpConnect"),
                                value: BackendType.HTTP_CONNECT,
                                onClick: () => {
                                    setBackendType(BackendType.HTTP_CONNECT)
                                },
                                selected: backendType === BackendType.HTTP_CONNECT,
                            },
                        ]}
                    />
                </FormLabel>
            )}

            {backendType === BackendType.FIXED && !hideBackendDomain && (
                <FormLabel
                    title={localization.getString("backendDomain")}
                    tooltip={localization.getString("backendDomainExplanation")}
                    htmlFor="backendDomain"
                >
                    <Input
                        id="backendDomain"
                        className={styles.domainInput}
                        required
                        placeholder={localization.getString("domainNameInternalPlaceholder")}
                        aria-label={localization.getString("backendDomainName")}
                        value={targetDomain}
                        onChangeValue={(v) => {
                            setTargetDomain(v)
                            setUpdatedName(true)
                        }}
                        //disable the backend domain when editing a service if the service is linked to a cloud resource and backend domain is not empty
                        disabled={linkedResource && targetDomain !== ""}
                        variables={variables?.backendDomain}
                    />
                    <PortInput
                        className={styles.portInput}
                        required
                        value={targetPort}
                        onChange={(value) => {
                            setTargetPort(value)
                            setUpdatedPort(true)
                        }}
                        id="port"
                        aria-label={localization.getString("backendDomainPort")}
                    />
                    <br />
                    {showTls && (
                        <>
                            <Checkbox
                                id="tls"
                                className={styles.checkbox}
                                checked={targetTls}
                                onChange={(e) => {
                                    setTargetTls(e.target.checked)
                                    // if we are disabled tls, disable the related field
                                    if (!e.target.checked) {
                                        setTargetTlsInsecure(false)
                                        setTargetClientCert(false)
                                    }
                                }}
                            />
                            <label htmlFor="tls" className={styles.checkboxLabel}>
                                {localization.getString("tls")}
                                <InfoTip message={localization.getString("tlsDescription")} />
                            </label>
                            {targetTls && (
                                <>
                                    <Checkbox
                                        id="tls-insecure"
                                        className={styles.checkbox}
                                        checked={targetTlsInsecure}
                                        onChange={(e) => setTargetTlsInsecure(e.target.checked)}
                                    />
                                    <label htmlFor="tls-insecure" className={styles.checkboxLabel}>
                                        {localization.getString("tlsInsecure")}
                                        <InfoTip
                                            message={localization.getString(
                                                "tlsInsecureDescription"
                                            )}
                                        />
                                    </label>
                                    <Checkbox
                                        id="client-certificate"
                                        className={styles.checkbox}
                                        checked={targetClientCert}
                                        onChange={(e) => setTargetClientCert(e.target.checked)}
                                    />
                                    <label
                                        htmlFor="client-certificate"
                                        className={styles.checkboxLabel}
                                    >
                                        {localization.getString("clientCertificate")}
                                        <InfoTip
                                            message={localization.getString(
                                                "clientCertificateDescription"
                                            )}
                                        />
                                    </label>
                                </>
                            )}
                        </>
                    )}
                </FormLabel>
            )}
            {showAllowPatterns && backendType === BackendType.HTTP_CONNECT && (
                <>
                    <FormLabel
                        title={localization.getString("backendAllowedHostnamesAndCIDRRanges")}
                        tooltip={localization.getString("backendHostnameCIDRExplanation")}
                        className={styles.toggleContent}
                        htmlFor="backendAllowedHostnamesAndCIDRRanges"
                    >
                        <FormLabel
                            title={localization.getString("hostnames") + ":"}
                            htmlFor="hostnames"
                            className={styles.verticalSpace}
                        >
                            <MultiInput
                                className={styles.inputContainer}
                                initialValues={allowedHostnames}
                                placeholder={localization.getString("allowedBackendHostname")}
                                onChange={setAllowedHostnames}
                            />
                        </FormLabel>
                        <FormLabel
                            title={localization.getString("cidrRanges") + ":"}
                            htmlFor="cidrRanges"
                        >
                            <MultiInput
                                className={styles.inputContainer}
                                initialValues={cidrs}
                                placeholder={localization.getString("allowedBackendCidrRange")}
                                onChange={setCidrs}
                                pattern={manageService.CIDR_REGEX}
                            />
                        </FormLabel>
                    </FormLabel>
                </>
            )}
        </>
    )
}
