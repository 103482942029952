import { useQuery, UseQueryResult } from "@tanstack/react-query"

import { isProfileAdmin, profileResDict } from "../utils/profile.utils"
import { ClusterApi, GetClustersRes } from "../v3/api/Cluster.api"
import {
    OrgApi,
    OrgDetailsRes,
    LicenseInformationRes,
    UserOrgDetailsRes,
    LicenseUsageRes,
} from "../v3/api/Org.api"
import { Cluster, getEdgeClustersFromRes } from "../v3/services/shared/Cluster"
import { Organization } from "../v3/services/shared/Organization"
import { ApiFunction } from "../v3/services/shared/QueryKey"
import { AccountType } from "../v3/services/UserProfile.service"
import { OrgLicenseInformation } from "../v3/services/shared/LicenseInformation"
import { licenseTypeMap } from "../v3/services/Org.service"

export function useFeatureFlags(): UseQueryResult<FeatureFlags, unknown> {
    const clusterApi = new ClusterApi()
    const orgApi = new OrgApi()

    return useQuery({
        queryKey: [ApiFunction.GET_FEATURE_FLAGS],
        queryFn: async () => {
            const [clustersRes, orgDetailsRes, userOrgDetailsRes, licenseInformation] =
                await Promise.all([
                    clusterApi.getClusters(),
                    orgApi.getOrgDetails(),
                    orgApi.getUserOrgDetails(),
                    orgApi.getLicenseInformation(),
                ])
            const licenseCounts = licenseInformation ? await orgApi.getOrgLicensesUsage() : null

            return mapFeatureFlags(
                clustersRes,
                orgDetailsRes,
                userOrgDetailsRes,
                licenseInformation,
                licenseCounts
            )
        },
    })
}

export interface FeatureFlags {
    adminConsole: AdminConsoleFeatureFlags
    mspConsole: MspConsoleFeatureFlags
    momConsole: MomConsoleFeatureFlags
}

interface AdminConsoleFeatureFlags {
    // TODO: Merge these two properties into one: `enableAccessTierGroups` and `canAccessAccessTierGroups`
    enableAccessTierGroups: boolean
    canAccessAccessTiers: CanAccessAccessTiers
    canEditAccessTier: boolean
    canCreateAccessTier: boolean
    canDeleteAccessTier: boolean
    canEditConnector: boolean
    canDeleteConnector: boolean
    canCreateConnector: boolean
    canAccessConnectorInstallStep: boolean
    canAccessAccessTierInstallStep: boolean
    canAccessAccessTierGroups: CanAccessAccessTierGroups
    canAccessAdminDirectory: boolean
    canAccessAppDiscovery: boolean
    canAccessClusters: boolean
    canAccessDataLossPrevention: boolean
    canAccessItpSettings: boolean
    canUpdateItpSettings: boolean
    canAccessOnPremGateway: boolean
    canAccessServiceAccounts: boolean
    canAccessSettings: boolean
    canAddAdmin: boolean
    canUpdateAdmin: boolean
    canDeleteAdmin: boolean
    doesUseBanyanIdp: boolean
    canAddUser: boolean
    canRemoveUsers: boolean
    canChangeUser: boolean
    canCreateCustomApp: boolean
    canEditCustomApp: boolean
    canDeleteCustomApp: boolean
    canCreateTrustProfile: boolean
    canChangeTrustProfile: boolean
    canReorderTrustProfiles: boolean
    canAccessAdminSignOn: boolean
    canUpdatePassword: boolean
    emphasizeGlobalEdge: boolean
    globalEdge: GlobalEdge
    hasCommunityForum: boolean
    hasSupport: boolean
    isAiAssistedAdminSearchEnabled: boolean
    isEventChartEnabled: boolean
    isScimEnabled: boolean
    canAccessScimSetting: boolean
    shouldAddPrivateEdge: boolean
    doShowUpsellForConfigurationPasswordless: boolean
    doShowUpsellForConfigurationUnregisteredDevices: boolean
    canAccessPrivateAccessEventsOnly: boolean
    canAccessInternetAccessEventsOnly: boolean
    canAccessOnlyTunnelPolicy: boolean
    doShowUpsellForPrivateAccess: boolean
    canAccessApplicationInServiceTunnel: boolean
    canAccessConnectorOnlyInServiceTunnel: boolean
    doShowUpsellForInternetAccess: boolean
    doShowUpsellForDirectoryUnregisteredDevices: boolean
    doShowUpsellForIntegration: boolean
    doShowUpsellForAccessTiers: boolean
    doShowUpsellForItpSettings: boolean
    doShowUpsellForServiceAccounts: boolean
    doShowUpsellForDeviceManager: boolean
    doShowUpsellForServiceBundle: boolean
    doShowUpsellForInfrastructure: boolean
    doShowUpsellForHostedWebsites: boolean
    doShowUpsellForDiscovery: boolean
    doShowUpsellForIaasDiscovery: Boolean
    doShowUpsellForSaasApps: boolean
    doShowUpsellForAppDiscovery: boolean
    doShowUpsellForConnectors: boolean
    doShowUpsellForRegisteredDomain: boolean
    doShowUpgradeScreenItp: boolean
    showLicenseEnforcementBannerInHomeOverview: boolean
    showLicenseEnforcementBannerInPrivateAccess: boolean
    showLicenseEnforcementBannerInInternetAccess: boolean
    showLicenseEnforcementBannerInUserDirectory: boolean
    showLicenseEnforcementBannerInDeviceDirectory: boolean
    showSpaUserLicense: boolean
    showSiaUserLicense: boolean
    showUserLicenseUsageInfo: boolean
    showUserLicenseModal: CanAccessUserLicenseModal
    canDeleteWebService: boolean
    canDisableWebService: boolean
    canCloneWebService: boolean
    canAttachPolicy: boolean
    canEditWebService: boolean
    canAddHostedWebService: boolean
    canEditServiceTunnel: boolean
    canAddServiceTunnel: boolean
    canDeleteServiceTunnel: boolean
    doShowServicesInConnector: boolean
    canAccessApiKey: boolean
    canEditPolicy: boolean
    canDeletePolicy: boolean
    canCreateItpPolicy: boolean
    canReorderItpPolicy: boolean
    canUpdateTrustedNetwork: boolean
    canConfigureAdminSignOnSettings: boolean
    canConfigureEndUserSettings: boolean
    canAddRegisteredDomain: boolean
    canDeleteRegisteredDomain: boolean
    canUpdateNrptSetting: boolean
    canUpdateCustomization: boolean
    canUpdateMdmSetting: boolean
    canUpdateInviteCode: boolean
    canUpdateTrustScopeExpiry: boolean
    canUpdateTrustProviderDeviceManagerSetting: boolean
    canCreatePasswordLessAuth: boolean
    canUpdateUnregisteredDeviceConfig: boolean
    canUpdateDeviceTrustVerfication: boolean
    canUpdateTokenExpiry: boolean
    canUpdateUserExpiry: boolean
    canAccessTrustProviderCerts: boolean
    canUpdateGeoLocationSetting: boolean
    canAccessServiceBundle: boolean
    canAccessFileCheckFactor: boolean
    canAccessPListCheckFactor: boolean
    canAccessRegistryCheckFactor: boolean
    canDeleteIssuedCertificate: boolean
    canGenerateReport: boolean
    canUpdateCustomApp: boolean
    canUpdateAdminPassword: boolean
    canRefreshToken: boolean
    doShowUpsellForServiceTunnelConfig: boolean
    userList: {
        doShowLastLoginColumn: boolean
        doShowStatusColumn: boolean
        doShowInvitedAtColumn: boolean
        doShowSpaLicenseColumn: boolean
        doShowSpaLicenseFilter: boolean
        doShowSiaLicenseColumn: boolean
        doShowSiaLicenseFilter: boolean
    }
    doShowUpsellForClusters: boolean
    canGenerateScimApiToken: boolean
    canCreatePolicies: boolean
}

type SharedAdminConsoleFeatureFlagsKey =
    | "enableAccessTierGroups"
    | "canAccessAccessTiers"
    | "canEditAccessTier"
    | "canCreateAccessTier"
    | "canDeleteAccessTier"
    | "canEditConnector"
    | "canDeleteConnector"
    | "canAccessAccessTierGroups"
    | "canAccessAdminDirectory"
    | "canAccessAppDiscovery"
    | "canAccessDataLossPrevention"
    | "canAccessItpSettings"
    | "canUpdateItpSettings"
    | "canAccessOnPremGateway"
    | "canAccessServiceAccounts"
    | "canAccessSettings"
    | "canAddAdmin"
    | "canUpdateAdmin"
    | "canDeleteAdmin"
    | "doesUseBanyanIdp"
    | "canAddUser"
    | "canRemoveUsers"
    | "canChangeUser"
    | "canCreateTrustProfile"
    | "canChangeTrustProfile"
    | "canReorderTrustProfiles"
    | "canAccessAdminSignOn"
    | "canUpdatePassword"
    | "emphasizeGlobalEdge"
    | "globalEdge"
    | "hasCommunityForum"
    | "hasSupport"
    | "isAiAssistedAdminSearchEnabled"
    | "isEventChartEnabled"
    | "isScimEnabled"
    | "shouldAddPrivateEdge"
    | "canAccessApiKey"
    | "canAccessConnectorInstallStep"
    | "canCreateConnector"
    | "canAccessAccessTierInstallStep"
    | "canDeleteWebService"
    | "canDisableWebService"
    | "canCloneWebService"
    | "canAttachPolicy"
    | "canEditWebService"
    | "canAddHostedWebService"
    | "canEditServiceTunnel"
    | "canAddServiceTunnel"
    | "canDeleteServiceTunnel"
    | "canEditPolicy"
    | "canDeletePolicy"
    | "canCreateItpPolicy"
    | "canReorderItpPolicy"
    | "canUpdateTrustedNetwork"
    | "canConfigureAdminSignOnSettings"
    | "canConfigureEndUserSettings"
    | "canAddRegisteredDomain"
    | "canDeleteRegisteredDomain"
    | "canUpdateNrptSetting"
    | "canUpdateCustomization"
    | "canUpdateMdmSetting"
    | "canUpdateInviteCode"
    | "canUpdateTrustScopeExpiry"
    | "canUpdateTrustProviderDeviceManagerSetting"
    | "canCreatePasswordLessAuth"
    | "canUpdateUnregisteredDeviceConfig"
    | "canUpdateDeviceTrustVerfication"
    | "canUpdateTokenExpiry"
    | "canUpdateUserExpiry"
    | "canAccessTrustProviderCerts"
    | "canUpdateGeoLocationSetting"
    | "canAccessServiceBundle"
    | "canAccessFileCheckFactor"
    | "canAccessPListCheckFactor"
    | "canAccessRegistryCheckFactor"
    | "canDeleteIssuedCertificate"
    | "canGenerateReport"
    | "canUpdateCustomApp"
    | "canUpdateAdminPassword"
    | "canRefreshToken"
    | "canAccessScimSetting"
    | "canGenerateScimApiToken"
    | "canCreatePolicies"

type SharedAdminConsoleFeatureFlags = Pick<
    AdminConsoleFeatureFlags,
    SharedAdminConsoleFeatureFlagsKey
>

interface MspConsoleFeatureFlags {
    canAddAdmin: boolean
    canAssignAdmin: boolean
    canUpdateAdmin: boolean
    canDeleteAdmin: boolean
    canAddOrganization: boolean
    canAccessAdminSignOn: boolean
    canUpdatePassword: boolean
    canConfigureEndUserSettings: boolean
    canConfigureAdminSignOnSettings: boolean
    canUpdateCustomization: boolean
}

interface MomConsoleFeatureFlags {
    canAddAdmin: boolean
    canUpdateAdmin: boolean
    canDeleteAdmin: boolean
    canDeleteOrg: boolean
    canConfigureAdminSignOnSettings: boolean
    canAccessAdminSignOn: boolean
}

type CanAccessAccessTiers =
    | { canAccess: false }
    | { canAccess: true; privateEdgeCluster: Cluster; organization: Organization }

type CanAccessUserLicenseModal =
    | { canAccess: false }
    | { canAccess: true; orgLicenseInfo: OrgLicenseInformation }

type CanAccessAccessTierGroups =
    | { canAccess: false }
    | { canAccess: true; privateEdgeCluster: Cluster }

type GlobalEdge =
    | { shouldAddGlobalEdge: true }
    | { shouldAddGlobalEdge: false; globalEdgeClusterName: string }

function mapFeatureFlags(
    getClustersRes: GetClustersRes,
    orgDetailsRes: OrgDetailsRes,
    userOrgDetailsRes: UserOrgDetailsRes,
    licenseInformation: LicenseInformationRes | null,
    licenseCounts: LicenseUsageRes | null
): FeatureFlags {
    const canWriteAll = isProfileAdmin(profileResDict[userOrgDetailsRes.Profile])
    const canWriteServices =
        isProfileAdmin(profileResDict[userOrgDetailsRes.Profile]) ||
        userOrgDetailsRes.Profile === "ServiceAuthor"
    const doesUseBanyanIdp = orgDetailsRes.IDPName === "BANYAN"
    const edgeClusters = getEdgeClustersFromRes(getClustersRes)
    const isMspOrg = orgDetailsRes.IsMSPOrg
    const canAddAdmin =
        !orgDetailsRes.IsMySonicWall &&
        (userOrgDetailsRes.AccountType === "LOCAL" || userOrgDetailsRes.Profile === "Owner") &&
        (!doesUseBanyanIdp || isMspOrg)

    const sharedAdminConsoleFeatureFlags: SharedAdminConsoleFeatureFlags = {
        // TODO: Merge these two properties into one: `enableAccessTierGroups` and `canAccessAccessTierGroups`
        enableAccessTierGroups:
            process.env.REACT_APP_CAN_ACCESS_ACCESS_TIERS_GROUPS?.toLowerCase() === "true",
        canAccessAccessTiers: edgeClusters.private
            ? {
                  canAccess: true,
                  privateEdgeCluster: edgeClusters.private,
                  organization: { id: orgDetailsRes.OrgID, name: orgDetailsRes.OrgName },
              }
            : { canAccess: false },
        canEditAccessTier: canWriteAll,
        canCreateAccessTier: canWriteAll,
        canDeleteAccessTier: canWriteAll,
        canAccessAccessTierGroups: edgeClusters.private
            ? { canAccess: true, privateEdgeCluster: edgeClusters.private }
            : { canAccess: false },
        canAccessAdminDirectory: canWriteAll,
        canAccessAppDiscovery: orgDetailsRes.IsAppDiscoveryEnabled,
        canAccessDataLossPrevention: orgDetailsRes.IsTestDrive,
        canAccessItpSettings:
            orgDetailsRes.IsDNSFilterEnabled && orgDetailsRes.IsURLFilteringEnabled,
        canUpdateItpSettings: canWriteAll,
        canAccessOnPremGateway: orgDetailsRes.IsTestDrive,
        canAccessServiceAccounts: canWriteAll,
        canAccessSettings: canWriteAll,
        canAddAdmin,
        canUpdateAdmin: !orgDetailsRes.IsMySonicWall,
        canDeleteAdmin: !orgDetailsRes.IsMySonicWall,
        doesUseBanyanIdp,
        canAddUser: canWriteAll && doesUseBanyanIdp,
        canRemoveUsers: canWriteAll,
        canChangeUser: canWriteAll && canAddAdmin && doesUseBanyanIdp,
        canCreateTrustProfile: canWriteAll,
        canChangeTrustProfile: canWriteAll,
        canReorderTrustProfiles: canWriteAll,
        canAccessAdminSignOn: !orgDetailsRes.IsMySonicWall,
        canUpdatePassword:
            !orgDetailsRes.IsMySonicWall && userOrgDetailsRes.AccountType === AccountType.LOCAL,
        emphasizeGlobalEdge: !!edgeClusters.global,
        globalEdge: edgeClusters.global
            ? { shouldAddGlobalEdge: false, globalEdgeClusterName: edgeClusters.global.name }
            : { shouldAddGlobalEdge: true },
        hasCommunityForum: !orgDetailsRes.IsMySonicWall,
        hasSupport: !orgDetailsRes.IsMySonicWall,
        isAiAssistedAdminSearchEnabled: userOrgDetailsRes.IsAIAssistEnabled,
        isEventChartEnabled: orgDetailsRes.IsEventChartEnabled,
        isScimEnabled: userOrgDetailsRes.IsSCIMEnabled,
        canAccessScimSetting: canWriteAll,
        shouldAddPrivateEdge: !edgeClusters.private,
        canAccessApiKey: canWriteAll,
        canEditConnector: canWriteAll,
        canDeleteConnector: canWriteAll,
        canAccessConnectorInstallStep: canWriteAll,
        canCreateConnector: canWriteAll,
        canAccessAccessTierInstallStep: canWriteAll,
        canDeleteWebService: canWriteAll,
        canDisableWebService: canWriteAll,
        canCloneWebService: canWriteAll,
        canAttachPolicy: canWriteAll,
        canEditWebService: canWriteServices,
        canAddHostedWebService: canWriteServices,
        canEditServiceTunnel: canWriteServices,
        canAddServiceTunnel: canWriteServices,
        canDeleteServiceTunnel: canWriteAll,
        canEditPolicy: canWriteAll,
        canDeletePolicy: canWriteAll,
        canCreateItpPolicy: canWriteAll,
        canReorderItpPolicy: canWriteAll,
        canUpdateTrustedNetwork: canWriteAll,
        canConfigureAdminSignOnSettings: canWriteAll,
        canConfigureEndUserSettings: canWriteAll,
        canAddRegisteredDomain: canWriteAll,
        canDeleteRegisteredDomain: canWriteAll,
        canUpdateNrptSetting: canWriteAll,
        canUpdateCustomization: canWriteAll,
        canUpdateMdmSetting: canWriteAll,
        canUpdateInviteCode: canWriteAll,
        canUpdateTrustScopeExpiry: canWriteAll,
        canUpdateTrustProviderDeviceManagerSetting: canWriteAll,
        canCreatePasswordLessAuth: canWriteAll,
        canUpdateUnregisteredDeviceConfig: canWriteAll,
        canUpdateDeviceTrustVerfication: canWriteAll,
        canUpdateTokenExpiry: canWriteAll,
        canUpdateUserExpiry: canWriteAll,
        canAccessTrustProviderCerts: canWriteAll,
        canUpdateGeoLocationSetting: canWriteAll,
        canAccessServiceBundle: canWriteAll,
        canAccessFileCheckFactor: canWriteAll,
        canAccessPListCheckFactor: canWriteAll,
        canAccessRegistryCheckFactor: canWriteAll,
        canDeleteIssuedCertificate: canWriteAll,
        canGenerateReport: canWriteAll,
        canUpdateCustomApp: canWriteAll,
        canRefreshToken: canWriteAll,
        canUpdateAdminPassword: canWriteAll,
        canGenerateScimApiToken: canWriteAll,
        canCreatePolicies: canWriteAll || userOrgDetailsRes.Profile === "PolicyAuthor",
    }

    const mspConsoleFeatureFlags: MspConsoleFeatureFlags = {
        canAddAdmin: !orgDetailsRes.IsMySonicWall,
        canAssignAdmin: !orgDetailsRes.IsMySonicWall,
        canUpdateAdmin: !orgDetailsRes.IsMySonicWall,
        canDeleteAdmin: !orgDetailsRes.IsMySonicWall,
        canAddOrganization: !orgDetailsRes.IsMySonicWall,
        canAccessAdminSignOn: !orgDetailsRes.IsMySonicWall,
        canUpdatePassword: !orgDetailsRes.IsMySonicWall,
        canConfigureEndUserSettings: canWriteAll,
        canConfigureAdminSignOnSettings: canWriteAll,
        canUpdateCustomization: canWriteAll,
    }

    const momConsoleFeatureFlags: MomConsoleFeatureFlags = {
        canAddAdmin: true,
        canUpdateAdmin: true,
        canDeleteAdmin: true,
        canDeleteOrg: userOrgDetailsRes.Profile === "SuperAdmin",
        canConfigureAdminSignOnSettings: canWriteAll,
        canAccessAdminSignOn: !orgDetailsRes.IsMySonicWall,
    }

    if (!licenseInformation || !licenseCounts) {
        const isTeamEdition = orgDetailsRes.Edition === "Team"
        const isUnlimitedEdition = orgDetailsRes.Edition === "Unlimited"
        const isDnsFilterEnabled = orgDetailsRes.IsDNSFilterEnabled

        return {
            adminConsole: {
                ...sharedAdminConsoleFeatureFlags,
                canAccessClusters: !isTeamEdition,
                canCreateCustomApp: isUnlimitedEdition && canWriteAll,
                canEditCustomApp: isUnlimitedEdition,
                canDeleteCustomApp: isUnlimitedEdition,
                doShowUpsellForConfigurationPasswordless: false,
                doShowUpsellForConfigurationUnregisteredDevices: false,
                canAccessPrivateAccessEventsOnly: false,
                canAccessInternetAccessEventsOnly: false,
                canAccessOnlyTunnelPolicy: false,
                doShowUpsellForPrivateAccess: false,
                canAccessApplicationInServiceTunnel: true,
                canAccessConnectorOnlyInServiceTunnel: false,
                doShowUpsellForInternetAccess: false,
                doShowUpsellForDirectoryUnregisteredDevices: false,
                doShowUpsellForIntegration: false,
                doShowUpsellForAccessTiers: false,
                doShowUpsellForServiceAccounts: false,
                doShowUpsellForDeviceManager: false,
                doShowUpsellForServiceBundle: false,
                doShowUpsellForInfrastructure: false,
                doShowUpsellForHostedWebsites: false,
                doShowUpsellForDiscovery: false,
                doShowUpsellForIaasDiscovery: false,
                doShowUpsellForSaasApps: false,
                doShowUpsellForAppDiscovery: false,
                doShowUpsellForItpSettings: false,
                doShowUpsellForConnectors: false,
                doShowUpsellForRegisteredDomain: false,
                doShowUpgradeScreenItp: !(isUnlimitedEdition && isDnsFilterEnabled),
                showLicenseEnforcementBannerInHomeOverview: false,
                showLicenseEnforcementBannerInPrivateAccess: false,
                showLicenseEnforcementBannerInInternetAccess: false,
                showLicenseEnforcementBannerInUserDirectory: false,
                showLicenseEnforcementBannerInDeviceDirectory: false,
                showSpaUserLicense: false,
                showSiaUserLicense: false,
                showUserLicenseUsageInfo: false,
                showUserLicenseModal: { canAccess: false },
                doShowServicesInConnector: !isTeamEdition,
                doShowUpsellForServiceTunnelConfig: false,
                userList: {
                    doShowLastLoginColumn: true,
                    doShowStatusColumn: doesUseBanyanIdp,
                    doShowInvitedAtColumn: doesUseBanyanIdp,
                    doShowSpaLicenseColumn: false,
                    doShowSpaLicenseFilter: false,
                    doShowSiaLicenseColumn: false,
                    doShowSiaLicenseFilter: false,
                },
                doShowUpsellForClusters: false,
            },
            mspConsole: mspConsoleFeatureFlags,
            momConsole: momConsoleFeatureFlags,
        }
    }

    const hasAdvancedSiaLicense = licenseInformation.sia === "Advanced"
    const hasAdvancedSpaLicense = licenseInformation.spa === "Advanced"
    const hasSpaLicense = licenseInformation.spa !== "None"
    const hasSiaLicense = licenseInformation.sia !== "None"
    const hasBasicSiaLicense = licenseInformation.spa === "Basic"
    const hasBasicSpaLicense = licenseInformation.spa === "Basic"

    const isSiaLicenseReached =
        hasSiaLicense && licenseInformation.sia_users <= licenseCounts.sia_license_count
    const isSpaLicenseReached =
        hasSpaLicense && licenseInformation.spa_users <= licenseCounts.spa_license_count

    return {
        adminConsole: {
            ...sharedAdminConsoleFeatureFlags,
            canAccessClusters: true,
            canCreateCustomApp: true,
            canEditCustomApp: true,
            canDeleteCustomApp: true,
            doShowUpsellForConfigurationPasswordless:
                !hasAdvancedSiaLicense && !hasAdvancedSpaLicense,
            doShowUpsellForConfigurationUnregisteredDevices:
                !hasAdvancedSiaLicense && !hasAdvancedSpaLicense,
            canAccessPrivateAccessEventsOnly: hasSpaLicense && !hasSiaLicense,
            canAccessInternetAccessEventsOnly: hasSiaLicense && !hasSpaLicense,
            doShowUpsellForPrivateAccess: !hasSpaLicense,
            doShowUpsellForInternetAccess: !hasSiaLicense,
            canAccessOnlyTunnelPolicy: hasBasicSiaLicense,
            canAccessApplicationInServiceTunnel: hasAdvancedSiaLicense,
            canAccessConnectorOnlyInServiceTunnel: hasBasicSpaLicense,
            doShowUpsellForDirectoryUnregisteredDevices:
                !hasAdvancedSiaLicense && !hasAdvancedSpaLicense,
            doShowUpsellForIntegration: !hasAdvancedSiaLicense && !hasAdvancedSpaLicense,
            doShowUpsellForAccessTiers: !hasAdvancedSpaLicense,
            doShowUpsellForServiceAccounts: !hasAdvancedSiaLicense && !hasAdvancedSpaLicense,
            doShowUpsellForDeviceManager: !hasAdvancedSiaLicense && !hasAdvancedSpaLicense,
            doShowUpsellForServiceBundle: !hasAdvancedSpaLicense,
            doShowUpsellForInfrastructure: !hasAdvancedSpaLicense,
            doShowUpsellForHostedWebsites: !hasAdvancedSpaLicense,
            doShowUpsellForDiscovery: !hasAdvancedSpaLicense,
            doShowUpsellForIaasDiscovery: !hasAdvancedSpaLicense,
            doShowUpsellForSaasApps: !hasAdvancedSiaLicense,
            doShowUpsellForAppDiscovery: !hasAdvancedSiaLicense,
            doShowUpsellForItpSettings: !hasAdvancedSiaLicense,
            doShowUpsellForConnectors: !hasSpaLicense,
            doShowUpsellForRegisteredDomain: !hasAdvancedSpaLicense,
            doShowUpgradeScreenItp: false,
            showLicenseEnforcementBannerInHomeOverview: isSiaLicenseReached || isSpaLicenseReached,
            showLicenseEnforcementBannerInPrivateAccess: isSpaLicenseReached,
            showLicenseEnforcementBannerInInternetAccess: isSiaLicenseReached,
            showLicenseEnforcementBannerInUserDirectory: isSiaLicenseReached || isSpaLicenseReached,
            showLicenseEnforcementBannerInDeviceDirectory:
                isSiaLicenseReached || isSpaLicenseReached,
            showSpaUserLicense: hasSpaLicense,
            showSiaUserLicense: hasSiaLicense,
            showUserLicenseUsageInfo: hasSpaLicense || hasSiaLicense,
            showUserLicenseModal:
                hasSpaLicense || hasSiaLicense
                    ? {
                          canAccess: canWriteAll,
                          orgLicenseInfo: {
                              sia: licenseTypeMap[licenseInformation.sia],
                              spa: licenseTypeMap[licenseInformation.spa],
                              siaCount: licenseInformation.sia_users,
                              spaCount: licenseInformation.spa_users,
                              spaUsageCount: licenseCounts.spa_license_count,
                              siaUsageCount: licenseCounts.sia_license_count,
                          },
                      }
                    : { canAccess: false },
            doShowServicesInConnector: true,
            doShowUpsellForServiceTunnelConfig: !hasSpaLicense,
            userList: {
                doShowLastLoginColumn: false,
                doShowStatusColumn: false,
                doShowInvitedAtColumn: false,
                doShowSpaLicenseColumn: hasSpaLicense,
                doShowSpaLicenseFilter: hasSpaLicense,
                doShowSiaLicenseColumn: hasSiaLicense,
                doShowSiaLicenseFilter: hasSiaLicense,
            },
            doShowUpsellForClusters: !hasAdvancedSpaLicense,
        },
        mspConsole: mspConsoleFeatureFlags,
        momConsole: momConsoleFeatureFlags,
    }
}
