import { faFilter } from "@fortawesome/pro-solid-svg-icons"
import { Tooltip } from "@mui/material"
import {
    GridReadyEvent,
    GridApi,
    ColDef,
    RowDoubleClickedEvent,
    ValueFormatterParams,
    ICellRendererParams,
} from "ag-grid-community"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useHistory } from "react-router-dom"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil, { FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { UrlUtil, encodeID } from "../../../../pre-v3/utils/Url.util"
import { ChoiceModal, ChoiceModalChoice, Grid } from "../../../../pre-v3/components"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { useGetAdminInfo } from "../../../services/Org.service"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { Status } from "../../../components/status/Status.component"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import { Loader } from "../../../components/loader/Loader.component"
import { SelectItem } from "../../../../pre-v3/utils/SelectValue.util"
import { StringUtil } from "../../../../pre-v3/utils/String.util"
import { MenuButton } from "../../../components/menu/menu-button/MenuButton.component"
import { Menu } from "../../../components/menu/Menu.component"
import { MenuItemProps } from "../../../components/menu/menu-item/MenuItem.component"
import { ModalService } from "../../../../pre-v3/services/Modal.service"
import {
    Policy,
    PolicyType as PolicyTypeFilter,
    labelMap,
    statusMap,
    useGetPolicies,
} from "../../../services/Policy.service"
import styles from "./PolicyList.module.scss"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"

//update
import { PolicyServiceTypeRes } from "../../../../pre-v3/api/Secure.api"
import { PolicyType } from "../../../../pre-v3/services"
import { PolicyStatus } from "../../../services/shared/Policy"

interface Props {
    canAccessOnlyTunnelPolicy: boolean
}

export function PolicyList(props: Props) {
    const ls = useServiceLocalization()
    const history = useHistory()
    const grid = useRef<GridApi>()
    const [search, setSearch] = useState(UrlUtil.getUrlParam("search"))
    const [statusFilterLabel, setStatusFilterLabel] = React.useState<string>("")
    const modalService = new ModalService()

    const {
        data: policies,
        isFetching: isPolicyFetching,
        refetch: refetchPolicies,
        error: policyError,
    } = useGetPolicies(props.canAccessOnlyTunnelPolicy ? PolicyTypeFilter.TUNNEL : undefined)

    const { data: adminInfo, refetch: refetchAdminInfo } = useGetAdminInfo()

    function onFilterChange(model: FilterModel): void {
        const filteredVal: string = model?.status?.filter?.toLowerCase() ?? ""
        UrlUtil.writeFilter(model)
        if (policies) {
            if (filteredVal) {
                grid.current?.setRowData(
                    policies.filter((a) => a.status?.toLowerCase() === filteredVal)
                )
                setStatusFilterLabel(StringUtil.capitalize(filteredVal))
            } else {
                grid.current?.setRowData(policies)
                setStatusFilterLabel(ls.getString("allStatuses"))
            }
        }
    }

    useEffect(() => {
        grid.current?.setQuickFilter?.(search)
        UrlUtil.setURlParams("search", search)
    }, [search, props.canAccessOnlyTunnelPolicy])

    function onGridReady(event: GridReadyEvent) {
        grid.current = event.api
        event.api.setQuickFilter?.(search)
    }

    const columns: ColDef[] = [
        {
            headerName: ls.getString("status"),
            field: "status",
            flex: 75,
            cellRenderer: StatusCellRenderer,
            filter: "agSetColumnFilter",
        },
        {
            headerName: ls.getString("policyName"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 125,
            cellRenderer: NameCellRenderer,
            comparator: AgGridUtil.alphaBetComparator,
            filter: "agSetColumnFilter",
        },
        {
            headerName: ls.getString("policyType"),
            flex: 100,
            field: "type",
            valueFormatter: (params: ValueFormatterParams) => {
                return params.value.toUpperCase()
            },
        },
        {
            headerName: ls.getString("services"),
            field: "noOfAttachments",
            flex: 75,
        },
        {
            headerName: ls.getString("lastUpdated"),
            field: "lastUpdatedAt",
            flex: 100,
            valueFormatter: AgGridUtil.dateFormatter,
        },
    ]

    function onRowDoubleClicked(event: RowDoubleClickedEvent): void {
        if (event?.data) {
            if (event.data.type === "tunnel") {
                history.push(
                    formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, {
                        id: encodeID(event.data.id),
                    })
                )
            } else {
                history.push(formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, { id: event.data.id }))
            }
        }
    }

    function onRefresh() {
        refetchPolicies()
        refetchAdminInfo()
    }

    const statusFilterOptions: SelectItem[] = [
        {
            displayName: ls.getString("allStatuses"),
            value: "",
        },
        {
            displayName: ls.getString("active"),
            value: PolicyStatus.ACTIVE,
        },
        {
            displayName: ls.getString("inactive"),
            value: PolicyStatus.INACTIVE,
        },
    ]

    const policyChoiceOptions = useMemo(() => {
        const tunnelPolicyChoice: ChoiceModalChoice = {
            title: ls.getString("tunnelPolicy"),
            description: ls.getString("tunnelPolicyDescription"),
            link: formatRoutePath(
                ROUTE.ACCESS_POLICIES_ADD,
                {},
                { type: PolicyType.USER, subtype: PolicyServiceTypeRes.TUNNEL }
            ),
            className: "tunnelPolicy",
        }

        if (props.canAccessOnlyTunnelPolicy) {
            return [tunnelPolicyChoice]
        }
        return [
            {
                title: ls.getString("webPolicy"),
                description: ls.getString("webPolicyDescription"),
                link: formatRoutePath(
                    ROUTE.ACCESS_POLICIES_ADD,
                    {},
                    { type: PolicyType.USER, subtype: PolicyServiceTypeRes.WEB }
                ),
                className: "webPolicy",
            },
            {
                title: ls.getString("infrastructurePolicy"),
                description: ls.getString("infrastructurePolicyDescription"),
                link: formatRoutePath(
                    ROUTE.ACCESS_POLICIES_ADD,
                    {},
                    { type: PolicyType.USER, subtype: PolicyServiceTypeRes.TCP }
                ),
                className: "infrastructurePolicy",
            },
            tunnelPolicyChoice,
            {
                title: ls.getString("customPolicyJson"),
                description: ls.getString("customPolicyJsonDescription"),
                link: formatRoutePath(ROUTE.ACCESS_POLICIES_ADD, {}, { type: PolicyType.CUSTOM }),
                className: "jsonTemplate",
                teamEditionLocked: true,
            },
        ]
    }, [props.canAccessOnlyTunnelPolicy])

    //update during policy epic work
    function onAddPolicy(): void {
        modalService.open(ls.getString("createNewPolicy"), {
            component: ChoiceModal,
            props: {
                description: ls.getString("policyDescription"),
                choices: policyChoiceOptions,
            },
            maxWidth: "md",
        })
    }

    return (
        <div className={styles.outerContainer}>
            <header className={styles.header}>
                <PageHeading>{ls.getString("accessPolicies")}</PageHeading>
                <Tooltip title={ls.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={onRefresh}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                    />
                </Tooltip>
            </header>
            <div className={styles.container}>
                <SearchInput
                    value={search}
                    onChangeValue={setSearch}
                    className={styles.search}
                    placeholder={ls.getString("search")}
                />
                <MenuButton
                    className={styles.filterButton}
                    label={statusFilterLabel}
                    icon={faFilter}
                >
                    <Menu
                        items={statusFilterOptions.map((option): MenuItemProps => {
                            return {
                                label: option.displayName,
                                onClick: () => onFilterChange({ status: { filter: option.value } }),
                            }
                        })}
                    />
                </MenuButton>
                {adminInfo?.canWritePolicies && (
                    <div className={styles.addButton}>
                        <Button
                            icon={IconType.PLUS}
                            onClick={onAddPolicy}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                        >
                            {ls.getString("createPolicy")}
                        </Button>
                    </div>
                )}
            </div>
            <Loader
                isLoading={isPolicyFetching}
                medium
                center
                title={ls.getString("loadingSomething", ls.getString("policies"))}
            >
                {policyError && (
                    <ErrorBanner className={styles.error}>{String(policyError)}</ErrorBanner>
                )}
                {!policyError && (
                    <div className={styles.gridContainer}>
                        <Grid
                            onGridReady={onGridReady}
                            rowData={policies}
                            columnDefs={columns}
                            onRowDoubleClicked={onRowDoubleClicked}
                            pagination
                        />
                    </div>
                )}
            </Loader>
        </div>
    )
}

export function NameCellRenderer(props: ICellRendererParams<Policy>) {
    if (!props.data?.id) {
        return props.value
    }
    if (props.data.type === "tunnel") {
        return (
            <RowTitle
                title={props.value || "-"}
                route={formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, {
                    id: encodeID(props.data.id),
                })}
            />
        )
    }
    return (
        <RowTitle
            title={props.value || "-"}
            route={formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, { id: encodeID(props.data.id) })}
        />
    )
}

export function StatusCellRenderer(props: ICellRendererParams<Policy>) {
    const ls = useServiceLocalization()
    if (!props.data?.status) {
        return props.value
    }
    return (
        <Status
            type={statusMap[props.data.status as PolicyStatus]}
            label={ls.getString(labelMap[props.data.status])}
        />
    )
}
