import React, { FC, useState, useMemo } from "react"
import styles from "./PolicyDetail.module.scss"
import { ServicesCellRenderer } from "../../services/cell-renderer/ServicesCellRenderer"
import {
    SimpleTable,
    SimpleTableItem,
} from "../../../components/simple-table/SimpleTable.component"
import { TabBar } from "../../../components/tab-bar/TabBar.component"
import { ColDef, Grid } from "../../../components/grid/Grid.component"
import { PolicyInfo } from "../info/PolicyInfo.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { Tooltip } from "../../../../v3/components/tooltip/Tooltip.component"
import { useHistory, useParams } from "react-router-dom"
import { useServiceLocalization } from "../../../services"
import { OtherPolicy, Policy } from "../../../../v3/services/Policy.service"
import classNames from "classnames"
import { PolicyStatus } from "../../../../v3/services/shared/Policy"
import { formatRoutePath, ROUTE } from "../../../../routes"
import { PolicyStatusCellRenderer } from "../cell-renderer/status/PolicyStatusCellRenderer.component"
import { DateUtil } from "../../../utils/Date.util"
import AgGridUtil from "../../../utils/AgGrid.util"
import { QueryObserverResult } from "@tanstack/react-query"

interface PageParams {
    id: string
}

interface Props {
    canCratePolices: boolean
    policy: OtherPolicy
    refetch: () => Promise<QueryObserverResult<Policy | undefined, string>>
}

export const PolicyDetail: FC<Props> = ({ canCratePolices, policy, refetch }) => {
    const [tab, setTab] = useState(1)
    const { id } = useParams<PageParams>()
    const localization = useServiceLocalization()
    const history = useHistory()

    const tableItems: TableItems = useMemo(() => {
        return {
            statusTableItems: [
                {
                    label: localization.getString("version"),
                    value: "v" + policy.version,
                },
                {
                    label: localization.getString("lastUpdated"),
                    value: DateUtil.format(policy.lastUpdatedAt),
                },
            ],
            dataTableItems: [
                {
                    label: localization.getString("description"),
                    value: policy.description,
                },
                {
                    label: localization.getString("createdAt"),
                    value: DateUtil.format(policy.createdAt),
                },
                {
                    label: localization.getString("createdBy"),
                    value: policy.createdBy,
                },
                {
                    label: localization.getString("lastUpdatedBy"),
                    value: policy.lastUpdatedBy,
                },
            ],
        }
    }, [policy])

    const statusBlockClassName = classNames(styles.statusBlock, {
        [styles.success]: policy && policy.status === PolicyStatus.ACTIVE,
        [styles.warning]: policy && policy.status === PolicyStatus.INACTIVE,
    })

    const tabs = [
        {
            label: localization.getString("specification"),
            value: 1,
            active: true,
        },
        {
            label: `${localization.getString("services")} (${policy?.attachments.length ?? 0})`,
            value: 2,
        },
    ]
    const attachmentColumns: ColDef[] = [
        {
            headerName: localization.getString("serviceName"),
            field: "serviceName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            cellRenderer: "servicesCellRenderer",
        },
        {
            headerName: localization.getString("attachedOn"),
            field: "attachedAt",
            valueFormatter: AgGridUtil.dateFormatter,
        },
        {
            headerName: localization.getString("status"),
            field: "enabled",
            valueFormatter: (params) =>
                params.value
                    ? localization.getString("enforcing")
                    : localization.getString("permissive"),
        },
    ]

    const downloadSpec = () => {
        if (!policy) {
            return
        }

        // create the blob object
        const blob: Blob = new Blob([JSON.stringify(JSON.parse(policy.spec), null, 4) + "\n"], {
            type: "application/json",
        })

        // download the blob with a ghost anchor
        const a = document.createElement("a")
        document.body.appendChild(a)
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = policy.name + ".json"
        a.click()
        setTimeout(() => {
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
        }, 0)
    }

    const onEdit = () => {
        history.push(formatRoutePath(ROUTE.ACCESS_POLICIES_EDIT, { id }))
    }

    return (
        <>
            <header className={styles.header}>
                <PageHeading>{policy?.name}</PageHeading>
                <div className={styles.actions}>
                    {canCratePolices && (
                        <>
                            <Tooltip title={localization.getString("downloadPolicySpec")}>
                                <Button
                                    icon={IconType.DOWNLOAD}
                                    onClick={downloadSpec}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={localization.getString("downloadPolicySpec")}
                                />
                            </Tooltip>
                            <Tooltip title={localization.getString("editPolicy")}>
                                <Button
                                    icon={IconType.PEN}
                                    onClick={onEdit}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={localization.getString("editPolicy")}
                                />
                            </Tooltip>
                        </>
                    )}
                    <Tooltip title={localization.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={() => refetch()}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            <div className={styles.overviewContainer}>
                {policy && (
                    <>
                        <div className={styles.leftContainer}>
                            <div className={statusBlockClassName}>
                                <p className={styles.statusLabel}>
                                    {localization.getString("currentStatus")}
                                </p>
                                <div className={styles.statusContent}>
                                    <PolicyStatusCellRenderer data={policy} />
                                </div>
                                <SimpleTable
                                    className={styles.statusTable}
                                    items={tableItems.statusTableItems}
                                />
                            </div>
                            <SimpleTable
                                className={styles.SimpleTable}
                                items={tableItems.dataTableItems}
                            />
                        </div>
                        <div className={styles.rightContainer}>
                            <TabBar tabs={tabs} onChange={setTab} />
                            <div className={styles.overviewRightGridContainer}>
                                {tab === 1 && (
                                    <PolicyInfo className={styles.info} policy={policy} />
                                )}
                                {tab === 2 && (
                                    <Grid
                                        rowData={policy.attachments}
                                        columnDefs={attachmentColumns}
                                        components={{
                                            servicesCellRenderer: ServicesCellRenderer,
                                        }}
                                        context={{ history: history }}
                                        pagination
                                    />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

interface TableItems {
    statusTableItems: SimpleTableItem[]
    dataTableItems: SimpleTableItem[]
}
