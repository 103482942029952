import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { ROUTE } from "../../routes"
import { PolicyAdd } from "../../pre-v3/views/policy/add/PolicyAdd"
import { PolicyList } from "../../v3/views/policy/list/PolicyList.view"
import { Loader } from "../../v3/components/loader/Loader.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import useTitle from "../../hooks/useTitle.hook"
import { AccessPolicyDetails } from "./details/AccessPolicyDetails.view"
import { AccessPolicyEdit } from "./edit/AccessPolicyEdit.page"

export function AccessPolicy(): JSX.Element {
    useTitle(["accessPolicies", "privateAccess", "adminConsole"])
    const { data: featureFlags } = useFeatureFlags()
    if (!featureFlags) {
        return <Loader center medium />
    }
    return (
        <Switch>
            <Route path={ROUTE.ACCESS_POLICIES_ADD} exact component={PolicyAdd} />
            <Route path={ROUTE.ACCESS_POLICIES_EDIT} component={AccessPolicyEdit} />
            <Route path={ROUTE.ACCESS_POLICIES_DETAILS}>
                <AccessPolicyDetails
                    canDeletePolicy={featureFlags.adminConsole.canDeletePolicy}
                    canEditPolicy={featureFlags.adminConsole.canEditPolicy}
                    canCratePolices={featureFlags.adminConsole.canCreatePolicies}
                />
            </Route>
            <Route path={ROUTE.ACCESS_POLICIES}>
                <PolicyList
                    canAccessOnlyTunnelPolicy={featureFlags.adminConsole.canAccessOnlyTunnelPolicy}
                />
            </Route>
            <Redirect to={ROUTE.ACCESS_POLICIES} />
        </Switch>
    )
}
