import { useQuery } from "@tanstack/react-query"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import {
    OrgApi,
    OrgInfoRes,
    LicenseInformationRes,
    ProvisionStatusRes,
    StatusRes,
    TypeRes,
} from "../api/Org.api"
import { Edition } from "./shared/Edition"
import { OrgInfoBase, Status } from "./Org.service"

export function useGetOrgs(disabled?: boolean) {
    const orgApi = new OrgApi()

    return useQuery<SuperAdminOrgInfo[], string>({
        queryKey: ["superAdminService.getOrgs"],
        queryFn: async () => {
            const res = await orgApi.getOrgs()
            return res.map((r) => mapOrgResToOrg(r))
        },
        enabled: !disabled,
    })
}

function mapOrgResToOrg(
    org: OrgInfoRes,
    licenseInformation?: LicenseInformationRes
): SuperAdminOrgInfo {
    const {
        access_tier,
        shield,
        user_pool,
        org_data,
        registered_domain,
        connector,
        invite_code,
        user_pool_domain,
        user,
        default_groups_user,
        user_pool_client,
        default_groups_user_pool,
        update_user_pool,
        invite_admin_user,
        private_edge_shield,
        ...otherStatusRes
    } = org.provision_status

    return {
        createdAt: DateUtil.convertLargeTimestamp(org.created_at),
        createdBy: org.created_by,
        id: org.org_id,
        edition: licenseInformation ? undefined : editionMap[org.edition],
        lastUpdatedAt: DateUtil.convertLargeTimestamp(org.last_updated_at),
        lastUpdatedBy: org.last_updated_by,
        orgName: org.org_name,
        isAiAssistedAdminSearchEnabled: org.is_ai_assist_enabled,
        isDnsFilterEnabled: org.is_dns_filter_enabled,
        // @cspell:ignore mysonicwall
        isSonicWallProvisioned: org.is_mysonicwall,
        status: org.status ? statusMap[org.status] : "unknown",
        type: orgTypeMapRes[org.type],
        archived: org.archived,
        isBanyanIdp: org.banyan_idp,
        isMspOrg: org.is_msp_org,
        provisionStatus: {
            accessTier: access_tier && provisionStatusMap[access_tier],
            shield: shield && provisionStatusMap[shield],
            userPool: user_pool && provisionStatusMap[user_pool],
            orgData: org_data && provisionStatusMap[org_data],
            registeredDomain: registered_domain && provisionStatusMap[registered_domain],
            connector: connector && provisionStatusMap[connector],
            inviteCode: invite_code && provisionStatusMap[invite_code],
            userPoolDomain: user_pool_domain && provisionStatusMap[user_pool_domain],
            user: user && provisionStatusMap[user],
            defaultGroupsUser: default_groups_user && provisionStatusMap[default_groups_user],
            userPoolClient: user_pool_client && provisionStatusMap[user_pool_client],
            defaultGroupsUserPool:
                default_groups_user_pool && provisionStatusMap[default_groups_user_pool],
            updateUserPool: update_user_pool && provisionStatusMap[update_user_pool],
            inviteAdminUser: invite_admin_user && provisionStatusMap[invite_admin_user],
            privateEdgeShield: private_edge_shield && provisionStatusMap[private_edge_shield],
        },
        ...mapOtherStatusRes(otherStatusRes),
    }
}

function mapOtherStatusRes(
    otherStatusRes: Record<string, ProvisionStatusRes | undefined>
): Pick<SuperAdminOrgInfo, "accessTierComponents" | "unknownComponents"> {
    return Object.entries(otherStatusRes).reduce<
        Pick<SuperAdminOrgInfo, "accessTierComponents" | "unknownComponents">
    >((acc, [component, status]) => {
        if (!status) return acc

        const provisionStatus = provisionStatusMap[status]

        const [accessTier, region, ...rest] = component.split("access_tier-")

        if (accessTier === "" && rest.length <= 0) {
            return {
                ...acc,
                accessTierComponents: {
                    ...acc.accessTierComponents,
                    [region]: provisionStatus,
                },
            }
        }

        return {
            ...acc,
            unknownComponents: { ...acc.unknownComponents, [component]: provisionStatus },
        }
    }, {})
}

const editionMap: Record<OrgInfoRes["edition"], Edition> = {
    Team: Edition.TEAM,
    Enterprise: Edition.ENTERPRISE,
    Unlimited: Edition.UNLIMITED,
}

const statusMap: Record<StatusRes, Status> = {
    Success: "success",
    Failed: "error",
    PartialSuccess: "partialSuccess",
    InProgress: "inProgress",
}

const orgTypeMapRes: Record<TypeRes, OrgType> = {
    Production: "Production",
    Trial: "Trial",
    Testing: "Testing",
    Staging: "Staging",
    Internal: "Internal",
    InternalPersistent: "Internal Persistent",
    InternalTemporary: "Internal Temporary",
}

type OrgType =
    | "Production"
    | "Trial"
    | "Staging"
    | "Testing"
    | "Internal"
    | "Internal Temporary"
    | "Internal Persistent"

enum ProvisionStatus {
    Success = "success",
    Failed = "error",
    Archived = "archived",
    Pending = "pending",
    Deleted = "deleted",
}

const provisionStatusMap: Record<ProvisionStatusRes, ProvisionStatus> = {
    Success: ProvisionStatus.Success,
    Failed: ProvisionStatus.Failed,
    Archived: ProvisionStatus.Archived,
    Pending: ProvisionStatus.Pending,
    Deleted: ProvisionStatus.Deleted,
}

type ProvisionedComponent =
    | "accessTier"
    | "shield"
    | "userPool"
    | "orgData"
    | "registeredDomain"
    | "connector"
    | "inviteCode"
    | "userPoolDomain"
    | "user"
    | "defaultGroupsUser"
    | "userPoolClient"
    | "defaultGroupsUserPool"
    | "updateUserPool"
    | "inviteAdminUser"
    | "privateEdgeShield"

export interface SuperAdminOrgInfo extends Omit<OrgInfoBase, "orgType"> {
    orgName: string
    status?: Status
    edition?: Edition
    type:
        | "Production"
        | "Trial"
        | "Staging"
        | "Testing"
        | "Internal"
        | "Internal Temporary"
        | "Internal Persistent"
    isSonicWallProvisioned: boolean
    createdBy?: string
    archived: boolean
    provisionStatus?: Record<ProvisionedComponent, ProvisionStatus | undefined>
    accessTierComponents?: Record<string, ProvisionStatus>
    unknownComponents?: Record<string, ProvisionStatus>
    isDnsFilterEnabled: boolean
}
