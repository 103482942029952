import { Tooltip } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"

import styles from "./ActionBar.module.scss"
import {
    Button,
    ButtonElement,
    ButtonProps,
    ButtonType,
    IconType,
} from "../button/Button.component"

export { IconType } from "../button/Button.component"

export interface BreadcrumbProps {
    label: string
    href?: string
}

export interface ActionButtonProps {
    label: string
    icon: IconType
    disabled?: boolean
    onClick?(): void
    href?: string
}

interface Props {
    title?: React.ReactNode
    breadcrumbs?: BreadcrumbProps[]
    actions?: ActionButtonProps[]
}

/**
 * @deprecated
 * This component will be removed with the MSP and MOM Console update.
 */
export function ActionBar(props: Props): JSX.Element {
    return (
        <div className={styles.actionBar}>
            <div className={styles.outerContainer}>
                <div>
                    {props.breadcrumbs?.map((breadcrumbProps) => (
                        <Breadcrumb key={breadcrumbProps.label} {...breadcrumbProps} />
                    ))}
                </div>
                <div className={styles.actionsContainer}>
                    {props.actions?.map((actionButtonProps) => (
                        <ActionButton key={actionButtonProps.label} {...actionButtonProps} />
                    ))}
                </div>
            </div>
            <h2 className={styles.title}>{props.title}</h2>
        </div>
    )
}

function Breadcrumb(props: BreadcrumbProps): JSX.Element {
    return (
        <span className={styles.breadcrumb}>
            {props.href ? <Link to={props.href}>{props.label}</Link> : <span>{props.label}</span>}
            <span className={styles.breadcrumbSeparator}>&#8963;</span>
        </span>
    )
}

type CommonButtonProps = "aria-label" | "className" | "icon" | "disabled" | "buttonType"

function ActionButton(props: ActionButtonProps): JSX.Element {
    const buttonProps: Required<Pick<ButtonProps, CommonButtonProps>> = {
        "aria-label": props.label,
        className: styles.actionBarItem,
        icon: props.icon,
        disabled: props.disabled ?? false,
        buttonType: ButtonType.SECONDARY,
    }

    return (
        <Tooltip title={props.label} placement="bottom">
            <span>
                {props.href ? (
                    <Button {...buttonProps} to={props.href} asElement={ButtonElement.LINK} />
                ) : (
                    <Button
                        {...buttonProps}
                        asElement={ButtonElement.BUTTON}
                        onClick={(event) => {
                            event.preventDefault()
                            props.onClick?.()
                        }}
                    />
                )}
            </span>
        </Tooltip>
    )
}
