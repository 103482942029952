import * as React from "react"
import styles from "./Form.module.scss"
import classNames from "classnames/bind"

interface Props {
    onSubmit: React.FormEventHandler<HTMLFormElement>
    display?: "grid" | "list"
    labelWidth?: number | string
    children: React.ReactNode
    className?: string
    floating?: boolean
    slim?: boolean
}

export const Form = React.forwardRef(function Form(
    {
        onSubmit,
        children,
        display,
        labelWidth = "auto",
        className,
        floating,
        slim,
        ...extra
    }: Props,
    ref: React.Ref<HTMLFormElement>
) {
    return (
        <FormContext.Provider value={{ displayMode: display, labelWidth, floating, slim }}>
            <form
                onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => {
                    e.preventDefault()
                    onSubmit(e)
                }}
                className={classNames(className, {
                    [styles.floating]: floating,
                })}
                ref={ref}
                {...extra}
            >
                {children}
            </form>
        </FormContext.Provider>
    )
})

// if the form is displayed as a grid we need to embed that in the form's context so nested components
// can render appropriate
type FormContextShape = {
    displayMode?: Props["display"]
    labelWidth: Props["labelWidth"]
    floating: Props["floating"]
    slim: Props["slim"]
}
export const FormContext = React.createContext<FormContextShape>({
    displayMode: "list",
    labelWidth: "auto",
    floating: false,
    slim: false,
})

// a hooks to grab the form configuration
export const useFormDisplayMode = () => React.useContext(FormContext).displayMode
export const useFormLabelWidth = () => React.useContext(FormContext).labelWidth
export const useFormFloating = () => React.useContext(FormContext).floating
export const useFormSlim = () => React.useContext(FormContext).slim
