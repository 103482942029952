import { Singleton } from "../decorators/Singleton.decorator"
import { PaginationOptions, BaseApi } from "./Base.api"

@Singleton("EntityApi")
export class EntityApi extends BaseApi {
    public getUsers(search: EndUserSearch): Promise<EndUserRes> {
        const urlParams: URLSearchParams = new URLSearchParams(search as any)
        return this.get("/api/v1/endusers?" + urlParams.toString())
    }

    public getUnregisteredDevices(
        search: UnregisteredDeviceSearch
    ): Promise<UnregisteredDeviceRes> {
        const urlParams: URLSearchParams = new URLSearchParams(search as any)
        return this.get("/api/v1/unregistered_device/endusers?" + urlParams.toString())
    }

    public getUnregisteredDevicesCSV(): Promise<Blob> {
        return this.getBlob("/api/v1/unregistered_device/endusers/csv?active=true")
    }
}

interface EndUser {
    Name?: string
    Email: string
    Groups?: string
    Roles?: string[]
    LastLogin?: number
    SerialNumbers?: string[]
    TrustData: TrustData
}

export interface EndUserSearch extends PaginationOptions, TimestampOptions {
    serialnumber?: string
    email?: string
    orderby?: string
    active?: string
    name?: string
    role_names?: string
}

interface EndUserRes {
    count: number
    endusers: EndUser[]
}

export interface DeviceSearch extends PaginationOptions, TimestampOptions {
    serial_number?: string
    email?: string
    trust_level?: TrustLevel
    platform?: Platform
    name?: string
    model?: string
    active?: string
    trustscore_status?: string
    role_names?: string
    app_version?: string
    ownership?: string
    trust_profile_id?: string
}

export interface UnregisteredDeviceSearch extends PaginationOptions {
    email?: string
    active?: string
    platform?: string
}

interface UnregisteredDeviceRes {
    devices: UnregisteredDevice[]
    count: number
}

export interface UnregisteredDevice {
    name: string
    email: string
    platform: string
    last_login: number
    ip_address: string
    roles: string[]
    enduser_id: string
    enduser_device_id: string
}

interface TrustData {
    AccessTrustscore: number
    EntityTrustscore: number
    OverrideTrustscore: number
    Level: TrustLevel
    UpdatedAt: number
    Factors: TrustFactor[]
}

export enum Platform {
    MACOS = "macOS",
    WINDOWS = "Windows",
    LINUX = "Linux",
    IOS = "iOS",
    ANDROID = "Android",
}

export enum TrustLevel {
    NONE = "",
    ALWAYS_DENY = "AlwaysDeny",
    LOW = "Low",
    MID = "Medium",
    HIGH = "High",
    ALWAYS_ALLOW = "AlwaysAllow",
    PENDING = "Pending",
}

interface TrustFactor {
    Name: string
    Value: string
    Type: "internal" | "external"
    Source: string
    Description: string
    Remediation: Remediation
}

interface Remediation {
    Windows: RemediationInstructions
    MacOS: RemediationInstructions
    Linux: RemediationInstructions
}

interface RemediationInstructions {
    Description: string
    URL: string
}

interface TimestampOptions {
    last_login_before?: number
    last_login_after?: number
}
