import React, { FormEvent, forwardRef, ReactNode, Ref, useCallback } from "react"

import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { Accordion } from "../../../components/accordion/Accordion.component"
import { AppText } from "../../../components/app-text/AppText.component"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { Form } from "../../../components/form/Form.component"
import { FormRow } from "../../../components/form/FormRow.component"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { MultiInput } from "../../../components/multi-input/MultiInput.component"
import { PillMultiSelect } from "../../../components/pill-multi-select/PillMultiSelect.component"
import { DnsFilter } from "../../../services/ItpPolicy.service"
import styles from "../ItpPolicy.module.scss"
import { Step, ariaControls } from "./ItpPolicyForm.component"

interface ContentFilteringTabProps {
    categories: DnsFilter[]
    value: ContentFilteringFormValues
    onSubmit: () => void
    disabled?: boolean
    errors?: ReactNode[]
    onBack: () => void
    isEdit?: boolean
    onChange: (updatedValue: ContentFilteringFormValues) => void
}

function ContentFilteringTabComponent(props: ContentFilteringTabProps, ref: Ref<HTMLFormElement>) {
    const { categories, value, disabled, errors, isEdit, onChange, onBack } = props

    const localization = useServiceLocalization()

    const categoriesValue = value.categories.value
    const categoriesOption = categories.map(({ label, value }) => ({
        label,
        value,
    }))

    const onCategoriesChange = useCallback(
        (updatedCategories: string[]) => {
            onChange({
                ...value,
                categories: {
                    ...value.categories,
                    value: updatedCategories,
                },
            })
        },
        [value]
    )

    const onBlacklistDomainChange = useCallback(
        (updatedBlackListDomains: string[]) => {
            onChange({
                ...value,
                blacklistDomains: {
                    ...value.blacklistDomains,
                    value: updatedBlackListDomains,
                },
            })
        },
        [value]
    )

    const onWhitelistDomainChange = useCallback(
        (updatedWhitelistDomains: string[]) => {
            onChange({
                ...value,
                whitelistDomains: {
                    ...value.whitelistDomains,
                    value: updatedWhitelistDomains,
                },
            })
        },
        [value]
    )

    function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()

        props.onSubmit()
    }

    function onSetEnabled(accordionKey: AccordionKey, updatedValue: boolean) {
        onChange({
            ...value,
            [accordionKey]: {
                ...value[accordionKey],
                enabled: updatedValue,
            },
        })
    }

    return (
        <Form
            className={styles.tabContainer}
            onSubmit={onSubmit}
            id={ariaControls[Step.CONTENT_FILTERING]}
            ref={ref}
        >
            <AppText ls="whatTypesOfTrafficDoYouWantToPreventEndUsersFromAccessing" />
            <Accordion
                open={value.categories.enabled}
                disabled={disabled}
                type="switch"
                label={localization.getString("categoryFiltering")}
                tooltipInfo={localization.getString(
                    "domainsGroupedTogetherInTermsOfTheContentTheyProvideToEndUsersSelectOneOrMore"
                )}
                onToggle={(enabled: boolean) => {
                    onSetEnabled(AccordionKey.CATEGORIES, enabled)
                }}
                children={
                    <PillMultiSelect
                        label={localization.getString("selectCategoriesForFiltering")}
                        placeholder={localization.getString("selectCategoriesToFilter")}
                        options={categoriesOption}
                        value={categoriesValue}
                        onChange={onCategoriesChange}
                        className={styles.categoryDropdown}
                        disabled={disabled}
                        required
                    />
                }
            />
            <Accordion
                open={value.blacklistDomains.enabled}
                disabled={disabled}
                type="switch"
                label={localization.getString("domainFiltering")}
                tooltipInfo={localization.getString("enterSpecificDomainsToBlockAccessTo")}
                onToggle={(enabled: boolean) => {
                    onSetEnabled(AccordionKey.BLACKLIST_DOMAINS, enabled)
                }}
                children={
                    <FormRow
                        label={localization.getString("whichDomainsWouldYouLikeToBlockAccessTo")}
                        description={localization.getString(
                            "includesAllSubdomainsEGGamblingComWouldIncludeBetsGamblingCom"
                        )}
                    >
                        <MultiInput
                            required
                            disabled={disabled}
                            onChange={onBlacklistDomainChange}
                            values={value.blacklistDomains.value}
                            label={localization.getString(
                                "whichDomainsWouldYouLikeToBlockAccessTo"
                            )}
                            placeholder={localization.getString("enterDomainEGSalesforceCom")}
                            caseInsensitiveDuplicateMatch
                        />
                    </FormRow>
                }
            />
            <Accordion
                open={value.whitelistDomains.enabled}
                disabled={disabled}
                type="switch"
                label={localization.getString("domainExceptions")}
                tooltipInfo={localization.getString(
                    "enterSpecificDomainsThatBypassThisITPPolicyIncludesAllSubdomains"
                )}
                onToggle={(enabled: boolean) => {
                    onSetEnabled(AccordionKey.WHITELIST_DOMAINS, enabled)
                }}
                children={
                    <FormRow
                        label={localization.getString("whichDomainsShouldBypassThisITPPolicy")}
                        description={localization.getString(
                            "listTheDomainsThatAreExceptionsToBlocking"
                        )}
                    >
                        <MultiInput
                            required
                            disabled={disabled}
                            onChange={onWhitelistDomainChange}
                            values={value.whitelistDomains.value}
                            label={localization.getString("whichDomainsShouldBypassThisITPPolicy")}
                            placeholder={localization.getString("enterDomainEGSalesforceCom")}
                            caseInsensitiveDuplicateMatch
                        />
                    </FormRow>
                }
            />
            {!disabled && (
                <FormButtons
                    leftButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            type="button"
                            onClick={onBack}
                        >
                            {localization.getString(isEdit ? "cancel" : "back")}
                        </Button>
                    }
                    rightButtons={
                        <Button
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.PRIMARY}
                            type="submit"
                        >
                            {localization.getString(isEdit ? "save" : "next")}
                        </Button>
                    }
                >
                    {errors && <ErrorBanners errors={errors} />}
                </FormButtons>
            )}
            <div className={styles.bottomPadding} />
        </Form>
    )
}

export const ContentFilteringTab = forwardRef(ContentFilteringTabComponent)

export interface ContentFilteringFormValues {
    categories: {
        enabled: boolean
        value: string[]
    }
    blacklistDomains: {
        enabled: boolean
        value: string[]
    }
    whitelistDomains: {
        enabled: boolean
        value: string[]
    }
}

enum AccordionKey {
    CATEGORIES = "categories",
    BLACKLIST_DOMAINS = "blacklistDomains",
    WHITELIST_DOMAINS = "whitelistDomains",
}
