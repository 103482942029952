import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import {
    Application,
    Network,
    GlobalEdgeNetworkSetting as NetworkSetting,
    PublicInfo,
} from "../../../v3/services/ServiceTunnelV2.service"
import { CollapsibleCard } from "../../../components/collapsible-card/CollapsibleCard.component"
import { PublicInfoCard, PublicInfoCardApi } from "./PublicInfoCard.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import { Grid } from "../../../pre-v3/components/grid/Grid.component"
import {
    ColDef,
    ColumnApi,
    GridReadyEvent,
    ICellRendererParams,
    ITooltipParams,
} from "ag-grid-community"
import { CidrCellRenderer, DomainCellRenderer } from "./GridCellRenderers.component"
import classNames from "classnames"
import styles from "./GlobalEdgeNetworkSettingCard.module.scss"
import { checkPublicInfoDuplicates } from "./checkPublicInfoDuplicates"
export interface Props {
    networkSetting: NetworkSetting
    onNetworkSettingChange(networkSetting: NetworkSetting): void
    applications: Application[]
    disabled?: boolean
    hideApplication: boolean
}

export function GlobalEdgeNetworkSettingCard(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const publicIncludeInfoRef = React.useRef<PublicInfoCardApi>(null)
    const publicExcludeInfoRef = React.useRef<PublicInfoCardApi>(null)

    const onPublicIncludeInfoChange = (publicInfo: PublicInfo): void => {
        const checkingDupes = checkPublicInfoDuplicates(
            publicInfo,
            props.networkSetting.publicExcludeInfo
        )

        publicIncludeInfoRef.current?.triggerDomainInputDuplicateError(checkingDupes.dupeDomains)
        publicIncludeInfoRef.current?.triggerIpRangeInputDuplicateError(checkingDupes.dupeIpRanges)

        if (checkingDupes.dupeDomains.length === 0) {
            publicExcludeInfoRef.current?.triggerDomainInputDuplicateError([])
        }

        if (checkingDupes.dupeIpRanges.length === 0) {
            publicExcludeInfoRef.current?.triggerIpRangeInputDuplicateError([])
        }

        props.onNetworkSettingChange({
            ...props.networkSetting,
            publicIncludeInfo: publicInfo,
        })
    }

    const onPublicExcludeInfoChange = (publicInfo: PublicInfo): void => {
        const checkingDupes = checkPublicInfoDuplicates(
            publicInfo,
            props.networkSetting.publicIncludeInfo
        )

        publicExcludeInfoRef.current?.triggerDomainInputDuplicateError(checkingDupes.dupeDomains)

        publicExcludeInfoRef.current?.triggerIpRangeInputDuplicateError(checkingDupes.dupeIpRanges)

        if (checkingDupes.dupeDomains.length === 0) {
            publicIncludeInfoRef.current?.triggerDomainInputDuplicateError([])
        }

        if (checkingDupes.dupeIpRanges.length === 0) {
            publicIncludeInfoRef.current?.triggerIpRangeInputDuplicateError([])
        }

        props.onNetworkSettingChange({
            ...props.networkSetting,
            publicExcludeInfo: publicInfo,
        })
    }

    const onRemoveConnector = (connector: Network): void => {
        props.onNetworkSettingChange({
            ...props.networkSetting,
            networks: props.networkSetting.networks.filter((c) => c.id !== connector.id),
        })
    }

    // pill multi select requires the complete list of items to be passed in to render properly the selected items
    const ignoredApplications = React.useMemo(
        () =>
            [
                ...props.networkSetting.publicIncludeInfo.applications,
                ...props.networkSetting.publicExcludeInfo.applications,
            ].map((app) => app.id),
        [
            props.networkSetting.publicIncludeInfo.applications,
            props.networkSetting.publicExcludeInfo.applications,
        ]
    )

    const cardId = "globalEdge"
    const privateInfoId = `${cardId}PrivateInfo`
    const publicIncludeInfoId = `${cardId}PublicIncludeInfo`
    const publicExcludeInfoId = `${cardId}PublicExcludeInfo`

    return (
        <CollapsibleCard id={cardId} title={localization.getString("globalEdge")}>
            <PrivateInfo
                id={privateInfoId}
                networkSetting={props.networkSetting}
                onRemoveConnector={onRemoveConnector}
                disabled={props.disabled}
            />
            <PublicInfoCard
                id={publicIncludeInfoId}
                publicInfo={props.networkSetting.publicIncludeInfo}
                onPublicInfoChange={onPublicIncludeInfoChange}
                applications={props.applications}
                cardTitle={localization.getString("publicIncludeInfo")}
                ignoredApplications={ignoredApplications}
                disabled={props.disabled}
                appsInputLabel={localization.getString(
                    "somethingToInclude",
                    localization.getString("apps")
                )}
                appsInputDescription={localization.getString(
                    "somethingToRouteThroughThisNetwork",
                    localization.getString("apps")
                )}
                domainsInputLabel={localization.getString(
                    "somethingToInclude",
                    localization.getString("publicDomains")
                )}
                domainsInputDescription={localization.getString(
                    "somethingToRouteThroughThisNetwork",
                    localization.getString("publicDomains")
                )}
                ipRangesInputLabel={localization.getString(
                    "somethingToInclude",
                    localization.getString("publicIpRanges")
                )}
                ipRangesInputDescription={localization.getString(
                    "somethingToRouteThroughThisNetwork",
                    localization.getString("publicIpRanges")
                )}
                ref={publicIncludeInfoRef}
                hideApplication={props.hideApplication}
            />
            <PublicInfoCard
                id={publicExcludeInfoId}
                publicInfo={props.networkSetting.publicExcludeInfo}
                onPublicInfoChange={onPublicExcludeInfoChange}
                applications={props.applications}
                cardTitle={localization.getString("publicExcludeInfo")}
                ignoredApplications={ignoredApplications}
                disabled={props.disabled}
                appsInputLabel={localization.getString(
                    "somethingToExclude",
                    localization.getString("apps")
                )}
                appsInputDescription={localization.getString(
                    "somethingToExcludeFromThisNetwork",
                    localization.getString("apps")
                )}
                domainsInputLabel={localization.getString(
                    "somethingToExclude",
                    localization.getString("domains")
                )}
                domainsInputDescription={localization.getString(
                    "somethingToExcludeFromThisNetwork",
                    localization.getString("domains")
                )}
                ipRangesInputLabel={localization.getString(
                    "somethingToExclude",
                    localization.getString("ipRanges")
                )}
                ipRangesInputDescription={localization.getString(
                    "somethingToExcludeFromThisNetwork",
                    localization.getString("ips")
                )}
                ref={publicExcludeInfoRef}
                hideApplication={props.hideApplication}
            />
        </CollapsibleCard>
    )
}

interface PrivateInfoProps {
    id: string
    networkSetting: NetworkSetting
    onRemoveConnector(connector: Network): void
    disabled?: boolean
}

function PrivateInfo(props: PrivateInfoProps): JSX.Element {
    const localization = useServiceLocalization()
    const gridColumnApi = React.useRef<ColumnApi>()

    const cols: ColDef[] = React.useMemo(
        (): ColDef[] => [
            {
                field: "name",
                headerName: localization.getString("connectors"),
                tooltipComponent: (c: ITooltipParams) => (
                    <div className={styles.tooltip}>{c.data.name}</div>
                ),
            },
            {
                field: "privateDomains",
                headerName: localization.getString("privateDomains"),
                cellRenderer: DomainCellRenderer,
                autoHeight: true,
            },
            {
                field: "privateCidrs",
                headerName: localization.getString("privateCIDRs"),
                cellRenderer: CidrCellRenderer,
                autoHeight: true,
            },
            {
                field: "actions",
                headerName: "",
                cellRenderer: (params: ICellRendererParams) => {
                    const connector: Network = params.data
                    return (
                        <Button
                            type="button"
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.DESTRUCTIVE}
                            aria-label="Remove"
                            icon={IconType.TRASH}
                            onClick={() => props.onRemoveConnector(connector)}
                            disabled={props.disabled}
                        />
                    )
                },
                type: "rightAligned",
            },
        ],
        [props.networkSetting, props.disabled]
    )

    return (
        <CollapsibleCard id={props.id} title={localization.getString("privateInfo")} isCardChild>
            <div className={styles.heading}>
                {localization.getString("connectorsOfTheGlobalEdge")}
            </div>
            <Grid
                onGridReady={(event: GridReadyEvent) => {
                    gridColumnApi.current = event.columnApi
                }}
                className={classNames(
                    { [styles.hiddenGrid]: props.networkSetting.networks.length < 1 },
                    [styles.connectorsTable]
                )}
                autoHeight
                rowData={props.networkSetting.networks}
                columnDefs={cols}
                rowHeight={60}
            />
        </CollapsibleCard>
    )
}
