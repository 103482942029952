import { PolicyRes, PolicySpecRes } from "../../api/Policy.api"

/**
 * Summary:
 * if spec.options does not exist, it is a tunnel policy
 * if l7_protocol is "http", it's a web policy
 * if spec.options exists AND there's l4 rules, then it's a tunnel policy
 * if spec.options exists AND there's no l4 rules, then it's an infra policy
 */
export function getPolicyType(res: PolicyRes): PolicyType {
    const spec = getPolicySpec(res)

    if (!spec.options) return PolicyType.TUNNEL

    if (spec.options.l7_protocol === "http") {
        return PolicyType.WEB
    }

    const hasL4AccessRule = JSON.stringify(spec.access).includes("l4_access")
    return hasL4AccessRule ? PolicyType.TUNNEL : PolicyType.INFRASTRUCTURE
}

function getPolicySpec(res: PolicyRes): PolicySpecRes {
    return JSON.parse(res.PolicySpec).spec
}

export enum PolicyType {
    WEB = "web",
    INFRASTRUCTURE = "infrastructure",
    TUNNEL = "tunnel",
    CUSTOM = "custom",
}

export enum PolicyStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
}
export type OtherPolicyTypes = PolicyType.CUSTOM | PolicyType.INFRASTRUCTURE | PolicyType.WEB
