import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import {
    ConfirmationModalWithoutRef,
    ModalApi,
} from "../../../components/modal/ConfirmationModal.component"
import { Status } from "../../../components/status/Status.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Column, Grid } from "../../../v3/components/grid/Grid.component"
import { SearchInput } from "../../../v3/components/search-input/SearchInput.component"
import {
    Status as AccessTierStatus,
    statusLabelKey,
    statusTypeKey,
} from "../../../v3/services/shared/AccessTier"
import {
    AccessTierDetails as AccessTier,
    AccessTierInstance as Instance,
    useDeleteNetAgent,
} from "../../../v3/services/AccessTier.service"
import { ErrorToast, SuccessToast, ToastApi } from "../../../components/toast/Toast.components"
import { toLocalizedList } from "../../../utils/String.utils"
import styles from "./AccessTierInstancesTab.module.scss"

interface Props {
    id: string
    accessTier: AccessTier
}

export function AccessTierInstancesTab(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const [search, setSearch] = React.useState("")

    const columns = useColumns(props.accessTier)

    return (
        <div id={props.id} className={styles.container}>
            <SearchInput
                className={styles.search}
                value={search}
                onChangeValue={setSearch}
                placeholder={localization.getString("search")}
            />
            <Grid
                data={props.accessTier.instances}
                columns={columns}
                getId={getName}
                filterAllColumnsValue={search}
                hasPagination
            />
        </div>
    )
}

enum Id {
    CONFIRMATION_MODAL = "confirmation-modal",
}

function useColumns(accessTier: AccessTier): Column<Instance>[] {
    const localization = useServiceLocalization()
    const locale = localization.getLocale()

    return React.useMemo(() => {
        const statusColumn: Column<Instance> = {
            id: "status",
            name: localization.getString("status"),
            cellRenderer: (instance) => (
                <Status
                    type={statusTypeKey[instance.status]}
                    label={localization.getString(statusLabelKey[instance.status])}
                />
            ),
            getTooltipValue: (instance) => localization.getString(statusLabelKey[instance.status]),
        }

        const instanceNameColumn: Column<Instance> = {
            id: "hostname",
            name: localization.getString("instanceName"),
            cellRenderer: "name",
            getTooltipValue: "name",
        }

        const operatingSystemColumn: Column<Instance> = {
            id: "os",
            name: localization.getString("os"),
            cellRenderer: getOperatingSystem,
            getTooltipValue: getOperatingSystem,
        }

        const ipAddressesColumn: Column<Instance> = {
            id: "ips",
            name: localization.getString("ipAddresses"),
            cellRenderer: (instance) =>
                toLocalizedList(instance.ipAddresses, locale, "conjunction"),
            getTooltipValue: (instance) =>
                toLocalizedList(instance.ipAddresses, locale, "conjunction"),
        }

        const versionColumn: Column<Instance> = {
            id: "version",
            name: localization.getString("version"),
            cellRenderer: "version",
            getTooltipValue: "version",
        }

        const actionColumn: Column<Instance> = {
            id: "action",
            name: "",
            cellRenderer: (instance) => <ActionCell accessTier={accessTier} instance={instance} />,
            getTooltipValue: () => "",
        }

        return [
            statusColumn,
            instanceNameColumn,
            operatingSystemColumn,
            ipAddressesColumn,
            versionColumn,
            actionColumn,
        ]
    }, [accessTier, localization, locale])
}

interface ActionCellProps {
    accessTier: AccessTier
    instance: Instance
}

function ActionCell(props: ActionCellProps): JSX.Element {
    const localization = useServiceLocalization()

    const successToastRef = React.useRef<ToastApi>(null)
    const errorToastRef = React.useRef<ToastApi>(null)
    const confirmationModalRef = React.useRef<ModalApi<void>>(null)

    const { mutateAsync: deleteNetAgent } = useDeleteNetAgent(props.accessTier, props.instance, {
        onSuccess: () =>
            successToastRef.current?.openToast(
                localization.getString(
                    "somethingHasBeenSuccessfullyDeleted",
                    localization.getString("instance"),
                    props.instance.name
                )
            ),
        onError: (error) =>
            typeof error === "string"
                ? errorToastRef.current?.openToast(error)
                : console.error(error),
    })

    const onDelete: React.MouseEventHandler = async (event) => {
        event.preventDefault()
        confirmationModalRef.current?.open()
    }

    const onConfirmDelete = async () => {
        await deleteNetAgent()
    }

    return props.instance.status === AccessTierStatus.TERMINATED ? (
        <React.Fragment>
            <Button
                asElement={ButtonElement.BUTTON}
                buttonType={ButtonType.SECONDARY}
                icon={IconType.TRASH}
                onClick={onDelete}
                aria-label={localization.getString("deleteSomething", props.instance.name)}
            />
            <SuccessToast ref={successToastRef} />
            <ErrorToast ref={errorToastRef} />
            <ConfirmationModal
                id={Id.CONFIRMATION_MODAL + props.instance.name}
                title={localization.getString("confirmAccessTierInstanceDeletion")}
                onConfirm={onConfirmDelete}
                confirmButtonLabel={localization.getString("delete")}
                isDestructive
                ref={confirmationModalRef}
            >
                {localization.getString("deleteAccessTierInstanceExplanation")}
            </ConfirmationModal>
        </React.Fragment>
    ) : (
        <React.Fragment />
    )
}

function getName(instance: Instance): string {
    return instance.name
}

function getOperatingSystem(instance: Instance): string {
    return instance.operatingSystem ?? "-"
}

const ConfirmationModal = React.forwardRef(ConfirmationModalWithoutRef)
