import classNames from "classnames/bind"
import React from "react"

import styles from "./InputWithAction.module.scss"
import { Icon } from "../icon/Icon.component"
import { type IconType } from "../button/Button.component"
import { Tooltip } from "../../v3/components/tooltip/Tooltip.component"
import { Input, InputProps } from "../input/Input.component"

export interface InputWithActionProps extends InputProps {
    onAction: React.MouseEventHandler<HTMLButtonElement>
    tooltip?: string
    className?: string
    icon: IconType
    actionClassName?: string
    inputClassName?: string
    actionDisabled?: boolean
    actionAriaLabel?: string
}

export const InputWithAction = React.forwardRef<HTMLInputElement, InputWithActionProps>(
    (props, ref) => {
        const {
            onAction,
            actionClassName,
            icon,
            className,
            tooltip = "",
            inputClassName,
            actionDisabled,
            actionAriaLabel,
            ...inputProps
        } = props

        return (
            <div className={classNames(styles.container, className)}>
                <Input
                    {...inputProps}
                    className={classNames(styles.input, inputClassName)}
                    ref={ref}
                />
                <Tooltip title={tooltip}>
                    <button
                        className={classNames(styles.action, actionClassName)}
                        disabled={actionDisabled || props.disabled}
                        type="button"
                        onClick={onAction}
                        aria-label={actionAriaLabel}
                    >
                        <Icon icon={icon} />
                    </button>
                </Tooltip>
            </div>
        )
    }
)
