import React from "react"
import { Route, Redirect, Switch } from "react-router-dom"

import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../routes"
import { ErrorBanner } from "../../v3/components/banner/Banner.component"
import { Loader } from "../../v3/components/loader/Loader.component"
import { ServiceTunnelList } from "./list/ServiceTunnelList.view"
import { ServiceTunnelAdd } from "./add/ServiceTunnelAdd.view"
import styles from "./ServiceTunnel.module.scss"
import { ServiceTunnelEdit } from "./edit/ServiceTunnelEdit.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import useTitle from "../../hooks/useTitle.hook"

export function ServiceTunnel(): JSX.Element {
    useTitle(["serviceTunnels", "privateAccess", "adminConsole"])
    const localization = useServiceLocalization()
    const { data: featureFlags, status: ffStatus, error: ffError } = useFeatureFlags()

    if (ffStatus === "loading") {
        return (
            <Loader
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("serviceTunnel")
                )}
                medium
                center
            />
        )
    }

    if (ffStatus === "error") {
        return <ErrorBanner className={styles.errorContainer} children={String(ffError)} />
    }

    const canEditServiceTunnel: boolean = featureFlags.adminConsole.canEditServiceTunnel
    const canAddServiceTunnel: boolean = featureFlags.adminConsole.canAddServiceTunnel

    const hideApplicationInServiceTunnnel: boolean =
        !featureFlags.adminConsole.canAccessApplicationInServiceTunnel

    return (
        <Switch>
            <Route exact path={ROUTE.SERVICE_TUNNELS}>
                <ServiceTunnelList
                    canAddServiceTunnel={canAddServiceTunnel}
                    enableATG={featureFlags?.adminConsole.enableAccessTierGroups}
                />
            </Route>
            {canAddServiceTunnel && (
                <Route exact path={ROUTE.SERVICE_TUNNELS_ADD}>
                    <ServiceTunnelAdd
                        enableATG={featureFlags.adminConsole.enableAccessTierGroups}
                        hideApplication={hideApplicationInServiceTunnnel}
                        hideAccessTier={
                            featureFlags.adminConsole.canAccessConnectorOnlyInServiceTunnel
                        }
                    />
                </Route>
            )}
            <Route exact path={ROUTE.SERVICE_TUNNELS_DETAILS}>
                <ServiceTunnelEdit
                    enableATG={featureFlags?.adminConsole.enableAccessTierGroups}
                    hideApplication={hideApplicationInServiceTunnnel}
                    hideAccessTier={featureFlags.adminConsole.canAccessConnectorOnlyInServiceTunnel}
                    canDeleteServiceTunnel={featureFlags.adminConsole.canDeleteServiceTunnel}
                    canEditServiceTunnel={canEditServiceTunnel}
                />
            </Route>
            {canEditServiceTunnel && (
                <Route exact path={ROUTE.SERVICE_TUNNELS_EDIT}>
                    <ServiceTunnelEdit
                        isEditMode
                        enableATG={featureFlags?.adminConsole.enableAccessTierGroups}
                        hideApplication={hideApplicationInServiceTunnnel}
                        hideAccessTier={
                            featureFlags.adminConsole.canAccessConnectorOnlyInServiceTunnel
                        }
                    />
                </Route>
            )}
            <Redirect to={ROUTE.SERVICE_TUNNELS} />
        </Switch>
    )
}
