import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { decodeID, encodeID } from "../../../../pre-v3/utils/Url.util"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { Loader } from "../../../components/loader/Loader.component"
import {
    useDeleteHostedService,
    useDisableService,
    useEnableService,
    useGetCloudResourceByServiceId,
    useGetHostedServiceById,
    useUpdateHostedService,
    WebService,
} from "../../../services/HostedService.service"
import { OverviewTopContainer } from "../../../components/overview/OverviewTopContainer/OverviewTopContainer.component"
import {
    labelMap,
    RegisteredServiceStatus,
    statusMap,
} from "../../../services/shared/RegisteredService"

import { ModalService } from "../../../../pre-v3/services"
import {
    ServiceTestConnection,
    useTestConnectionById,
} from "../../../services/ServiceTestConnection.service"
import { TabBar } from "../../../components/tab-bar/TabBar.component"
import { queryClient } from "../../../../queryClient"
import { Configuration } from "../configuration/Configuration.component"
import { AccessPermissions } from "../create/access-permissions/AccessPermissions.component"
import { Advanced } from "../create/advanced/Advanced.component"
import { ServiceAccessActivity } from "./AccessActivity.component"
import styles from "./HostedWebsiteOverview.module.scss"
import { ServiceCloneModal } from "./ServiceCloneModal.component"
import { ServiceTestConnectionModal } from "./ServiceTestConnectionModal.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { IconType } from "../../../../components/icon/Icon.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"
import { Link, LinkElement } from "../../../../components/link/Link.component"
import { PatternUtil } from "../../../../pre-v3/utils/Pattern.util"

interface Props {
    enableAccessTierGroups: boolean
    canDeleteWebService: boolean
    canDisableWebService: boolean
    canCloneWebService: boolean
    canAttachPolicy: boolean
    canEditWebService: boolean
    canAddRegisteredDomain?: boolean
}

export function HostedWebsiteOverview(props: Props) {
    const params = useParams<{ id: string }>()
    const localization = useServiceLocalization()
    const history = useHistory()
    const modalService = new ModalService()

    const hostedWebsiteLabel = localization.getString("hostedWebsite")

    const {
        data: hostedWebsite,
        isFetching: isHostedWebsiteLoading,
        refetch: refetchHostedWebsiteData,
        error: hostedWebsiteError,
    } = useGetHostedServiceById(decodeID(params.id), props.enableAccessTierGroups, {
        enabled: !!params.id,
    })

    const hostedWebsiteId: string = hostedWebsite?.id || ""
    const policyId: string = hostedWebsite?.policyAttachment?.policyId || ""
    const policyName: string = hostedWebsite?.policyAttachment?.policyName || ""
    const rawServiceSpec: string = hostedWebsite?.raw || ""

    // TODO - fix type casting (https://banyan-sec.atlassian.net/browse/BC-14587)
    const disableTestConnection = doPrivateDomainUseVariables(hostedWebsite as WebService)

    const tabs = Object.values(Tab)
    function onTabChange(tab: string): void {
        setCurrentTab(tab)
    }
    const [currentTab, setCurrentTab] = useState<string>(tabs[0])
    const [isEdit, setIsEdit] = useState<boolean>(false)

    const { mutateAsync: testConnection } = useTestConnectionById()
    const {
        mutateAsync: deleteService,
        isLoading: isDeleteServiceLoading,
        error: deleteServiceError,
    } = useDeleteHostedService()

    const enableService = useEnableService()
    const disableService = useDisableService()

    const {
        data: cloudResources,
        isFetching: isCloudResourceLoading,
        error: cloudResourceError,
    } = useGetCloudResourceByServiceId(hostedWebsiteId)

    function downloadServiceSpec() {
        const blobUrl = URL.createObjectURL(
            new Blob([`${JSON.stringify(JSON.parse(rawServiceSpec), null, 4)}\n`], {
                type: "application/json",
            })
        )
        const link: HTMLAnchorElement = document.createElement("a")
        link.download = `${hostedWebsite?.name}.json`
        link.href = blobUrl
        link.click()
        URL.revokeObjectURL(blobUrl)
    }

    function openServiceCloneModal() {
        modalService.open(localization.getString("cloneService"), {
            component: ServiceCloneModal,
            props: {
                service: hostedWebsite,
            },
            maxWidth: "lg",
        })
    }

    async function testServiceConnection() {
        let loading: boolean = false
        let data: ServiceTestConnection | undefined = undefined
        let error: string = ""
        const modal = modalService.open(localization.getString("testConnection"), {
            component: ServiceTestConnectionModal,
            props: {
                data: {},
                readOnly: true,
                isTestConnectionLoading: true,
            },
            maxWidth: "lg",
        })

        try {
            data = await testConnection(hostedWebsiteId)
            loading = false
        } catch (e) {
            error = e as string
            loading = false
        }

        modal.updateContent({ data, isTestConnectionLoading: loading, error: error })
    }

    const errors: React.ReactNode[] = [
        hostedWebsiteError,
        cloudResourceError,
        deleteServiceError,
        enableService.error,
        disableService.error,
    ]
    const isDataLoading: boolean =
        isHostedWebsiteLoading ||
        isDeleteServiceLoading ||
        enableService.isLoading ||
        disableService.isLoading

    function onDeleteService(): void {
        if (!hostedWebsite) {
            return
        }

        modalService
            .openConfirmation(
                localization.getString("deleteService"),
                localization.getString("deleteServiceDescription")
            )
            .onClose(async () => {
                await deleteService({ service: hostedWebsite, force: true })
                history.push(ROUTE.HOSTED_WEBSITES)
            })
    }

    function onEnableDisableService(): void {
        if (!hostedWebsite || !hostedWebsite.id) {
            return
        }

        const doDisable: boolean = hostedWebsite.enabled

        modalService
            .openConfirmation(
                localization.getString(doDisable ? "disableService" : "enableService"),
                localization.getString(
                    doDisable ? "disableServiceDescription" : "enableServiceDescription"
                )
            )
            .onClose(async () => {
                doDisable
                    ? disableService.mutate(hostedWebsite.id!)
                    : enableService.mutate(hostedWebsite.id!)
            })
    }

    const onRefresh: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        queryClient.removeQueries(["hostedService.getHostedServiceById", hostedWebsite?.id])
        refetchHostedWebsiteData()
    }

    function onAttachPolicy() {
        setCurrentTab(Tab.ACCESS_PERMISSIONS)
        setIsEdit(true)
    }

    function onCancel() {
        setIsEdit(false)
        queryClient.removeQueries(["hostedService.getHostedServiceById", hostedWebsite?.id])
        refetchHostedWebsiteData()
    }

    const {
        mutateAsync: onUpdateHostedWebsite,
        isLoading: isHostedWebsiteUpdating,
        error: hostedWebsiteUpdateError,
    } = useUpdateHostedService(props.enableAccessTierGroups)

    const updateError: string =
        typeof hostedWebsiteUpdateError === "string" ? hostedWebsiteUpdateError : ""

    async function onSave(updatedService: WebService) {
        const updatedData = await onUpdateHostedWebsite(updatedService as WebService)
        if (updatedData) {
            setIsEdit(false)
        }
    }

    return (
        <Loader
            title={localization.getString("loadingSomething", hostedWebsiteLabel)}
            isLoading={isDataLoading}
            center
            medium
        >
            <ErrorBanners className={styles.errors} errors={errors} />
            {hostedWebsite && (
                <section aria-labelledby={Id.HEADING} className={styles.container}>
                    <header className={styles.header} aria-labelledby={Id.HEADING}>
                        <PageHeading id={Id.HEADING}>{hostedWebsite.name}</PageHeading>
                        <div className={styles.actions}>
                            {props.canAttachPolicy && !policyName && (
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    onClick={onAttachPolicy}
                                    icon={IconType.PLUS}
                                >
                                    {localization.getString("attachAPolicy")}
                                </Button>
                            )}
                            {props.canDeleteWebService && (
                                <Tooltip title={localization.getString("deleteService")}>
                                    <Button
                                        icon={IconType.TRASH}
                                        onClick={onDeleteService}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        aria-label={localization.getString("deleteService")}
                                    />
                                </Tooltip>
                            )}
                            {props.canDeleteWebService &&
                                (hostedWebsite?.enabled ? (
                                    <Tooltip title={localization.getString("disableService")}>
                                        <Button
                                            icon={IconType.PAUSE_CIRCLE}
                                            onClick={onEnableDisableService}
                                            asElement={ButtonElement.BUTTON}
                                            buttonType={ButtonType.SECONDARY}
                                            aria-label={localization.getString("disableService")}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={localization.getString("enableService")}>
                                        <Button
                                            icon={IconType.PLAY_CIRCLE}
                                            onClick={onEnableDisableService}
                                            asElement={ButtonElement.BUTTON}
                                            buttonType={ButtonType.SECONDARY}
                                            aria-label={localization.getString("enableService")}
                                        />
                                    </Tooltip>
                                ))}
                            {props.canCloneWebService && (
                                <Tooltip title={localization.getString("cloneService")}>
                                    <Button
                                        icon={IconType.COPY}
                                        onClick={() => openServiceCloneModal()}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        aria-label={localization.getString("cloneService")}
                                    />
                                </Tooltip>
                            )}
                            {props.canEditWebService && (
                                <Tooltip title={localization.getString("edit")}>
                                    <Button
                                        icon={IconType.PEN}
                                        onClick={() => setIsEdit(true)}
                                        asElement={ButtonElement.BUTTON}
                                        buttonType={ButtonType.SECONDARY}
                                        aria-label={localization.getString("edit")}
                                        disabled={isEdit}
                                    />
                                </Tooltip>
                            )}
                            {(hostedWebsite as WebService)?.isServiceTestable
                                ? [
                                      <Tooltip
                                          title={localization.getString(
                                              disableTestConnection
                                                  ? "removeUserAttributeFromPrivateUrlToTestTheConnection"
                                                  : "testConnection"
                                          )}
                                      >
                                          <span>
                                              <Button
                                                  icon={IconType.CHECK}
                                                  onClick={testServiceConnection}
                                                  asElement={ButtonElement.BUTTON}
                                                  buttonType={ButtonType.SECONDARY}
                                                  aria-label={localization.getString(
                                                      "testConnection"
                                                  )}
                                                  disabled={disableTestConnection}
                                              />
                                          </span>
                                      </Tooltip>,
                                  ]
                                : []}
                            <Tooltip title={localization.getString("download")}>
                                <Button
                                    icon={IconType.DOWNLOAD}
                                    onClick={() => downloadServiceSpec()}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={localization.getString("download")}
                                />
                            </Tooltip>

                            <Tooltip title={localization.getString("refresh")}>
                                <Button
                                    icon={IconType.REDO}
                                    onClick={onRefresh}
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    aria-label={localization.getString("refresh")}
                                />
                            </Tooltip>
                        </div>
                    </header>
                    <OverviewTopContainer
                        statusType={statusMap[hostedWebsite?.status as RegisteredServiceStatus]}
                        statusLabel={localization.getString(
                            labelMap[hostedWebsite?.status as RegisteredServiceStatus]
                        )}
                        statusItems={[
                            {
                                label: localization.getString("policyAttached"),
                                value: policyName ? (
                                    <Link
                                        asElement={LinkElement.LINK}
                                        to={formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, {
                                            id: policyId,
                                        })}
                                    >
                                        {policyName}
                                    </Link>
                                ) : (
                                    <span>{`${localization.getString("none")}`}</span>
                                ),
                            },
                            {
                                label: localization.getString("attachedOn"),
                                value: DateUtil.format(hostedWebsite.policyAttachment?.attachedAt),
                            },
                        ]}
                        listItems={[
                            {
                                label: localization.getString("linkedCloudResource"),
                                value: (
                                    <Loader isLoading={isCloudResourceLoading}>
                                        {cloudResources && cloudResources.length > 0 ? (
                                            <Link
                                                asElement={LinkElement.LINK}
                                                to={formatRoutePath(ROUTE.IAAS_DISCOVERY_DETAILS, {
                                                    id: encodeID(cloudResources[0].cloudResourceId),
                                                })}
                                            >
                                                {cloudResources[0].resourceName}
                                            </Link>
                                        ) : (
                                            <>{" - "}</>
                                        )}
                                    </Loader>
                                ),
                            },
                            {
                                label: localization.getString("createdAt"),
                                value:
                                    hostedWebsite.createdAt &&
                                    DateUtil.format(hostedWebsite.createdAt),
                            },
                            {
                                label: localization.getString("createdBy"),
                                value: hostedWebsite.createdBy,
                            },
                            {
                                label: localization.getString("lastUpdated"),
                                value:
                                    hostedWebsite.updatedAt &&
                                    DateUtil.format(hostedWebsite.updatedAt),
                            },
                            {
                                label: localization.getString("lastUpdatedBy"),
                                value: hostedWebsite.updatedBy,
                            },
                        ]}
                    />
                    <TabBar
                        tabs={tabs.map((tab) => {
                            return {
                                label: tab,
                                id: tab,
                                ariaControls: `tab-panel-${tab}`,
                            }
                        })}
                        selectedTabId={currentTab}
                        onChange={onTabChange}
                    />
                    {currentTab === Tab.CONFIGURATION && (
                        <Configuration
                            initialValue={hostedWebsite as WebService}
                            disabled={!isEdit}
                            isEdit={isEdit}
                            onCancel={onCancel}
                            onSave={onSave}
                            error={updateError}
                            isDataLoading={isHostedWebsiteUpdating}
                            enableAccessTierGroups={props.enableAccessTierGroups}
                            canAddRegisteredDomain={props.canAddRegisteredDomain}
                        />
                    )}
                    {currentTab === Tab.ACCESS_PERMISSIONS && (
                        <AccessPermissions
                            initialValue={hostedWebsite as WebService}
                            disabled={!isEdit}
                            isEdit={isEdit}
                            onCancel={onCancel}
                            onSave={onSave}
                            error={updateError}
                            isDataLoading={isHostedWebsiteUpdating}
                        />
                    )}
                    {currentTab === Tab.ADVANCED && (
                        <Advanced
                            initialValue={hostedWebsite as WebService}
                            disabled={!isEdit}
                            isEdit={isEdit}
                            onCancel={onCancel}
                            onSave={onSave}
                            error={updateError}
                            isDataLoading={isHostedWebsiteUpdating}
                        />
                    )}
                    {currentTab === Tab.ACCESS_ACTIVITY && (
                        <ServiceAccessActivity serviceId={hostedWebsiteId} />
                    )}
                    <div className={styles.bottomPadding}></div>
                </section>
            )}
        </Loader>
    )
}

enum Id {
    HEADING = "heading",
}

enum Tab {
    CONFIGURATION = "Configuration",
    ACCESS_PERMISSIONS = "Access Permissions",
    ADVANCED = "Advanced",
    ACCESS_ACTIVITY = "Access Activity",
}

function doPrivateDomainUseVariables(hostedWebsite: WebService | undefined): boolean {
    if (!hostedWebsite) return false
    return PatternUtil.VARIABLE.test(hostedWebsite.backendDomain)
}
