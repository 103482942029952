import classNames from "classnames/bind"
import React, { useEffect, useState } from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { CollectionUtil } from "../../../pre-v3/utils/Collection.util"
import { Input } from "../input/Input.component"
import { IconType, InputWithAction } from "../input-with-action/InputWithAction.component"
import styles from "./MultiInput.module.scss"

type OmittedNewValueInputProps =
    | "aria-label"
    | "className"
    | "onChange"
    | "pattern"
    | "placeholder"
    | "required"
    | "type"
    | "value"
type NewValueInputProps = Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    OmittedNewValueInputProps
>

export interface PatternProps {
    /**
     * The pattern that input needs to follow to consider valid
     */
    pattern: string
    /**
     * Message shown to the user when input doesn't conform to the pattern
     */
    errorMessage: string
}

export interface Props {
    /**
     * Values to show in the read only inputs.
     */
    values?: string[]
    /**
     * Maximum number of values
     */
    max?: number
    /**
     * Ensure that a valid values list has at least one value
     */
    required?: boolean
    /**
     * Ensure that an input follows a pattern
     */
    patternProps?: PatternProps
    /**
     * A11y label describing the input.
     */
    label?: string
    /**
     * Extra optional props for the New Value Input Field
     */
    newValueInputProps?: NewValueInputProps
    /**
     * Callback called when the list of values changes.
     * @param updatedValues Updated values
     */
    onChange?: (updatedValues: string[]) => void

    disabled?: boolean
    placeholder?: string
    className?: string
    caseInsensitiveDuplicateMatch: boolean
}

/**
 * Input for a list of string values
 */
export function MultiInput(props: Props): JSX.Element {
    const [values, setValues] = useState<string[]>()
    const isMax = typeof props.max === "number" && props.max <= (props.values?.length ?? 0)
    const shouldShowNewValue = !props.disabled && !isMax

    useEffect(() => {
        // Delay subject list render to prevent onClick blocking when is triggered at the same time that the onBlur
        // Fixing: https://banyan-sec.atlassian.net/browse/BC-9470
        setTimeout(() => setValues(props.values), 100)
    }, [props.values])

    return (
        <div className={classNames(styles.container, props.className)}>
            {values?.map((value: string, index: number): JSX.Element => {
                return <PreviousValueInput {...props} key={value} value={value} index={index} />
            })}
            {props.disabled && !CollectionUtil.isTruthy(props.values) && <Input disabled />}
            {shouldShowNewValue && <NewValueInput {...props} />}
        </div>
    )
}

// Sub Components

interface PreviousValueInputProps extends Props {
    index: number
    value: string
}

function PreviousValueInput(props: PreviousValueInputProps): JSX.Element {
    const { index, label, onChange, value, values = [] } = props

    const localization = useServiceLocalization()

    const onRemove: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        onChange?.(CollectionUtil.removeValueByIndex(values, index))
    }

    return props.disabled ? (
        <Input value={value} disabled />
    ) : (
        <InputWithAction
            value={value}
            onAction={onRemove}
            icon={IconType.MINUS}
            aria-label={label && localization.getOrdinalString("somethingLast", index + 1, label)}
            actionAriaLabel={localization.getString("removeValue", value)}
            disabled
        />
    )
}

function NewValueInput(props: Props): JSX.Element {
    const {
        label,
        newValueInputProps,
        onChange,
        patternProps,
        placeholder,
        required,
        values = [],
        caseInsensitiveDuplicateMatch,
    } = props

    const localization = useServiceLocalization()

    const textInput = React.useRef<HTMLInputElement>(null)

    const [newValue, setNewValue] = React.useState("")
    const [validationMessage, setValidationMessage] = React.useState<string>("")
    const trimmedValue = newValue.trim()
    const isNewValueEmpty = trimmedValue.length <= 0

    useEffect(() => {
        let updatedValidationMessage: string = ""
        const isFirstInput: boolean = values.length <= 0

        if (required && isFirstInput && isNewValueEmpty) {
            // Required is only for first input
            updatedValidationMessage =
                newValueInputProps?.title || localization.getString("pleaseFillOutThisField")
        }

        const hasDuplicateValue: boolean = values.some((value) => {
            if (caseInsensitiveDuplicateMatch)
                return value.toLowerCase() === trimmedValue.toLowerCase()
            return value === trimmedValue
        })

        if (hasDuplicateValue) {
            updatedValidationMessage = localization.getString("valuesMustBeUnique")
        }

        if (textInput.current?.validity.patternMismatch && patternProps) {
            updatedValidationMessage = patternProps.errorMessage
        }

        textInput.current?.setCustomValidity(updatedValidationMessage)
        setValidationMessage(updatedValidationMessage)
    }, [isNewValueEmpty, localization, patternProps, required, trimmedValue, values])

    const onNewValueChange = () => {
        if (isNewValueEmpty || validationMessage) return

        onChange?.(CollectionUtil.addValue(values, trimmedValue))
        setNewValue("")
    }

    const onNewValueChangeByBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        onNewValueChange()
    }

    const onNewValueChangeByEnter: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === "Enter") {
            event.preventDefault()
            if (validationMessage) {
                textInput.current?.reportValidity()
            } else {
                onNewValueChange()
            }
        }
    }

    const onValueChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        event.preventDefault()
        textInput.current?.setCustomValidity("")
        setNewValue(event.target.value)
    }
    const onAddNewInput: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        if (validationMessage) {
            textInput.current?.reportValidity()
        }
    }

    const newValueLabel = label && localization.getString("newSomething", label)

    return (
        <InputWithAction
            {...newValueInputProps}
            value={newValue}
            icon={IconType.PLUS}
            actionDisabled={isNewValueEmpty || !onChange}
            onAction={onAddNewInput}
            onBlur={onNewValueChangeByBlur}
            onChange={onValueChange}
            onKeyDown={onNewValueChangeByEnter}
            aria-label={newValueLabel}
            actionAriaLabel={localization.getString("addNewValue")}
            pattern={patternProps?.pattern}
            placeholder={placeholder}
            ref={textInput}
        />
    )
}
