import { ApiResponse, BaseApi, MessageRes, PaginationOptions } from "./Base.api"
import { Singleton } from "../decorators/Singleton.decorator"

@Singleton("InfraApi")
export class InfraApi extends BaseApi {
    public getShieldConfig(): Promise<ApiResponse<ShieldConfig>> {
        return this.get("/api/experimental/v2/shield_config")
    }

    public getShieldActivityForCluster(clusterName: string, count: number): Promise<Activity[]> {
        const searchParams: URLSearchParams = new URLSearchParams({
            shieldname: clusterName,
            num: count.toString(),
        })
        return this.get("/api/v1/shield_activity?" + searchParams.toString())
    }

    public deleteCluster(clusterName: string): Promise<MessageRes> {
        return this.delete(
            "/api/v1/delete_shield_cluster?ClusterName=" + encodeURIComponent(clusterName)
        )
    }

    public getAccessTiers(params: AccessTiersParams): Promise<ApiResponse<AccessTierListRes>> {
        const urlParams: URLSearchParams = new URLSearchParams(params as any)
        return this.get("/api/experimental/v2/access_tier?" + urlParams.toString())
    }

    public getConnectors(search: ConnectorSearch): Promise<ApiResponse<ConnectorsListRes>> {
        const urlParams: URLSearchParams = new URLSearchParams(search as any)
        return this.get("/api/experimental/v2/satellite?" + urlParams.toString())
    }
}

export interface ShieldConfig {
    Configs: Shield[]
    ShieldLastActivitiesMap: { [key: string]: ShieldActivity }
}

export interface Shield {
    ShieldName: string
    ShieldVersion: string
    UUID: string
    CACert: string
    SSHCAPublicKey: string
    ClusterMgrIP: string
    ClusterMgrType: string
    GroupType: ClusterGroupType
    OneTimeKey: string
    OTKEnabled: string
    OTKExpiryTime: string
    PublicAddr: string
    OrgID: string
}

export enum ClusterGroupType {
    EDGE = "EDGE",
}

export interface ShieldActivity {
    InsertTime: string
    Status: string
}

export interface Activity {
    InsertTime: string
    Status: string
    UUID: string
}

export interface NetAgent {
    Hostname: string
    IPs: string[]
    Version: string
    Visibility: boolean
    CIDRs: string
    HostTags: StringMap
    Uname: string
    SiteName: string
    ClusterID: string
    Status: NetAgentStatus
}

export enum NetAgentStatus {
    REPORTING = "Reporting",
    INACTIVE = "Inactive",
    TERMINATED = "Terminated",
    PENDING = "Pending",
}

export interface ConnectorsListRes {
    count: number
    satellites: ConnectorRes[]
}

export interface ConnectorRes {
    id: string
    org_id: string
    name: string
    display_name: string
    access_tiers: ConnectorAccessTierRes[]
    cidrs: string[]
    domains: string[]
    tunnel_ip_address: string
    keepalive: number
    wireguard_public_key: string
    created_at: number
    created_by: string
    updated_at: number
    updated_by: string
    api_key_id: string
    connector_version: string
    host_info: HostInfo
    spec: string
}

interface ConnectorAccessTierRes {
    satellite_tunnel_peer_id: string
    access_tier_id: string
    access_tier_name: string
    allowed_ips: string
    endpoint: string
    healthy: boolean
    wireguard_public_key: string
}

export interface ConnectorReq {
    kind: "BanyanConnector"
    api_version: "rbac.banyanops.com/v1"
    type: "attribute-based"
    metadata: ConnectorMetadataReq
    spec: ConnectorSpecReq
}

interface ConnectorMetadataReq {
    name: string
    display_name: string
}

export interface ConnectorSpecReq {
    api_key_id: string
    keepalive: number
    cidrs: string[]
    domains: string[]
    peer_access_tiers: ConnectorClusterReq[]
    disable_snat: boolean
}

export interface ConnectorClusterReq {
    cluster: string
    access_tiers: string[]
    connectors?: string[]
}

export interface HostInfo {
    name: string
    ip_addresses: string[]
}

export interface AccessTierListRes {
    count: number
    access_tiers: AccessTierRes[]
}

export interface AccessTierRes {
    id: string
    name: string
    cluster_name: string
    address: string
    status: AccessTierStatusRes
    domains?: string[]
    netagents: NetAgent[]
    tunnel_satellite?: TunnelInfraRes
    tunnel_enduser?: TunnelInfraRes
    created_at: number
    created_by: string
    updated_at: number
    updated_by: string
}

export type AccessTierStatusRes = "Pending" | "Reporting" | "Inactive" | "Terminated"

export interface AccessTiersParams extends PaginationOptions {
    name?: string
    cluster_name?: string
    status?: string
    address?: string
}

export interface TunnelInfraRes {
    id: string
    org_id: string
    access_tier_id: string
    tunnel_peer_type: string
    dns_search_domains: string
    udp_port_number: number
    tunnel_ip_address: string
    wireguard_public_key: string
    dns_enabled: boolean
    keepalive: number
    created_at: number
    updated_at: number
    cidrs: string[]
    domains: string[]
}

export interface ConnectorSearch extends PaginationOptions {
    display_name?: string
}
