export { type FileSize }

export function getFileSizeFromBytes(bytes: number): FileSize {
    return new FileSize(bytes)
}

export enum FormattedUnitLength {
    SHORT = "SHORT",
    LONG = "LONG",
}

class FileSize {
    private convertedSize: ConvertedSize

    constructor(sizeInBytes: number) {
        this.convertedSize = convertSize(sizeInBytes)
    }

    public toFormattedString(
        locale: string,
        formattedUnitLength: FormattedUnitLength = FormattedUnitLength.SHORT
    ): string {
        const numberFormat = new Intl.NumberFormat(locale, {
            style: "unit",
            unit: this.convertedSize.unit,
            unitDisplay: formattedUnitLengthDict[formattedUnitLength],
        })

        return numberFormat.format(this.convertedSize.value)
    }
}

enum Unit {
    BYTE = "byte",
    KILOBYTE = "kilobyte",
    MEGABYTE = "megabyte",
    GIGABYTE = "gigabyte",
}

interface ConvertedSize {
    value: number
    unit: Unit
}

function convertSize(sizeInBytes: number): ConvertedSize {
    if (sizeInBytes < 1_000) {
        return { value: sizeInBytes, unit: Unit.BYTE }
    }

    if (sizeInBytes < 1_000_000) {
        return { value: sizeInBytes / 1_000, unit: Unit.KILOBYTE }
    }

    if (sizeInBytes < 1_000_000_000) {
        return { value: sizeInBytes / 1_000_000, unit: Unit.MEGABYTE }
    }

    return { value: sizeInBytes / 1_000_000_000, unit: Unit.GIGABYTE }
}

const formattedUnitLengthDict: Record<
    FormattedUnitLength,
    Intl.NumberFormatOptions["unitDisplay"]
> = {
    [FormattedUnitLength.SHORT]: "short",
    [FormattedUnitLength.LONG]: "long",
}
