import React from "react"
import { PageHeading } from "../../components/page-heading/PageHeading.component"
import { Tooltip } from "../../v3/components/tooltip/Tooltip.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../components/button/Button.component"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import styles from "./OrgCustomization.module.scss"
import { useGetCustomization } from "../../v3/services/Org.service"
import { Customization } from "../../v3/views/customization/Customization.view"
import { Loader } from "../../v3/components/loader/Loader.component"
import { Banner, Variant } from "../../components/banner/Banner.component"

interface Props {
    canUpdateCustomization: boolean
}

export function OrgCustomization(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const {
        data: customization,
        status: customizationStatus,
        error: customizationError,
        refetch: refetchCustomization,
    } = useGetCustomization()

    if (customizationStatus === "loading") {
        return (
            <Loader
                center
                medium
                title={localization.getString(
                    "loadingSomething",
                    localization.getString("customization")
                )}
            />
        )
    }

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <PageHeading>
                    {localization.getString("brandingAndMessageCustomization")}
                </PageHeading>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={() => refetchCustomization()}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={localization.getString("refresh")}
                    />
                </Tooltip>
            </header>

            {customizationStatus === "error" ? (
                <Banner label={String(customizationError)} variant={Variant.ERROR} />
            ) : (
                <Customization
                    canUpdateCustomization={props.canUpdateCustomization}
                    customization={customization}
                    refetchCustomization={refetchCustomization}
                />
            )}
        </div>
    )
}
