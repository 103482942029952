import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { ITooltipParams, ValueGetterParams } from "ag-grid-community"
import classNames from "classnames/bind"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { useAlphaSort } from "../../../../hooks/useAlphaSort.hook"
import {
    Grid,
    ColDef,
    ICellRendererParams,
} from "../../../../pre-v3/components/grid/Grid.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil from "../../../../pre-v3/utils/AgGrid.util"
import { FormRow } from "../../../components/form/FormRow.component"
import { MenuButton } from "../../../components/menu/menu-button/MenuButton.component"
import { MenuItemProps } from "../../../components/menu/menu-item/MenuItem.component"
import { SearchableMenu } from "../../../components/menu/searchable-menu/SearchableMenu.component"
import { AccessTier } from "../../../services/AccessTier.service"
import { AccessTierGroup, Cluster } from "../../../services/Cluster.service"
import { Connector } from "../../../services/Connector.service"
import { HostedServiceInfra, NetworkType } from "../../../services/HostedService.service"
import { RegisteredDomain } from "../../../services/RegisteredDomain.service"
import styles from "./HostedServiceInfraSelect.module.scss"

export interface HostedServiceInfraSelectProps {
    initialValue?: HostedServiceInfra[]
    clusters: Cluster[]
    registeredDomains: RegisteredDomain[]
    disabled?: boolean
    className?: string
    onChange?(selectedInfra: HostedServiceInfra[]): void
    required?: boolean
    initialNetworkType: NetworkType | undefined
}

export function HostedServiceInfraSelect(props: HostedServiceInfraSelectProps): JSX.Element {
    const localization = useServiceLocalization()

    const [selected, setSelected] = React.useState<HostedServiceInfra[]>(props.initialValue ?? [])

    const onUpdateSelected = (updatedSelected: HostedServiceInfra[]) => {
        setSelected(updatedSelected)
        props.onChange?.(updatedSelected)
    }

    const onSelectInfra = (infra: HostedServiceInfra) => {
        const updatedSelected = updateSelectedInfra(selected, infra)
        onUpdateSelected(updatedSelected)
    }

    const nameColumn: ColDef<HostedServiceInfra> = {
        field: "name",
        headerName: localization.getString("networks"),
        tooltipComponent: (c: ITooltipParams) => (
            <div className={styles.tooltip}>{c.data.name}</div>
        ),
    }

    const registeredDomainColumn: ColDef<HostedServiceInfra> = {
        field: "registeredDomains",
        headerName: localization.getString("registeredDomains"),
        flex: 200,
        valueGetter: (params: ValueGetterParams<HostedServiceInfra>): string[] =>
            params.data
                ? getRegisteredDomainNamesFromInfra(params.data, props.registeredDomains)
                : [],
        valueFormatter: AgGridUtil.stringArrayFormatter,
    }

    const actionsColumn: ColDef<HostedServiceInfra> = {
        field: "actions",
        headerName: "",
        cellRenderer: (params: ICellRendererParams<HostedServiceInfra>) =>
            props.disabled ? (
                <React.Fragment />
            ) : (
                <Button
                    asElement={ButtonElement.BUTTON}
                    buttonType={ButtonType.SECONDARY}
                    icon={IconType.TRASH}
                    onClick={() =>
                        onUpdateSelected(selected.filter(({ id }) => id !== params.data?.id))
                    }
                />
            ),
        cellClass: styles.flex,
    }

    const columns = [nameColumn, registeredDomainColumn, actionsColumn]

    const menuItems = useMenuItems(
        props.clusters,
        selected,
        onSelectInfra,
        props.initialNetworkType
    )

    return (
        <div className={classNames(styles.container, props.className)}>
            <FormRow
                label={localization.getString("whatPrivateNetworkIsYourServiceLocatedOn")}
                description={localization.getString(
                    "theseAreTheNetworksThatWillProtectThisHostedWebsiteYouMaySelectOneOrMore"
                )}
            >
                <MenuButton
                    label={localization.getString("addNetwork")}
                    icon={faPlus}
                    disabled={props.disabled}
                >
                    <SearchableMenu items={menuItems} />
                </MenuButton>
                {!selected.length && (
                    <input
                        className={styles.fakeInput}
                        value={""}
                        required={props.required}
                        onChange={() => {}}
                    />
                )}
            </FormRow>
            <Grid
                rowData={selected}
                columnDefs={columns}
                autoHeight
                className={classNames(styles.grid, { [styles.hide]: selected.length <= 0 })}
            />
        </div>
    )
}

function useMenuItems(
    clusters: Cluster[],
    selectedNetworks: HostedServiceInfra[],
    onSelectInfra: (infra: HostedServiceInfra) => void,
    initialNetworkType?: NetworkType
): MenuItemProps[] {
    const localization = useServiceLocalization()
    const accessTiersLabel = localization.getString("accessTiers")
    const connectorsLabel = localization.getString("connectors")
    const accessTierGroupLabel = localization.getString("accessTierGroups")
    const alphaSort = useAlphaSort({ ignoreCase: true })

    return React.useMemo((): MenuItemProps[] => {
        const { accessTierMenuItems, connectorMenuItems, accessTierGroupMenuItems } =
            separateMenuItems(clusters, selectedNetworks, onSelectInfra)

        switch (initialNetworkType) {
            case "accessTier":
            case "accessTierGroup":
                return buildPrivateNetworksItems(
                    accessTierMenuItems,
                    accessTierGroupMenuItems,
                    {
                        accessTier: accessTiersLabel,
                        accessTierGroup: connectorsLabel,
                    },
                    alphaSort
                )
            case "connector":
                return buildGlobalNetworksItems(connectorMenuItems, {
                    connector: connectorsLabel,
                })
            default:
                return buildAllNetworksItems(
                    accessTierMenuItems,
                    connectorMenuItems,
                    accessTierGroupMenuItems,
                    {
                        accessTier: accessTiersLabel,
                        connectors: connectorsLabel,
                        accessTierGroups: accessTierGroupLabel,
                    },
                    alphaSort
                )
        }
    }, [
        clusters,
        selectedNetworks,
        onSelectInfra,
        accessTiersLabel,
        connectorsLabel,
        accessTierGroupLabel,
    ])
}

interface SeparateMenuItemsOutput {
    accessTierMenuItems: MenuItemProps[]
    connectorMenuItems: MenuItemProps[]
    accessTierGroupMenuItems: MenuItemProps[]
}

const emptyOutput: SeparateMenuItemsOutput = {
    accessTierMenuItems: [],
    connectorMenuItems: [],
    accessTierGroupMenuItems: [],
}

function separateMenuItems(
    clusters: Cluster[],
    selectedNetwork: HostedServiceInfra[],
    onSelectNetwork: (infra: HostedServiceInfra) => void
): SeparateMenuItemsOutput {
    return clusters.reduce<SeparateMenuItemsOutput>((acc, cluster) => {
        const { accessTiers = [], connectors = [], accessTierGroups = [], isEdge = false } = cluster
        return {
            accessTierMenuItems: accessTiers.reduce((acc, accessTier) => {
                return shouldAddAccessTierMenuItem(accessTier, selectedNetwork, isEdge)
                    ? [...acc, mapAccessTierMenuItem(accessTier, onSelectNetwork)]
                    : acc
            }, acc.accessTierMenuItems),
            connectorMenuItems: connectors.reduce((acc, connector) => {
                return selectedNetwork.length <= 0
                    ? [...acc, mapConnectorMenuItem(connector, onSelectNetwork)]
                    : acc
            }, acc.connectorMenuItems),
            accessTierGroupMenuItems: accessTierGroups.reduce((acc, accessTierGroup) => {
                const doesIncludeAccessTier = selectedNetwork.some(
                    ({ id }) => id === accessTierGroup.id
                )
                const [firstInfra] = selectedNetwork
                const hasSelectedAccessTier = firstInfra?.type === "accessTier"
                return !doesIncludeAccessTier && !hasSelectedAccessTier
                    ? [...acc, mapAccessTierGroupMenuItem(accessTierGroup, onSelectNetwork)]
                    : acc
            }, acc.accessTierGroupMenuItems),
        }
    }, emptyOutput)
}

function mapAccessTierMenuItem(
    { id = "", name, clusterName }: AccessTier,
    onSelectNetwork: (infra: HostedServiceInfra) => void
): MenuItemProps {
    const infra: HostedServiceInfra = {
        id,
        name,
        networkIds: [id],
        clusterName,
        type: NetworkType.ACCESS_TIER,
    }

    return {
        label: name,
        onClick: () => onSelectNetwork(infra),
    }
}

function mapAccessTierGroupMenuItem(
    { id = "", name, clusterName }: AccessTierGroup,
    onSelectNetwork: (infra: HostedServiceInfra) => void
): MenuItemProps {
    const infra: HostedServiceInfra = {
        id,
        name,
        networkIds: [id],
        clusterName,
        type: NetworkType.ACCESS_TIER_GROUP,
    }

    return {
        label: name,
        onClick: () => onSelectNetwork(infra),
    }
}

function mapConnectorMenuItem(
    { id = "", name, accessTierIds, clusterName }: Connector,
    onSelectNetwork: (infra: HostedServiceInfra) => void
): MenuItemProps {
    const infra: HostedServiceInfra = {
        id,
        name,
        networkIds: accessTierIds,
        clusterName,
        type: NetworkType.CONNECTOR,
    }

    return {
        label: name,
        onClick: () => onSelectNetwork(infra),
    }
}

function getRegisteredDomainNamesFromInfra(
    network: HostedServiceInfra,
    registeredDomains: RegisteredDomain[]
): string[] {
    return registeredDomains.reduce<string[]>(
        (acc, registeredDomain) =>
            registeredDomain.networkId && network.networkIds?.includes(registeredDomain.networkId)
                ? [...acc, registeredDomain.name]
                : acc,
        []
    )
}

function updateSelectedInfra(
    alreadySelectedNetwork: HostedServiceInfra[],
    newlySelectedNetwork: HostedServiceInfra
) {
    switch (newlySelectedNetwork.type) {
        case NetworkType.ACCESS_TIER: {
            return [...alreadySelectedNetwork, newlySelectedNetwork]
        }
        case NetworkType.CONNECTOR: {
            return [newlySelectedNetwork]
        }
        case NetworkType.ACCESS_TIER_GROUP: {
            return [newlySelectedNetwork]
        }
    }
}

function buildPrivateNetworksItems(
    accessTierMenuItems: MenuItemProps[],
    accessTierGroupMenuItems: MenuItemProps[],
    localization: { accessTier: string; accessTierGroup: string },
    alphaSort: (a: string, b: string) => number
) {
    const accessTierHeaderItem: MenuItemProps = { type: "divider", label: localization.accessTier }
    const accessTierGroupHeaderItem: MenuItemProps = {
        type: "divider",
        label: localization.accessTierGroup,
    }

    return [
        ...(accessTierMenuItems.length > 0
            ? [
                  accessTierHeaderItem,
                  ...accessTierMenuItems.sort((a, b) => alphaSort(a.label!, b.label!)),
              ]
            : []),
        ...(accessTierGroupMenuItems.length > 0
            ? [
                  accessTierGroupHeaderItem,
                  ...accessTierGroupMenuItems.sort((a, b) => alphaSort(a.label!, b.label!)),
              ]
            : []),
    ]
}

function buildGlobalNetworksItems(
    connectorMenuItems: MenuItemProps[],
    localization: { connector: string }
) {
    const connectorHeaderItem: MenuItemProps = { type: "divider", label: localization.connector }

    return [...(connectorMenuItems.length > 0 ? [connectorHeaderItem, ...connectorMenuItems] : [])]
}

function buildAllNetworksItems(
    accessTierMenuItems: MenuItemProps[],
    connectorMenuItems: MenuItemProps[],
    accessTierGroupMenuItems: MenuItemProps[],
    localization: { accessTier: string; connectors: string; accessTierGroups: string },
    alphaSort: (a: string, b: string) => number
) {
    const accessTierHeaderItem: MenuItemProps = { type: "divider", label: localization.accessTier }
    const connectorHeaderItem: MenuItemProps = { type: "divider", label: localization.connectors }
    const accessTierGroupHeaderItem: MenuItemProps = {
        type: "divider",
        label: localization.accessTierGroups,
    }

    return [
        ...(accessTierMenuItems.length > 0
            ? [
                  accessTierHeaderItem,
                  ...accessTierMenuItems.sort((a, b) => alphaSort(a.label!, b.label!)),
              ]
            : []),
        ...(connectorMenuItems.length > 0 ? [connectorHeaderItem, ...connectorMenuItems] : []),
        ...(accessTierGroupMenuItems.length > 0
            ? [
                  accessTierGroupHeaderItem,
                  ...accessTierGroupMenuItems.sort((a, b) => alphaSort(a.label!, b.label!)),
              ]
            : []),
    ]
}

const shouldAddAccessTierMenuItem = (
    accessTier: AccessTier,
    selectedNetwork: HostedServiceInfra[],
    isEdge: boolean
) => {
    const [firstNetwork] = selectedNetwork
    const hasSelectedConnector = firstNetwork?.type === "connector"
    const hasSelectedATGroup = firstNetwork?.type === "accessTierGroup"
    const doesIncludeAccessTier = selectedNetwork.some(({ id }) => id === accessTier.id)
    const hasOtherEdge = firstNetwork && firstNetwork.clusterName !== accessTier.clusterName

    return (
        !hasSelectedConnector &&
        !hasSelectedATGroup &&
        !doesIncludeAccessTier &&
        !hasOtherEdge &&
        !isEdge
    )
}
