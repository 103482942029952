import {
    QueryKey,
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { Paginated, PaginatedSearch } from "../../pre-v3/utils/AgGrid.util"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import { PatternUtil } from "../../pre-v3/utils/Pattern.util"
import { convertFromServerTimestamp } from "../../utils/Date.utils"
import { StatusType } from "../../utils/StatusType.utils"
import {
    AccessTierApi,
    AccessTierSearch,
    AccessTierRes,
    AdvancedConfigBody,
    StatusRes,
    AccessTierSpecJson,
    TunnelReq,
    EdgeTypeRes,
    DeploymentMethodRes,
    AccessTierBody,
    HostTags,
    AccessTierServicesRes,
    AccessTierServiceType,
} from "../api/AccessTier.api"
import { AccessTierFacingApi, AdvancedConfigRes } from "../api/AccessTierFacing.api"
import { AccessTierGroupApi, AccessTierGroupRes } from "../api/AccessTierGroup.api"
import { ApiKeyApi, ApiKeyRes, ApiKeyScope } from "../api/ApiKey.api"
import { BanyanSecurityApi } from "../api/BanyanSecurity.api"
import { ClusterApi } from "../api/Cluster.api"
import { StatusType as StatusTypeV1 } from "../components/status/Status.component"
import {
    AdvancedSettings,
    AdvancedSettingsStatus,
    NetworkSettings,
    defaultUdpPortNumber,
    getNetagentVersions,
    getTunnelEnduserReq,
    statusFromResDict,
} from "./shared/AccessTier"
import { getAdvancedSettingsFromRes } from "./shared/AccessTierGroup"
import { Cluster, mapClusterRes } from "./shared/Cluster"
import { ApiFunction } from "./shared/QueryKey"

@Singleton("AccessTierService")
export class AccessTierService {
    public async getAccessTiers(
        enableAccessTierGroups: boolean,
        params: PaginatedSearch = { skip: 0, limit: 1000 }
    ): Promise<Paginated<AccessTier>> {
        const search: Partial<AccessTierSearch> = {
            skip: params.skip,
            limit: params?.limit,
        }
        if (params?.filterModel?.name) {
            search.name = params.filterModel.name.filter
        }
        if (params?.filterModel?.clusterName) {
            search.cluster_name = params.filterModel.clusterName.filter
        }

        if (params?.filterModel?.address) {
            search.address = params.filterModel.address.filter
        }

        if (params?.filterModel?.status) {
            search.status = params.filterModel.status.filter as StatusRes
        }

        if (params.sortModel && params.sortModel.length > 0) {
            search.order = params.sortModel[0].sort
            search.order_by = params.sortModel[0].colId
        }

        const response = await this.accessTierApi.getAccessTiers(search)

        if (enableAccessTierGroups)
            return {
                total: response.count,
                data: response.access_tiers.map(mapAccessTier),
            }

        const filteredAccessTiers = response.access_tiers.filter(isNotAttachedToAGroup)
        const difference = response.access_tiers.length - filteredAccessTiers.length

        return {
            total: response.count - difference,
            data: filteredAccessTiers.map(mapAccessTier),
        }
    }

    private accessTierApi: AccessTierApi = new AccessTierApi()
}

const serviceName = "AccessTierService"

const useGetAccessTiersKey = [ApiFunction.GET_ACCESS_TIERS, serviceName]
function getAccessTierByIdKey(id: string): QueryKey {
    return [ApiFunction.GET_ACCESS_TIER_BY_ID, id, serviceName]
}

export function useGetAccessTiers(
    enableAccessTierGroups: boolean,
    options?: QueryOptions<Paginated<AccessTier>>
) {
    const accessTierService = new AccessTierService()

    return useQuery<Paginated<AccessTier>, string>({
        ...options,
        queryKey: useGetAccessTiersKey,
        queryFn: () => accessTierService.getAccessTiers(enableAccessTierGroups),
    })
}

export function useGetAccessTierById(
    id: string,
    enableAccessTierGroups: boolean,
    options?: QueryOptions<AccessTierDetails, unknown>
): UseQueryResult<AccessTierDetails> {
    const localization = useServiceLocalization()

    const accessTierApi = new AccessTierApi()
    const accessTierFacingApi = new AccessTierFacingApi()
    const accessTierGroupApi = new AccessTierGroupApi()
    const apiKeyApi = new ApiKeyApi()
    const clusterApi = new ClusterApi()

    return useQuery<AccessTierDetails>({
        ...options,
        queryKey: getAccessTierByIdKey(id),
        queryFn: async (): Promise<AccessTierDetails> => {
            const [accessTierRes, { Configs }] = await Promise.all([
                accessTierApi.getAccessTierById(id),
                clusterApi.getClusters(),
            ])

            if (!enableAccessTierGroups && accessTierRes.access_tier_group_id) {
                return Promise.reject(
                    localization.getString(
                        "somethingNotFound",
                        localization.getString("accessTier")
                    )
                )
            }

            const clusterRes = Configs?.find(
                (cluster) => cluster.ShieldName === accessTierRes.cluster_name
            )

            if (!clusterRes) {
                return Promise.reject(
                    localization.getString("somethingNotFound", localization.getString("cluster"))
                )
            }

            const [apiKeyRes, accessTierGroupRes, advancedConfigRes] = await Promise.all([
                apiKeyApi.getApiKey(accessTierRes.api_key_id),
                accessTierRes.access_tier_group_id
                    ? accessTierGroupApi.getAccessTierGroupById(accessTierRes.access_tier_group_id)
                    : undefined,
                accessTierFacingApi.getAccessTierAdvancedConfig(accessTierRes.name),
            ])

            return mapAccessTierDetails(
                accessTierRes,
                mapClusterRes(clusterRes),
                apiKeyRes,
                accessTierGroupRes && mapAccessTierGroupFromRes(accessTierGroupRes),
                advancedConfigRes
            )
        },
    })
}

export function useCreateOrUpdateAccessTier(
    privateEdgeCluster: Cluster,
    originalAccessTier?: AccessTierDetails,
    options?: QueryOptions<AccessTierDetails, unknown, CreateAccessTierVariables>
) {
    const queryClient = useQueryClient()

    const accessTierApi = new AccessTierApi()
    const accessTierFacingApi = new AccessTierFacingApi()
    const accessTierGroupApi = new AccessTierGroupApi()
    const apiKeyApi = new ApiKeyApi()

    return useMutation<AccessTierDetails, unknown, CreateAccessTierVariables>({
        ...options,
        mutationFn: async (variables) => {
            const accessTierBody = getCreateAccessTierToBody(variables, privateEdgeCluster)
            const accessTierRes = await (originalAccessTier
                ? accessTierApi.updateAccessTier(originalAccessTier.id, accessTierBody)
                : accessTierApi.createAccessTier(accessTierBody))

            const [apiKeyRes, advancedConfigRes] = await Promise.all([
                apiKeyApi.getApiKey(variables.apiKey.id),
                accessTierFacingApi.getAccessTierAdvancedConfig(accessTierRes.name),
                attachAccessTierToGroup(
                    accessTierGroupApi,
                    accessTierRes.id,
                    variables,
                    originalAccessTier
                ),
            ])

            return mapAccessTierDetails(
                accessTierRes,
                privateEdgeCluster,
                apiKeyRes,
                variables.accessTierGroup,
                advancedConfigRes
            )
        },
        onSuccess: (accessTier) => {
            queryClient.removeQueries([ApiFunction.GET_ACCESS_TIERS])
            queryClient.setQueryData<AccessTierDetails>(
                getAccessTierByIdKey(accessTier.id),
                accessTier
            )
            options?.onSuccess?.(accessTier)
        },
    })
}

export function useUpdateAccessTier(
    accessTier: AccessTierDetails,
    options?: QueryOptions<AccessTierDetails, unknown, AccessTierDetails>
): UseMutationResult<AccessTierDetails, unknown, AccessTierDetails> {
    const accessTierApi = new AccessTierApi()
    const accessTierFacingApi = new AccessTierFacingApi()
    const accessTierGroupApi = new AccessTierGroupApi()
    const apiKeyApi = new ApiKeyApi()
    const queryClient = useQueryClient()

    return useMutation<AccessTierDetails, unknown, AccessTierDetails>({
        ...options,
        mutationFn: async (updatedAccessTier) => {
            const accessTierRes = await accessTierApi.updateAccessTier(
                accessTier.id,
                getUpdateAccessTierToBody(updatedAccessTier)
            )

            const [apiKeyRes, advancedConfigRes] = await Promise.all([
                apiKeyApi.getApiKey(accessTierRes.api_key_id),
                accessTier.advancedSettings === updatedAccessTier.advancedSettings
                    ? accessTierFacingApi.getAccessTierAdvancedConfig(accessTierRes.name)
                    : accessTierApi.updateAccessTierAdvancedConfig(
                          accessTier.id,
                          getAdvancedConfigBody(updatedAccessTier)
                      ),
                attachAccessTierToGroup(
                    accessTierGroupApi,
                    accessTierRes.id,
                    updatedAccessTier,
                    accessTier
                ),
            ])

            return mapAccessTierDetails(
                accessTierRes,
                updatedAccessTier.cluster,
                apiKeyRes,
                updatedAccessTier.accessTierGroup,
                advancedConfigRes
            )
        },
        onSuccess: (editedAccessTier) => {
            queryClient.removeQueries([ApiFunction.GET_ACCESS_TIERS])
            queryClient.setQueryData<AccessTierDetails>(
                getAccessTierByIdKey(editedAccessTier.id),
                editedAccessTier
            )
            options?.onSuccess?.(editedAccessTier)
        },
    })
}

export function useTestConnection(
    accessTier: AccessTierDetails,
    options?: QueryOptions<ConnectionResult, unknown>
) {
    const accessTierApi = new AccessTierApi()

    return useMutation<ConnectionResult>({
        ...options,
        mutationFn: async () => {
            try {
                await accessTierApi.testConnection(accessTier.id)
                return ConnectionResult.SUCCESS
            } catch (error) {
                switch (error) {
                    case AccessTierApi.RESOLUTION_ERROR:
                        return ConnectionResult.RESOLUTION_ERROR

                    case AccessTierApi.REACHABILITY_ERROR:
                        return ConnectionResult.REACHABILITY_ERROR
                }

                throw error
            }
        },
    })
}

export function useSendAccessTierLogs(
    accessTier: AccessTierDetails,
    options?: QueryOptions<void, unknown>
) {
    const accessTierApi = new AccessTierApi()

    return useMutation({
        ...options,
        mutationFn: async () => {
            await accessTierApi.sendAccessTierLogs({
                ClusterName: accessTier.cluster.name,
                SiteName: accessTier.name,
            })
        },
    })
}

export function useDeleteAccessTier(options?: QueryOptions<void, unknown, AccessTierDetails>) {
    const queryClient = useQueryClient()

    const accessTierApi = new AccessTierApi()

    return useMutation<void, unknown, AccessTierDetails>({
        ...options,
        mutationFn: async (accessTier) => {
            await accessTierApi.deleteAccessTier(accessTier.id)
        },
        onSuccess: (_data, accessTier) => {
            queryClient.removeQueries([ApiFunction.GET_ACCESS_TIERS])
            queryClient.removeQueries([ApiFunction.GET_ACCESS_TIER_BY_ID, accessTier.id])
            options?.onSuccess?.()
        },
    })
}

export function useDeleteNetAgent(
    accessTier: AccessTierDetails,
    instance: AccessTierInstance,
    options?: QueryOptions<void, unknown>
) {
    const accessTierApi = new AccessTierApi()

    const queryClient = useQueryClient()

    return useMutation({
        ...options,
        mutationFn: () =>
            accessTierApi.deleteNetagent({
                CLUSTERNAME: accessTier.cluster.name,
                HOSTNAME: instance.name,
            }),
        onSuccess: () => {
            queryClient.setQueryData<AccessTierDetails>(
                getAccessTierByIdKey(accessTier.id),
                (oldAccessTier) =>
                    oldAccessTier && {
                        ...oldAccessTier,
                        instances: oldAccessTier.instances.filter(
                            (oldInstance) => oldInstance.name !== instance.name
                        ),
                    }
            )
            options?.onSuccess?.()
        },
    })
}

export function useGetAccessTierStatus(
    accessTier: AccessTierDetails,
    options?: QueryOptions<Status, unknown>
): UseQueryResult<Status> {
    const accessTierApi = new AccessTierApi()
    return useQuery<Status>({
        ...options,
        queryKey: [ApiFunction.GET_ACCESS_TIER_STATUS_BY_ID, accessTier.id, serviceName],
        queryFn: async (): Promise<Status> => {
            const accessTierRes = await accessTierApi.getAccessTierById(accessTier.id)
            return statusFromResDict[accessTierRes.status]
        },
    })
}

export function useGetLatestNetagentVersion(
    options?: QueryOptions<string>
): UseQueryResult<string> {
    const banyanSecurityApi = new BanyanSecurityApi()

    return useQuery({
        ...options,
        queryKey: ["accessTierService.getLatestNetAgentVersion"],
        queryFn: async () => {
            const { latest_versions } = await banyanSecurityApi.getMetadata()
            return latest_versions.netagent
        },
    })
}

export function useGetApiKeys(options?: QueryOptions<ApiKey[], unknown>): UseQueryResult<ApiKey[]> {
    const apiKeyApi = new ApiKeyApi()

    return useQuery({
        ...options,
        queryKey: [ApiFunction.GET_API_KEYS, ApiKeyScope.ACCESS_TIER, serviceName],
        queryFn: () => apiKeyApi.getApiKeys({ scope: ApiKeyScope.ACCESS_TIER }),
    })
}

export function useGetAccessTiersGroups(
    options?: QueryOptions<AccessTierGroup[], unknown>
): UseQueryResult<AccessTierGroup[]> {
    const accessTierGroupApi = new AccessTierGroupApi()

    return useQuery({
        ...options,
        queryKey: [ApiFunction.GET_ACCESS_TIER_GROUPS, serviceName],
        queryFn: async () => {
            const { access_tier_groups } = await accessTierGroupApi.getAccessTierGroups()

            return access_tier_groups.map(mapAccessTierGroupFromRes)
        },
    })
}

export function useGetServicesByAccessTierCount(accessTier?: AccessTierDetails) {
    const accessTierApi = new AccessTierApi()

    return useQuery({
        enabled: !!accessTier,
        queryKey: [
            ApiFunction.GET_SERVICES_BY_ACCESS_TIER,
            accessTier?.id,
            "getServicesByAccessTiersCount",
            serviceName,
        ],
        queryFn: async () => {
            if (!accessTier) return 0
            const servicesResByAccessTier = await accessTierApi.getAccessTierServices(
                accessTier.id,
                { skip: 0, limit: 1 }
            )

            return servicesResByAccessTier.count
        },
    })
}

interface UseGetServicesByAccessTierResult {
    getServicesByAccessTier(search: PaginatedSearch): Promise<Paginated<Service>>
    clearCache(): Promise<void>
}

export function useGetServicesByAccessTier(
    accessTier: AccessTierDetails
): UseGetServicesByAccessTierResult {
    const accessTierApi = new AccessTierApi()

    const client = useQueryClient()

    return {
        getServicesByAccessTier: (search) =>
            client.ensureQueryData({
                queryKey: [
                    ApiFunction.GET_SERVICES_BY_ACCESS_TIER,
                    accessTier.id,
                    search,
                    serviceName,
                ],
                queryFn: async (): Promise<Paginated<Service>> => {
                    const servicesResByAccessTier = await accessTierApi.getAccessTierServices(
                        accessTier.id,
                        search
                    )

                    return {
                        data: servicesResByAccessTier.services.map((serviceRes) =>
                            mapServiceFromRes(serviceRes)
                        ),
                        total: servicesResByAccessTier.count,
                    }
                },
            }),
        clearCache: () =>
            client.resetQueries([ApiFunction.GET_SERVICES_BY_ACCESS_TIER, accessTier.id]),
    }
}

// Types

export interface CreateAccessTierVariables {
    name: string
    description?: string
    apiKey: ApiKey
    udpPortNumber: number
    accessTierGroup?: AccessTierGroup
    networkSettings?: NetworkSettings
    isSecure: boolean
}

export interface ApiKey {
    id: string
    name: string
    secret: string
}

export interface AccessTierDetails extends CreateAccessTierVariables {
    id: string
    status: Status
    publicAddress?: string
    edgeType: EdgeType
    createdAt: Date
    createdBy: string
    lastUpdatedAt: Date
    lastUpdatedBy: string
    installationMethod: InstallationMethod
    cluster: Cluster
    instances: AccessTierInstance[]
    advancedSettings?: AdvancedSettings
    isSecure: boolean
    extra: {
        accessTierRes: AccessTierRes
        advancedConfigRes: AdvancedConfigRes
    }
}

export enum Status {
    PENDING = "PENDING",
    REPORTING = "REPORTING",
    INACTIVE = "INACTIVE",
    TERMINATED = "TERMINATED",
}

export const statusTypeDict: Record<Status, StatusType> = {
    [Status.REPORTING]: StatusType.SUCCESS,
    [Status.INACTIVE]: StatusType.WARNING,
    [Status.TERMINATED]: StatusType.ERROR,
    [Status.PENDING]: StatusType.DISABLED,
}

export const statusLabelDict: Record<Status, LanguageKey> = {
    [Status.REPORTING]: "reporting",
    [Status.INACTIVE]: "inactive",
    [Status.TERMINATED]: "terminated",
    [Status.PENDING]: "pending",
}

export enum InstallationMethod {
    DOCKER_CONTAINER = "DOCKER_CONTAINER",
    TARBALL_INSTALLER = "TARBALL_INSTALLER",
    DEBIAN_PACKAGE = "DEBIAN_PACKAGE",
    CLOUD_FORMATION = "CLOUD_FORMATION",
}

export interface AccessTierInstance {
    name: string
    status: Status
    version: string
    operatingSystem?: string
    ipAddresses: string[]
}

export interface AccessTierGroup {
    id: string
    name: string
    cidrRanges: string[]
    privateDomains: string[]
    advancedSettings?: AdvancedSettings
}

export enum ConnectionResult {
    SUCCESS = "SUCCESS",
    RESOLUTION_ERROR = "RESOLUTION_ERROR",
    REACHABILITY_ERROR = "REACHABILITY_ERROR",
}

export enum EdgeType {
    GLOBAL_EDGE = "GLOBAL_EDGE",
    PRIVATE_EDGE = "PRIVATE_EDGE",
}

export interface Service {
    id: string
    name: string
    type: ServiceType
    lastUpdatedAt: Date
}

export enum ServiceType {
    HOSTED_WEBSITE = "HOSTED_WEBSITE",
    SSH = "SSH",
    RDP = "RDP",
    KUBERNETES = "KUBERNETES",
    DATABASE = "DATABASE",
    GENERIC_TCP = "GENERIC",
    SERVICE_TUNNEL = "SERVICE_TUNNEL",
}

// Helper Functions

const installationMethodFromResDict: Record<DeploymentMethodRes, InstallationMethod> = {
    docker: InstallationMethod.DOCKER_CONTAINER,
    tarball: InstallationMethod.TARBALL_INSTALLER,
    cloudformation: InstallationMethod.CLOUD_FORMATION,
    "linux-package": InstallationMethod.DEBIAN_PACKAGE,
}

const installationMethodToResDict: Record<InstallationMethod, DeploymentMethodRes> = {
    [InstallationMethod.DOCKER_CONTAINER]: "docker",
    [InstallationMethod.TARBALL_INSTALLER]: "tarball",
    [InstallationMethod.CLOUD_FORMATION]: "cloudformation",
    [InstallationMethod.DEBIAN_PACKAGE]: "linux-package",
}

export const edgeTypeFromResDict: Record<EdgeTypeRes, EdgeType> = {
    private_edge: EdgeType.PRIVATE_EDGE,
    global_edge: EdgeType.GLOBAL_EDGE,
}

function mapAccessTier(accessTierRes: AccessTierRes): AccessTier {
    let parsedData: AccessTierSpecJson | undefined

    try {
        parsedData = JSON.parse(accessTierRes.spec)
    } catch {
        //ignore unparsable data
    }

    const tunnel_enduser_res = parsedData?.spec?.tunnel_enduser || accessTierRes?.tunnel_enduser
    const tunnel_satellite_res =
        parsedData?.spec?.tunnel_satellite || accessTierRes?.tunnel_satellite

    const accessTier: AccessTier = {
        id: accessTierRes.id || "",
        name: parsedData ? parsedData.metadata.name : accessTierRes.name,
        status: statusFromResDict[accessTierRes.status],
        apiKeyId: parsedData ? parsedData.spec.api_key_id : accessTierRes.api_key_id,
        clusterName: parsedData ? parsedData.spec.cluster_name : accessTierRes.cluster_name,
        deploymentMethod: parsedData?.spec?.deployment_method
            ? installationMethodFromResDict[parsedData.spec.deployment_method]
            : InstallationMethod.DOCKER_CONTAINER,
        description: parsedData ? parsedData.spec.description : "",
        tunnelEndUser: tunnel_enduser_res && mapTunnelReqToTunnelRes(tunnel_enduser_res),
        tunnelSatellite: tunnel_satellite_res && mapTunnelReqToTunnelRes(tunnel_satellite_res),
        createdAt: DateUtil.convertLargeTimestamp(accessTierRes.created_at),
        createdBy: accessTierRes.created_by,
        updatedAt: DateUtil.convertLargeTimestamp(accessTierRes.updated_at),
        updatedBy: accessTierRes.updated_by,
        instanceCount: accessTierRes.netagents?.length || 0,
        address: accessTierRes.address,
        versions: getNetagentVersions(accessTierRes),
        edgeType: edgeTypeFromResDict[accessTierRes.edge_type as EdgeTypeRes],
    }

    return accessTier
}

function mapTunnelReqToTunnelRes(tunnel: TunnelReq): TunnelRes {
    return {
        udpPortNumber: tunnel.udp_port_number,
        keepalive: tunnel.keepalive || 20, //verify
        domains: tunnel.domains || [],
        cidrs: tunnel.cidrs || [],
        sharedFqdn: tunnel.shared_fqdn,
    }
}

function getCreateAccessTierToBody(
    accessTier: CreateAccessTierVariables,
    privateEdgeCluster: Cluster,
    accessTierRes?: AccessTierRes
): AccessTierBody {
    return {
        kind: "BanyanAccessTier",
        api_version: "rbac.banyanops.com/v1",
        type: "attribute-based",
        metadata: {
            name: accessTier.name,
        },
        spec: {
            address: accessTierRes?.address ?? "",
            domains: accessTierRes?.domains ?? [],
            api_key_id: accessTier.apiKey.id,
            deployment_method: accessTierRes?.deployment_method ?? "docker",
            description: accessTier.description ?? "",
            cluster_name: privateEdgeCluster.name,
            tunnel_satellite: accessTierRes?.tunnel_satellite,
            tunnel_enduser:
                accessTier.accessTierGroup || !accessTier.isSecure
                    ? undefined
                    : getTunnelEnduserReq(
                          accessTier.udpPortNumber,
                          accessTier.networkSettings,
                          accessTierRes?.tunnel_enduser
                      ),
        },
    }
}

function getUpdateAccessTierToBody(accessTier: AccessTierDetails): AccessTierBody {
    const base = getCreateAccessTierToBody(
        accessTier,
        accessTier.cluster,
        accessTier.extra?.accessTierRes
    )

    return {
        ...base,
        spec: {
            ...base.spec,
            address: accessTier.publicAddress ?? "",
            deployment_method: installationMethodToResDict[accessTier.installationMethod],
        },
    }
}

function mapAccessTierDetails(
    accessTierRes: AccessTierRes,
    cluster: Cluster,
    apiKey: ApiKeyRes,
    accessTierGroup: AccessTierGroup | undefined,
    advancedConfigRes: AdvancedConfigRes
): AccessTierDetails {
    return {
        id: accessTierRes.id,
        name: accessTierRes.name,
        status: statusFromResDict[accessTierRes.status],
        description: accessTierRes.description || undefined,
        publicAddress: accessTierRes.address || undefined,
        edgeType: edgeTypeFromResDict[accessTierRes.edge_type],
        createdAt: convertFromServerTimestamp(accessTierRes.created_at),
        createdBy: accessTierRes.created_by,
        lastUpdatedAt: convertFromServerTimestamp(accessTierRes.updated_at),
        lastUpdatedBy: accessTierRes.updated_by,
        installationMethod:
            (accessTierRes.deployment_method &&
                installationMethodFromResDict[accessTierRes.deployment_method]) ??
            InstallationMethod.DOCKER_CONTAINER,
        udpPortNumber: accessTierRes.tunnel_enduser?.udp_port_number ?? defaultUdpPortNumber,
        cluster,
        instances: accessTierRes.netagents.map<AccessTierInstance>((netagentRes) => ({
            name: netagentRes.Hostname,
            status: statusFromResDict[netagentRes.Status],
            version: netagentRes.Version,
            operatingSystem: getOperatingSystem(netagentRes.HostTags),
            ipAddresses: netagentRes.IPs,
        })),
        apiKey,
        accessTierGroup,
        networkSettings: {
            cidrs: accessTierRes.tunnel_enduser?.cidrs,
            domains: accessTierRes.tunnel_enduser?.domains,
        },
        advancedSettings: {
            metricsCollectionAddress: advancedConfigRes.logging?.statsd_address,
            eventsRateLimiting: getAdvancedSettingsStatus(
                advancedConfigRes.events?.access_event_credits_limiting
            ),
            eventKeyRateLimiting: getAdvancedSettingsStatus(
                advancedConfigRes.events?.access_event_key_limiting
            ),
            forwardTrustCookie: getAdvancedSettingsStatus(
                advancedConfigRes.hosted_web_services?.forward_trust_cookie
            ),
            enableStrictTransport: getAdvancedSettingsStatus(
                typeof advancedConfigRes.hosted_web_services?.disable_hsts === "boolean"
                    ? !advancedConfigRes.hosted_web_services.disable_hsts
                    : undefined
            ),
            enablePrivateResourceDiscovery: getAdvancedSettingsStatus(
                advancedConfigRes.service_discovery?.service_discovery_enable
            ),
        },
        isSecure: !!accessTierRes.tunnel_enduser,
        extra: { accessTierRes, advancedConfigRes },
    }
}

function getOperatingSystem(hostTags: HostTags): string | undefined {
    return hostTags["com.banyanops.hosttag.os1"]?.split(",").reduce(reduceHostTag, undefined)
}

function reduceHostTag(operatingSystem: string | undefined, token: string): string | undefined {
    if (operatingSystem) return operatingSystem
    if (!token.includes("=")) return token

    const [key, value] = token.split("=")
    if (key !== "PRETTY_NAME" && key !== "DISTRIB_DESCRIPTION") return

    return value.replace(PatternUtil.DOUBLE_QUOTES, "")
}

function getAdvancedSettingsStatus(value?: boolean): AdvancedSettingsStatus {
    switch (value) {
        case true:
            return AdvancedSettingsStatus.ENABLED
        case false:
            return AdvancedSettingsStatus.DISABLED
        case undefined:
            return AdvancedSettingsStatus.DEFAULT
    }
}

function getAdvancedConfigBody(accessTier: AccessTierDetails): AdvancedConfigBody {
    const enableServiceDiscovery = getAdvancedSettingsStatusBodyBoolean(
        accessTier.advancedSettings?.enablePrivateResourceDiscovery
    )

    return {
        kind: "BanyanAccessTierLocalConfig",
        api_version: "rbac.banyanops.com/v1",
        type: "attribute-based",
        metadata: {},
        spec: {
            ...accessTier.extra.advancedConfigRes,
            logging: {
                ...accessTier.extra.advancedConfigRes.logging,
                statsd: getAdvancedSettingsStatusBodyBoolean(
                    accessTier.advancedSettings?.metricsCollectionAddress
                        ? AdvancedSettingsStatus.ENABLED
                        : AdvancedSettingsStatus.DEFAULT
                ),
                statsd_address: accessTier.advancedSettings?.metricsCollectionAddress,
            },
            events: {
                ...accessTier.extra.advancedConfigRes.events,
                access_event_credits_limiting: getAdvancedSettingsStatusBodyBoolean(
                    accessTier.advancedSettings?.eventsRateLimiting
                ),
                access_event_key_limiting: getAdvancedSettingsStatusBodyBoolean(
                    accessTier.advancedSettings?.eventKeyRateLimiting
                ),
            },
            hosted_web_services: {
                ...accessTier.extra.advancedConfigRes.hosted_web_services,
                forward_trust_cookie: getAdvancedSettingsStatusBodyBoolean(
                    accessTier.advancedSettings?.forwardTrustCookie
                ),
                disable_hsts: getDisableHstsBodyBoolean(
                    accessTier.advancedSettings?.enableStrictTransport
                ),
            },
            service_discovery:
                enableServiceDiscovery === undefined
                    ? undefined
                    : {
                          service_discovery_enable: enableServiceDiscovery,
                          service_discovery_msg_limit:
                              accessTier.extra.advancedConfigRes.service_discovery
                                  ?.service_discovery_msg_limit ?? 100,
                          service_discovery_msg_timeout:
                              accessTier.extra.advancedConfigRes.service_discovery
                                  ?.service_discovery_msg_timeout ?? 10_000_000_000,
                      },
            miscellaneous:
                enableServiceDiscovery === undefined
                    ? undefined
                    : {
                          enable_ipv6_resolution:
                              accessTier.extra.advancedConfigRes.miscellaneous
                                  ?.enable_ipv6_resolution ?? true,
                      },
            spec: undefined,
        },
    }
}

function getAdvancedSettingsStatusBodyBoolean(
    status?: AdvancedSettingsStatus
): boolean | undefined {
    switch (status) {
        case AdvancedSettingsStatus.ENABLED:
            return true
        case AdvancedSettingsStatus.DISABLED:
            return false
        case AdvancedSettingsStatus.DEFAULT:
        case undefined:
            return undefined
    }
}

function getDisableHstsBodyBoolean(
    enableStrictTransport?: AdvancedSettingsStatus
): boolean | undefined {
    switch (enableStrictTransport) {
        case AdvancedSettingsStatus.ENABLED:
            return false
        case AdvancedSettingsStatus.DISABLED:
            return true
        case AdvancedSettingsStatus.DEFAULT:
        case undefined:
            return undefined
    }
}

function mapAccessTierGroupFromRes(accessTierGroupRes: AccessTierGroupRes): AccessTierGroup {
    return {
        id: accessTierGroupRes.id,
        name: accessTierGroupRes.name,
        cidrRanges: accessTierGroupRes.tunnel_enduser.cidrs ?? [],
        privateDomains: accessTierGroupRes.tunnel_enduser.domains ?? [],
        advancedSettings: getAdvancedSettingsFromRes(accessTierGroupRes),
    }
}

async function attachAccessTierToGroup(
    accessTierGroupApi: AccessTierGroupApi,
    accessTierId: string,
    updatedAccessTier: CreateAccessTierVariables,
    originalAccessTier?: CreateAccessTierVariables
): Promise<void> {
    if (originalAccessTier?.accessTierGroup?.id === updatedAccessTier.accessTierGroup?.id) return

    if (originalAccessTier?.accessTierGroup) {
        accessTierGroupApi.detachAccessTierToGroup(originalAccessTier.accessTierGroup.id, {
            access_tier_ids: [accessTierId],
        })
    }

    if (updatedAccessTier.accessTierGroup) {
        accessTierGroupApi.attachAccessTierToGroup(updatedAccessTier.accessTierGroup.id, {
            access_tier_ids: [accessTierId],
        })
    }
}

function isNotAttachedToAGroup(accessTierRes: AccessTierRes): boolean {
    return !accessTierRes.access_tier_group_id
}

function mapServiceFromRes(serviceRes: AccessTierServicesRes): Service {
    return {
        id: serviceRes.service_id,
        name: serviceRes.service_name,
        type: getServiceTypeFromRes(serviceRes.service_type),
        lastUpdatedAt: convertFromServerTimestamp(serviceRes.updated_at),
    }
}

function getServiceTypeFromRes(serviceAppTypeRes?: AccessTierServiceType): ServiceType {
    switch (serviceAppTypeRes) {
        case "WEB":
            return ServiceType.HOSTED_WEBSITE
        case "SSH":
            return ServiceType.SSH
        case "RDP":
            return ServiceType.RDP
        case "K8S":
            return ServiceType.KUBERNETES
        case "DATABASE":
            return ServiceType.DATABASE
        case "service_tunnel":
            return ServiceType.SERVICE_TUNNEL
        default:
            return ServiceType.GENERIC_TCP
    }
}

export function getConnectivityParameters(
    apiSecretKey?: string,
    accessTierName?: string,
    url?: string
): string {
    return [
        `export COMMAND_CENTER_URL=${url || window.location.origin}`,
        `export API_KEY_SECRET=${apiSecretKey}`,
        `export ACCESS_TIER_NAME='${accessTierName}'`,
    ].join("\n")
}

export function getConfigYamlParameters(
    apiSecretKey?: string,
    accessTierName?: string,
    url?: string
): string {
    return [
        `echo "command_center_url: ${url || window.location.origin}`,
        `api_key_secret: ${apiSecretKey}`,
        `access_tier_name: '${accessTierName}'" > config.yaml`,
    ].join("\n")
}

export interface AccessTier {
    id?: string
    name: string
    status: Status
    clusterName: string
    address?: string
    apiKeyId: string
    createdAt?: number
    createdBy?: string
    description?: string
    updatedAt?: number
    updatedBy?: string
    deploymentMethod?: InstallationMethod
    instanceCount?: number
    tunnelSatellite?: TunnelRes
    tunnelEndUser?: TunnelRes
    domains?: string[]
    versions?: string[]
    edgeType?: EdgeType
}

export interface TunnelRes {
    udpPortNumber: number
    keepalive?: number
    cidrs?: string[]
    domains?: string[]
    dnsSearchDomains?: string
    sharedFqdn?: string
}

export enum AccessTierStatus {
    PENDING = "Pending",
    REPORTING = "Reporting",
    INACTIVE = "Inactive",
    TERMINATED = "Terminated",
}

export const statusMap: Record<AccessTierStatus, StatusTypeV1> = {
    Reporting: "success",
    Inactive: "warning",
    Terminated: "error",
    Pending: "disabled",
}

export const labelMap: Record<AccessTierStatus, LanguageKey> = {
    Reporting: "reporting",
    Inactive: "inactive",
    Terminated: "terminated",
    Pending: "pending",
}
