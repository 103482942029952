import { IconName } from "@fortawesome/fontawesome-common-types"

import { BaseApi, MessageRes } from "./Base.api"
import { Singleton } from "../decorators/Singleton.decorator"

@Singleton("ManageApi")
export class ManageApi extends BaseApi {
    public getRegisteredServices(): Promise<RegisteredService[]> {
        return this.get("/api/v1/registered_services")
    }

    public getRegisteredService(serviceId: string): Promise<RegisteredService[]> {
        return this.get("/api/v1/registered_services?ServiceID=" + encodeURIComponent(serviceId))
    }

    public getExternalService(): Promise<RegisteredService[]> {
        return this.get(
            "/api/v1/registered_services?ServiceID=externalservice&defaultservices=true"
        )
    }

    public createRegisteredService(spec: ServiceSpec): Promise<RegisteredService> {
        return this.post("/api/v1/insert_registered_service", spec)
    }

    public attachPolicyToService(
        policyId: string,
        serviceId: string,
        enabled: boolean
    ): Promise<ServiceToPolicy> {
        return this.postForm("/api/v1/insert_security_attach_policy", {
            PolicyID: policyId,
            ServiceID: serviceId,
            Enabled: enabled ? "TRUE" : "FALSE",
        })
    }

    public detachPolicyFromService(policyId: string, serviceId: string): Promise<MessageRes> {
        return this.delete(
            "/api/v1/delete_security_attach_policy?PolicyID=" +
                encodeURIComponent(policyId) +
                "&ServiceID=" +
                encodeURIComponent(serviceId)
        )
    }

    public enableRegisteredService(serviceId: string): Promise<MessageRes> {
        return this.post(
            "/api/v1/enable_registered_service?ServiceID=" + encodeURIComponent(serviceId),
            {}
        )
    }

    public disableRegisteredService(serviceId: string): Promise<MessageRes> {
        return this.post(
            "/api/v1/disable_registered_service?ServiceID=" + encodeURIComponent(serviceId),
            {}
        )
    }

    public deleteRegisteredService(serviceId: string): Promise<MessageRes> {
        return this.delete(
            "/api/v1/delete_registered_service?ServiceID=" + encodeURIComponent(serviceId)
        )
    }

    public getSaasApps(): Promise<SaasService[]> {
        return this.get("/api/v1/saasapp")
    }

    public getSaasApp(id: string): Promise<SaasService[]> {
        return this.get("/api/v1/saasapp?ID=" + encodeURIComponent(id))
    }

    public createSaasApp(spec: SaasSpec): Promise<SaasService> {
        return this.post("/api/v1/insert_saasapp", spec)
    }

    public deleteSaasApp(id: string): Promise<boolean> {
        return this.delete("/api/v1/delete_saasapp?ID=" + encodeURIComponent(id))
    }

    public enableSaasApp(id: string): Promise<boolean> {
        return this.post("/api/v1/enable_saasapp?ID=" + encodeURIComponent(id), {})
    }

    public disableSaasApp(id: string): Promise<boolean> {
        return this.post("/api/v1/disable_saasapp?ID=" + encodeURIComponent(id), {})
    }

    public attachSaasToPolicy(saasId: string, policyId: string, enabled: boolean): Promise<void> {
        return this.put("/api/v1/policy/" + encodeURIComponent(policyId) + "/attach", {
            attached_to_id: saasId,
            attached_to_type: "saasapp",
            enabled: enabled ? "TRUE" : "FALSE",
        })
    }

    public detachSaasToPolicy(saasId: string, policyId: string): Promise<void> {
        return this.put("/api/v1/policy/" + encodeURIComponent(policyId) + "/detach", {
            attached_to_id: saasId,
            attached_to_type: "saasapp",
        })
    }

    public testServiceConnection(
        payload: ServiceConnectionTestReq
    ): Promise<ServiceConnectionStatusRes> {
        return this.post("/api/v1/service_connection_test", payload)
    }

    public getPolicies(
        policyId?: string,
        serviceId?: string,
        isSaas?: boolean
    ): Promise<ServiceToPolicy[]> {
        if (policyId) {
            return this.get("/api/v1/policy/" + encodeURIComponent(policyId) + "/attachment")
        } else if (serviceId && isSaas) {
            return this.get("/api/v1/policy/attachment/saasapp/" + encodeURIComponent(serviceId))
        } else if (serviceId && !isSaas) {
            return this.get("/api/v1/policy/attachment/service/" + encodeURIComponent(serviceId))
        } else {
            return this.get("/api/v1/policy/attachment")
        }
    }
}

export interface RegisteredService {
    FriendlyName: string
    ClusterName: string
    CreatedAt: number
    CreatedBy: string
    Description: string
    Enabled: string
    LastUpdatedAt: number
    LastUpdatedBy: string
    OIDCClientSpec: string
    OIDCEnabled: true
    ServiceID: string
    ServiceName: string
    ServiceSpec: string
    ServiceType: string
    ServiceVersion: number
    UserFacing?: string
    AttachedPolicy: AttachedPolicy | null
}

export interface ServiceToPolicy {
    PolicyID: string
    PolicyName: string
    ServiceID: string
    ServiceName: string
    ServiceFriendlyName?: string
    Enabled: string
    AttachedAt: number
    AttachedToID: string
    AttachedToName: string
    AttachedToFriendlyName: string
    AttachedToType?: PolicyAttachmentType
}

export enum PolicyAttachmentType {
    SAAS_APP = "saasapp",
    SERVICE = "service",
}

export interface ServiceSpec {
    kind: string
    apiVersion: string
    type: string
    metadata: ServiceMetadata
    spec: ServiceAttr
}

export interface ServiceMetadata {
    friendly_name?: string
    name: string
    description: string
    cluster: string
    autorun: boolean
    tags: TagsRes
}

type BanyanProxyModeRes = "CHAIN" | "BASTION" | "CONNECT" | "TCP" | "RDPGATEWAY"
type EnforcementModeRes = "ACCESS_TIER" | "ACCESS_TIER_GROUP" | "NET_AGENT"
type ServiceAppTypeRes = "WEB" | "SSH" | "RDP" | "K8S" | "GENERIC" | "DATABASE" | "ALL"
type SshServiceTypeRes = "TRUSTCERT" | "SSHCERT" | "BOTH"
type TemplateRes = "WEB_USER" | "TCP_USER" | "TCP_WORKLOAD" | "CUSTOM" | "TUNNEL"

interface TagsRes {
    allow_user_override?: boolean
    app_listen_port?: string
    // cspell: ignore banyanproxy
    banyanproxy_mode?: BanyanProxyModeRes
    description_link?: string
    domain?: string
    enforcementMode?: EnforcementModeRes
    icon?: IconName
    include_domains?: string[]
    kube_ca_key?: string
    kube_cluster_name?: string
    port?: string
    protocol?: string
    rdp_settings?: string[]
    service_app_type?: ServiceAppTypeRes
    ssh_chain_mode?: string
    ssh_host_directive?: string
    ssh_service_type?: SshServiceTypeRes
    template?: TemplateRes
    user_facing?: "true" | "false"
    write_ssh_config?: boolean
}

export interface ServiceAttr {
    attributes: FrontendAttr
    backend: BackendAttr
    cert_settings: CertSettings
    http_settings: HttpSettings
    client_cidrs: ClientCidr[]
}

interface FrontendAttr {
    tls_sni: string[]
    frontend_addresses: FrontendAddress[]
    host_tag_selector: StringMap[]
    disable_private_dns: boolean
}

export interface FrontendAddress {
    cidr: string
    port: string
}

export interface BackendAttr {
    target: BackendTarget
    dns_overrides: StringMap
    whitelist: string[]
    http_connect?: boolean
    allow_patterns?: BackendAllowPattern[]
    connector_name?: string
}

interface BackendTarget {
    name: string
    port: string
    tls: boolean
    tls_insecure: boolean
    client_certificate: boolean
}

interface BackendAllowPattern {
    hostnames?: string[]
    cidrs?: string[]
    ports?: BackendAllowPorts
}

interface BackendAllowPorts {
    port_list: number[]
    port_ranges: PortRange[]
}

interface PortRange {
    min: number
    max: number
}

export interface CertSettings {
    dns_names: string[]
    custom_tls_cert: CustomTLSCert
    letsencrypt?: boolean
}

interface CustomTLSCert {
    enabled: boolean
    cert_file?: string
    key_file?: string
}

export interface HttpSettings {
    enabled: boolean
    oidc_settings?: OidcSettings
    http_health_check?: HttpHealthCheck
    http_redirect?: HttpRedirect
    exempted_paths?: ExemptedPaths
    headers?: StringMap
    token_loc?: {
        query_param: string
        custom_header: string
        authorization_header: boolean
    }
}

interface OidcSettings {
    enabled: boolean
    service_domain_name?: string
    post_auth_redirect_path?: string
    api_path?: string
    suppress_device_trust_verification?: boolean
}

interface HttpHealthCheck {
    enabled: boolean
    method?: string
    path?: string
    user_agent?: string
    from_address?: string[]
    https?: boolean
}

export interface ServiceConnectionTestReq {
    ServiceID: string
    user_email?: string
}

export interface ServiceConnectionStatusRes {
    FrontendConnStatus: ConnectionStatus
    BackendConnStatus: BackendConnectionStatus[]
}

interface BackendConnectionStatus {
    SiteName: string
    AccessTierStatus: ConnectionStatus
    NetagentBackendStatus: NetagentBackendStatus[]
}

interface NetagentBackendStatus {
    ID: string
    SiteName: string
    NetagentHostname: string
    ConnStatus: ConnectionStatus
    ValidBefore: string
}

interface ConnectionStatus {
    DomainNameResolutionStatus: boolean
    Reachability: boolean
    ErrorMsg?: string
}

interface HttpRedirect {
    enabled: boolean
    from_address?: string[]
    url?: string
    status_code?: number
}

interface ExemptedPaths {
    enabled: boolean
    paths?: string[]
    patterns?: ExemptedPathPattern[]
}

export interface ExemptedPathPattern {
    hosts?: ExemptedPathPatternTarget[]
    methods?: ExemptedPathPatternMethods[]
    mandatory_headers?: string[]
    paths?: string[]
    source_cidrs?: string[]
    template?: string
}

interface ExemptedPathPatternTarget {
    origin_header: string[]
    target: string[]
}

export enum ExemptedPathPatternTemplates {
    CORS = "CORS",
    CIDR = "CIDR",
    PATH = "PATH",
}

export enum ExemptedPathPatternMethods {
    OPTIONS = "OPTIONS",
    GET = "GET",
    POST = "POST",
    HEAD = "HEAD",
    PUT = "PUT",
    DELETE = "DELETE",
    ALL = "*",
}

interface ClientCidr {
    addresses: FrontendAddress[]
    host_tag_selector: StringMap[]
    clusters: string[]
}

export interface SaasSpec {
    kind: string
    apiVersion: string
    metadata: SaasMetadata
    spec: SaasAttr
}

export interface SaasMetadata {
    id?: string
    name: string
    description: string
    tags: {
        template: string
    }
}

export interface SaasAttr {
    client_id?: string
    client_secret?: string
    redirect_url: string
    protocol: SaasAuthProtocol
    audience_uri?: string
    name_id_format?: SaasNameIdFormat
    name_id_value?: SaasNameIdValue
    suppress_device_trust_verification?: boolean
    enable_service_level_passwordless?: boolean
}

export enum SaasAuthProtocol {
    OIDC = "OIDC",
    SAML = "SAML",
}

export enum SaasNameIdFormat {
    UNSPECIFIED = "unspecified",
    EMAIL = "email",
    TRANSIENT = "transient",
    PERSISTENT = "persistent",
}

export enum SaasNameIdValueOpts {
    CUSTOM = "CUSTOM",
    PASSTHROUGH_NAME_ID = "PASSTHROUGH",
    LEGACY = "LEGACY",
}

export enum SaasAppType {
    IDP_FIRST = "IDP_FIRST",
    BANYAN_FIRST = "BANYAN_FIRST",
}

export interface SaasNameIdValue {
    passthrough_name_id: boolean
    name_id_value_type?: SaasNameIdValueOpts
    name_id_attribute_selector?: string
}

export interface SaasService {
    ID: string
    Name: string
    Description: string
    CreatedBy: string
    CreatedAt: number
    LastUpdatedBy: string
    LastUpdatedAt: number
    Spec: SaasSpec
    Enabled: string
    Protocol: SaasAuthProtocol
    Type: SaasAppType
    AudienceURI?: string
    MetadataUrl?: string
    AttachedPolicy: AttachedPolicy | null
}

interface AttachedPolicy {
    AttachedAt: number
    AttachedBy: string
    PolicyID: string
    PolicyName: string
    PolicyStatus: boolean
}

export enum ServiceTag {
    SSH_CHAIN_MODE = "ssh_chain_mode",
    SSH_HOST_DIRECTIVE = "ssh_host_directive",
    SSH_SERVICE_TYPE = "ssh_service_type",
    WRITE_SSH_CONFIG = "write_ssh_config",
    PROTOCOL = "protocol",

    BANYAN_PROXY_MODE = "banyanproxy_mode",
    APP_LISTEN_PORT = "app_listen_port",
    ALLOW_USER_OVERRIDE = "allow_user_override",

    KUBE_CLUSTER_NAME = "kube_cluster_name",
    KUBE_CA_KEY = "kube_ca_key",

    INCLUDE_DOMAINS = "include_domains",
}
