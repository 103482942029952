import React from "react"

import { Input } from "../../../components/input/Input.component"
import { FormLabel } from "../../../pre-v3/components/form-label/FormLabel"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { PatternUtil } from "../../../pre-v3/utils/Pattern.util"

interface Props {
    id: string
    value: string
    disabled?: boolean
    onChange?(value: string): void
}

export function OrgNameInput(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <FormLabel
            inline
            title={localization.getString("orgName")}
            tooltip={localization.getString("orgNameRequirements")}
            htmlFor={props.id}
        >
            <Input
                type="text"
                id={props.id}
                value={props.value}
                onChangeValue={props.onChange}
                minLength={4}
                maxLength={30}
                pattern={PatternUtil.ORG_NAME.source}
                disabled={props.disabled}
                required
            />
        </FormLabel>
    )
}
