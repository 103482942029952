import styles from "./OverviewTopContainer.module.scss"
import * as React from "react"
import { useServiceLocalization } from "../../pre-v3/services"
import { StatusType } from "../../utils/StatusType.utils"
import { Status } from "../status/Status.component"
import classNames from "classnames/bind"
import { IconType } from "../icon/Icon.component"
import { Link, LinkElement } from "../link/Link.component"
import { formatRoutePath, ROUTE } from "../../routes"
import { PolicyAttachment, PolicyType } from "../../v3/services/Policy.service"
import classnames from "classnames"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import {
    KeyValuePair,
    KeyValuePairList,
} from "../../v3/components/key-value-pair/KeyValuePair.component"
import { encodeID } from "../../pre-v3/utils/Url.util"

export { StatusType } from "../../utils/StatusType.utils"

export interface KeyValuePairItems {
    label: string
    value: React.ReactNode
}

interface RecordItems {
    type: string
    at?: number
    by?: string
}

export interface Props {
    statusType: StatusType
    statusItems?: KeyValuePairItems[]
    recordItems?: RecordItems[]
    statusLabel?: string
    statusLabelTitle?: string
    listItems?: KeyValuePairItems[]
    listClassName?: string
    className?: string
    hideStatusType?: boolean
    hideIcon?: boolean
    statusContainerClassName?: string
    icon?: IconType
    iconClassName?: string
    statusValueClassName?: string
    listValueClassName?: string
    hideStatusBlock?: boolean
    policyAttachment?: PolicyAttachment
    recordsLabelTitle?: string
}

export function OverviewTopContainer(props: Props) {
    const { statusType, listItems, className, statusContainerClassName, hideStatusBlock } = props

    return (
        <div className={classNames(styles.container, className)}>
            {!hideStatusBlock && (
                <div
                    className={classNames(
                        statusContainerClassName,
                        styles.statusInfo,
                        { [styles.success]: statusType === StatusType.SUCCESS },
                        { [styles.warning]: statusType === StatusType.WARNING },
                        { [styles.error]: statusType === StatusType.ERROR },
                        { [styles.disabled]: statusType === StatusType.DISABLED },
                        { [styles.disabled]: statusType === StatusType.UNKNOWN }
                    )}
                >
                    {!props.policyAttachment && <StatusInfoList {...props} />}
                    {props.policyAttachment && <PolicyStatus {...props} />}
                </div>
            )}
            {(listItems || props.recordItems) && (
                <div className={styles.mainContainer}>
                    {props.recordsLabelTitle && (
                        <div className={styles.header}>{props.recordsLabelTitle}</div>
                    )}
                    {listItems && (
                        <KeyValuePairList className={styles.keyValueContainer}>
                            {listItems.map((item, i) => (
                                <KeyValuePair
                                    {...item}
                                    className={props.listValueClassName || styles.listValue}
                                />
                            ))}
                        </KeyValuePairList>
                    )}

                    {props.recordItems && props.recordItems?.length > 0 && (
                        <Records recordItems={props.recordItems} />
                    )}
                </div>
            )}
        </div>
    )
}

function Records(records: { recordItems: RecordItems[] }) {
    const localization = useServiceLocalization()
    return (
        <div className={styles.content}>
            {records.recordItems.map((record, idx) => (
                <div className={styles.statusRow} key={idx}>
                    <span>{record.type}</span>
                    {record.at ? <span>{DateUtil.format(record.at)}</span> : " -- "}
                    <span>{localization.getString("by")}</span>
                    {record.by ? <span>{record.by}</span> : " -- "}
                </div>
            ))}
        </div>
    )
}

function PolicyStatus(props: Props) {
    const localization = useServiceLocalization()
    const { policyAttachment } = props

    return (
        <div className={styles.status}>
            <div className={styles.header}>{localization.getString("policyStatus")}</div>
            <div className={styles.content}>
                <div className={styles.statusRow}>
                    <Status
                        type={props.statusType}
                        label={props.statusLabel}
                        icon={props.icon}
                        hideIcon={props.hideIcon}
                        iconClassName={props.iconClassName}
                    />

                    {policyAttachment ? (
                        <Link
                            asElement={LinkElement.LINK}
                            to={LinkToPolicyDetailPage(policyAttachment)}
                        >
                            {policyAttachment.policyName}
                        </Link>
                    ) : (
                        "-"
                    )}
                </div>

                <div className={classnames(styles.statusRow, styles.iconPadding)}>
                    <span>{localization.getString("attachedOn")}</span>
                    <span>{DateUtil.format(policyAttachment?.attachedAt)}</span>
                </div>
            </div>
        </div>
    )
}

function LinkToPolicyDetailPage(policyAttachment: PolicyAttachment): string {
    if (policyAttachment.type === PolicyType.TUNNEL)
        return formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, {
            id: encodeID(policyAttachment.policyId),
        })

    return formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, { id: policyAttachment.policyId })
}

function StatusInfoList(props: Props) {
    const localization = useServiceLocalization()

    return (
        <>
            {!props.hideStatusType && (
                <div className={classNames(styles.columnFlexContainer)}>
                    <label className={styles.header}>
                        {props.statusLabelTitle ?? localization.getString("status")}
                    </label>
                    <Status
                        type={props.statusType}
                        label={props.statusLabel}
                        icon={props.icon}
                        hideIcon={props.hideIcon}
                        iconClassName={props.iconClassName}
                    />
                </div>
            )}

            {props.statusItems && (
                <div className={classNames(styles.columnFlexContainer, props.listClassName)}>
                    <KeyValuePairList>
                        {props.statusItems.map((item) => (
                            <KeyValuePair
                                {...item}
                                key={item.label}
                                className={props.statusValueClassName || styles.statusValue}
                            />
                        ))}
                    </KeyValuePairList>
                </div>
            )}
        </>
    )
}
