import React from "react"

import { Input } from "../../../components/input/Input.component"
import { FormLabel } from "../../../pre-v3/components/form-label/FormLabel"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"

interface Props {
    id: string
    value: string
}

export function OrgIdInput(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <FormLabel inline title={localization.getString("orgId")} htmlFor={props.id}>
            <Input type="text" id={props.id} value={props.value} disabled required />
        </FormLabel>
    )
}
