import * as React from "react"
import { Singleton } from "../decorators/Singleton.decorator"
import {
    CertsApi,
    ExternalCertificateItemRes,
    ExternalCertificateRes,
    ChallengeRecordRes,
    RegisteredDomainsItemRes,
} from "../api/Certs.api"
import { DateUtil } from "../utils/Date.util"
import ServiceContext from "./context"

@Singleton("CertsService")
export class CertsService {
    private certsApi: CertsApi = new CertsApi()

    public getRegisteredDomains(clusterName?: string): Promise<RegisteredDomain[]> {
        return this.certsApi.getRegisteredDomains().then((registeredDomain) => {
            const registeredDomains: RegisteredDomain[] = registeredDomain.registered_domains
                ? registeredDomain.registered_domains.map(this.mapRegisteredDomain)
                : []
            return !clusterName
                ? registeredDomains
                : registeredDomains.filter((domain) => domain.clusterName === clusterName)
        })
    }

    public getRegisteredDomain(domainId: string): Promise<RegisteredDomain> {
        return this.certsApi.getRegisteredDomainById(domainId).then((domain) => {
            const registeredDomain: RegisteredDomain = this.mapRegisteredDomain(domain)
            return registeredDomain
        })
    }

    public createRegisteredDomain(domainData: RegisteredDomain): Promise<RegisteredDomain> {
        return this.certsApi
            .createRegisteredDomain({
                name: domainData.name,
                cluster_name: domainData.clusterName,
                cname: domainData.cName,
                description: domainData.description,
                registered_domain_challenge_id: domainData.challengeId,
            })
            .then((registeredDomain) => {
                const registeredDomainData: RegisteredDomain = registeredDomain
                return registeredDomainData
            })
    }

    public deleteRegisterdDomain(id: string): Promise<RegisteredDomain> {
        return this.certsApi.deleteRegisteredDomain(id)
    }

    public validateRegisteredDomain(id: string): Promise<RegisteredDomain> {
        return this.certsApi.validateRegisteredDomain(id)
    }

    public createChallengeRecord(
        domainName: RegisteredDomainChallengeRecord
    ): Promise<ChallengeRecordRes> {
        return this.certsApi
            .createChallengeRecord({
                registered_domain_name: domainName.registeredDomainName,
            })
            .then((registeredDomain) => {
                const challengeRecord: ChallengeRecordRes = registeredDomain
                return challengeRecord
            })
    }

    public getChallengeRecord(domainId: string): Promise<ChallengeRecordRes> {
        return this.certsApi.getChallengeRecordById(domainId).then((domain) => {
            const registeredDomain: ChallengeRecordRes = domain
            return registeredDomain
        })
    }

    public getExternalCertificates(): Promise<ExternalCertificate[]> {
        return this.certsApi.getCertificates().then((externalCertificate) => {
            const externalCertificates: ExternalCertificate[] = externalCertificate?.certificates
                ? externalCertificate.certificates.map(this.mapExternalCertificate)
                : []
            return externalCertificates
        })
    }

    public getExternalCertificate(domainName: string): Promise<ExternalCertificateRes> {
        return this.certsApi.getExternalCertificate(domainName)
    }

    public deleteExternalCertificate(id: string): Promise<ExternalCertificateRes> {
        return this.certsApi.deleteExternalCertificate(id)
    }

    public reissueCertificate(id: string): Promise<ExternalCertificateRes> {
        return this.certsApi.reissueCertificate(id)
    }

    private mapRegisteredDomain(domainItems: RegisteredDomainsItemRes): RegisteredDomain {
        return {
            id: domainItems.id,
            name: domainItems.name,
            clusterName: domainItems.cluster_name,
            cName: domainItems.cname,
            description: domainItems.description,
            challengeId: domainItems.registered_domain_challenge_id,
            status: domainItems.status as RegisteredDomainStatus | undefined,
            createdAt: DateUtil.convertLargeTimestamp(domainItems.created_at),
            updatedAt: DateUtil.convertLargeTimestamp(domainItems.updated_at),
            createdBy: domainItems.created_by,
            updatedBy: domainItems.updated_by,
        }
    }

    private mapExternalCertificate(certificates: ExternalCertificateItemRes): ExternalCertificate {
        return {
            id: certificates.id,
            ordId: certificates.org_id,
            name: certificates.domains,
            status: certificates.status as IssuedCertStatus,
            failedReason: certificates.failed_reason,
            certificate: certificates.certificate,
            expiresAt: DateUtil.convertLargeTimestamp(certificates.expires_at),
            createdAt: DateUtil.convertLargeTimestamp(certificates.created_at),
            updatedAt: DateUtil.convertLargeTimestamp(certificates.updated_at),
        }
    }
}

export interface RegisteredDomain {
    id?: string
    name: string
    clusterName: string
    cName: string
    description: string
    challengeId?: string
    status?: RegisteredDomainStatus
    createdAt?: number
    createdBy?: string
    updatedAt?: number
    updatedBy?: string
}

export interface ExternalCertificate {
    id: string
    ordId: string
    name: string[]
    status: IssuedCertStatus
    failedReason?: string
    certificate: string
    expiresAt: number
    createdAt: number
    updatedAt: number
}

export interface RegisteredDomainChallengeRecord {
    registeredDomainName: string
}

export enum RegisteredDomainStatus {
    PENDING = "Pending",
    VERIFIED = "Verified",
    FAILED = "Failed",
}

export enum IssuedCertStatus {
    PROCESSING = "Processing",
    PENDING = "Pending",
    ACTIVE = "Active",
    FAILED = "Failed",
}

export const useServiceCerts = () => React.useContext(ServiceContext)?.certs || new CertsService()
