import React from "react"

import { HiddenInput } from "../../../components/input/HiddenInput.component"
import { FormLabel } from "../../../pre-v3/components/form-label/FormLabel"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { SelectInput, Option } from "../../../v3/components/select-input/SelectInput.component"
import {
    KnownOrgType,
    OrgType,
    TypeInformation,
    allOrgTypes,
    orgTypeLabelDict,
} from "../../../v3/services/MomOrgManagement.service"
import styles from "./OrgType.module.scss"

interface Props {
    id: string
    value: OrgType
    typeInformation?: TypeInformation
    disabled?: boolean
    onChange?(value: KnownOrgType): void
}

export function OrgTypeInput(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const hiddenInputRef = React.useRef<HTMLInputElement>(null)

    React.useEffect(() => {
        hiddenInputRef.current?.setCustomValidity(
            props.value === OrgType.UNKNOWN
                ? localization.getString("pleaseSelectATypeThatIsNotDeprecated")
                : ""
        )
    }, [props.value])

    const orgTypeOptions = allOrgTypes.map<Option>((orgType) => ({
        displayName: localization.getString(orgTypeLabelDict[orgType]),
        value: orgType,
    }))

    const getTypeValue = (): string => {
        switch (props.value) {
            case undefined:
                return ""

            case OrgType.UNKNOWN:
                return props.typeInformation?.type === OrgType.UNKNOWN
                    ? localization.getString("somethingDeprecated", props.typeInformation.typeValue)
                    : ""

            default:
                return localization.getString(orgTypeLabelDict[props.value])
        }
    }

    return (
        <FormLabel
            inline
            title={localization.getString("type")}
            htmlFor={props.id}
            valueClassName={styles.container}
        >
            <SelectInput
                disabled={props.disabled}
                value={props.value}
                options={
                    props.typeInformation?.type === OrgType.UNKNOWN
                        ? [
                              {
                                  displayName: localization.getString(
                                      "somethingDeprecated",
                                      props.typeInformation.typeValue
                                  ),
                                  value: OrgType.UNKNOWN,
                              } satisfies Option,
                              ...orgTypeOptions,
                          ]
                        : orgTypeOptions
                }
                onChange={(orgType) => {
                    if (orgType !== OrgType.UNKNOWN) {
                        props.onChange?.(orgType)
                    }
                }}
            />
            <HiddenInput id={props.id} value={getTypeValue()} type="text" ref={hiddenInputRef} />
        </FormLabel>
    )
}
