import { faChevronDown } from "@fortawesome/pro-regular-svg-icons"
import { ListSubheader, MenuItem, Select as MuiSelect, SelectChangeEvent } from "@mui/material"
import classNames from "classnames/bind"
import React from "react"

import { SelectValue, SelectValueUtil } from "../../../pre-v3/utils/SelectValue.util"
import { Icon } from "../Icon/Icon.component"
import styles from "./SelectInput.module.scss"

export type { SelectValue as Option } from "../../../pre-v3/utils/SelectValue.util"

type PossibleValue = string | number

export interface Props<Value extends PossibleValue = string> {
    id?: string
    labelId?: string
    name?: string
    value?: Value
    options: SelectValue[]
    required?: boolean
    disabled?: boolean
    placeholder?: string
    className?: string
    selectClassName?: string
    defaultValue?: Value
    onChange?: (o: Value) => void
}

export const SelectInput = React.forwardRef(
    <Value extends PossibleValue = string>(
        props: Props<Value>,
        ref: React.ForwardedRef<HTMLInputElement>
    ): JSX.Element => {
        const {
            options,
            placeholder,
            className,
            selectClassName,
            onChange: originalOnChange,
            ...selectProps
        } = props

        const onChange = (event: SelectChangeEvent<Value>) =>
            originalOnChange?.(event.target.value as Value)

        const renderValue = (value: Value): React.ReactNode => {
            if (!value && placeholder) {
                return <span className={styles.placeholder}>{placeholder}</span>
            }

            if (!value) return

            const selectedOption = options.find(
                (option) => SelectValueUtil.getSerializedValue(option) === value
            )

            return selectedOption && SelectValueUtil.getSerializedDisplayName(selectedOption)
        }

        return (
            <MuiSelect
                {...selectProps}
                inputRef={ref}
                className={classNames(styles.select, className)}
                onChange={onChange}
                classes={{
                    select: classNames(styles.input, selectClassName),
                    disabled: styles.disabled,
                }}
                IconComponent={getIconComponent}
                renderValue={renderValue}
                fullWidth
                displayEmpty
            >
                {options.map(getMenuItem)}
            </MuiSelect>
        )
    }
) as (<Value extends PossibleValue = string>(
    props: Props<Value> & { ref?: React.ForwardedRef<HTMLInputElement> }
) => JSX.Element) & { displayName?: string }

SelectInput.displayName = "SelectInput"

function getIconComponent(): JSX.Element {
    return (
        <div className={styles.iconWrapper}>
            <Icon icon={faChevronDown} className={styles.icon} small />
        </div>
    )
}

function getMenuItem(value: SelectValue): JSX.Element {
    if (typeof value === "object" && value.divisor) {
        return <ListSubheader className={styles.divisor}>{value.displayName}</ListSubheader>
    }

    const serializedValue = SelectValueUtil.getSerializedValue(value)
    return (
        <MenuItem
            className={styles.menuItem}
            key={serializedValue}
            value={serializedValue}
            classes={{ selected: styles.itemSelected }}
        >
            {SelectValueUtil.getSerializedDisplayName(value)}
        </MenuItem>
    )
}
