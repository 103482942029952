import { LicenseTypeRes } from "../../api/Mom.api"

export enum LicenseLevel {
    NONE = "none",
    BASIC = "basic",
    ADVANCED = "advanced",
}

export const licenseTypeMap: Record<LicenseTypeRes, LicenseLevel> = {
    None: LicenseLevel.NONE,
    Basic: LicenseLevel.BASIC,
    Advanced: LicenseLevel.ADVANCED,
}

export interface OrgLicenseInformation {
    sia: LicenseLevel
    spa: LicenseLevel
    spaCount: number
    siaCount: number
    spaUsageCount: number
    siaUsageCount: number
}
