import React, { useMemo } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { ROUTE } from "../../routes"
import { useActivityMonitor } from "../../hooks/useActivityMonitor.hook"
import { useProtectPages } from "../../hooks/useProtectPages.hook"
import { useSoftwareVersion } from "../../hooks/useSoftwareVersion.hook"
import useTitle from "../../hooks/useTitle.hook"
import { TopNav } from "../../pre-v3/components/top-nav/TopNav"
import { useServiceLinks } from "../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { AppNavLink } from "../../pre-v3/services/shared/AppNavLink"
import { useAuthService } from "../../pre-v3/services/Auth.service"
import { Loader } from "../../v3/components/loader/Loader.component"
import { useGetUserProfile } from "../../v3/services/UserProfile.service"
import styles from "./MspConsole.module.scss"
import { MspOrgManagement } from "../../v3/views/msp-org-management/MspOrgManagement.view"
import { Profile } from "../../utils/profile.utils"
import { MspManagedOrgList } from "../../v3/views/msp-org-management/msp-managed-organization/list/MspManagedOrgList.view"
import { NavBar } from "../../pre-v3/components/nav-bar/NavBar.component"
import { MyProfile } from "../../v3/views/user-settings/MyProfile.view"
import { AppConsole } from "../../utils/consoles.utils"
import { SonicWallCurtain } from "../../components/sonic-wall-curtain/SonicWallCurtain.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import { SystemLogs } from "../../pre-v3/views/monitor/system-logs/SystemLogs.component"
import { useGenerateCscCurtainUrl } from "../../v3/services/MswCsc.service"
import { Settings } from "./Settings"
import { ErrorBanner } from "../../v3/components/banner/Banner.component"

export function MspConsole(): JSX.Element {
    useActivityMonitor()
    useTitle(["mspConsole"])
    const isLoading = useProtectPages(AppConsole.MSP)
    const { data, isLoading: isProfileLoading } = useGetUserProfile()
    const authService = useAuthService()

    const mswUrl = useMemo(() => authService.getMswCscUrl(), [])
    const { mutateAsync: generateScsUrl } = useGenerateCscCurtainUrl()

    const softwareVersion = useSoftwareVersion()
    const supportLinks = useSupportLinks()
    const topLinks = useTopLinks(data?.profile)
    const userMenuLinks = useUserMenuLinks()
    const isMspAdmin: boolean = data?.profile === Profile.ADMIN || data?.profile === Profile.OWNER
    const isMspReadOnlyAdmin: boolean = data?.profile === Profile.READONLY
    const {
        data: featureFlags,
        isLoading: isFeatureFlagLoading,
        isError: isFeatureFlagsError,
        error: featureFlagError,
    } = useFeatureFlags()

    if (isLoading || isProfileLoading || isFeatureFlagLoading) return <Loader center medium />

    if (isFeatureFlagsError) {
        return <ErrorBanner>{String(featureFlagError)}</ErrorBanner>
    }

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <TopNav
                    logoLink="/"
                    userMenuLinks={userMenuLinks}
                    supportLinks={supportLinks}
                    fullName={data?.fullName ?? ""}
                    orgName={data?.orgName ?? ""}
                    appVersion={softwareVersion}
                    hideSettings={isMspReadOnlyAdmin}
                    settingsLink={
                        featureFlags?.mspConsole.canAccessAdminSignOn
                            ? ROUTE.MSP_SIGN_ON
                            : ROUTE.MSP_MANAGE_ADMIN
                    }
                >
                    <NavBar links={topLinks} />
                </TopNav>
            </header>
            <div className={styles.body}>
                <Switch>
                    <Route path={ROUTE.MSP_MY_PROFILE} component={MyProfile} exact />
                    <Route path={ROUTE.MSP_SETTINGS}>
                        <Settings
                            canAccessSignOn={featureFlags?.mspConsole.canAccessAdminSignOn}
                            canConfigureAdminSignOnSettings={
                                featureFlags?.mspConsole.canConfigureAdminSignOnSettings
                            }
                            canConfigureEndUserSettings={
                                featureFlags?.mspConsole.canConfigureEndUserSettings
                            }
                            canUpdateCustomization={featureFlags?.mspConsole.canUpdateCustomization}
                        />
                    </Route>
                    {isMspAdmin && (
                        <Route path={ROUTE.MSP_ORG_MANAGEMENT} component={MspOrgManagement} />
                    )}
                    <Route path={ROUTE.MSP_ASSIGNED_ORGS}>
                        <MspManagedOrgList email={isMspAdmin ? data?.emailAddress : ""} />
                    </Route>
                    <Route path={ROUTE.MSP_SYSTEM_LOGS} component={SystemLogs} />
                    <Redirect
                        to={isMspReadOnlyAdmin ? ROUTE.MSP_ASSIGNED_ORGS : ROUTE.MSP_ORG_MANAGEMENT}
                    />
                </Switch>
            </div>
            {mswUrl && <SonicWallCurtain generateUrl={() => generateScsUrl(mswUrl)} />}
        </div>
    )
}

function useTopLinks(profile?: Profile): AppNavLink[] {
    const localization = useServiceLocalization()
    return [
        ...(profile && (profile === Profile.ADMIN || profile === Profile.OWNER)
            ? [
                  {
                      label: localization.getString("orgManagement"),
                      href: ROUTE.MSP_ORG_MANAGEMENT,
                  },
              ]
            : []),
        {
            label: localization.getString("assignedOrgs"),
            href: ROUTE.MSP_ASSIGNED_ORGS,
        },
        {
            label: localization.getString("systemLogs"),
            href: ROUTE.MSP_SYSTEM_LOGS,
        },
    ]
}

function useUserMenuLinks(): AppNavLink[] {
    const localization = useServiceLocalization()
    const authService = useAuthService()

    return [
        {
            label: localization.getString("myProfile"),
            href: ROUTE.MSP_MY_PROFILE,
        },
        {
            divider: true,
        },
        {
            label: localization.getString("signOut"),
            onClick: () => authService.logoutOfAllTabs(),
        },
    ]
}

function useSupportLinks(): AppNavLink[] {
    const localization = useServiceLocalization()
    const linkService = useServiceLinks()

    return [
        {
            label: localization.getString("documentation"),
            href: linkService.getLink("docSite"),
            target: "_blank",
        },
        {
            label: localization.getString("supportSite"),
            href: linkService.getLink("supportHome"),
            target: "_blank",
        },
        {
            label: localization.getString("fileATicket"),
            href: linkService.getLink("fileATicket"),
            target: "_blank",
            teamEditionHidden: true,
        },
        {
            label: localization.getString("communityForum"),
            href: linkService.getLink("communityForum"),
            target: "_blank",
            teamEditionOnly: true,
        },
    ]
}
