import { useQuery } from "@tanstack/react-query"
import { RegisteredServiceApi } from "../api/RegisteredService.api"
import { PolicyApi, PolicyRes, PolicySpecReq } from "../api/Policy.api"
import { getPolicyType, PolicyType } from "./shared/Policy"
import { ApiFunction } from "./shared/QueryKey"

interface Variables {
    backendDomain: string[]
}

const serviceName = "Infrastructure"

export function useGetVariables() {
    const registeredServiceApi = new RegisteredServiceApi()

    return useQuery<Variables, string>({
        queryKey: [ApiFunction.GET_TEMPLATES, serviceName],
        queryFn: async () => {
            const { tcp } = await registeredServiceApi.getTemplates()
            return {
                backendDomain: tcp.backend_target,
            }
        },
    })
}

export function useGetInfrastructurePolices() {
    const policyApi = new PolicyApi()
    return useQuery({
        queryKey: [ApiFunction.GET_POLICIES, serviceName],
        queryFn: async () => {
            const polices = await policyApi.getAccessPolicies()
            // TODO - remove filter when https://banyan-sec.atlassian.net/browse/BC-14544 were done
            return polices.filter(filterPolicy).map(mapPolices)
        },
    })
}

function filterPolicy(policesRes: PolicyRes) {
    const policyType = getPolicyType(policesRes)
    return policyType === PolicyType.INFRASTRUCTURE
}

function mapPolices(policyRes: PolicyRes): InfrastructurePolicy {
    return {
        name: policyRes.PolicyName,
        id: policyRes.PolicyID,
    }
}

function getPolicySpec(policyRes: PolicyRes): PolicySpecReq {
    return JSON.parse(policyRes.PolicySpec).spec
}

interface InfrastructurePolicy {
    name: string
    id: string
}
