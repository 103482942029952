import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    IServerSideDatasource,
    IServerSideGetRowsParams,
} from "ag-grid-community"
import React, { useRef, useState } from "react"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil, { FilterModel } from "../../../../pre-v3/utils/AgGrid.util"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"
import { useDebouncedCallback } from "../../../../pre-v3/utils/UseDebouncedCallback.hook"
import styles from "./DeletedOrgList.module.scss"
import { SearchInput } from "../../../../components/search-input/SearchInput.component"
import {
    DeletedOrg,
    getDeletedOrgs,
    KnownOrgType,
    orgTypeLabelDict,
} from "../../../services/MomOrgManagement.service"
import { ITooltipParams } from "ag-grid-community"

export function DeletedOrgList() {
    const [error, setError] = useState<string>("")
    const [filter, setFilter] = useState<string>("")

    const gridApi = useRef<GridApi>()

    const localization = useServiceLocalization()

    const columns: ColDef[] = [
        {
            headerName: localization.getString("deletedAt"),
            field: "deletedAt",
            valueFormatter: AgGridUtil.nullableStringFormatter,
            sortable: false,
        },
        {
            headerName: localization.getString("orgName"),
            field: "orgName",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 150,
            filter: "agTextColumnFilter",
            sortable: false,
        },
        {
            headerName: localization.getString("email"),
            field: "adminEmail",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            flex: 150,
            sortable: false,
        },
        {
            headerName: localization.getString("type"),
            field: "type",
            cellRenderer: OrgTypeCellRenderer,
            valueFormatter: AgGridUtil.nullableStringFormatter,
            tooltipValueGetter: (props: ITooltipParams) =>
                localization.getString(orgTypeLabelDict[props.value as KnownOrgType]),
            sortable: false,
        },
        {
            headerName: localization.getString("customerId"),
            field: "customerId",
            valueFormatter: AgGridUtil.nullableStringFormatter,
            sortable: false,
        },
        {
            headerName: localization.getString("deletedBy"),
            field: "deletedBy",
            valueFormatter: AgGridUtil.nullableStringFormatter,
            sortable: false,
        },
        {
            headerName: localization.getString("deletedReason"),
            field: "reason",
            valueFormatter: AgGridUtil.nullableStringFormatter,
            sortable: false,
        },
        {
            headerName: localization.getString("createdAt"),
            field: "createdAt",
            valueFormatter: AgGridUtil.nullableStringFormatter,
            sortable: false,
        },
    ]

    function onRefresh() {
        gridApi.current?.refreshServerSide()
    }

    function onFilterChange(newFilter: string): void {
        setFilter(newFilter)
        setFilterModel(newFilter)
    }

    const setFilterModel = useDebouncedCallback((filter: string) => {
        if (gridApi.current) {
            gridApi.current.setFilterModel({ orgName: { filter } })
        }
    }, [])

    function onGridReady(event: GridReadyEvent): void {
        if (event?.api) {
            gridApi.current = event.api

            fetchData()
        }
    }

    function onDataLoaded(error?: string): void {
        setError(error || "")
    }

    function fetchData(): void {
        gridApi.current?.setServerSideDatasource(Datasource(gridApi.current, onDataLoaded))
    }

    return (
        <section aria-labelledby={Id.HEADING} className={styles.container}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>{localization.getString("deletedOrgs")}</PageHeading>
                <Tooltip title={localization.getString("refresh")}>
                    <Button
                        icon={IconType.REDO}
                        onClick={onRefresh}
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        aria-label={localization.getString("refresh")}
                    />
                </Tooltip>
            </header>
            <ErrorBanners errors={[error]} />
            <div className={styles.toolbar}>
                <div className={styles.leftContainer}>
                    <SearchInput
                        value={filter}
                        onChangeValue={onFilterChange}
                        placeholder={localization.getString("searchByOrgName")}
                    />
                </div>
            </div>
            <Grid onGridReady={onGridReady} columnDefs={columns} serverSidePagination />
        </section>
    )
}

enum Id {
    HEADING = "heading",
}

function Datasource(gridApi: GridApi, callback: (error?: string) => void): IServerSideDatasource {
    return {
        getRows: async (params: IServerSideGetRowsParams) => {
            const skip: number = params.request.startRow || 0
            const limit: number = (params.request.endRow || 0) - skip
            const filterModel: FilterModel = params.request.filterModel

            gridApi.showLoadingOverlay()

            try {
                const res = await getDeletedOrgs({ skip, limit, filterModel })
                params.success({
                    rowData: res.data,
                    rowCount: res.total,
                })

                if (res.total === 0) {
                    gridApi.showNoRowsOverlay()
                } else {
                    gridApi.hideOverlay()
                }

                callback()
            } catch (error) {
                params.fail()
                gridApi.showNoRowsOverlay()

                if (typeof error === "string") {
                    callback(error)
                }
            }
        },
    }
}

function OrgTypeCellRenderer(props: ICellRendererParams) {
    const localization = useServiceLocalization()
    const deletedOrg: DeletedOrg = props.data
    return localization.getString(orgTypeLabelDict[deletedOrg.type as KnownOrgType])
}
