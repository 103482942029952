import React from "react"

import { FormLabel } from "../../../pre-v3/components/form-label/FormLabel"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Checkbox } from "../../../v3/components/checkbox/Checkbox.component"

interface Props {
    id: string
    value: boolean
    disabled?: boolean
    onChange?(value: boolean): void
}

export function AiAssistedAdminSearchCheckbox(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <FormLabel
            inline
            title={localization.getString("enableAiAssistedAdminSearch")}
            htmlFor={props.id}
        >
            <Checkbox
                id={props.id}
                checked={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
            />
        </FormLabel>
    )
}
