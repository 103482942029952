import { faLink } from "@fortawesome/pro-solid-svg-icons"
import React, { useEffect, useMemo } from "react"
import { LinkService, LocalizationService, ModalService } from "../../../../pre-v3/services"
import {
    concatUrl,
    getFirstSubdomain,
    hasLeadingWildcard,
    matchesWildcard,
    removeLeadingWildcard,
} from "../../../../pre-v3/utils"
import { PatternUtil } from "../../../../pre-v3/utils/Pattern.util"
import { AppText } from "../../../components/app-text/AppText.component"
import { Checkbox } from "../../../components/checkbox/Checkbox.component"
import { FormGroup } from "../../../components/form/FormGroup.component"
import { FormRow } from "../../../components/form/FormRow.component"
import { Input } from "../../../components/input/Input.component"
import { PortInput } from "../../../components/port-input/PortInput"
import { MenuSelect, MenuSelectOption } from "../../../components/menu-select/MenuSelect.component"
import { Option, SelectInput } from "../../../components/select-input/SelectInput.component"
import {
    Certificate,
    CertificateType,
    HostedServiceInfra,
    WebService,
    banyanPkiCertificate,
    certificateTypeLabelDict as labelDict,
    letsEncryptCertificate,
} from "../../../services/HostedService.service"
import { RegisteredDomain } from "../../../services/RegisteredDomain.service"
import styles from "./AccessDetails.module.scss"
import { faPlus } from "@fortawesome/pro-regular-svg-icons"
import { RegisteredDomainsModal } from "../../registered-domains/modal/RegisteredDomainsModal.component"
import { Button } from "../../../components/button/Button.component"
import { InternalValues } from "./shared"

interface Props {
    selectedRegisteredDomainId: string
    registeredDomains: RegisteredDomain[]
    infra: HostedServiceInfra[]
    disabled?: boolean
    onChange?: (value: AccessDetailsFormValue) => void
    onAddRegisteredDomain?: (value: RegisteredDomain) => void
    initialValue?: WebService
    canAddRegisteredDomain?: boolean
    internalValues: InternalValues
    setInternalValues: (values: InternalValues) => void
}

export interface AccessDetailsFormValue {
    registeredDomainId?: string
    domain: string
    port: number
    certificate: Certificate
}

export function AccessDetails(props: Props) {
    const { disabled, selectedRegisteredDomainId, internalValues, setInternalValues } = props

    const ls = new LocalizationService()
    const link = new LinkService()
    const modalService: ModalService = new ModalService()

    const registeredDomains: RegisteredDomain[] = useMemo(() => {
        return getAvailableRegisteredDomains(props.registeredDomains, props.infra)
    }, [props.registeredDomains, props.infra])

    const registeredDomainOptions: MenuSelectOption[] = useMemo(() => {
        return registeredDomains.map((r) => {
            return {
                label: r.name,
                value: r.id || "",
            }
        })
    }, [registeredDomains])

    // Fix data inconsistencies on initial load
    useEffect(() => {
        if (!props.initialValue) {
            return
        }

        if (!props.initialValue.name) {
            // Service is unnamed, only time this can happen is on the initial render
            // during the Add flow
            const values: InternalValues = {
                isUnregisteredDomain: false,
                certificate: letsEncryptCertificate,
                isWildCard: false,
                domainName: "",
                subdomain: "",
            }

            emitChanges("", values)
            return
        }

        let registeredDomain: RegisteredDomain | undefined
        const registeredDomains = getAvailableRegisteredDomains(
            props.registeredDomains,
            props.infra
        )
        if (
            props.selectedRegisteredDomainId &&
            props.initialValue?.registeredDomainId !== props.selectedRegisteredDomainId
        ) {
            // selection has changed intentionally, listen to new selection
            const exists = registeredDomains.find((r) => r.id === props.selectedRegisteredDomainId)
            if (exists) {
                registeredDomain = exists
            }

            const isWildcard = Boolean(
                registeredDomain && hasLeadingWildcard(registeredDomain.name)
            )
            const values: InternalValues = {
                isUnregisteredDomain: !registeredDomain,
                certificate: registeredDomain
                    ? props.initialValue.certificate
                    : banyanPkiCertificate,
                isWildCard: isWildcard,
                domainName:
                    isWildcard && registeredDomain
                        ? removeLeadingWildcard(registeredDomain.name)
                        : registeredDomain?.name || "",
                subdomain:
                    isWildcard && registeredDomain ? getFirstSubdomain(registeredDomain.name) : "",
            }

            emitChanges(registeredDomain?.id || "", values)
            return
        }
        if (
            props.initialValue.certificate?.type === CertificateType.LETS_ENCRYPT &&
            !props.initialValue?.registeredDomainId
        ) {
            // service that uses let's encrypt, but does not have a domain mapped, either
            // find the correct registered domain, or switch to an unregistered domain
            const serviceDomain: string = props.initialValue?.domain
            for (const r of registeredDomains) {
                const matches = matchesWildcard(r.name, serviceDomain)
                if (matches) {
                    registeredDomain = r
                    break
                }
            }
        }
        if (props.initialValue?.registeredDomainId) {
            // service that has a specified registered domain, check if that domain still exists
            const exists = registeredDomains.find(
                (r) => r.id === props.initialValue?.registeredDomainId
            )
            if (exists) {
                registeredDomain = exists
            }
        }
        const isWildcard = Boolean(registeredDomain && hasLeadingWildcard(registeredDomain.name))
        const values: InternalValues = {
            isUnregisteredDomain: !registeredDomain,
            certificate:
                !registeredDomain &&
                props.initialValue.certificate?.type === CertificateType.LETS_ENCRYPT
                    ? banyanPkiCertificate
                    : props.initialValue.certificate,
            isWildCard: isWildcard,
            domainName:
                isWildcard && registeredDomain
                    ? removeLeadingWildcard(registeredDomain.name)
                    : props.initialValue.domain,
            subdomain:
                isWildcard && registeredDomain ? getFirstSubdomain(props.initialValue.domain) : "",
        }
        emitChanges(registeredDomain?.id || "", values)
    }, [])

    // Ensure selection is valid when infra changes
    useEffect(() => {
        if (!selectedRegisteredDomainId || internalValues.isUnregisteredDomain) {
            return
        }
        const availableDomains: RegisteredDomain[] = getAvailableRegisteredDomains(
            props.registeredDomains,
            props.infra
        )
        const exists = availableDomains.find((a) => a.id === selectedRegisteredDomainId)
        // Infra has changed such that the previously selected registered domain is no longer valid
        if (!exists) {
            const values: InternalValues = {
                ...internalValues,
                domainName: "",
                subdomain: "",
                isWildCard: false,
            }
            emitChanges("", values)
        }
    }, [props.infra])

    const port: number = 443

    function onRegisteredDomainChange(value: string): void {
        if (value) {
            const exists = registeredDomains.find((r) => r.id === value)
            if (exists) {
                const values: InternalValues = {
                    ...internalValues,
                    isUnregisteredDomain: false,
                    isWildCard: hasLeadingWildcard(exists.name),
                    domainName: removeLeadingWildcard(exists.name),
                }
                emitChanges(exists.id || "", values)
            }
        } else {
            const values: InternalValues = {
                ...internalValues,
                isWildCard: false,
                domainName: "",
            }
            emitChanges("", values)
        }
    }

    function onAddRegisteredDomain(): void {
        const networkIds: string[] = props.infra.reduce<string[]>((acc, i) => {
            return acc.concat(i.networkIds)
        }, [])
        modalService
            .open(ls.getString("addRegisteredDomain"), {
                component: RegisteredDomainsModal,
                props: {
                    networkIds,
                    canAddRegisteredDomain: props.canAddRegisteredDomain,
                },
                maxWidth: "lg",
            })
            .onClose((newRegisteredDomain: RegisteredDomain) => {
                props.onAddRegisteredDomain?.(newRegisteredDomain)
                const values: InternalValues = {
                    ...internalValues,
                    isUnregisteredDomain: false,
                    isWildCard: hasLeadingWildcard(newRegisteredDomain.name),
                    domainName: removeLeadingWildcard(newRegisteredDomain.name),
                }
                emitChanges(newRegisteredDomain.id || "", values)
            })
    }

    function setIsUnregisteredDomain(isUnregisteredDomain: boolean): void {
        const values: InternalValues = {
            ...internalValues,
            certificate:
                internalValues.certificate.type === CertificateType.LETS_ENCRYPT &&
                isUnregisteredDomain
                    ? banyanPkiCertificate
                    : internalValues.certificate,
            isUnregisteredDomain,
            domainName: isUnregisteredDomain ? internalValues.domainName : "",
        }
        emitChanges(props.selectedRegisteredDomainId, values)
    }

    function onCertificateTypeChange(certificateType: CertificateType): void {
        const values: InternalValues = {
            ...internalValues,
            certificate: getEmptyCertificate(certificateType),
        }
        emitChanges(props.selectedRegisteredDomainId, values)
    }

    function onCertificateFilePathChange(certificateFilePath: string): void {
        if (internalValues.certificate.type !== CertificateType.CUSTOM) return

        const values: InternalValues = {
            ...internalValues,
            certificate: {
                ...internalValues.certificate,
                certificateFilePath,
            },
        }
        emitChanges(props.selectedRegisteredDomainId, values)
    }

    function onPrivateKeyFilePathChange(privateKeyFilePath: string): void {
        if (internalValues.certificate.type !== CertificateType.CUSTOM) return

        const values: InternalValues = {
            ...internalValues,
            certificate: {
                ...internalValues.certificate,
                privateKeyFilePath,
            },
        }
        emitChanges(props.selectedRegisteredDomainId, values)
    }

    function setSubdomain(subdomain: string): void {
        const values: InternalValues = {
            ...internalValues,
            subdomain,
        }
        emitChanges(props.selectedRegisteredDomainId, values)
    }

    function setDomainName(domainName: string): void {
        const values: InternalValues = {
            ...internalValues,
            domainName,
        }
        emitChanges(props.selectedRegisteredDomainId, values)
    }

    function emitChanges(registeredDomainId: string, internalValues: InternalValues): void {
        let domain: string = internalValues.domainName
        if (selectedRegisteredDomainId && internalValues.isWildCard) {
            domain = concatUrl(internalValues.subdomain, internalValues.domainName)
        }

        setInternalValues(internalValues)
        props.onChange?.({
            registeredDomainId,
            domain: domain,
            port: port,
            certificate:
                !registeredDomainId &&
                internalValues.certificate?.type === CertificateType.LETS_ENCRYPT
                    ? banyanPkiCertificate
                    : internalValues.certificate,
        })
    }

    const certificateOptions = Object.values(CertificateType).reduce<Option[]>((acc, type) => {
        const displayName = ls.getString(labelDict[type])

        if (type === CertificateType.LETS_ENCRYPT) {
            if (internalValues.isUnregisteredDomain) return acc

            return [
                ...acc,
                {
                    displayName: ls.getString("somethingRecommended", displayName),
                    value: type,
                },
            ]
        }

        return [...acc, { displayName, value: type }]
    }, [])

    return (
        <FormGroup label={ls.getString("accessDetails")}>
            <FormRow
                label={ls.getString("whatDomainWouldYouLikeToUse")}
                description={
                    <AppText
                        ls={{
                            key: "sonicWallCseRecommendsUsingRegisteredDomainDesc",
                            replaceVals: [link.getLink("documentation")],
                        }}
                    />
                } //update with actual link
            >
                {props.infra.length > 0 ? (
                    <>
                        <MenuSelect
                            icon={faLink}
                            options={registeredDomainOptions}
                            value={selectedRegisteredDomainId}
                            label={ls.getString("selectRegisteredDomain")}
                            onSelect={onRegisteredDomainChange}
                            disabled={disabled || internalValues.isUnregisteredDomain}
                            required={!Boolean(internalValues.domainName)}
                            additionalOption={{
                                onClick: onAddRegisteredDomain,
                                label: ls.getString("addRegisteredDomain"),
                                icon: faPlus,
                            }}
                        />
                        {!selectedRegisteredDomainId && (
                            <Checkbox
                                checked={internalValues.isUnregisteredDomain}
                                onChange={setIsUnregisteredDomain}
                                className={styles.checkbox}
                                disabled={disabled}
                            >
                                <span>{ls.getString("useUnregisteredDomain")}</span>
                            </Checkbox>
                        )}
                    </>
                ) : (
                    <Button icon={faLink} disabled>
                        {ls.getString("pleaseSelectANetworkAbove")}
                    </Button>
                )}
            </FormRow>
            <FormRow
                label={ls.getString("customCertificateLabel")}
                description={
                    <AppText
                        ls={{
                            key: "customCertificateDescription",
                            replaceVals: [link.getLink("letsEncryptCertDoc")],
                        }}
                    />
                }
            >
                <SelectInput
                    value={internalValues.certificate?.type ?? ""}
                    options={certificateOptions}
                    onChange={onCertificateTypeChange}
                    disabled={disabled}
                    required
                />
            </FormRow>
            {internalValues.certificate?.type === CertificateType.CUSTOM && (
                <React.Fragment>
                    <FormRow label={ls.getString("certificateFilePath")} indented>
                        <Input
                            type="text"
                            placeholder={ls.getString("certificateFilePathPlaceholder")}
                            pattern={PatternUtil.STARTS_WITH_FORWARD_SLASH.source}
                            title={ls.getString("relativePathRegex")}
                            value={internalValues.certificate.certificateFilePath}
                            onChangeValue={onCertificateFilePathChange}
                            disabled={disabled}
                            required
                        />
                    </FormRow>
                    <FormRow label={ls.getString("privateKeyFilePath")} indented>
                        <Input
                            type="text"
                            placeholder={ls.getString("privateKeyFilePathPlaceholder")}
                            pattern={PatternUtil.STARTS_WITH_FORWARD_SLASH.source}
                            title={ls.getString("relativePathRegex")}
                            value={internalValues.certificate.privateKeyFilePath}
                            onChangeValue={onPrivateKeyFilePathChange}
                            disabled={disabled}
                            required
                        />
                    </FormRow>
                </React.Fragment>
            )}
            <FormRow
                label={ls.getString("publicUrlForThisService")}
                description={ls.getString("thisIsTheUrlEndUsersWillTypeDesc")}
                childrenClassName={styles.container}
            >
                {internalValues.isWildCard ? (
                    <>
                        <Input
                            type="text"
                            placeholder={ls.getString("egSubdomain")}
                            className={styles.input}
                            value={internalValues.subdomain}
                            onChange={(e) => setSubdomain(e.target.value)}
                            required
                            disabled={disabled}
                            aria-label={ls.getString("publicUrlSubdomain")}
                        />
                        <div className={styles.dot}>{ls.getString("dot")}</div>
                        <Input
                            type="text"
                            value={internalValues.domainName}
                            disabled={disabled || Boolean(selectedRegisteredDomainId)}
                            className={styles.input}
                        />
                    </>
                ) : (
                    <Input
                        type="text"
                        placeholder={ls.getString("egDomainExample")}
                        onChange={(e) => setDomainName(e.target.value)}
                        value={internalValues.domainName ?? ""}
                        required
                        aria-label={ls.getString("publicUrlDomain")}
                        disabled={
                            disabled ||
                            Boolean(
                                selectedRegisteredDomainId || !internalValues.isUnregisteredDomain
                            )
                        }
                        className={styles.input}
                    />
                )}
                <PortInput disabled className={styles.portInput} value={port} />
            </FormRow>
        </FormGroup>
    )
}

function getAvailableRegisteredDomains(
    registeredDomains: RegisteredDomain[],
    infra: HostedServiceInfra[]
): RegisteredDomain[] {
    const networkIds: string[] = infra.reduce<string[]>((acc, i) => {
        return acc.concat(i.networkIds)
    }, [])
    return registeredDomains.filter((r) => r.networkId && networkIds.includes(r.networkId))
}

function getEmptyCertificate(certificateType: CertificateType): Certificate {
    switch (certificateType) {
        case CertificateType.LETS_ENCRYPT:
            return letsEncryptCertificate
        case CertificateType.BANYAN_PKI:
            return banyanPkiCertificate
        case CertificateType.CUSTOM:
            return {
                type: CertificateType.CUSTOM,
                certificateFilePath: "",
                privateKeyFilePath: "",
            }
    }
}
