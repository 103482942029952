import { faSignIn, faUserShield } from "@fortawesome/pro-solid-svg-icons"
import React from "react"

import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { ROUTE } from "../../routes"
import { AppNavLink } from "../../pre-v3/services/shared/AppNavLink"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import styles from "./Settings.module.scss"
import { LeftNav } from "../../pre-v3/components/left-nav/LeftNav.component"
import { ActionBar } from "../../pre-v3/components/action-bar/ActionBar.component"
import classNames from "classnames"
import { Redirect, Route, Switch } from "react-router"
import { OrgSignOnSettings } from "../../pre-v3/views/settings/org/sign-on/OrgSignOnSettings.component"
import { MomManageAdmins } from "../mom-manage-admins/MomManageAdmins.page"

interface Props {
    canAccessSignOn: boolean
    canConfigureAdminSignOnSettings: boolean
}

export function Settings(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    function getDivider(label: LanguageKey): AppNavLink {
        return {
            divider: true,
            label: localization.getString(label),
        }
    }

    const sharedLinks = {
        adminSignOn: {
            label: localization.getString("adminSignOn"),
            href: ROUTE.MOM_SIGN_ON,
            icon: faSignIn,
        },
        manageAdmins: {
            label: localization.getString("manageAdmins"),
            href: ROUTE.MOM_MANAGE_ADMINS,
            icon: faUserShield,
        },
    }

    const navLinks = [
        getDivider("adminSettings"),
        sharedLinks.adminSignOn,
        sharedLinks.manageAdmins,
    ]

    return (
        <div className={styles.pageWithLeftNav}>
            <LeftNav links={navLinks} />
            <div className={styles.pageRightWithActionBar}>
                <ActionBar />
                <div className={classNames(styles.pageContent, styles.settingsContainer)}>
                    <Switch>
                        <Route path={ROUTE.MOM_MANAGE_ADMINS} component={MomManageAdmins} />
                        {props.canAccessSignOn && (
                            <Route path={[ROUTE.MOM_SIGN_ON]}>
                                <OrgSignOnSettings
                                    canConfigureAdminSignOnSettings={
                                        props.canConfigureAdminSignOnSettings
                                    }
                                />
                            </Route>
                        )}
                        <Redirect to={ROUTE.MOM_MANAGE_ADMINS} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}
