import { BaseApi } from "./Base.api"

export class SuperAdminApi extends BaseApi {
    public impersonate(orgName: string): Promise<MessageRes> {
        return this.post(
            `/api/v1/impersonate?OrgName=${encodeURIComponent(orgName)}`,
            {},
            {
                default: this.localization.getString("errorCouldNotImpersonate"),
            }
        )
    }
}

interface MessageRes {
    Message: string
}
