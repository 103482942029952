import React, { useState } from "react"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import styles from "./EndUser.module.scss"
import { Card } from "../../../../components/card/Card.component"
import { AppText } from "../../../../v3/components/app-text/AppText.component"
import { FormRow } from "../../../../v3/components/form/FormRow.component"
import { Input } from "../../../../components/input/Input.component"
import { SelectInput } from "../../../../v3/components/select-input/SelectInput.component"
import {
    IDPConfig,
    emptyIdpConfig,
    useGetEndUserIDPConfig,
    useSwitchBanyanIDPToLocalIDP,
    useUpdateIdpConfig,
} from "../../../../v3/services/EndUser.service"
import { Tooltip } from "../../../../v3/components/tooltip/Tooltip.component"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import { InputWithHide } from "../../../../pre-v3/components/input-with-hide/InputWithHide.component"
import { TextArea } from "../../../../components/input/TextArea.component"
import { Loader } from "../../../../v3/components/loader/Loader.component"
import { Banner, Variant } from "../../../../components/banner/Banner.component"
import { ScimProvisioning } from "./ScimProvisioning"
import { Form } from "../../../../components/form/Form.component"
import { ErrorToast, ToastApi } from "../../../../components/toast/Toast.components"
import { useServiceLinks } from "../../../../pre-v3/services/link/Link.service"
import { MessageContent, OkCancelActions, SectionScreen } from "../../../../pre-v3/components"
import { useServiceModal } from "../../../../pre-v3/services"
import {
    MetadataMethod,
    ProviderName,
    ProviderProtocol,
} from "../../../../v3/services/shared/EndUserDeviceConfig"
import { Icon } from "../../../../components/icon/Icon.component"
import { useDeleteDeviceProviderConfig } from "../../../../v3/services/DeviceConfig.service"

interface Props {
    canConfigureEndUserSettings?: boolean
    canAccessScimSetting: boolean
    canGenerateScimApiToken: boolean
}

export function EndUser(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const modalService = useServiceModal()
    const [initialConfig, setInitialConfig] = React.useState<IDPConfig>()
    const [isScimEnabled, setIsScimEnabled] = useState<boolean>(false)
    const knownIdpNames: ProviderName[] = Object.values(ProviderName)
    const [providerName, setProviderName] = useState("")
    const [providerProtocol, setProviderProtocol] = useState<ProviderProtocol>(
        ProviderProtocol.UNSET
    )
    const [otherIdpProviderName, setOtherIdpProviderName] = useState<string>("")
    const [isEdit, setIsEdit] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const links = useServiceLinks()
    const [metadataMethod, setMetaDataMethod] = useState<MetadataMethod>(MetadataMethod.MANUAL)
    const [caFileName, setCaFileName] = React.useState<string>("")
    const [caData, setCaData] = useState("")
    const errorToastRef = React.useRef<ToastApi>(null)
    const {
        data: endUserConfig,
        error: idpConfigError,
        refetch: refetchConfig,
    } = useGetEndUserIDPConfig()

    React.useEffect(() => {
        setIsScimEnabled(endUserConfig?.isScimEnabled ?? false)
        setInitialConfig(
            endUserConfig?.idpConfig.idpName === "" ? emptyIdpConfig : endUserConfig?.idpConfig
        )
        setProviderProtocol(endUserConfig?.idpConfig.idpProtocol ?? ProviderProtocol.UNSET)
        setProviderName(
            endUserConfig?.idpConfig.idpName
                ? getProviderName(endUserConfig?.idpConfig.idpName)
                : ""
        )
        setMetaDataMethod(
            endUserConfig?.idpConfig.metaDataUrl
                ? MetadataMethod.METADATA_URL
                : MetadataMethod.MANUAL
        )
        setOtherIdpProviderName(
            endUserConfig?.idpConfig.idpName
                ? getOtherProviderName(endUserConfig?.idpConfig.idpName)
                : ""
        )
        setCaData(endUserConfig?.idpConfig.caData ?? "")
    }, [endUserConfig])

    const { mutate: deleteDeviceConfig, isLoading: deleteConfigLoading } =
        useDeleteDeviceProviderConfig()

    const { mutateAsync: updateIdpConfig, isLoading: updateLoading } = useUpdateIdpConfig({
        onSuccess: () => {
            if (endUserConfig?.isDeviceIDPBanyan) {
                deleteDeviceConfig()
            }
            setIsEdit(false)
            setIsDisabled(true)
        },
        onError: () => {
            errorToastRef.current?.openToast(
                localization.getString("errorFailedToUpdateUserConfig")
            )
        },
    })

    const { mutate: switchBanyanIDPToLocalIDP } = useSwitchBanyanIDPToLocalIDP({
        onError: () => {
            errorToastRef.current?.openToast(localization.getString("failedToSwitchToLocalIdp"))
        },
    })

    if (typeof idpConfigError === "string") {
        return <Banner label={String(idpConfigError)} variant={Variant.ERROR} />
    }
    if (!initialConfig) return <Loader isLoading children center medium />

    function getProviderName(idpName: string): string {
        if (knownIdpNames.includes(idpName as ProviderName)) {
            return idpName
        } else {
            return ProviderName.OTHER
        }
    }

    function getOtherProviderName(idpName: string): string {
        if (knownIdpNames.includes(idpName as ProviderName)) {
            return ""
        } else {
            return idpName
        }
    }

    function onChangeProviderName(providerName: string) {
        if (providerName !== endUserConfig?.idpConfig.idpName) {
            setCaData("")
            setInitialConfig({
                ...emptyIdpConfig,
                entityIssuer: endUserConfig?.idpConfig.entityIssuer ?? "",
                redirectUrl: endUserConfig?.idpConfig.redirectUrl ?? "",
            })
        } else {
            setCaData(endUserConfig.idpConfig.caData)
            setInitialConfig(endUserConfig?.idpConfig)
        }
        setProviderName(providerName)
        if (providerName === ProviderName.ENTRA_ID) setProviderProtocol(ProviderProtocol.SAML)
        else if (providerName === ProviderName.OKTA) setProviderProtocol(ProviderProtocol.OIDC)
        else setProviderProtocol(ProviderProtocol.OIDC)

        if (initialConfig?.metaDataUrl || endUserConfig?.idpConfig.metaDataUrl) {
            setMetaDataMethod(MetadataMethod.METADATA_URL)
        } else {
            setMetaDataMethod(MetadataMethod.MANUAL)
        }
    }

    function onChangeProtocol(protocol: ProviderProtocol) {
        setProviderProtocol(protocol)
        if (protocol === ProviderProtocol.SAML && providerName === ProviderName.ENTRA_ID) {
            setMetaDataMethod(MetadataMethod.METADATA_URL)
        } else {
            setMetaDataMethod(MetadataMethod.MANUAL)
        }
    }

    const providerNameOptions = [
        {
            displayName: localization.getString("entraId"),
            value: ProviderName.ENTRA_ID,
        },
        {
            displayName: localization.getString("okta"),
            value: ProviderName.OKTA,
        },
        {
            displayName: localization.getString("oneLogin"),
            value: ProviderName.ONE_LOGIN,
        },
        {
            displayName: localization.getString("other"),
            value: ProviderName.OTHER,
        },
    ]

    const metaDataMethodOptions = [
        {
            displayName: localization.getString("manual"),
            value: MetadataMethod.MANUAL,
        },
        {
            displayName: localization.getString("idpMetadataUrl"),
            value: MetadataMethod.METADATA_URL,
        },
    ]

    const protocolOptions = Object.values(ProviderProtocol).filter(
        (p) => p !== ProviderProtocol.UNSET
    )

    const onRedirectUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialConfig({
            ...initialConfig,
            redirectUrl: event.target.value,
        })
    }

    const onIssuerUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialConfig({
            ...initialConfig,
            issuerUrl: event.target.value,
        })
    }

    const onClientIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialConfig({
            ...initialConfig,
            clientId: event.target.value,
        })
    }

    const onClientSecretChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialConfig({
            ...initialConfig,
            clientSecret: event.target.value,
        })
    }

    const onIdpSsoUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialConfig({
            ...initialConfig,
            ssoUrl: event.target.value,
        })
    }

    const onEntityIssuerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialConfig({
            ...initialConfig,
            entityIssuer: event.target.value,
        })
    }

    const onUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialConfig({
            ...initialConfig,
            userName: event.target.value,
        })
    }

    const onGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialConfig({
            ...initialConfig,
            group: event.target.value,
        })
    }

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialConfig({
            ...initialConfig,
            email: event.target.value,
        })
    }

    const onGroupDelimiterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialConfig({
            ...initialConfig,
            groupDelimiter: event.target.value,
        })
    }

    const onMetaDataUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialConfig({
            ...initialConfig,
            metaDataUrl: event.target.value,
        })
    }

    function onEdit() {
        setIsEdit(true)
        setIsDisabled(false)
    }

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const idpConfig: IDPConfig = {
            ...initialConfig,
            idpName: providerName === ProviderName.OTHER ? otherIdpProviderName : providerName,
            idpProtocol: providerProtocol as ProviderProtocol,
            metaDataUrl:
                metadataMethod === MetadataMethod.METADATA_URL ? initialConfig.metaDataUrl : "",
            entityIssuer: initialConfig.entityIssuer
                ? initialConfig.entityIssuer
                : initialConfig.redirectUrl,
            caData: caData,
        }
        await updateIdpConfig(idpConfig)
        setCaFileName("")
    }

    const onReset = () => {
        setInitialConfig(
            endUserConfig?.idpConfig.idpName === "" ? emptyIdpConfig : endUserConfig?.idpConfig
        )
        setProviderProtocol(endUserConfig?.idpConfig.idpProtocol ?? ProviderProtocol.UNSET)
        setMetaDataMethod(
            endUserConfig?.idpConfig.metaDataUrl
                ? MetadataMethod.METADATA_URL
                : MetadataMethod.MANUAL
        )
        setProviderName(
            endUserConfig?.idpConfig.idpName
                ? getProviderName(endUserConfig?.idpConfig.idpName)
                : ""
        )
        setOtherIdpProviderName(
            endUserConfig?.idpConfig.idpName
                ? getOtherProviderName(endUserConfig?.idpConfig.idpName)
                : ""
        )
        setIsEdit(false)
        setIsDisabled(true)
        setCaData(endUserConfig?.idpConfig.caData ?? "")
        setCaFileName("")
    }

    function onConfigure(): void {
        modalService
            .open(
                localization.getString(
                    "configureSomething",
                    localization.getString("userIdentityProvider")
                ),
                {
                    component: MessageContent,
                    props: {
                        text: (
                            <AppText
                                ls={{
                                    key: "areYouSureYouWantToConfigureSomething",
                                    replaceVals: [localization.getString("userIdentityProvider")],
                                }}
                            />
                        ),
                    },
                    maxWidth: "sm",
                },
                {
                    component: OkCancelActions,
                    props: { okString: localization.getString("confirm") },
                }
            )
            .onClose(() => {
                switchBanyanIDPToLocalIDP()
            })
    }

    function onScimToggleChange(state: boolean) {
        setIsScimEnabled(state)
        refetchConfig()
    }

    const onUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        if (event.target.files === null) return
        const file = event.target.files[0]
        setCaFileName(file.name)
        const filetest = file && (await getBase64(file))
        setCaData(filetest)
    }

    return (
        <div className={styles.container}>
            {endUserConfig?.isAdminBanyanIdp ? (
                <>
                    <SectionScreen
                        title={localization.getString("userIdentityProvider")}
                        description={<AppText ls="userIdentityProviderUpgradeDescription" />}
                        onConfigureButtonClick={onConfigure}
                        disableButton={!props.canConfigureEndUserSettings}
                    />
                </>
            ) : (
                <>
                    {endUserConfig?.isCognitoIdp && (
                        <Banner
                            label={localization.getString(
                                "currentlyCloudSecureEdgeIsConfiguredToAuthenticateDesc"
                            )}
                            variant={Variant.INFORMATION}
                        />
                    )}
                    <div className={styles.buttons}>
                        {!isEdit && (
                            <>
                                {props.canConfigureEndUserSettings && (
                                    <Tooltip
                                        title={
                                            isScimEnabled
                                                ? localization.getString(
                                                      "toModifyIdpConfigurationDisableScim"
                                                  )
                                                : localization.getString("edit")
                                        }
                                    >
                                        <span>
                                            <Button
                                                icon={IconType.PEN}
                                                onClick={onEdit}
                                                asElement={ButtonElement.BUTTON}
                                                buttonType={ButtonType.SECONDARY}
                                                aria-label={localization.getString("edit")}
                                                disabled={isScimEnabled}
                                            />
                                        </span>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </div>
                    <Form onSubmit={onSubmit} onReset={onReset}>
                        <Card className={styles.endUserContainer}>
                            <div className={styles.titleDescription}>
                                <div className={styles.titleContainer}>
                                    <h2>{localization.getString("userIdentityProvider")}</h2>
                                    <AppText
                                        ls={{
                                            key: "userIdentityProviderDescription",
                                            replaceVals: [links.getLink("setUpDirectory")],
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.form}>
                                <FormRow
                                    label={localization.getString("providerName")}
                                    description={localization.getString("providerNameDescription")}
                                >
                                    <div className={styles.otherInput}>
                                        <SelectInput
                                            required
                                            value={providerName}
                                            onChange={(p) => onChangeProviderName(p)}
                                            options={providerNameOptions}
                                            disabled={
                                                isDisabled || !props.canConfigureEndUserSettings
                                            }
                                        />
                                        {providerName === ProviderName.OTHER && (
                                            <Input
                                                value={otherIdpProviderName}
                                                type="text"
                                                name="name"
                                                onChangeValue={setOtherIdpProviderName}
                                                placeholder={localization.getString("providerName")}
                                                disabled={
                                                    isDisabled || !props.canConfigureEndUserSettings
                                                }
                                                required
                                            />
                                        )}
                                    </div>
                                </FormRow>
                                <FormRow
                                    label={localization.getString("providerProtocol")}
                                    description={localization.getString(
                                        "providerProtocolDescription"
                                    )}
                                >
                                    <SelectInput
                                        required
                                        value={providerProtocol}
                                        onChange={(p) => onChangeProtocol(p)}
                                        options={protocolOptions}
                                        disabled={isDisabled || !props.canConfigureEndUserSettings}
                                    />
                                </FormRow>
                                <FormRow
                                    label={localization.getString("redirectUrl")}
                                    description={localization.getString("redirectUrlDescription")}
                                >
                                    <Input
                                        value={initialConfig.redirectUrl}
                                        type="text"
                                        name="name"
                                        placeholder={localization.getString("redirectUrl")}
                                        disabled={isDisabled || !props.canConfigureEndUserSettings}
                                        onChange={onRedirectUrlChange}
                                        required
                                    />
                                </FormRow>
                                {providerProtocol === ProviderProtocol.SAML && (
                                    <FormRow
                                        label={localization.getString(
                                            "somethingOptional",
                                            localization.getString("entityIssuer")
                                        )}
                                        description={localization.getString(
                                            "entityIssuerIdentifierMustBeUniqueForYourIdp"
                                        )}
                                    >
                                        <Input
                                            value={initialConfig.entityIssuer}
                                            type="text"
                                            name="name"
                                            placeholder={localization.getString("entityIssuer")}
                                            disabled={isDisabled}
                                            onChange={onEntityIssuerChange}
                                        />
                                    </FormRow>
                                )}
                            </div>
                        </Card>
                        {providerProtocol && (
                            <Card className={styles.endUserContainer}>
                                <div className={styles.titleDescription}>
                                    <div className={styles.titleContainer}>
                                        <h2>{localization.getString("idpSettings")}</h2>
                                    </div>
                                </div>
                                <div className={styles.form}>
                                    {providerProtocol === ProviderProtocol.OIDC && (
                                        <>
                                            <FormRow
                                                label={localization.getString("appClientName")}
                                                description={localization.getString(
                                                    "appClientNameCSEDescription"
                                                )}
                                            >
                                                <Input
                                                    value={initialConfig.appClientName}
                                                    type="text"
                                                    name="name"
                                                    placeholder={localization.getString(
                                                        "appClientName"
                                                    )}
                                                    disabled
                                                />
                                            </FormRow>
                                            <FormRow
                                                label={localization.getString("issuerUrl")}
                                                description={localization.getString(
                                                    "issuerUrlDescription"
                                                )}
                                            >
                                                <Input
                                                    value={initialConfig.issuerUrl}
                                                    type="text"
                                                    name="name"
                                                    placeholder={localization.getString(
                                                        "issuerUrl"
                                                    )}
                                                    disabled={
                                                        isDisabled ||
                                                        !props.canConfigureEndUserSettings
                                                    }
                                                    onChange={onIssuerUrlChange}
                                                    required
                                                />
                                            </FormRow>
                                            <FormRow
                                                label={localization.getString("clientId")}
                                                description={localization.getString(
                                                    "clientIdDescription"
                                                )}
                                            >
                                                <Input
                                                    value={initialConfig.clientId}
                                                    type="text"
                                                    name="name"
                                                    placeholder={localization.getString("clientId")}
                                                    disabled={
                                                        isDisabled ||
                                                        !props.canConfigureEndUserSettings
                                                    }
                                                    onChange={onClientIdChange}
                                                    required
                                                />
                                            </FormRow>
                                            <FormRow
                                                label={localization.getString("clientSecret")}
                                                description={localization.getString(
                                                    "clientSecretDescription"
                                                )}
                                            >
                                                {isDisabled ? (
                                                    <InputWithHide
                                                        disabled
                                                        value={initialConfig.clientSecret}
                                                        hideEyeIcon={
                                                            !props.canConfigureEndUserSettings
                                                        }
                                                    />
                                                ) : (
                                                    <Input
                                                        type="text"
                                                        value={initialConfig.clientSecret}
                                                        onChange={onClientSecretChange}
                                                        placeholder={localization.getString(
                                                            "clientSecret"
                                                        )}
                                                        disabled={isDisabled}
                                                        required
                                                    />
                                                )}
                                            </FormRow>
                                        </>
                                    )}
                                    {providerProtocol === ProviderProtocol.SAML && (
                                        <FormRow
                                            label={localization.getString("idpMetadataMethod")}
                                            description={localization.getString(
                                                "idpMetadataMethodDescription"
                                            )}
                                        >
                                            <SelectInput
                                                value={metadataMethod}
                                                onChange={setMetaDataMethod}
                                                options={metaDataMethodOptions}
                                                disabled={
                                                    isDisabled ||
                                                    providerName !== ProviderName.ENTRA_ID
                                                }
                                            />
                                        </FormRow>
                                    )}
                                    {providerProtocol === ProviderProtocol.SAML &&
                                        metadataMethod === MetadataMethod.METADATA_URL && (
                                            <FormRow
                                                label={localization.getString("idpMetadataUrl")}
                                                description={localization.getString(
                                                    "idpMetadataUrlDescription"
                                                )}
                                            >
                                                <Input
                                                    value={initialConfig.metaDataUrl}
                                                    type="text"
                                                    name="name"
                                                    placeholder={localization.getString(
                                                        "idpMetadataUrl"
                                                    )}
                                                    disabled={isDisabled}
                                                    onChange={onMetaDataUrlChange}
                                                    required
                                                />
                                            </FormRow>
                                        )}
                                    {providerProtocol === ProviderProtocol.SAML &&
                                        metadataMethod === MetadataMethod.MANUAL && (
                                            <>
                                                <FormRow
                                                    label={localization.getString("idpSsoUrl")}
                                                    description={localization.getString(
                                                        "idpSsoUrlDescription"
                                                    )}
                                                >
                                                    <Input
                                                        value={initialConfig.ssoUrl}
                                                        type="text"
                                                        name="name"
                                                        placeholder={localization.getString(
                                                            "idpSsoUrl"
                                                        )}
                                                        disabled={isDisabled}
                                                        onChange={onIdpSsoUrl}
                                                        required
                                                    />
                                                </FormRow>
                                                <FormRow
                                                    label={localization.getString(
                                                        "idpCaCertificate"
                                                    )}
                                                    description={localization.getString(
                                                        "idpCaCertificateDescription"
                                                    )}
                                                >
                                                    <TextArea
                                                        placeholder={localization.getString(
                                                            "idpCaCertificate"
                                                        )}
                                                        value={caData}
                                                        disabled={
                                                            isDisabled ||
                                                            !props.canConfigureEndUserSettings
                                                        }
                                                        onChangeValue={setCaData}
                                                        required
                                                        className={styles.textArea}
                                                    />
                                                    {isEdit && (
                                                        <>
                                                            <label
                                                                htmlFor="filePicker"
                                                                className={styles.filePicker}
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.uploadFileButtonContainer
                                                                    }
                                                                >
                                                                    <label>
                                                                        {localization.getString(
                                                                            "certificateFileSupportedFormats"
                                                                        )}
                                                                    </label>
                                                                    <div
                                                                        className={
                                                                            styles.uploadFileButton
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            icon={IconType.UPLOAD}
                                                                        />
                                                                        <span>
                                                                            {localization.getString(
                                                                                "upload"
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    {caFileName && (
                                                                        <span
                                                                            className={
                                                                                styles.filename
                                                                            }
                                                                        >
                                                                            {caFileName}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </label>
                                                            <Input
                                                                disabled={isDisabled}
                                                                id="filePicker"
                                                                type="file"
                                                                onChange={onUpload}
                                                                accept=".cer, .cert"
                                                                key={caFileName}
                                                            />
                                                        </>
                                                    )}
                                                </FormRow>
                                                <FormRow
                                                    label={localization.getString("username")}
                                                    description={localization.getString(
                                                        "clientIdDescription"
                                                    )}
                                                >
                                                    <Input
                                                        value={initialConfig.userName}
                                                        type="text"
                                                        name="name"
                                                        disabled={
                                                            isDisabled ||
                                                            !props.canConfigureEndUserSettings
                                                        }
                                                        placeholder={localization.getString(
                                                            "username"
                                                        )}
                                                        onChange={onUserNameChange}
                                                        required
                                                    />
                                                </FormRow>
                                                <FormRow
                                                    label={localization.getString("email")}
                                                    description={localization.getString(
                                                        "clientSecretDescription"
                                                    )}
                                                >
                                                    <Input
                                                        value={initialConfig.email}
                                                        type="text"
                                                        name="name"
                                                        disabled={
                                                            isDisabled ||
                                                            !props.canConfigureEndUserSettings
                                                        }
                                                        placeholder={localization.getString(
                                                            "email"
                                                        )}
                                                        onChange={onEmailChange}
                                                        required
                                                    />
                                                </FormRow>
                                                <FormRow
                                                    label={localization.getString("groups")}
                                                    description={localization.getString(
                                                        "groupsDescription"
                                                    )}
                                                >
                                                    <Input
                                                        value={initialConfig.group}
                                                        disabled={
                                                            isDisabled ||
                                                            !props.canConfigureEndUserSettings
                                                        }
                                                        type="text"
                                                        name="name"
                                                        placeholder={localization.getString(
                                                            "groups"
                                                        )}
                                                        onChange={onGroupChange}
                                                        required
                                                    />
                                                </FormRow>
                                                <FormRow
                                                    label={localization.getString(
                                                        "somethingOptional",
                                                        localization.getString("groupDelimiter")
                                                    )}
                                                    description={localization.getString(
                                                        "groupDelimiterDescription"
                                                    )}
                                                >
                                                    <Input
                                                        value={initialConfig.groupDelimiter}
                                                        disabled={
                                                            isDisabled ||
                                                            !props.canConfigureEndUserSettings
                                                        }
                                                        type="text"
                                                        name="name"
                                                        placeholder={localization.getString(
                                                            "groupDelimiter"
                                                        )}
                                                        onChange={onGroupDelimiterChange}
                                                    />
                                                </FormRow>
                                            </>
                                        )}
                                </div>
                            </Card>
                        )}
                        {isEdit && (
                            <div className={styles.buttons}>
                                <Button
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.SECONDARY}
                                    type="reset"
                                >
                                    {localization.getString("cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    asElement={ButtonElement.BUTTON}
                                    buttonType={ButtonType.PRIMARY}
                                    loading={updateLoading || deleteConfigLoading}
                                >
                                    {localization.getString("save")}
                                </Button>
                            </div>
                        )}
                        <ErrorToast ref={errorToastRef} />
                    </Form>
                    <ScimProvisioning
                        isScimEnabled={isScimEnabled}
                        enableScimToggle={endUserConfig?.idpConfig ? false : true}
                        onChange={(state) => onScimToggleChange(state)}
                        canAccessScimSetting={props.canAccessScimSetting}
                        isIdpEdit={isEdit}
                        canGenerateScimApiToken={props.canGenerateScimApiToken}
                    />
                </>
            )}
        </div>
    )
}

function getBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = (error) => reject(error)
    })
}
