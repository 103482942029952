import { getUrlDomain } from "../../../utils/url.utils"

export const noneDeviceProviderName: string = "NONE"

export enum ProviderName {
    ENTRA_ID = "ENTRA ID",
    OKTA = "OKTA",
    ONE_LOGIN = "ONE LOGIN",
    OTHER = "OTHER",
}

export enum CseProviderName {
    COGNITO = "COGNITO",
    BANYAN = "BANYAN",
}

export enum ProviderProtocol {
    UNSET = "",
    SAML = "SAML",
    OIDC = "OIDC",
}

export enum MetadataMethod {
    MANUAL = "MANUAL",
    METADATA_URL = "METADATA_URL",
}

export function isBanyanIdp(idpName: string) {
    return idpName === CseProviderName.COGNITO || idpName === CseProviderName.BANYAN
}

export function getEntityIssuerUrl(orgName: string, entityIssuerIdentifier: "user" | "device") {
    const url = window.location.origin
    const urlDomain = getUrlDomain(url)
    return `urn:entity-${entityIssuerIdentifier}:${orgName}.portal-${urlDomain}`
}
