import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    ITooltipParams,
    ValueFormatterParams,
    ValueGetterParams,
} from "ag-grid-community"
import React, { useEffect, useMemo, useRef } from "react"

import { ErrorBanner } from "../../../../pre-v3/components/banner/Banner.component"
import { Grid } from "../../../../pre-v3/components/grid/Grid.component"
import { LocalizationService, useServiceLocalization } from "../../../../pre-v3/services"
import { UrlUtil, encodeID } from "../../../../pre-v3/utils/Url.util"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Loader } from "../../../components/loader/Loader.component"
import { Policy, useGetPoliciesByRoleId } from "../../../services/Policy.service"
import { Status } from "../../../components/status/Status.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import ActionBarService from "../../../../pre-v3/services/ActionBar.service"
import { SearchInput } from "../../../components/search-input/SearchInput.component"
import styles from "./RoleOverview.module.scss"
import { PolicyStatus } from "../../../services/shared/Policy"

export interface RoleDevicesTabProps {
    roleId: string
}

export function RoleLinkedPoliciesTab(props: RoleDevicesTabProps) {
    const columns = useColumns()
    const grid = useRef<GridApi>()
    const actionBarService = new ActionBarService()
    const localization = new LocalizationService()

    const [search, setSearch] = React.useState<string>(UrlUtil.getUrlParam("linkedPolicySearch"))

    const getPoliciesByRoleIdQuery = useGetPoliciesByRoleId(props.roleId)

    function onGridReady(event: GridReadyEvent) {
        grid.current = event.api
        grid.current?.setQuickFilter(search)
    }

    function onSearchChange(updatedSearch: string) {
        grid.current?.setQuickFilter(updatedSearch)
        setSearch(updatedSearch)
        UrlUtil.setURlParams("linkedPolicySearch", updatedSearch)
    }

    useEffect(() => {
        actionBarService.registerRefreshFn(getPoliciesByRoleIdQuery.refetch)

        return () => {
            actionBarService.unregisterRefreshFn(getPoliciesByRoleIdQuery.refetch)
        }
    }, [])

    return (
        <div className={styles.tabContainer}>
            <SearchInput
                value={search}
                onChangeValue={onSearchChange}
                placeholder={localization.getString("search")}
                className={styles.searchInput}
            />
            <Loader isLoading={getPoliciesByRoleIdQuery.isLoading} medium center>
                {getPoliciesByRoleIdQuery.error ? (
                    <ErrorBanner>{String(getPoliciesByRoleIdQuery.error)}</ErrorBanner>
                ) : (
                    <Grid
                        rowData={getPoliciesByRoleIdQuery.data}
                        onGridReady={onGridReady}
                        columnDefs={columns}
                        pagination
                    />
                )}
            </Loader>
        </div>
    )
}

function useColumns(): ColDef<Policy>[] {
    const localization = useServiceLocalization()

    return useMemo((): ColDef<Policy>[] => {
        return [
            {
                colId: "status",
                field: "status",
                headerName: localization.getString("status"),
                cellRenderer: (props: ICellRendererParams<Policy>) => {
                    return (
                        <Status
                            type={
                                props.data?.status === PolicyStatus.ACTIVE ? "success" : "disabled"
                            }
                            label={localization.getString(
                                props.data?.status === PolicyStatus.ACTIVE ? "active" : "inactive"
                            )}
                        />
                    )
                },
            },
            {
                colId: "name",
                field: "name",
                headerName: localization.getString("policyName"),
                cellRenderer: (props: ICellRendererParams<Policy>) => {
                    return (
                        props.data &&
                        (props.data.type === "tunnel" ? (
                            <RowTitle
                                title={props.data.name || ""}
                                route={formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, {
                                    id: encodeID(props.data.id),
                                })}
                            />
                        ) : (
                            <RowTitle
                                title={props.data.name || ""}
                                route={formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, {
                                    id: props.data.id,
                                })}
                            />
                        ))
                    )
                },
            },
            {
                colId: "type",
                field: "type",
                headerName: localization.getString("policyType"),
                cellRenderer: (props: ICellRendererParams<Policy>) => {
                    return props.data ? localization.getString(props.data.type) : ""
                },
            },
            {
                colId: "lastUpdated",
                field: "lastUpdated",
                headerName: localization.getString("lastUpdated"),
                valueFormatter: (props: ValueFormatterParams<Policy>) =>
                    DateUtil.format(props.data?.lastUpdatedAt),
                tooltipValueGetter: (props: ITooltipParams<Policy>) =>
                    DateUtil.format(props.data?.lastUpdatedAt),
                valueGetter: (props: ValueGetterParams<Policy>) =>
                    DateUtil.format(props.data?.lastUpdatedAt),
                filter: "agTextColumnFilter",
            },
        ]
    }, [])
}
