import React from "react"

import { Input } from "../../../components/input/Input.component"
import { FormLabel } from "../../../pre-v3/components/form-label/FormLabel"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"

interface Props {
    id: string
    value: string
    disabled?: boolean
    onChange?(value: string): void
}

export function CustomerIdInput(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <FormLabel inline title={localization.getString("customerId")} htmlFor={props.id}>
            <Input
                type="text"
                id={props.id}
                value={props.value}
                onChangeValue={props.onChange}
                disabled={props.disabled}
                required
            />
        </FormLabel>
    )
}
