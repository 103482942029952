import React from "react"
import styles from "./AccessGroupInfo.module.scss"
import { FormRow } from "../../../components/form/FormRow.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { TrustLevel, useGetRoles } from "../../../v3/services/Policy.service"
import { Input } from "../../../components/input/Input.component"
import { SelectInput } from "../../../v3/components/select-input/SelectInput.component"
import { FormColumn } from "../../../v3/components/form/FormColumn.component"
import { PillMultiSelect } from "../../../v3/components/pill-multi-select/PillMultiSelect.component"
import { IconType } from "../../../components/icon/Icon.component"
import { Checkbox } from "../../../v3/components/checkbox/Checkbox.component"
import { PolicyAccessExtendedWithId } from "./PolicyAccess.type"

export interface Props {
    disabled?: boolean
    accessGroupInfo: PolicyAccessExtendedWithId
    onAccessGroupInfoChange(accessGroupInfo: PolicyAccessExtendedWithId): void
}

export function AccessGroupInformation(props: Props): JSX.Element {
    const localization = useServiceLocalization()
    const { data: roles, isLoading } = useGetRoles()
    const anyRole: string = "ANY"
    const [isAnyRole, setIsAnyRole] = React.useState<boolean>(
        props.accessGroupInfo.roles.includes(anyRole)
    )

    const [initialRoles, setInitialRoles] = React.useState<string[]>(props.accessGroupInfo.roles)

    const trustLevelOptions = [
        {
            displayName: localization.getString("ignoreTrustLevel"),
            value: TrustLevel.NONE,
        },
        {
            displayName: localization.getString("lowTrustLevel"),
            value: TrustLevel.LOW,
        },
        {
            displayName: localization.getString("mediumTrustLevel"),
            value: TrustLevel.MID,
        },
        {
            displayName: localization.getString("highTrustLevel"),
            value: TrustLevel.HIGH,
        },
    ]

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onAccessGroupInfoChange({
            ...props.accessGroupInfo,
            name: event.target.value,
        })
    }

    const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onAccessGroupInfoChange({
            ...props.accessGroupInfo,
            description: event.target.value,
        })
    }

    const onTrustLevelChange = (value: string) => {
        props.onAccessGroupInfoChange({
            ...props.accessGroupInfo,
            rules: {
                ...props.accessGroupInfo.rules,
                conditions: {
                    ...props.accessGroupInfo.rules.conditions,
                    trust_level: value,
                },
            },
        })
    }

    const onRolesChange = (roles: string[]) => {
        setInitialRoles(roles)
        setIsAnyRole(!roles.includes(anyRole) ? false : true)
        props.onAccessGroupInfoChange({
            ...props.accessGroupInfo,
            roles,
        })
    }

    const onAnyRoleCheck = (checked: boolean) => {
        setIsAnyRole(checked)
        props.onAccessGroupInfoChange({
            ...props.accessGroupInfo,
            roles: checked ? [anyRole] : initialRoles.filter((role) => role !== anyRole),
        })
    }

    return (
        <div className={styles.accessGroupInfo}>
            <FormRow
                label={localization.getString("somethingOptional", localization.getString("name"))}
                htmlFor={`${Id.NAME}-${props.accessGroupInfo.id}`}
            >
                <Input
                    id={`${Id.NAME}-${props.accessGroupInfo.id}`}
                    defaultValue={props.accessGroupInfo.name}
                    type="text"
                    name="name"
                    placeholder={localization.getString("name")}
                    disabled={props.disabled}
                    onChange={onNameChange}
                />
            </FormRow>

            <FormRow
                label={localization.getString(
                    "somethingOptional",
                    localization.getString("description")
                )}
            >
                <Input
                    defaultValue={props.accessGroupInfo.description}
                    type="text"
                    name="description"
                    onChange={onDescriptionChange}
                    placeholder={localization.getString("description")}
                    disabled={props.disabled}
                />
            </FormRow>
            <FormRow
                label={localization.getString("selectTrustLevel")}
                description={localization.getString("selectTheMinimumRequiredTrustLevelForAccess")}
            >
                <SelectInput
                    required
                    disabled={props.disabled}
                    value={props.accessGroupInfo.rules.conditions.trust_level || TrustLevel.NONE}
                    onChange={onTrustLevelChange}
                    options={trustLevelOptions}
                />
            </FormRow>
            {/* TODO: Remove FormColumn */}
            <FormColumn label={""}>
                <PillMultiSelect
                    id={Id.ROLES}
                    loading={isLoading}
                    label={localization.getString("selectRoles")}
                    description={localization.getString("selectOneOrMoreRolesDesc")}
                    placeholder={localization.getString("attachARole")}
                    disableButton={isAnyRole}
                    options={
                        roles?.map((roleName) => {
                            return {
                                label: roleName,
                                value: roleName,
                            }
                        }) || []
                    }
                    value={props.accessGroupInfo.roles}
                    onChange={onRolesChange}
                    icon={IconType.PLUS}
                    required
                    disabled={props.disabled}
                >
                    <Checkbox
                        checked={isAnyRole}
                        onChange={onAnyRoleCheck}
                        disabled={props.disabled}
                    >
                        {localization.getString("any")}
                    </Checkbox>
                </PillMultiSelect>
            </FormColumn>
        </div>
    )
}

enum Id {
    NAME = "accessGroupName",
    ROLES = "accessGroupRoles",
}
