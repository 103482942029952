import * as React from "react"
import { useHistory } from "react-router-dom"

import { ROUTE, formatRoutePath } from "../../../../routes"
import { PolicyAttr, PolicyMetadata } from "../../../api/Secure.api"
import { PolicyType } from "../../../services"
import { PolicyForm } from "../form/PolicyForm"
import { encodeID } from "../../../utils/Url.util"
import { OtherPolicy, useOldEditPolicy } from "../../../../v3/services/Policy.service"

interface Props {
    policy: OtherPolicy
}

export function PolicyEdit({ policy }: Props) {
    const history = useHistory()

    const { mutateAsync: editPolicy } = useOldEditPolicy(policy.id)

    const submitForm = async ({
        attr,
        metadata,
        type,
    }: {
        type: PolicyType
        attr: PolicyAttr
        metadata: PolicyMetadata
    }) => {
        await editPolicy({ metadata, type, attr })
        history.push(formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, { id: encodeID(policy.id) }))
    }

    const onCancel = () => {
        history.push(formatRoutePath(ROUTE.ACCESS_POLICIES_DETAILS, { id: encodeID(policy.id) }))
    }

    return <PolicyForm onSubmit={submitForm} edit={policy} onCancel={onCancel} />
}
