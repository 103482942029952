import React from "react"

import { Button, ButtonType } from "../../../components/button/Button.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import {
    AccessTierBundles,
    SupportBundleInformation as BundleInformation,
    ClusterBundles,
    NetagentInstanceBundle,
    SupportBundlesRequest as Request,
} from "../../../v3/services/MomOrgManagement.service"
import styles from "./SupportBundlesTable.module.scss"

interface Props {
    request: Request
    onSupportBundleDownload(bundleInformation: BundleInformation): Promise<void>
}

export const SupportBundlesTable = (props: Props) => {
    const localization = useServiceLocalization()

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th rowSpan={2} />
                    <th colSpan={2}>{localization.getString("component")}</th>
                    <th colSpan={3}>{localization.getString("supportBundle")}</th>
                </tr>
                <tr>
                    <th>{localization.getString("accessTier")}</th>
                    <th>{localization.getString("netagentInstance")}</th>
                    <th>{localization.getString("fileSize")}</th>
                    <th>{localization.getString("lastModified")}</th>
                    <th className={styles.downloadCellHeader}></th>
                </tr>
            </thead>
            <tbody>
                {props.request.clusterBundles.map((clusterBundles) => (
                    <ClusterBundlesRow
                        key={clusterBundles.clusterName}
                        clusterBundles={clusterBundles}
                        requestedAt={props.request.requestedAt}
                        onSupportBundleDownload={props.onSupportBundleDownload}
                    />
                ))}
            </tbody>
        </table>
    )
}

interface ClusterBundlesRowProps {
    clusterBundles: ClusterBundles
    requestedAt: Date
    onSupportBundleDownload(bundleInformation: BundleInformation): Promise<void>
}

const ClusterBundlesRow = (props: ClusterBundlesRowProps) => {
    const localization = useServiceLocalization()
    const shieldCount = 1
    const totalNetagentInstanceCount = props.clusterBundles.accessTierBundles.reduce(
        addNetagentCountToTotal,
        0
    )

    return (
        <React.Fragment>
            <tr>
                <td rowSpan={shieldCount + totalNetagentInstanceCount}>
                    {props.clusterBundles.clusterName}
                </td>
                <td colSpan={2}>{localization.getString("shield")}</td>
                <BundleInformationCell
                    bundleInformation={props.clusterBundles.shieldBundle}
                    requestedAt={props.requestedAt}
                    onSupportBundleDownload={props.onSupportBundleDownload}
                />
            </tr>
            {props.clusterBundles.accessTierBundles.map((accessTierBundles) => (
                <AccessTierBundlesRow
                    key={accessTierBundles.accessTierName}
                    accessTierBundles={accessTierBundles}
                    requestedAt={props.requestedAt}
                    onSupportBundleDownload={props.onSupportBundleDownload}
                />
            ))}
        </React.Fragment>
    )
}

function addNetagentCountToTotal(total: number, accessTierBundles: AccessTierBundles): number {
    return total + accessTierBundles.netagentInstanceBundles.length
}

interface AccessTierBundlesRowProps {
    accessTierBundles: AccessTierBundles
    requestedAt: Date
    onSupportBundleDownload(bundleInformation: BundleInformation): Promise<void>
}

const AccessTierBundlesRow = (props: AccessTierBundlesRowProps) => {
    const netagentInstanceCount = props.accessTierBundles.netagentInstanceBundles.length
    const [firstNetagentInstanceBundle, ...restNetagentInstanceBundles] =
        props.accessTierBundles.netagentInstanceBundles

    return (
        <React.Fragment>
            <tr>
                <td rowSpan={netagentInstanceCount}>{props.accessTierBundles.accessTierName}</td>
                <NetagentInstanceBundleRowContent
                    netagentInstanceBundle={firstNetagentInstanceBundle}
                    requestedAt={props.requestedAt}
                    onSupportBundleDownload={props.onSupportBundleDownload}
                />
            </tr>
            {restNetagentInstanceBundles.map((netagentInstanceBundle) => (
                <tr key={netagentInstanceBundle.netagentName}>
                    <NetagentInstanceBundleRowContent
                        netagentInstanceBundle={netagentInstanceBundle}
                        requestedAt={props.requestedAt}
                        onSupportBundleDownload={props.onSupportBundleDownload}
                    />
                </tr>
            ))}
        </React.Fragment>
    )
}

interface NetagentInstanceBundleRowContentProps {
    netagentInstanceBundle: NetagentInstanceBundle
    requestedAt: Date
    onSupportBundleDownload(bundleInformation: BundleInformation): Promise<void>
}

const NetagentInstanceBundleRowContent = (props: NetagentInstanceBundleRowContentProps) => {
    return (
        <React.Fragment>
            <td>{props.netagentInstanceBundle.netagentName}</td>
            <BundleInformationCell
                bundleInformation={props.netagentInstanceBundle.supportBundleInformation}
                requestedAt={props.requestedAt}
                onSupportBundleDownload={props.onSupportBundleDownload}
            />
        </React.Fragment>
    )
}

interface BundleInformationCellProps {
    bundleInformation?: BundleInformation
    requestedAt: Date
    onSupportBundleDownload(bundleInformation: BundleInformation): Promise<void>
}

const BundleInformationCell = (props: BundleInformationCellProps) => {
    const localization = useServiceLocalization()
    const locale = localization.getLocale()

    const [isDownloading, setIsDownloading] = React.useState(false)

    if (!props.bundleInformation) {
        return (
            <td colSpan={3}>
                {localization.getString(
                    "waitingOnSupportBundleRequestedAtSomething",
                    props.requestedAt.toLocaleString(locale)
                )}
            </td>
        )
    }

    const { bundleInformation } = props

    const onClick: React.MouseEventHandler = async (event) => {
        event.preventDefault()
        setIsDownloading(true)

        try {
            await props.onSupportBundleDownload(bundleInformation)
        } finally {
            setIsDownloading(false)
        }
    }

    return (
        <React.Fragment>
            <td>{props.bundleInformation.fileSize.toFormattedString(locale)}</td>
            <td>{props.bundleInformation.lastModifiedAt.toLocaleString(locale)}</td>
            <td className={styles.downloadCell}>
                <Button
                    buttonType={ButtonType.SECONDARY_SOLID}
                    onClick={onClick}
                    loading={isDownloading}
                >
                    {localization.getString(
                        "downloadSomething",
                        localization.getString("supportBundle")
                    )}
                </Button>
            </td>
        </React.Fragment>
    )
}
