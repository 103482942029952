import React from "react"

import { FormLabel } from "../../../pre-v3/components/form-label/FormLabel"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { SelectInput } from "../../../v3/components/select-input/SelectInput.component"
import { Edition as EditionType, allEditions } from "../../../v3/services/shared/Edition"

interface Props {
    id: string
    value: EditionType
    disabled?: boolean
    onChange?(value: EditionType): void
}

export function EditionInput(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    return (
        <FormLabel inline title={localization.getString("edition")} htmlFor={props.id}>
            <SelectInput
                id={props.id}
                disabled={props.disabled}
                value={props.value}
                options={allEditions}
                onChange={props.onChange}
            />
        </FormLabel>
    )
}
