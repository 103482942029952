import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { ROUTE } from "../../../routes"
import { ServiceAccountAdd } from "./add/ServiceAccountAdd.view"
import { ServiceAccountList } from "./list/ServiceAccountList.view"
import { ServiceAccountOverview } from "./overview/ServiceAccountOverview.view"
import useTitle from "../../../hooks/useTitle.hook"

interface Props {
    canAccessServiceAccounts: boolean
}

export function ServiceAccounts(props: Props): JSX.Element {
    useTitle(["serviceAccounts", "directory", "adminConsole"])
    return (
        <Switch>
            <Route path={ROUTE.SERVICE_ACCOUNTS_ADD}>
                <ServiceAccountAdd canAccessServiceAccounts={props.canAccessServiceAccounts} />
            </Route>
            <Route path={ROUTE.SERVICE_ACCOUNTS_DETAILS}>
                <ServiceAccountOverview canAccessServiceAccounts={props.canAccessServiceAccounts} />
            </Route>
            <Route path={ROUTE.SERVICE_ACCOUNTS}>
                <ServiceAccountList canAccessServiceAccounts={props.canAccessServiceAccounts} />
            </Route>
            <Redirect to={ROUTE.SERVICE_ACCOUNTS} />
        </Switch>
    )
}
