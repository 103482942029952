import { L4Rule, PolicyAccess } from "../../../v3/services/Policy.service"
import { v4 as uuidV4 } from "uuid"

export const emptyDestination: L4Rule = {
    id: uuidV4(),
    ipRanges: "",
    fqdnList: "",
    protocols: [],
    ports: [],
    description: "",
    fqdns: [],
    cidrs: [],
}

export const emptyPolicyAccess: PolicyAccess = {
    name: "",
    description: "",
    roles: [],
    rules: {
        l4_access: {
            allow: [emptyDestination],
            deny: [emptyDestination],
        },
        conditions: {},
    },
    id: uuidV4(),
}
