import React, { useState } from "react"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../../components/button/Button.component"
import styles from "./ScimProvisioning.module.scss"
import { FormRow } from "../../../../components/form/FormRow.component"
import { TextWithCopy } from "../../../../v3/components/text-with-copy/TextWithCopy.component"
import {
    Token,
    useDeleteScimToken,
    useGenerateScimCredentials,
    useGetScimCredentials,
    useToggleScimProvisioning,
} from "../../../../v3/services/ScimProvisioning.service"
import { Loader } from "../../../../v3/components/loader/Loader.component"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import { DoneActions, MessageContent, OkCancelActions } from "../../../../pre-v3/components"
import { DeleteCancelActions } from "../../../../pre-v3/components/modal/delete-cancel/DeleteCancelActions"
import { Input } from "../../../../v3/components/input/Input.component"
import { Tooltip } from "../../../../v3/components/tooltip/Tooltip.component"
import { Banner, Variant } from "../../../../components/banner/Banner.component"
import { FormColumn } from "../../../../v3/components/form/FormColumn.component"
import { ErrorToast, SuccessToast, ToastApi } from "../../../../components/toast/Toast.components"
import { Card } from "../../../../components/card/Card.component"
import { AppText } from "../../../../v3/components/app-text/AppText.component"
import { Switch } from "../../../../v3/components/switch/Switch.component"

interface Props {
    isScimEnabled: boolean
    onChange?: (data: boolean) => void
    enableScimToggle: boolean
    canAccessScimSetting: boolean
    isIdpEdit: boolean
    canGenerateScimApiToken: boolean
}

export function ScimProvisioning(props: Props): JSX.Element {
    const modalService = useServiceModal()
    const localization = useServiceLocalization()
    const [isScimProvisioningEnabled, setIsScimProvisioningEnabled] = useState<boolean>(false)
    const errorToastRef = React.useRef<ToastApi>(null)
    const successToastRef = React.useRef<ToastApi>(null)

    const {
        data: scimCredentials,
        isLoading: isScimCredentialsLoading,
        error: scimCredentialsError,
    } = useGetScimCredentials({
        enabled: isScimProvisioningEnabled || props.isScimEnabled,
    })

    const baseUrl: string = scimCredentials?.baseUrl || ""
    const tokens: Token[] = scimCredentials?.tokens || []

    const { mutate: toggleScimProvisioning, isLoading: isScimProvisioningLoading } =
        useToggleScimProvisioning({
            onSuccess: (_data, params) => {
                if (typeof params !== "boolean") return

                setIsScimProvisioningEnabled(params)
                successToastRef.current?.openToast(
                    localization.getString(
                        params ? "successfullyEnabledScim" : "successfullyDisabledScim"
                    )
                )
                props.onChange?.(params)
            },
            onError: () => {
                errorToastRef.current?.openToast(
                    localization.getString("failedToUpdateTheScimProvisioningStatus")
                )
            },
        })

    const { mutate: deleteScimToken, error: deleteTokenError } = useDeleteScimToken()

    function onOpenGenerateModal(): void {
        modalService.open(
            localization.getString("generateApiToken"),
            {
                component: GenerateScimCredential,
                maxWidth: "lg",
            },
            {
                component: DoneActions,
            }
        )
    }

    function onOpenDeleteTokenModal(tokenId: string): void {
        modalService
            .open(
                localization.getString("deleteApiToken"),
                {
                    component: MessageContent,
                    props: {
                        text: localization.getString("areYouSureYouWantToDeleteThisToken"),
                    },
                    maxWidth: "lg",
                },
                {
                    component: DeleteCancelActions,
                }
            )
            .onClose(() => {
                deleteScimToken(tokenId)
            })
    }

    function onOpenUpdateScimProvisioningModal(state: boolean): void {
        modalService
            .open(
                localization.getString(state ? "enableScim" : "disableScim"),
                {
                    component: MessageContent,
                    props: {
                        text: localization.getString(
                            state ? "doYouWantToEnableScim" : "disablingScimDesc"
                        ),
                    },
                    maxWidth: "lg",
                },
                {
                    component: OkCancelActions,
                    props: {
                        okString: localization.getString(state ? "enableScim" : "yesDisableScim"),
                    },
                }
            )
            .onClose(() => toggleScimProvisioning(state))
    }

    React.useEffect(() => {
        setIsScimProvisioningEnabled(props.isScimEnabled)
    }, [props.isScimEnabled])

    return (
        <React.Fragment>
            <Loader isLoading={isScimProvisioningLoading} center>
                <Card className={styles.endUserContainer}>
                    <div className={styles.titleDescription}>
                        <div className={styles.titleContainer}>
                            <h2>{localization.getString("scimProvisioning")}</h2>
                            <AppText ls="scimProvisioningDesc" />
                        </div>
                        <Tooltip
                            title={
                                props.enableScimToggle || props.isIdpEdit
                                    ? localization.getString("toUpdateSCIMProvisioningStatusDesc")
                                    : tokens.length > 0
                                      ? localization.getString(
                                            "toDisableScimEnsureAllActiveTokensAreDeleted"
                                        )
                                      : ""
                            }
                        >
                            <span>
                                <Switch
                                    value={isScimProvisioningEnabled}
                                    disabled={
                                        props.enableScimToggle ||
                                        tokens.length > 0 ||
                                        !props.canAccessScimSetting ||
                                        props.isIdpEdit
                                    }
                                    className={styles.accordionSummarySwitchWrapper}
                                    onChange={(val) => {
                                        onOpenUpdateScimProvisioningModal(val)
                                    }}
                                />
                            </span>
                        </Tooltip>
                    </div>
                    {isScimProvisioningEnabled && (
                        <Loader isLoading={isScimCredentialsLoading}>
                            {typeof deleteTokenError === "string" && (
                                <Banner label={String(deleteTokenError)} variant={Variant.ERROR} />
                            )}
                            {typeof scimCredentialsError === "string" && (
                                <Banner
                                    label={String(scimCredentialsError)}
                                    variant={Variant.ERROR}
                                />
                            )}
                            <div className={styles.container}>
                                {baseUrl && (
                                    <FormRow label={localization.getString("baseUrl")}>
                                        <TextWithCopy value={baseUrl} />
                                    </FormRow>
                                )}
                                {tokens.length > 0 && (
                                    <FormColumn
                                        labelClassName={styles.columnHeader}
                                        label={localization.getString("apiTokenCreatedAt")}
                                    >
                                        <div className={styles.tokenContainer}>
                                            {tokens.map((token) => {
                                                return (
                                                    <div className={styles.tableContainer}>
                                                        <p>{token.createdAt}</p>
                                                        <div
                                                            className={styles.token}
                                                            key={token.id}
                                                        >
                                                            <Input
                                                                defaultValue={token.id}
                                                                className={styles.copyText}
                                                                disabled
                                                                type="password"
                                                            />
                                                            <Tooltip
                                                                title={localization.getString(
                                                                    "remove"
                                                                )}
                                                            >
                                                                <Button
                                                                    onClick={() =>
                                                                        onOpenDeleteTokenModal(
                                                                            token.id
                                                                        )
                                                                    }
                                                                    buttonType={
                                                                        ButtonType.DESTRUCTIVE
                                                                    }
                                                                    icon={IconType.TRASH}
                                                                    asElement={ButtonElement.BUTTON}
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </FormColumn>
                                )}
                            </div>
                            {props.canGenerateScimApiToken && (
                                <div className={styles.button}>
                                    <Tooltip
                                        title={
                                            tokens.length === 2
                                                ? localization.getString("twoTokensAllowedDesc")
                                                : ""
                                        }
                                    >
                                        <span>
                                            <Button
                                                buttonType={ButtonType.SECONDARY}
                                                asElement={ButtonElement.BUTTON}
                                                onClick={onOpenGenerateModal}
                                                disabled={tokens.length === 2}
                                            >
                                                {localization.getString("generateApiToken")}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </div>
                            )}
                        </Loader>
                    )}
                </Card>
            </Loader>
            <SuccessToast ref={successToastRef} />
            <ErrorToast ref={errorToastRef} />
        </React.Fragment>
    )
}

function GenerateScimCredential() {
    const {
        data: generateScimCredential,
        error: generateScimCredentialError,
        isLoading,
    } = useGenerateScimCredentials()
    const localization = useServiceLocalization()

    return (
        <Loader isLoading={isLoading} medium center>
            <div className={styles.modalContainer}>
                {typeof generateScimCredentialError === "string" ? (
                    <Banner label={String(generateScimCredentialError)} variant={Variant.ERROR} />
                ) : (
                    <>
                        <Banner
                            label={localization.getString("tokenIsAvailableOnlyOnceDesc")}
                            variant={Variant.INFORMATION}
                        />
                        <FormRow
                            label={localization.getString("baseUrl")}
                            description={localization.getString("baseUrlDesc")}
                        >
                            <TextWithCopy value={generateScimCredential?.baseUrl!} />
                        </FormRow>
                        <FormRow
                            label={localization.getString("apiToken")}
                            description={localization.getString("apiTokenDesc")}
                        >
                            <TextWithCopy value={generateScimCredential?.token!} />
                        </FormRow>
                    </>
                )}
            </div>
        </Loader>
    )
}
