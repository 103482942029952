import React from "react"

import { FormLabel } from "../../../pre-v3/components/form-label/FormLabel"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Option, SelectInput } from "../../../v3/components/select-input/SelectInput.component"
import { MspOrg } from "../../../v3/services/MomOrgManagement.service"

interface Props {
    id: string
    value?: MspOrg
    mspOrgs?: MspOrg[]
    disabled?: boolean
    onChange?(value?: MspOrg): void
}

export function ParentMspInput(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const noAssignmentLabel = localization.getString("noAssignment")

    const onChange = (mspOrgId: string) => {
        const mspOrg = props.mspOrgs?.find((mspOrg) => mspOrg.id === mspOrgId)
        props.onChange?.(mspOrg)
    }

    const mspOrgOptions = React.useMemo<Option[]>(() => {
        const mspOrgs =
            (props.mspOrgs && props.mspOrgs.length > 0) || !props.value
                ? props.mspOrgs
                : [props.value]

        const noAssignmentOption: Option = {
            displayName: noAssignmentLabel,
            value: NO_ASSIGNMENT_VALUE,
        }

        return [noAssignmentOption, ...(mspOrgs?.map(getMspOption) ?? [])]
    }, [props.mspOrgs, props.value])

    return (
        <FormLabel inline title={localization.getString("assignToAnMsp")} htmlFor={props.id}>
            <SelectInput
                id={props.id}
                disabled={props.disabled}
                value={props.value?.id ?? NO_ASSIGNMENT_VALUE}
                options={mspOrgOptions}
                onChange={onChange}
                placeholder={noAssignmentLabel}
            />
        </FormLabel>
    )
}

const NO_ASSIGNMENT_VALUE = ""

function getMspOption(mspOrg: MspOrg): Option {
    return {
        displayName: mspOrg.name,
        value: mspOrg.id,
    }
}
