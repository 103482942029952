import React from "react"

import {
    ModalApi,
    ModalWithoutRef,
    Response as ModalResponse,
    ModalAction,
} from "../../../components/modal/Modal.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { MspOrg } from "../../../v3/services/MomOrgManagement.service"
import { ModalFooter } from "../../../components/modal/ModalFooter.component"

export { ModalAction } from "../../../components/modal/Modal.component"

export type Response = ModalResponse<void>

export interface Api {
    open(mspOrg: MspOrg): Promise<Response>
}

interface Props {
    id: string
    onMspAssignmentConfirmed(mspOrg: MspOrg): Promise<void>
}

export const ConfirmMspAssignmentModal = React.forwardRef<Api, Props>((props, ref) => {
    const localization = useServiceLocalization()

    const modalRef = React.useRef<ModalApi<void>>(null)
    const [mspOrg, setMspOrg] = React.useState<MspOrg>()

    React.useImperativeHandle(ref, () => ({
        open: async (mspOrg) => {
            setMspOrg(mspOrg)
            return (await modalRef.current?.open()) ?? { action: ModalAction.DISMISS }
        },
    }))

    const onCancel = () => modalRef.current?.dismiss()

    const onConfirm = async (mspOrg: MspOrg) => {
        await props.onMspAssignmentConfirmed(mspOrg)
        modalRef.current?.complete()
    }

    return ModalWithoutRef(
        {
            id: props.id,
            title: localization.getString("confirmOrganizationAssignment"),
            ...(mspOrg && {
                children: localization.getString("assignToMspConfirmationMessage", mspOrg.name),
                footer: <Footer mspOrg={mspOrg} onCancel={onCancel} onConfirm={onConfirm} />,
            }),
        },
        modalRef
    )
})

interface FooterProps {
    mspOrg: MspOrg
    onCancel(): void
    onConfirm(mspOrg: MspOrg): Promise<void>
}

function Footer(props: FooterProps): JSX.Element {
    const onConfirm = () => props.onConfirm(props.mspOrg)

    return <ModalFooter onCancel={props.onCancel} onConfirm={onConfirm} />
}
