import React, { ReactNode, useState } from "react"
import { useHistory } from "react-router-dom"

import { ModalService, useServiceLocalization } from "../../../../pre-v3/services"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { formatRoutePath, ROUTE } from "../../../../routes"
import { ErrorBanners } from "../../../components/banner/Banner.component"
import { TabBar } from "../../../components/tab-bar/TabBar.component"
import {
    useCreateHostedService,
    useUpdateHostedService,
    WebService,
} from "../../../services/HostedService.service"
import {
    ServiceTestConnection,
    useTestConnectionById,
} from "../../../services/ServiceTestConnection.service"
import { Configuration, ConfigurationFormValues } from "../configuration/Configuration.component"
import { ServiceTestConnectionModal } from "../overview/ServiceTestConnectionModal.component"
import {
    AccessPermissions,
    AccessPermissionsFormValues,
} from "./access-permissions/AccessPermissions.component"
import { Advanced, AdvancedConfigFormValues } from "./advanced/Advanced.component"
import styles from "./HostedWebsiteAdd.module.scss"

import useTitle from "../../../../hooks/useTitle.hook"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"

export interface Props {
    initialValue?: WebService
    onSubmit?: (data: WebService) => void
    enableAccessTierGroups: boolean
    canAddRegisteredDomain?: boolean
}

export function HostedWebsiteAdd(props: Props) {
    useTitle(["registerAHostedWebsite", "hostedWebsites", "adminConsole"])

    const ls = useServiceLocalization()
    const history = useHistory()
    const modalService = new ModalService()

    const [currentStep, setCurrentStep] = useState<number>(0)

    const tabList = Object.values(Tab)

    const [webServiceData, setWebServiceData] = useState<WebService>(
        props.initialValue ?? ({ postAuthRedirectPath: "/", infra: [] } as unknown as WebService)
    )

    const [hostedWebsiteId, setHostedWebsiteId] = useState<string>("")

    function nextStep() {
        setCurrentStep(currentStep + 1)
    }

    function prevStep() {
        setCurrentStep(currentStep - 1)
    }

    const {
        mutateAsync: onCreateHostedWebsite,
        isLoading: isCreatingHostedWebsite,
        error: createHostedWebsiteError,
    } = useCreateHostedService(props.enableAccessTierGroups)

    const {
        mutateAsync: onUpdateHostedWebsite,
        isLoading: isHostedWebsiteUpdating,
        error: hostedWebsiteUpdateError,
    } = useUpdateHostedService(props.enableAccessTierGroups)

    const {
        mutateAsync: testConnection,
        isLoading: isTestConnectionLoading,
        error: testConnectionError,
    } = useTestConnectionById()

    const errors: ReactNode[] = [
        typeof createHostedWebsiteError === "string" && createHostedWebsiteError,
        typeof hostedWebsiteUpdateError === "string" && hostedWebsiteUpdateError,
    ]

    async function onSubmitForm(data: WebService) {
        const response = hostedWebsiteId
            ? await onUpdateHostedWebsite(data)
            : await onCreateHostedWebsite({ service: data })
        if (response.id) {
            setHostedWebsiteId(response.id)
            let data: ServiceTestConnection
            try {
                data = await testConnection(response.id)
                const backendConnErrors: string[] =
                    data?.backend?.map((a) => a.errorMsg!).filter((str) => str !== "") || []
                const frontendConnError: boolean = Boolean(data.errorMsg)
                const isTestable: boolean = backendConnErrors.length > 0 || frontendConnError

                if (isTestable) {
                    modalService.open(ls.getString("testConnection"), {
                        component: ServiceTestConnectionModal,
                        props: {
                            data,
                            readOnly: false,
                            isTestConnectionLoading,
                            isContinueWithError: isTestable,
                            error: typeof testConnectionError === "string" && testConnectionError,
                            service: response,
                            onSubmit: props.onSubmit,
                        },
                        maxWidth: "lg",
                    })
                } else if (props.onSubmit) {
                    props.onSubmit(response as WebService)
                } else {
                    history.push(
                        formatRoutePath(ROUTE.HOSTED_WEBSITES_DETAILS, {
                            id: encodeID(response.id),
                        })
                    )
                }
            } catch (e) {
                modalService.open(ls.getString("testConnection"), {
                    component: ServiceTestConnectionModal,
                    props: {
                        data: undefined,
                        service: response,
                        readOnly: false,
                        isTestConnectionLoading: false,
                        isContinueWithError: e,
                        error: String(e),
                    },
                    maxWidth: "lg",
                })
            }
        }
    }

    function onConfigurationFormChange(formValue: ConfigurationFormValues) {
        const tlsSniCopy = Array.isArray(webServiceData.tlsSni) ? [...webServiceData.tlsSni] : []
        tlsSniCopy.shift()
        const updatedTlsSni = [formValue.accessDetails.domain, ...tlsSniCopy]

        const updatedWebService = {
            ...webServiceData,
            name: formValue.name,
            description: formValue.description,
            enabled: true,
            type: "web",

            infra: formValue.privateResourceDetails.hostedServiceInfra,
            backendDomain: formValue.privateResourceDetails.backendDomain,
            backendPort: formValue.privateResourceDetails.backendPort,
            tls: formValue.privateResourceDetails.tls,
            tlsInsecure: formValue.privateResourceDetails.tlsInsecure,
            clientCertificate: formValue.privateResourceDetails.clientCertificate,

            domain: formValue.accessDetails.domain,
            port: formValue.accessDetails.port,
            certificate: formValue.accessDetails.certificate,
            registeredDomainId: formValue.accessDetails.registeredDomainId,

            tlsSni: updatedTlsSni,
        }

        setWebServiceData(updatedWebService as WebService)
    }

    function onAccessPermissionsFormSubmit(formValue: AccessPermissionsFormValues) {
        const updatedWebService = {
            ...webServiceData,
            policyAttachment: formValue.policyAttachment,
            exemptions: formValue.exemptions,
            serviceAccountAccess: formValue.serviceAccount,
        }

        setWebServiceData(updatedWebService as WebService)
    }

    function onAdvancedFormSubmit(formValue: AdvancedConfigFormValues, isSubmitFormData: boolean) {
        const updatedWebService: WebService = {
            ...webServiceData,
            customHeaders: formValue.customHeaders,
            icon: formValue.icon,
            descriptionLink: formValue.descriptionLink,
            userFacing: formValue.userFacing,
            suppressDtv: formValue.suppressDTV,
            disablePrivateDns: formValue.disableDNS,
            trustCookieSameSitePolicy: formValue.trustCookieSameSitePolicy,
            trustCookiePath: formValue.trustCookiePath,
            id: hostedWebsiteId ? hostedWebsiteId : "",
            postAuthRedirectPath: formValue.postAuthRedirectPath,
            tlsSni: formValue.tlsSni,
        }

        setWebServiceData(updatedWebService)

        if (isSubmitFormData) {
            onSubmitForm(updatedWebService)
        }
    }

    return (
        <section aria-labelledby={Id.HEADING} className={styles.container}>
            <PageHeading id={Id.HEADING}>{ls.getString("registerAHostedWebsite")}</PageHeading>
            <TabBar
                type="stepper"
                tabs={tabList.map((step, stepIndex) => {
                    return {
                        id: stepIndex,
                        label: step,
                        ariaControls: `${step}-tab`,
                    }
                })}
                selectedTabId={currentStep}
            />
            {tabList[currentStep] === Tab.CONFIGURATION && (
                <Configuration
                    initialValue={webServiceData}
                    nextStep={nextStep}
                    onSubmit={onConfigurationFormChange}
                    enableAccessTierGroups={props.enableAccessTierGroups}
                    canAddRegisteredDomain={props.canAddRegisteredDomain}
                />
            )}
            {tabList[currentStep] === Tab.ACCESS_PERMISSIONS && (
                <AccessPermissions
                    initialValue={webServiceData}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    onSubmit={onAccessPermissionsFormSubmit}
                />
            )}
            {tabList[currentStep] === Tab.ADVANCED && (
                <Advanced
                    initialValue={webServiceData}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    onSubmit={(data) => onAdvancedFormSubmit(data, true)}
                    isDataLoading={
                        isCreatingHostedWebsite ||
                        isHostedWebsiteUpdating ||
                        isTestConnectionLoading
                    }
                />
            )}
            <br />
            <ErrorBanners errors={errors} />
            <div className={styles.bottomPadding}></div>
        </section>
    )
}

enum Id {
    HEADING = "heading",
}

enum Tab {
    CONFIGURATION = "Configuration",
    ACCESS_PERMISSIONS = "Access Permissions",
    ADVANCED = "Advanced",
}
