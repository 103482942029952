import React from "react"

import { HiddenInput } from "../../../components/input/HiddenInput.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { CollapsibleCard } from "../../../components/collapsible-card/CollapsibleCard.component"
import { PatternUtil } from "../../../pre-v3/utils/Pattern.util"
import {
    PillMultiSelect,
    PillMultiSelectOption as AppOption,
} from "../../../v3/components/pill-multi-select/PillMultiSelect.component"
import { Application, PublicInfo } from "../../../v3/services/ServiceTunnelV2.service"
import { MultiInput } from "../../../components/multi-input/MultiInput.component"
import { FormRow } from "../../../components/form/FormRow.component"
import { useServiceModal } from "../../../pre-v3/services/Modal.service"
import { AppDetailsModalWrapper } from "../../../v3/views/shared/AppModalWrapper"
import styles from "./PublicInfoCard.module.scss"

export interface PublicInfoCardApi {
    triggerDomainInputDuplicateError(dupeList: string[]): void
    triggerIpRangeInputDuplicateError(dupeList: string[]): void
}

export interface Props {
    id: string
    publicInfo: PublicInfo
    onPublicInfoChange(publicInfo: PublicInfo): void
    applications: Application[]
    cardTitle: string
    appsInputLabel: string
    appsInputDescription: string
    domainsInputLabel: string
    domainsInputDescription: string
    ipRangesInputLabel: string
    ipRangesInputDescription: string
    disabled?: boolean
    ignoredApplications?: string[]
    hideApplication: boolean
}

export const PublicInfoCard = React.forwardRef<PublicInfoCardApi, Props>((props, ref) => {
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const domainInputRef = React.useRef<HTMLInputElement>(null)
    const ipRangeInputRef = React.useRef<HTMLInputElement>(null)

    React.useImperativeHandle(ref, () => ({
        triggerDomainInputDuplicateError: (dupeList) => {
            domainInputRef.current?.setCustomValidity(
                dupeList.length > 0
                    ? localization.getString(
                          "theFollowingInputIsBothInExcludeIncludeSomeThing",
                          dupeList.join(", ")
                      )
                    : ""
            )
            domainInputRef.current?.reportValidity()
        },
        triggerIpRangeInputDuplicateError: (dupeList) => {
            ipRangeInputRef.current?.setCustomValidity(
                dupeList.length > 0
                    ? localization.getString(
                          "theFollowingInputIsBothInExcludeIncludeSomeThing",
                          dupeList.join(", ")
                      )
                    : ""
            )
            ipRangeInputRef.current?.reportValidity()
        },
    }))

    const applicationIds = React.useMemo(
        () => props.publicInfo.applications.map((app) => app.id),
        [props.publicInfo.applications]
    )

    const appOptions = React.useMemo(
        () =>
            props.applications.map<AppOption>((app) => {
                return {
                    label: app.name,
                    value: app.id,
                }
            }),
        [props.applications]
    )

    function onApplicationsChange(appIds: string[]): void {
        const filteredApps = props.applications.filter((app) => appIds.includes(app.id))
        props.onPublicInfoChange({
            ...props.publicInfo,
            applications: filteredApps,
        })
    }

    function onRemoveAllApplications() {
        props.onPublicInfoChange({
            ...props.publicInfo,
            applications: [],
        })
    }

    function onDomainsChange(domains: string[]): void {
        props.onPublicInfoChange({
            ...props.publicInfo,
            domains,
        })
    }

    function onIpsChange(ips: string[]) {
        props.onPublicInfoChange({
            ...props.publicInfo,
            ipRanges: ips,
        })
    }
    function openAppDetails(pill: string): void {
        modalService.open(localization.getString("about"), {
            component: AppDetailsModalWrapper,
            props: {
                appId: pill,
            },
            maxWidth: "md",
        })
    }
    return (
        <React.Fragment>
            <CollapsibleCard id={props.id} title={props.cardTitle} isCardChild>
                {!props.hideApplication && (
                    <PillMultiSelect
                        label={props.appsInputLabel}
                        description={props.appsInputDescription}
                        placeholder={localization.getString("selectApplications")}
                        options={appOptions}
                        value={applicationIds}
                        onChange={onApplicationsChange}
                        onRemoveAll={onRemoveAllApplications}
                        clearSelection
                        disabled={props.disabled}
                        onPillClick={(e) => {
                            openAppDetails(e)
                        }}
                        labelClassName={styles.formLabel}
                        ignoreOptions={props.ignoredApplications}
                    />
                )}
                <FormRow
                    label={props.domainsInputLabel}
                    description={props.domainsInputDescription}
                    className={styles.formRowContent}
                    labelClassName={styles.formLabel}
                    childrenClassName={styles.formRowContent}
                >
                    <MultiInput
                        label={props.domainsInputLabel}
                        values={props.publicInfo.domains}
                        onChange={onDomainsChange}
                        placeholder={localization.getString("includeDomainEx")}
                        patternProps={{
                            pattern: PatternUtil.DOMAIN.source,
                            errorMessage: localization.getString("domainFieldError"),
                        }}
                        disabled={props.disabled}
                    />
                    <HiddenInput type="text" ref={domainInputRef} />
                </FormRow>
                <FormRow
                    label={props.ipRangesInputLabel}
                    description={props.ipRangesInputDescription}
                    className={styles.formRowContent}
                    labelClassName={styles.formLabel}
                    childrenClassName={styles.formRowContent}
                >
                    <MultiInput
                        label={props.ipRangesInputLabel}
                        values={props.publicInfo.ipRanges}
                        placeholder={localization.getString("includeIpEx")}
                        onChange={onIpsChange}
                        patternProps={{
                            pattern: PatternUtil.CIDR_REGEX.source,
                            errorMessage: localization.getString("cidrPlaceholder"),
                        }}
                        disabled={props.disabled}
                    />
                    <HiddenInput type="text" ref={ipRangeInputRef} />
                </FormRow>
            </CollapsibleCard>
        </React.Fragment>
    )
})
