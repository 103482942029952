import classNames from "classnames/bind"
import React from "react"

import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Input } from "../../../components/input/Input.component"
import { PortInput } from "../../../components/port-input/PortInput.component"
import { SelectInput } from "../select-input/SelectInput.component"
import styles from "./UrlInput.module.scss"

export interface UrlInputProps {
    onChange?: (data: UrlItem) => void
    initialData?: UrlItem
    hideProtocol?: boolean
    disabled?: boolean
    portRequired?: boolean
    domainRequired?: boolean
    domainPlaceholder?: string
    className?: string
    domainVariables?: string[]
    domainPattern?: string
}

export function UrlInput(props: UrlInputProps): JSX.Element {
    const {
        initialData,
        disabled,
        portRequired,
        domainPlaceholder,
        domainRequired,
        hideProtocol,
        domainVariables,
        domainPattern,
    } = props

    const ls = useServiceLocalization()
    const [port, setPort] = React.useState(initialData?.port?.toString() ?? "")
    const [domain, setDomain] = React.useState(initialData?.domain ?? "")
    const [protocol, setProtocol] = React.useState(initialData?.protocol ?? "http")

    const domainInputRef = React.useRef<HTMLInputElement>(null)

    function validateDomainInput(domain: string) {
        if (!domain) {
            domainInputRef.current?.setCustomValidity(ls.getString("pleaseFillOutThisField"))
        } else {
            domainInputRef.current?.setCustomValidity("")
        }
    }

    function onChange() {
        props.onChange?.({ protocol: protocol, port: Number(port), domain })

        if (domainRequired) {
            validateDomainInput(domain)
        }
    }

    React.useEffect(() => {
        onChange()
        // eslint-disable-next-line
    }, [port, protocol, domain])

    return (
        <div className={classNames(styles.container, props.className)}>
            {!hideProtocol && (
                <SelectInput
                    className={styles.protocol}
                    options={protocolOptions}
                    value={protocol}
                    onChange={setProtocol}
                    disabled={disabled}
                />
            )}
            <Input
                ref={domainInputRef}
                value={domain}
                type="text"
                onChangeValue={setDomain}
                placeholder={domainPlaceholder}
                disabled={disabled}
                aria-label={ls.getString("domainOrIpAddress")}
                variables={domainVariables}
                pattern={domainPattern}
            />
            <PortInput
                value={port}
                className={styles.port}
                onChange={setPort}
                disabled={disabled}
                required={portRequired}
            />
        </div>
    )
}

export interface UrlItem {
    protocol?: ProtocolOption
    domain?: string
    port?: number
}

type ProtocolOption = "http" | "https"
const protocolOptions: ProtocolOption[] = ["http", "https"]
