import React from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import {
    NavigationItemProps,
    TopNavigationBar,
} from "../../components/top-navigation-bar/TopNavigationBar.component"
import { useFeatureFlags } from "../../hooks/useFeatureFlags.hook"
import useTitle from "../../hooks/useTitle.hook"
import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { ROUTE, doesRouteMatch } from "../../routes"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { Loader } from "../../v3/components/loader/Loader.component"
import { Users } from "./Users.view"
import { Devices } from "../../v3/views/devices/Devices.view"
import { ServiceAccounts } from "../../v3/views/service-accounts/ServiceAccounts.view"
import { UnregisteredDevices } from "./UnregisteredDevices.view"
import { Roles } from "../../v3/views/roles/Roles.view"
import { Admins } from "./admins/Admins.view"
import { Upsell } from "../../shared/upsell/Upsell.component"

export function Directory() {
    useTitle(["directory", "adminConsole"])

    const localization = useServiceLocalization()
    const location = useLocation()

    const { data: featureFlags } = useFeatureFlags()

    const getNavigationItemProps = (route: SecondLevelRoute): NavigationItemProps => ({
        key: route,
        label: localization.getString(secondLevelLabels[route]),
        to: route,
        active: doesRouteMatch<SecondLevelRoute>(route, location),
    })

    const navigationItems = React.useMemo(
        () =>
            secondLevelRoutes.filter((route) => {
                switch (route) {
                    case ROUTE.ADMINS:
                        return featureFlags?.adminConsole.canAccessAdminDirectory
                    default:
                        return true
                }
            }),
        [featureFlags]
    )

    if (!featureFlags) return <Loader center medium />

    const serviceAccountsView = featureFlags.adminConsole.doShowUpsellForServiceTunnelConfig ? (
        <Upsell />
    ) : (
        <ServiceAccounts
            canAccessServiceAccounts={featureFlags.adminConsole.canAccessServiceAccounts}
        />
    )
    return (
        <React.Fragment>
            <header>
                <TopNavigationBar
                    headingTitle={localization.getString("directory")}
                    navigationItems={navigationItems}
                    getNavigationItemProps={getNavigationItemProps}
                />
            </header>
            <Switch>
                <Route path={ROUTE.USERS} component={Users} />
                <Route path={ROUTE.DEVICES} component={Devices} />
                <Route path={ROUTE.SERVICE_ACCOUNTS}>{serviceAccountsView}</Route>
                <Route path={ROUTE.UNREGISTERED_DEVICES} component={UnregisteredDevices} />
                <Route path={ROUTE.ROLES} component={Roles} />
                {featureFlags.adminConsole.canAccessAdminDirectory && (
                    <Route path={ROUTE.ADMINS} component={Admins} />
                )}
                <Redirect to={ROUTE.USERS} />
            </Switch>
        </React.Fragment>
    )
}

const secondLevelRoutes = [
    ROUTE.USERS,
    ROUTE.DEVICES,
    ROUTE.SERVICE_ACCOUNTS,
    ROUTE.UNREGISTERED_DEVICES,
    ROUTE.ROLES,
    ROUTE.ADMINS,
] satisfies ROUTE[]

type SecondLevelRoute = (typeof secondLevelRoutes)[number]

const secondLevelLabels: Record<SecondLevelRoute, LanguageKey> = {
    [ROUTE.USERS]: "users",
    [ROUTE.DEVICES]: "devices",
    [ROUTE.SERVICE_ACCOUNTS]: "serviceAccounts",
    [ROUTE.UNREGISTERED_DEVICES]: "unregisteredDevices",
    [ROUTE.ROLES]: "roles",
    [ROUTE.ADMINS]: "admins",
}
