import React from "react"

import { FilterBar } from "../../../../pre-v3/components/filter-bar/FilterBar.component"
import {
    ColDef,
    Grid,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    IServerSideDatasource,
    IServerSideGetRowsParams,
    ValueGetterParams,
} from "../../../../pre-v3/components/grid/Grid.component"
import { IconType } from "../../../../pre-v3/services/ActionBar.service"
import { useServiceLinks } from "../../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import AgGridUtil, {
    DataFilter,
    DataFilterType,
    FilterModel,
    PaginatedSearch,
} from "../../../../pre-v3/utils/AgGrid.util"
import { SelectItem } from "../../../../pre-v3/utils/SelectValue.util"
import { UrlUtil, encodeID } from "../../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { ErrorBanner } from "../../../components/banner/Banner.component"
import { RowTitle } from "../../../components/grid/RowTitle.component"
import {
    ServiceAccount,
    ServiceAccountType,
    getServiceAccounts,
    serviceAccountTypeLabelDict,
} from "../../../services/ServiceAccount.service"
import styles from "./ServiceAccountList.module.scss"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { Button, ButtonElement, ButtonType } from "../../../../components/button/Button.component"
import { Tooltip } from "../../../components/tooltip/Tooltip.component"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"

interface Props {
    canAccessServiceAccounts: boolean
}

export function ServiceAccountList(props: Props): JSX.Element {
    const links = useServiceLinks()
    const localization = useServiceLocalization()

    const gridRef = React.useRef<GridApi<ServiceAccount>>()

    const [error, setError] = React.useState<string>()

    function onRefresh() {
        setError(undefined)
        gridRef.current?.refreshServerSideStore()
    }

    const addServiceAccountLabel = localization.getString("addServiceAccount")
    const nameLabel = localization.getString("name")

    const initialModel = UrlUtil.readFilter()

    const nameFilter: DataFilter = {
        key: "name",
        displayName: nameLabel,
        type: DataFilterType.DEBOUNCEDINPUT,
    }

    const typeFilter: DataFilter = {
        key: "details.type",
        displayName: localization.getString("type"),
        type: DataFilterType.LIST,
        options: Object.values(ServiceAccountType).map<SelectItem>((type) => ({
            displayName: localization.getString(serviceAccountTypeLabelDict[type]),
            value: type,
        })),
    }

    const onFilterChange = (model: FilterModel): void => {
        gridRef.current?.setFilterModel(model)
        UrlUtil.writeFilter(model)
    }

    const nameColumn: ColDef<ServiceAccount> = {
        headerName: nameLabel,
        field: "name",
        cellRenderer: renderNameCell,
        tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
        filter: "agTextColumnFilter",
        comparator: AgGridUtil.alphaBetComparator,
        sortable: true,
    }

    const descriptionColumn: ColDef<ServiceAccount> = {
        headerName: localization.getString("description"),
        field: "description",
        valueFormatter: AgGridUtil.nullableStringFormatter,
        tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
        filter: "agTextColumnFilter",
        sortable: true,
    }

    const typeColumn: ColDef<ServiceAccount> = {
        headerName: localization.getString("type"),
        field: "details.type",
        valueGetter: (params: ValueGetterParams<ServiceAccount>) =>
            params.data &&
            localization.getString(serviceAccountTypeLabelDict[params.data.details.type]),
        tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
        filter: "agTextColumnFilter",
        sortable: false,
    }

    const rolesColumn: ColDef<ServiceAccount> = {
        headerName: localization.getString("roles"),
        field: "roles",
        valueFormatter: AgGridUtil.stringArrayFormatter,
        sortable: false,
    }

    const lastAuthorizedAtColumn: ColDef<ServiceAccount> = {
        headerName: localization.getString("lastAuthorizedAt"),
        field: "lastAuthorizedAt",
        valueFormatter: AgGridUtil.dateFormatter,
        sortable: false,
        tooltipValueGetter: (params) => {
            return DateUtil.format(params.data?.lastAuthorizedAt)
        },
    }

    const onGridReady = (event: GridReadyEvent<ServiceAccount>) => {
        event.api.setServerSideDatasource(DataSource(event.api, setError))
        gridRef.current = event.api
        onFilterChange(initialModel)
    }

    return (
        <section aria-labelledby={Id.HEADING} className={styles.container}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>
                    {localization.getString("serviceAccounts")}
                </PageHeading>
                <div className={styles.actionButtons}>
                    <Tooltip title={localization.getString("helpDocs")}>
                        <Button
                            icon={IconType.QUESTION_CIRCLE}
                            to={links.getLink("serviceAccounts")}
                            asElement={ButtonElement.LINK}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("helpDocs")}
                        />
                    </Tooltip>
                    <Tooltip title={localization.getString("refresh")}>
                        <Button
                            icon={IconType.REDO}
                            onClick={onRefresh}
                            asElement={ButtonElement.BUTTON}
                            buttonType={ButtonType.SECONDARY}
                            aria-label={localization.getString("refresh")}
                        />
                    </Tooltip>
                </div>
            </header>
            {error ? (
                <ErrorBanner>{error}</ErrorBanner>
            ) : (
                <React.Fragment>
                    <div className={styles.toolbarContainer}>
                        <FilterBar
                            initialModel={initialModel}
                            onChange={onFilterChange}
                            filters={[nameFilter, typeFilter]}
                            className={styles.filterBar}
                        />
                        {props.canAccessServiceAccounts && (
                            <Button
                                buttonType={ButtonType.PRIMARY}
                                asElement={ButtonElement.LINK}
                                icon={IconType.PLUS}
                                to={ROUTE.SERVICE_ACCOUNTS_ADD}
                            >
                                {addServiceAccountLabel}
                            </Button>
                        )}
                    </div>
                    <Grid
                        onGridReady={onGridReady}
                        columnDefs={[
                            nameColumn,
                            descriptionColumn,
                            typeColumn,
                            rolesColumn,
                            lastAuthorizedAtColumn,
                        ]}
                        className={styles.grid}
                        serverSidePagination
                    />
                </React.Fragment>
            )}
        </section>
    )
}

enum Id {
    HEADING = "heading",
}

function DataSource(
    gridApi: GridApi<ServiceAccount>,
    errorCallback: (error: string) => void
): IServerSideDatasource {
    const getRows = async (params: IServerSideGetRowsParams<ServiceAccount>) => {
        const { request } = params
        const { startRow = 0, endRow, filterModel, sortModel } = request

        const search: PaginatedSearch = {
            skip: startRow,
            limit: endRow ? endRow - startRow : 10_000,
            filterModel,
            sortModel,
        }

        gridApi.showLoadingOverlay()

        try {
            const { data, total } = await getServiceAccounts(search)

            params.success({ rowData: data, rowCount: total })
        } catch (error) {
            params.fail()
            errorCallback(String(error))
        } finally {
            gridApi.hideOverlay()
        }
    }

    return { getRows }
}

function renderNameCell(params: ICellRendererParams<ServiceAccount, string>): JSX.Element {
    const route =
        params.data &&
        formatRoutePath(ROUTE.SERVICE_ACCOUNTS_DETAILS, {
            id: encodeID(params.data.id),
        })

    return <RowTitle title={params.value} route={route} />
}
