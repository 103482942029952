import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { ButtonElement, Button, ButtonType } from "../../../../components/button/Button.component"
import { PageHeading } from "../../../../components/page-heading/PageHeading.component"
import { LocalizationService } from "../../../../pre-v3/services/localization/Localization.service"
import { encodeID } from "../../../../pre-v3/utils/Url.util"
import { useInterval } from "../../../../pre-v3/utils/UseInterval.hook"
import { ROUTE, formatRoutePath } from "../../../../routes"
import { Container } from "../../../components/container/Container.component"
import { FormButtons } from "../../../components/form/form-buttons/FormButtons.component"
import { TabBar } from "../../../components/tab-bar/TabBar.component"
import {
    Connector,
    ConnectorStatus,
    ServerEnvironments,
    useCreateConnector,
    useGetConnectorStatusById,
    useUpdateConnector,
    WindowsInstallationMethod,
} from "../../../services/Connector.service"
import {
    WarningBanner,
    SuccessBanner,
    ErrorBanner,
} from "../../../components/banner/Banner.component"
import { AppText } from "../../../components/app-text/AppText.component"
import { ConnectorConfigurationForm } from "../form/configuration/ConnectorConfigurationForm.component"
import { ConnectorInstallForm } from "../form/install/ConnectorInstallForm.component"
import styles from "./ConnectorAdd.module.scss"

interface Props {
    clusterName: string
    canCreateConnector?: boolean
}

export function ConnectorAdd(props: Props): JSX.Element {
    const localization: LocalizationService = new LocalizationService()

    const history = useHistory()

    const tabs = [localization.getString("configure"), localization.getString("install")]

    const [currentTab, setCurrentTab] = useState<number>(0)
    const [deployment, setDeployment] = useState<Connector["deployment"]>({
        platform: ServerEnvironments.WINDOWS,
        method: WindowsInstallationMethod.EXECUTABLE,
    })

    const {
        data: createdConnector,
        mutateAsync: createConnector,
        error: createConnectorError,
        isLoading: isCreatingConnector,
    } = useCreateConnector()

    const {
        data: updatedConnector,
        mutateAsync: updateConnector,
        error: updateConnectorError,
        isLoading: isUpdatingConnector,
    } = useUpdateConnector()

    const connector = updatedConnector ?? createdConnector

    function onCancel(): void {
        history.goBack()
    }

    function onGoToConfiguration(event: React.MouseEvent): void {
        event.preventDefault()
        setCurrentTab(0)
    }

    async function onContinue(connectorFromForm: Connector): Promise<void> {
        const newConnector: Connector = { ...connectorFromForm, clusterName: props.clusterName }
        try {
            await (connector ? updateConnector(newConnector) : createConnector(newConnector))
            setCurrentTab(1)
        } catch (error) {}
    }

    const updateDeployment = (connector: Connector) => {
        updateConnector({ ...connector, deployment })
    }

    return (
        <Container as="section" aria-labelledby={Id.HEADING}>
            <header className={styles.header}>
                <PageHeading id={Id.HEADING}>
                    {localization.getString("createConnector")}
                </PageHeading>
            </header>
            <AppText ls="createAConnectorDescription" className={styles.description} />
            <TabBar
                type="stepper"
                tabs={tabs.map((tab, tabIndex) => {
                    return {
                        id: tabIndex,
                        label: tab,
                        ariaControls: `${tab}-tab`,
                    }
                })}
                selectedTabId={currentTab}
                className={styles.tabs}
            />
            {currentTab === 0 && (
                <ConnectorConfigurationForm
                    initialValue={connector}
                    onCancel={onCancel}
                    onSubmit={onContinue}
                    error={createConnectorError || updateConnectorError || ""}
                    submitting={isCreatingConnector || isUpdatingConnector}
                    submitLabel={localization.getString("continue")}
                    canCreateConnector={props.canCreateConnector}
                />
            )}
            {currentTab === 1 && (
                <React.Fragment>
                    <ConnectorInstallForm
                        connector={{ ...connector!, deployment }}
                        onDeploymentChange={setDeployment}
                        canAccessConnectorInstallStep={props.canCreateConnector}
                    />
                    <InstallActions
                        connector={connector!}
                        onBack={onGoToConfiguration}
                        updateDeployment={updateDeployment}
                    />
                </React.Fragment>
            )}
        </Container>
    )
}

enum Id {
    HEADING = "heading",
}

interface InstallActionsProps {
    connector: Connector
    onBack: React.MouseEventHandler
    updateDeployment: (connector: Connector) => void
}

function InstallActions(props: InstallActionsProps): JSX.Element {
    const localization = new LocalizationService()

    const {
        refetch: testConnection,
        isLoading,
        isError,
        data: connectorStatus,
    } = useGetConnectorStatusById(props.connector.id)

    const connectorRoute = props.connector.id
        ? formatRoutePath(ROUTE.CONNECTORS_DETAILS, { id: encodeID(props.connector.id) })
        : ROUTE.CONNECTORS

    const bannerStatus = getBannerStatusFromConnector(isLoading, isError, connectorStatus)

    useInterval(() => {
        if (bannerStatus === BannerStatus.WARNING) testConnection()
    }, 10_000)

    useEffect(() => {
        const isReporting =
            connectorStatus === ConnectorStatus.PARTIALLY_REPORTING ||
            connectorStatus === ConnectorStatus.REPORTING
        if (isReporting) props.updateDeployment(props.connector)
    }, [connectorStatus])

    return (
        <div className={styles.installActions}>
            <FormButtons
                leftButtons={
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.SECONDARY}
                        onClick={props.onBack}
                    >
                        {localization.getString("back")}
                    </Button>
                }
                rightButtons={
                    <React.Fragment>
                        <Button
                            asElement={ButtonElement.LINK}
                            to={connectorRoute}
                            buttonType={ButtonType.SECONDARY}
                        >
                            {localization.getString("saveAndExit")}
                        </Button>
                        {bannerStatus === BannerStatus.SUCCESS ? (
                            <Button
                                asElement={ButtonElement.LINK}
                                to={connectorRoute}
                                buttonType={ButtonType.PRIMARY}
                            >
                                {localization.getString("done")}
                            </Button>
                        ) : (
                            <Button
                                loading={bannerStatus === BannerStatus.WARNING}
                                disabled
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.PRIMARY}
                            >
                                {localization.getString("continue")}
                            </Button>
                        )}
                    </React.Fragment>
                }
            >
                <Banner status={bannerStatus} />
            </FormButtons>
        </div>
    )
}

enum BannerStatus {
    WARNING = "WARNING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}

interface BannerProps {
    status?: BannerStatus
}

function Banner(props: BannerProps): JSX.Element {
    switch (props.status) {
        case BannerStatus.WARNING:
            return (
                <WarningBanner className={styles.banner}>
                    <AppText ls="youCanContinueOnceYouHaveRunTheInstallationStepsAndTheConnectorStatusReadsReporting" />
                </WarningBanner>
            )

        case BannerStatus.SUCCESS:
            return (
                <SuccessBanner className={styles.banner}>
                    <AppText ls="theConnectorIsSuccessfullyReportingClickDoneToReturnToTheConnectorPage" />
                </SuccessBanner>
            )

        case BannerStatus.ERROR:
            return (
                <ErrorBanner className={styles.banner}>
                    <AppText ls="thereSeemsToBeAProblemWithTheConnectorTryAgainInAFewMinutesIfTheProblemPersistsPleaseContactSonicWallCseSupport" />
                </ErrorBanner>
            )

        case undefined:
            return <React.Fragment />
    }
}

function getBannerStatusFromConnector(
    isLoading: boolean,
    isError: boolean,
    status?: ConnectorStatus
): BannerStatus {
    if (isLoading) return BannerStatus.WARNING
    if (isError) return BannerStatus.ERROR

    switch (status) {
        case undefined:
        case ConnectorStatus.PENDING:
            return BannerStatus.WARNING

        case ConnectorStatus.REPORTING:
        case ConnectorStatus.PARTIALLY_REPORTING:
            return BannerStatus.SUCCESS

        case ConnectorStatus.TERMINATED:
            return BannerStatus.ERROR
    }
}
