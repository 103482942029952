import React from "react"

import {
    ModalApi,
    ModalProps as BaseModalProps,
    ModalAction,
    ModalWithoutRef,
} from "./Modal.component"
import { ModalFooter, Props as ModalFooterProps } from "./ModalFooter.component"

export type { ModalApi } from "./Modal.component"
export { ModalAction } from "./Modal.component"

export type ModalProps = Omit<BaseModalProps, "footer"> &
    Pick<
        ModalFooterProps,
        "confirmButtonLabel" | "cancelButtonLabel" | "isDestructive" | "onConfirm"
    >

export function ConfirmationModalWithoutRef<Data>(
    props: ModalProps,
    ref: React.ForwardedRef<ModalApi<Data>>
): JSX.Element {
    const modalRef = React.useRef<ModalApi<Data>>(null)

    React.useImperativeHandle(ref, () => ({
        open: () => modalRef.current?.open() ?? Promise.resolve({ action: ModalAction.DISMISS }),
        dismiss: () => modalRef.current?.dismiss(),
        complete: (data) => modalRef.current?.complete(data),
    }))

    const { confirmButtonLabel, cancelButtonLabel, isDestructive, onConfirm, ...modalProps } = props

    const onDismiss = () => modalRef.current?.dismiss()

    return ModalWithoutRef(
        {
            ...modalProps,
            footer: (
                <ModalFooter
                    confirmButtonLabel={confirmButtonLabel}
                    cancelButtonLabel={cancelButtonLabel}
                    isDestructive={isDestructive}
                    onCancel={onDismiss}
                    onConfirm={onConfirm}
                />
            ),
        },
        modalRef
    )
}
