import classNames from "classnames/bind"
import React from "react"

import { useServiceLinks } from "../../../../pre-v3/services/link/Link.service"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import { useIsBreakpointUp } from "../../../../pre-v3/utils/UseBreakpoint.hook"
import {
    ApiKeyDetails,
    BanyanKeyDetails,
    NewServiceAccount as NewAccount,
    ServiceAccountDetails as Details,
    ServiceAccountType as Type,
    serviceAccountTypeLabelDict,
} from "../../../services/ServiceAccount.service"
import { AppText } from "../../../components/app-text/AppText.component"
import { FormColumn } from "../../../components/form/FormColumn.component"
import { FormRow } from "../../../components/form/FormRow.component"
import { Input } from "../../../components/input/Input.component"
import { MultiInput } from "../../../components/multi-input/MultiInput.component"
import { SecretInput } from "../../../components/secret-input/SecretInput.component"
import { ToggleButton, ToggleButtonItem } from "../../../components/toggle-button/ToggleButton"
import styles from "./ServiceAccountForm.module.scss"

export interface ServiceAccountFormProps {
    serviceAccount?: Partial<NewAccount>
    className?: string
    isDisabled?: boolean
    isEditing?: boolean
    isOverview?: boolean
    onChange?(serviceAccount: Partial<NewAccount>): void
}

export function ServiceAccountForm(props: ServiceAccountFormProps): JSX.Element {
    const shouldShowRow = useIsBreakpointUp("md")
    const linkService = useServiceLinks()
    const localization = useServiceLocalization()

    const onNameChange = (name: string) => props.onChange?.({ ...props.serviceAccount, name })

    const onDescriptionChange = (description: string) =>
        props.onChange?.({ ...props.serviceAccount, description })

    const onDetailsChange = (details: Details) =>
        props.onChange?.({ ...props.serviceAccount, details })

    const onTypeChange = (type: Type) => onDetailsChange(createEmptyDetails(type))

    const toggleButtons = Object.values(Type).map<ToggleButtonItem>((type) => ({
        label: localization.getString(serviceAccountTypeLabelDict[type]),
        value: type,
        selected: props.serviceAccount?.details?.type === type,
        onClick:
            props.serviceAccount?.details?.type === type ? undefined : () => onTypeChange(type),
    }))

    const FormHolder = shouldShowRow ? FormRow : FormColumn

    return (
        <div className={classNames(styles.container, props.className)}>
            <AppText
                ls={{
                    key: "serviceAccountDescription",
                    replaceVals: [linkService.getLink("serviceAccounts")],
                }}
            />
            <FormHolder label={localization.getString("name")} htmlFor={nameId}>
                <Input
                    id={nameId}
                    placeholder={localization.getString("serviceAccountName")}
                    value={props.serviceAccount?.name ?? ""}
                    onChangeValue={onNameChange}
                    disabled={props.isDisabled || props.isEditing}
                    required
                />
            </FormHolder>
            <FormHolder
                label={localization.getString(
                    "somethingOptional",
                    localization.getString("description")
                )}
                description={localization.getString(
                    "anOptionalFieldShownInTheWebConsoleToAdminUsersOnlyForYourOwnInternalNotes"
                )}
            >
                <Input
                    placeholder={localization.getString(
                        "somethingOptional",
                        localization.getString("description")
                    )}
                    value={props.serviceAccount?.description ?? ""}
                    onChangeValue={onDescriptionChange}
                    disabled={props.isDisabled}
                />
            </FormHolder>
            <FormColumn label={localization.getString("selectTypeOfServiceAccountToCreate")}>
                <ToggleButton
                    items={toggleButtons}
                    disabled={props.isDisabled || props.isOverview}
                    required
                />
            </FormColumn>
            <DetailsForm
                details={props.serviceAccount?.details}
                onDetailsChange={onDetailsChange}
                isDisabled={props.isDisabled}
                isOverview={props.isOverview}
            />
        </div>
    )
}

interface DetailsProps {
    details?: Details
    isDisabled?: boolean
    isOverview?: boolean
    onDetailsChange(details: Details): void
}

function DetailsForm(props: DetailsProps): JSX.Element {
    const shouldShowRow = useIsBreakpointUp("md")
    const localization = useServiceLocalization()

    const onIssuerChange = (issuer: string) =>
        props.onDetailsChange(
            props.details?.type === Type.JWT
                ? { ...props.details, issuer }
                : {
                      type: Type.JWT,
                      issuer,
                      audience: "",
                      subjects: [],
                  }
        )

    const onAudienceChange = (audience: string) =>
        props.onDetailsChange(
            props.details?.type === Type.JWT
                ? { ...props.details, audience }
                : {
                      type: Type.JWT,
                      issuer: "",
                      audience,
                      subjects: [],
                  }
        )

    const onSubjectsChange = (subjects: string[]) =>
        props.onDetailsChange(
            props.details?.type === Type.JWT
                ? { ...props.details, subjects }
                : {
                      type: Type.JWT,
                      issuer: "",
                      audience: "",
                      subjects,
                  }
        )

    const FormHolder = shouldShowRow ? FormRow : FormColumn

    switch (props.details?.type) {
        case Type.API_KEY:
            return <ApiKeyFormHolder {...props} details={props.details} />

        case Type.JWT:
            return (
                <React.Fragment>
                    <FormHolder label={localization.getString("issuer")}>
                        <Input
                            placeholder={localization.getString(
                                "enterSomething",
                                localization.getString("issuer")
                            )}
                            value={props.details.issuer}
                            onChangeValue={onIssuerChange}
                            disabled={props.isDisabled}
                            required
                        />
                    </FormHolder>
                    <FormHolder label={localization.getString("audience")}>
                        <Input
                            placeholder={localization.getString(
                                "enterSomething",
                                localization.getString("audience")
                            )}
                            value={props.details.audience}
                            onChangeValue={onAudienceChange}
                            disabled={props.isDisabled}
                            required
                        />
                    </FormHolder>
                    <FormHolder label={localization.getString("subjects")}>
                        <MultiInput
                            placeholder={localization.getString(
                                "enterSomething",
                                localization.getString("subjects")
                            )}
                            values={props.details.subjects}
                            onChange={onSubjectsChange}
                            disabled={props.isDisabled}
                            required
                            caseInsensitiveDuplicateMatch={false}
                        />
                    </FormHolder>
                </React.Fragment>
            )

        case Type.BANYAN_GENERATED:
            return props.isOverview && props.isDisabled ? (
                <ApiKeyFormHolder {...props} details={props.details} />
            ) : (
                <React.Fragment />
            )

        case undefined:
            return <React.Fragment />
    }
}

interface ApiKeyFormHolderProps {
    details: ApiKeyDetails | BanyanKeyDetails
    isDisabled?: boolean
    isOverview?: boolean
    onDetailsChange(details: Details): void
}

function ApiKeyFormHolder(props: ApiKeyFormHolderProps): JSX.Element {
    const shouldShowRow = useIsBreakpointUp("md")
    const localization = useServiceLocalization()

    const onApiKeyChange = (apiKey: string) => props.onDetailsChange({ ...props.details, apiKey })

    const FormHolder = shouldShowRow ? FormRow : FormColumn
    const ApiKeyInput = props.isOverview ? SecretInput : Input

    const apiKeyLabel = localization.getString("apiKey")

    return (
        <FormHolder label={apiKeyLabel}>
            <ApiKeyInput
                placeholder={localization.getString("enterSomething", apiKeyLabel)}
                value={props.details.apiKey}
                onChangeValue={onApiKeyChange}
                disabled={props.isDisabled}
                hideValueLabel={localization.getString("hideSomething", apiKeyLabel)}
                showValueLabel={localization.getString("showSomething", apiKeyLabel)}
                required
            />
        </FormHolder>
    )
}

function createEmptyDetails(serviceAccountType: Type): Details {
    switch (serviceAccountType) {
        case Type.API_KEY:
            return {
                type: Type.API_KEY,
                apiKey: "",
            }

        case Type.BANYAN_GENERATED:
            return {
                type: Type.BANYAN_GENERATED,
                apiKey: "",
            }

        case Type.JWT:
            return {
                type: Type.JWT,
                issuer: "",
                audience: "",
                subjects: [],
            }
    }
}

const nameId = "name"
