import { BaseApi } from "./Base.api"
import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"

@Singleton("OrgApi")
export class OrgApi extends BaseApi {
    public getUserOrgDetails(): Promise<UserOrgDetailsRes> {
        return this.get("/api/v1/user_org_details", {
            default: this.localization.getString("errorCouldNotFetchUserOrgDetails"),
        })
    }

    public getOrgDetails(): Promise<OrgDetailsRes> {
        return this.get("/api/v1/org_details", {
            default: this.localization.getString("errorCouldNotFetchOrgDetails"),
        })
    }

    public getOrgs(): Promise<OrgInfoRes[]> {
        return this.get("/api/v2/mom/org", {
            default: this.localization.getString("errorCouldNotFetchOrgs"),
        })
    }

    public patchOrgSettings(formData: PatchOrgSettingsData): Promise<void> {
        return this.postForm("/api/v1/update_org", formData, {
            default: this.localization.getString("failedToSaveOrgSettings"),
        })
    }

    public getLicenseInformation(): Promise<LicenseInformationRes | null> {
        return this.get("api/v2/org/msw/licenses", {
            default: this.localization.getString("failedToGetOrgLicense"),
        })
    }

    public getOrgLicensesUsage(): Promise<LicenseUsageRes | null> {
        return this.get("api/v2/org/msw/license_usage/summary", {
            default: this.localization.getString("failedToGetOrgLicensesUsage"),
        })
    }

    public getOrgCustomizations(): Promise<UserOrgCustomizationRes> {
        return this.get("/api/v2/org/customization", {
            default: this.localization.getString("errorCouldNotFetchOrgCustomizations"),
        })
    }
}

export interface UserOrgCustomizationRes {
    org_id: string
    org_name: string
    support_message: string
    support_link_title: string
    support_link_url: string
    error_support_message: string
    error_support_link_title: string
    error_support_link_url: string
    branding_logo: string
    branding_background_color: string
}

export interface UserOrgDetailsRes {
    Email: string
    First: string
    Last: string
    FullName: string
    OrgName: string
    ID: string
    OrgID: string
    LastUpdatedAt: number
    AccountType: "SAML" | "LOCAL"
    IDPType: "SAML" | "BANYAN"
    Profile: ProfileRes
    LastUpdatedBy: string
    MFAEnabled: boolean
    HasRefreshToken: boolean
    RefreshTokenForSAMLEnabled: boolean
    IsAIAssistEnabled: boolean
    IsMSPOrg: boolean
    IsSCIMEnabled: boolean
}

export type ProfileRes =
    | "SuperAdmin"
    | "MOM"
    | "ReadOnly"
    | "Admin"
    | "Owner"
    | "PolicyAuthor"
    | "ServiceAuthor"
export type EditionRes = "Team" | "Enterprise" | "Unlimited"
export type StatusRes = "Success" | "PartialSuccess" | "InProgress" | "Failed"
export type ProvisionStatusRes = "Success" | "Failed" | "Archived" | "Pending" | "Deleted"
export type TypeRes =
    | "Production"
    | "Trial"
    | "Staging"
    | "Testing"
    | "Internal"
    | "InternalTemporary"
    | "InternalPersistent"
export type IdpNameRes =
    | ""
    | "DEFAULT"
    | "OKTA"
    | "SAML"
    | "COGNITO"
    | "ONE LOGIN"
    | "OTHER"
    | "BANYAN"

export interface OrgDetailsRes {
    Edition: EditionRes
    OnboardingState?: OnboardingStateRes
    OrgName: string
    OrgID: string
    IsMSPOrg: boolean
    IsGranularTrustEnabled: boolean
    IsDNSFilterEnabled: boolean
    IDPName: IdpNameRes
    CreatedAt: number
    PrivateEdge: boolean
    GlobalEdge: boolean
    OrgType: OrgTypeRes
    IsAppDiscoveryEnabled: boolean
    IsMySonicWall: boolean
    IsTestDrive: boolean
    IsURLFilteringEnabled: boolean
    IsEventChartEnabled: boolean
    IsGeoLocationEnabled: boolean
}

export type OrgTypeRes =
    | "Production"
    | "Trial"
    | "Staging"
    | "InternalTemporary"
    | "InternalPersistent"
    | "Testing"
    | "Internal"

export interface OrgInfoRes {
    status?: StatusRes
    org_name: string
    org_id: string
    edition: EditionRes
    type: TypeRes
    last_updated_at: number
    is_ai_assist_enabled: boolean
    is_dns_filter_enabled: boolean
    is_msp_org: boolean
    // cspell:ignore mysonicwall
    is_mysonicwall: boolean
    created_at: number
    created_by: string
    last_updated_by: string
    archived: boolean
    banyan_idp: boolean
    provision_status: {
        access_tier?: ProvisionStatusRes
        shield?: ProvisionStatusRes
        user_pool?: ProvisionStatusRes
        org_data?: ProvisionStatusRes
        registered_domain?: ProvisionStatusRes
        connector?: ProvisionStatusRes
        invite_code?: ProvisionStatusRes
        user_pool_domain?: ProvisionStatusRes
        user?: ProvisionStatusRes
        default_groups_user?: ProvisionStatusRes
        user_pool_client?: ProvisionStatusRes
        default_groups_user_pool?: ProvisionStatusRes
        update_user_pool?: ProvisionStatusRes
        invite_admin_user?: ProvisionStatusRes
        private_edge_shield?: ProvisionStatusRes
        [key: string]: ProvisionStatusRes | undefined
    }
}

export interface PatchOrgSettingsData {
    IsGranularTrustEnabled?: "TRUE"
    BrandingLogo?: string
    BrandingBackgroundColor?: string
    SupportMessage?: string
    SupportLinkURL?: string
    ErrorSupportMessage?: string
    ErrorSupportLinkURL?: string
    MFAEnabled?: boolean
    IsGeoLocationEnabled?: boolean
}

interface OnboardingStateRes {
    type?: string
    connectorId?: string
    serviceId?: string
    state?: string
}

export type LicenseTypeRes = "None" | "Basic" | "Advanced"

export interface LicenseInformationRes {
    spa: LicenseTypeRes
    sia: LicenseTypeRes
    spa_users: number
    sia_users: number
    spa_overage_count: number
    sia_overage_count: number
}

export interface LicenseUsageRes {
    spa_license_count: number
    sia_license_count: number
}
