import { GridApi, GridReadyEvent, ColDef, RowSelectedEvent } from "ag-grid-community"
import React, { ReactNode } from "react"

import { LocalizationService } from "../../../services/localization/Localization.service"
import ActionBarService, { IconType } from "../../../services/ActionBar.service"
import { Bind } from "../../../decorators/Bind.decorator"
import AgGridUtil from "../../../utils/AgGrid.util"
import { CertsService, ExternalCertificate } from "../../../services/Certs.service"
import { ModalService } from "../../../services/Modal.service"
import { DeleteCancelActions } from "../../../components/modal/delete-cancel/DeleteCancelActions"
import { MessageListContent } from "../../../components/modal/message/MessageListContent"
import IssuedCertificatesListTemplate from "./IssuedCertificatesList.template"

interface Props {
    canDeleteIssuedCertificate?: boolean
}
export class IssuedCertificatesList extends React.Component<Props, IssuedCertificatesListState> {
    public state: IssuedCertificatesListState = { error: "", selectedCertificateLen: 0 }

    public render(): ReactNode {
        return IssuedCertificatesListTemplate.call(this)
    }

    public componentDidMount(): void {
        this.actionBarService.setItems(
            this.ls.getString("issuedCertificates"),
            [
                {
                    label: this.ls.getString("certificates"),
                    href: "",
                },
            ],
            this.onRefresh,
            [
                {
                    icon: IconType.TRASH,
                    onClick: this.onDeleteCertificate,
                    tooltip: this.ls.getString("selectACertificateToDelete"),
                    disabled: true,
                },
            ]
        )
    }

    public componentWillUnmount(): void {
        this.actionBarService.unregisterRefreshFn(this.onRefresh)
    }

    private onSelectionChange(selectedCertificate: ExternalCertificate[]): void {
        this.setState({ selectedCertificateLen: selectedCertificate.length })
        this.actionBarService.setActions([
            {
                icon: IconType.TRASH,
                onClick: this.onDeleteCertificate,
                tooltip: this.ls.getString(
                    selectedCertificate.length === 0
                        ? "selectACertificateToDelete"
                        : "deleteCertificate"
                ),
                disabled: selectedCertificate.length === 0,
            },
        ])
    }

    private ls: LocalizationService = new LocalizationService()
    private actionBarService: ActionBarService = new ActionBarService()
    private certsService: CertsService = new CertsService()
    private modalService: ModalService = new ModalService()
    private currentlySelectedRows: ExternalCertificate[] = []

    private gridApi: GridApi
    private columns: ColDef[] = [
        {
            headerName: this.ls.getString("status"),
            field: "status",
            width: 75,
            cellRenderer: "statusCellRenderer",
        },
        {
            headerName: this.ls.getString("domain"),
            field: "name",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            width: 75,
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: this.ls.getString("expiration"),
            field: "expiresAt",
            width: 75,
            cellRenderer: "epochTimeRenderer",
        },
        {
            headerName: "",
            field: "reissue",
            width: 5,
            cellRenderer: "reissueCertificateCellRenderer",
        },
    ]

    @Bind
    private onRefresh(): void {
        this.onSelectionChange([])
        this.fetchData()

        if (this.gridApi) {
            this.gridApi.deselectAll()
        }
    }

    @Bind
    private onDeleteCertificate(): void {
        let selectedRow: ExternalCertificate[] = this.gridApi.getSelectedRows()
        this.modalService
            .open(
                this.ls.getString("deleteCertificate"),
                {
                    component: MessageListContent,
                    props: {
                        text: this.ls.getString("deleteIssuedCertificateExplanation"),
                        list: selectedRow.length > 0 ? selectedRow[0].name : "",
                    },
                },
                DeleteCancelActions
            )
            .onClose(() => {
                if (selectedRow.length > 0) {
                    this.certsService.deleteExternalCertificate(selectedRow[0].id).then(
                        () => {
                            this.setState({ error: "" })
                            this.onRefresh()
                        },
                        (e) => {
                            let err = e
                                ? e.error_description
                                : this.ls.getString("failedToLoadDeleteCertificate")

                            this.setState({
                                error: err,
                            })
                            this.onRefresh()
                        }
                    )
                } else {
                    this.setState({ error: this.ls.getString("pleaseSelectACertificate") })
                }
            })
            .onCancel(() => {
                this.onRefresh()
            })
    }

    @Bind
    private rowSelectedHandler(event: RowSelectedEvent): void {
        if (event && event.data) {
            this.onSelectionChange(this.gridApi.getSelectedRows())
        }
    }

    @Bind
    private onGridReady(event: GridReadyEvent): void {
        this.gridApi = event.api
        this.fetchData()
    }

    @Bind
    private onFilter(value: string): void {
        if (this.gridApi) {
            this.gridApi.setQuickFilter(value)
        }
    }

    @Bind
    private fetchData(): void {
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay()
            this.certsService
                .getExternalCertificates()
                .then((certificates: ExternalCertificate[]) => {
                    this.gridApi.hideOverlay()
                    this.gridApi.setRowData(certificates)
                })
                .catch(() => {
                    this.gridApi.showNoRowsOverlay()
                    this.setState({ error: this.ls.getString("failedToLoadIssuedCertificates") })
                })
        }
    }

    @Bind
    private async onReissueCertificate(id: string): Promise<void> {
        try {
            await this.certsService.reissueCertificate(id)
            this.setState({ error: "" })
            this.onRefresh()
        } catch (e) {
            this.setState({
                error: this.ls.getString("failedToLoadIssuedCertificates"),
            })
        }
    }
}

interface IssuedCertificatesListState {
    error: string
    selectedCertificateLen: number
}
