import { Singleton } from "../../pre-v3/decorators/Singleton.decorator"
import { UrlUtil } from "../../pre-v3/utils/Url.util"
import { BaseApi } from "./Base.api"

@Singleton("RegisteredServiceApi")
export class RegisteredServiceApi extends BaseApi {
    public getRegisteredServices(
        getRegisteredServicesParams?: GetRegisteredServicesParams
    ): Promise<RegisteredServicesRes[]> {
        const params = getRegisteredServicesParams
            ? `?${UrlUtil.convertToURLSearchParams(getRegisteredServicesParams).toString()}`
            : ""

        return this.get(`/api/v1/registered_services${params}`, {
            default: this.localization.getString(
                "failedToGetSomething",
                this.localization.getString("registeredServices")
            ),
        })
    }

    public getTemplates(): Promise<Templates> {
        return this.get("/api/v2/registered_service/template", {
            default: this.localization.getString("errorFetchingVariables"),
        })
    }
}

export interface GetRegisteredServicesParams {
    ServiceID?: string
}

export interface RegisteredServicesRes {
    ServiceID: string
    ServiceSpec: string
    CreatedAt: number
    CreatedBy: string
    LastUpdatedAt: number
    LastUpdatedBy: string
    Enabled: "TRUE" | "FALSE"
    AttachedPolicy: AttachedPolicy | null
}

interface AttachedPolicy {
    PolicyID: string
    PolicyName: string
    PolicyStatus: boolean
    AttachedAt: number
}

export interface ServiceSpecJson {
    metadata: {
        name: string
        tags: {}
    }
    spec?: {
        attributes?: {
            host_tag_selector?: HostTagSelector[]
        }
    }
}

export interface HostTagSelector {
    ["com.banyanops.hosttag.site_name"]?: string
}

export interface Templates {
    tcp: {
        backend_target: string[]
    }
    web: {
        backend_target: string[]
        header: string[]
    }
}
