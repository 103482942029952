import { CellClassParams, ICellRendererParams, ValueFormatterParams } from "ag-grid-community"
import classNames from "classnames/bind"
import React from "react"

import {
    Button,
    ButtonElement,
    ButtonType,
    IconType,
} from "../../../components/button/Button.component"
import useTitle from "../../../hooks/useTitle.hook"
import { FilterBar } from "../../../pre-v3/components/filter-bar/FilterBar.component"
import {
    ColDef,
    Grid,
    GridApi,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
} from "../../../pre-v3/components/grid/Grid.component"
import { JsonTextArea } from "../../../pre-v3/components/json-text-area/JsonTextArea.component"
import { EventProps } from "../../../pre-v3/api/EventProps.types"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { useServiceEntity } from "../../../pre-v3/services/Entity.service"
import {
    ChartData,
    EventsService,
    MonitorEvent,
    useEventsService,
    useGetInternetAccessEventTypes,
} from "../../../pre-v3/services/Events.service"
import { useServiceManage } from "../../../pre-v3/services/Manage.service"
import { useServiceTunnelService } from "../../../pre-v3/services/ServiceTunnel.service"
import AgGridUtil, {
    DataFilter,
    DataFilterType,
    FilterModel,
} from "../../../pre-v3/utils/AgGrid.util"
import { DateUtil } from "../../../pre-v3/utils/Date.util"
import { EventsUtil } from "../../../pre-v3/utils/Events.util"
import { StringUtil } from "../../../pre-v3/utils/String.util"
import { UrlUtil } from "../../../pre-v3/utils/Url.util"
import { Loader } from "../../components/loader/Loader.component"
import styles from "./Events.module.scss"
import { Tooltip } from "../../components/tooltip/Tooltip.component"
import { EventsChart } from "./EventsChart.component"
import { useFeatureFlags } from "../../../hooks/useFeatureFlags.hook"
import { Accordion } from "../../components/accordion/Accordion.component"

export function InternetAccessEvents(): JSX.Element {
    useTitle(["internetAccess", "events", "home", "adminConsole"])

    const ls = useServiceLocalization()

    const eventsService = useEventsService()

    const columns = useColumns()
    const gridApi = React.useRef<GridApi>()
    const filters = useFilters()
    const initialModel = UrlUtil.readFilter()
    const { data: flags, status: flagsStatus } = useFeatureFlags()

    const [eventsCount, setEventsCount] = React.useState(0)
    const [chartData, setChartData] = React.useState<ChartData[]>()

    const internetAccessEventsLabel = ls.getString("internetAccessEvents")

    if (flagsStatus === "loading") {
        return (
            <Loader
                center
                medium
                title={ls.getString("loadingSomething", internetAccessEventsLabel)}
            />
        )
    }

    const fetchData = () => {
        if (flagsStatus === "success") {
            gridApi.current?.setServerSideDatasource(
                new DataSource(
                    gridApi.current,
                    eventsService,
                    setEventsCount,
                    setChartData,
                    flags.adminConsole.isEventChartEnabled
                )
            )
        }
    }

    const onRefresh: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        gridApi.current?.refreshServerSideStore()
    }

    const onGridReady = (event: GridReadyEvent) => {
        if (event?.api) gridApi.current = event.api

        if (Object.keys(initialModel).length > 0) {
            gridApi.current?.setFilterModel(initialModel)
        }

        fetchData()
    }

    const onSetEventsFilter = (model: FilterModel) => {
        gridApi.current?.setFilterModel(model)
        UrlUtil.writeFilter(model)
    }

    return (
        <React.Fragment>
            <section aria-label={internetAccessEventsLabel} className={styles.container}>
                <div className={styles.content}>
                    {!!flags?.adminConsole.isEventChartEnabled && (
                        <Accordion label={ls.getString("eventActivity")} defaultOpen>
                            <EventsChart data={chartData || []} isLoading={!chartData} />
                        </Accordion>
                    )}
                    <div className={styles.filterContainer}>
                        <FilterBar
                            filters={filters}
                            initialModel={initialModel}
                            className={styles.filterBar}
                            onChange={onSetEventsFilter}
                        />
                        <Tooltip title={ls.getString("refresh")}>
                            <Button
                                icon={IconType.REDO}
                                onClick={onRefresh}
                                asElement={ButtonElement.BUTTON}
                                buttonType={ButtonType.SECONDARY}
                            />
                        </Tooltip>
                    </div>

                    <div className={styles.eventsCount}>
                        {ls.getPluralString(
                            "showingEvents",
                            eventsCount,
                            EventsUtil.getFormattedEventsCount(eventsCount)
                        )}
                    </div>

                    <div className={classNames("ag-theme-material", styles.gridContainer)}>
                        <Grid
                            onGridReady={onGridReady}
                            columnDefs={columns}
                            serverSidePagination
                            masterDetail
                            detailCellRenderer={DetailCellRenderer}
                            detailRowHeight={500}
                            className={styles.grid}
                        />
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

class DataSource implements IServerSideDatasource {
    constructor(
        gridApi: GridApi,
        eventsService: EventsService,
        setEventsCount: (count: number) => void,
        setChartData: (data: ChartData[]) => void,
        isEventChartEnable: boolean
    ) {
        this.gridApi = gridApi

        this.eventsService = eventsService
        this.setEventsCount = setEventsCount
        this.setChartData = setChartData
        this.isEventChartEnable = isEventChartEnable
    }

    public mapParamsToFilterProps(params: IServerSideGetRowsParams): EventProps {
        const skipLimit: { skip: number; limit: number } = {
            skip: 0,
            limit: 10_000,
        }
        if (params.request.startRow) {
            skipLimit.skip = params.request.startRow
        }

        if (params.request.endRow) {
            skipLimit.limit = params.request.endRow - skipLimit.skip
        }

        const limit = skipLimit.limit
        const sortModel = params.request.sortModel
        const filterModel = params.request.filterModel
        let order = "desc"
        let startTime = 0
        let endTime = 0

        if (filterModel?.timestamp) {
            const timeRange = DateUtil.deserializeTimeRange(filterModel.timestamp.filter)
            startTime = timeRange.start || 0
            endTime = timeRange.end || 0
        }

        if (sortModel.length > 0 && sortModel[0].colId === "timestamp") {
            order = sortModel[0].sort
        }

        return {
            skip: skipLimit.skip,
            limit,
            order,
            after: startTime,
            before: endTime,
            type: filterModel.type ? filterModel.type.filter : "",
            user_email: filterModel.userEmail ? filterModel.userEmail.filter : "",
            service_name: filterModel.serviceName ? filterModel.serviceName.filter : "",
            serialnumber: filterModel.deviceSerial ? filterModel.deviceSerial.filter : "",
            external_id: filterModel?.externalId?.filter || "",
            sub_type: filterModel.subType ? filterModel.subType.filter : "",
            severity: filterModel?.severity?.filter || "",
            id: filterModel?.id?.filter || "",
            event_group: filterModel.type ? undefined : "internet_access",
        }
    }

    public getRows(params: IServerSideGetRowsParams): void {
        this.gridApi.showLoadingOverlay()
        this.eventsService
            .getEvents(this.mapParamsToFilterProps(params), this.isEventChartEnable)
            .then((result) => {
                params.success({
                    rowData: result.data,
                    rowCount: result.total,
                })
                this.gridApi.hideOverlay()
                if (result.total === 0) {
                    this.gridApi.showNoRowsOverlay()
                }
                this.setEventsCount(result.total)
                this.setChartData(result.chartData)
            }, params.fail)
    }

    private eventsService: EventsService
    private gridApi: GridApi
    private setEventsCount: (count: number) => void
    private setChartData: (data: ChartData[]) => void
    private isEventChartEnable: boolean
}

function useColumns(): ColDef[] {
    const ls = useServiceLocalization()

    return React.useMemo<ColDef[]>(
        () => [
            {
                headerName: ls.getString("timestamp"),
                field: "timestamp",
                valueFormatter: AgGridUtil.dateFormatter,
                width: 150,
                sort: "desc",
                sortingOrder: ["desc", "asc"],
                filter: "agTextColumnFilter",
                cellRenderer: "agGroupCellRenderer",
                cellClassRules: {
                    [styles.eventsGridCellWarning]: (params: CellClassParams<MonitorEvent>) =>
                        params.data?.severity === SeverityTypes.WARNING,
                    [styles.eventsGridCellError]: (params: CellClassParams) =>
                        params.data?.severity === SeverityTypes.ERROR,
                },
            },
            {
                headerName: ls.getString("eventType"),
                field: "type",
                width: 70,
                filter: "agTextColumnFilter", // always set to text filter, we are not using ag-grid filter UI
                sortable: false,
            },
            {
                headerName: ls.getString("userSlashDevice"),
                field: "userEmail",
                sortable: false,
                filter: "agTextColumnFilter",
                valueFormatter: userDeviceFormatter,
            },
            {
                headerName: ls.getString("service"),
                field: "serviceName",
                sortable: false,
                filter: "agTextColumnFilter",
                valueFormatter: targetFormatter,
            },
            {
                headerName: ls.getString("message"),
                field: "message",
                sortable: false,
            },
            {
                hide: true,
                field: "deviceSerial",
                filter: "agTextColumnFilter",
            },
            {
                hide: true,
                field: "subType",
                filter: "agTextColumnFilter",
            },
            {
                hide: true,
                field: "externalId",
                filter: "agTextColumnFilter",
            },
            {
                hide: true,
                field: "severity",
                filter: "agTextColumnFilter",
            },
            {
                hide: true,
                field: "id",
                filter: "agTextColumnFilter",
            },
        ],
        [ls]
    )
}

function userDeviceFormatter(params: ValueFormatterParams<MonitorEvent>): string {
    if (!params.data) return "-"

    if (params.data.userEmail && params.data.model) {
        return `${params.data.userEmail}\/${params.data.model}`
    }

    if (params.data.userEmail) return params.data.userEmail

    return params.data.model ?? "-"
}

function targetFormatter(params: ValueFormatterParams<MonitorEvent>): string {
    return params.data?.serviceName ?? "-"
}

function useFilters(): DataFilter[] {
    const ls = useServiceLocalization()

    const entityService = useServiceEntity()
    const manageService = useServiceManage()
    const serviceTunnelService = useServiceTunnelService()
    const { data: eventTypes } = useGetInternetAccessEventTypes()

    return React.useMemo<DataFilter[]>(
        () => [
            {
                key: EventFilterType.TYPE,
                displayName: ls.getString("eventType"),
                type: DataFilterType.MULTI_LIST,
                options: eventTypes?.data ?? [],
            },
            {
                key: EventFilterType.SEVERITY,
                displayName: ls.getString("minimumEventSeverity"),
                type: DataFilterType.LIST,
                options: [
                    SeverityTypes.DEBUG,
                    SeverityTypes.INFO,
                    SeverityTypes.WARNING,
                    SeverityTypes.ERROR,
                ],
            },
            {
                key: EventFilterType.USER_EMAIL,
                displayName: ls.getString("userEmail"),
                type: DataFilterType.MULTILOOKUP,
                dataSource: (search) =>
                    entityService.getUserEmails({
                        skip: 0,
                        limit: 20,
                        filterModel: { email: { filter: search } },
                    }),
            },
            {
                key: EventFilterType.DEVICE_SERIAL_NUMBER,
                displayName: ls.getString("deviceSerialNumber"),
                type: DataFilterType.MULTILOOKUP,
                dataSource: (search) =>
                    entityService.getDeviceSerialNumbers({
                        skip: 0,
                        limit: 20,
                        filterModel: { serial: { filter: search } },
                    }),
            },
            {
                key: EventFilterType.SERVICE_NAME,
                displayName: ls.getString("serviceName"),
                type: DataFilterType.MULTILOOKUP,
                dataSource: async (search) => {
                    const [serviceTunnels, services] = await Promise.all([
                        serviceTunnelService.getServiceTunnels(),
                        manageService.getRegisteredServices(),
                    ])

                    const registeredServices = services.map((service) => service.name)
                    const serviceTunnelNames = serviceTunnels.map((service_1) => service_1.name)

                    return registeredServices
                        .concat(serviceTunnelNames)
                        .filter((name) => StringUtil.caseInsensitiveIncludes(name, search))
                },
            },
            {
                key: EventFilterType.ID,
                displayName: ls.getString("eventId"),
                type: DataFilterType.INPUT,
            },
            {
                key: EventFilterType.EXTERNAL_ID,
                displayName: ls.getString("externalId"),
                type: DataFilterType.INPUT,
            },
            {
                key: EventFilterType.TIMESTAMP,
                displayName: ls.getString("timestamp"),
                type: DataFilterType.DATETIMERANGE,
            },
        ],
        [entityService, ls, serviceTunnelService, eventTypes]
    )
}

enum EventFilterType {
    TYPE = "type",
    SUBTYPE = "subType",
    USER_EMAIL = "userEmail",
    DEVICE_SERIAL_NUMBER = "deviceSerial",
    SERVICE_NAME = "serviceName",
    SEVERITY = "severity",
    EXTERNAL_ID = "externalId",
    ID = "id",
    TIMESTAMP = "timestamp",
}

enum SeverityTypes {
    DEBUG = "DEBUG",
    INFO = "INFO",
    WARNING = "WARN",
    ERROR = "ERROR",
}

function DetailCellRenderer(params: ICellRendererParams<MonitorEvent>) {
    return (
        <JsonTextArea
            className={classNames(styles.json, {
                [styles.eventsGridCellWarning]: params.data?.severity === SeverityTypes.WARNING,
                [styles.eventsGridCellError]: params.data?.severity === SeverityTypes.ERROR,
            })}
            height="400px"
            initialValue={params.data?.raw ?? ""}
            readOnly
            defaultFoldChildren
        />
    )
}
