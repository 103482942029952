import { Branded } from "../../types/Brand.types"
import { isSonicWallDomain } from "../../utils/url.utils"
import { Singleton } from "../decorators/Singleton.decorator"

@Singleton("AuthStore")
export class AuthStore {
    public getJwtToken(): string {
        return this.jwtToken
    }

    public setJwtToken(jwtToken: string): string {
        return (this.jwtToken = jwtToken)
    }

    public getCscJwtToken(): string {
        return this.cscJwtToken
    }

    public setCscJwtToken(jwtToken: string): string {
        return (this.cscJwtToken = jwtToken)
    }

    public setMswUrl(url: string): MswUrl | undefined {
        if (!isSonicWallDomain(url)) return
        return (this.mswUrl = url as MswUrl)
    }

    public getMswUrl(): MswUrl | undefined {
        return this.mswUrl
    }

    public clear() {
        this.mswUrl = "" as MswUrl
        this.jwtToken = ""
        this.cscJwtToken = ""
    }

    private mswUrl: MswUrl
    private jwtToken: string
    private cscJwtToken: string
}

export type MswUrl = Branded<string, "MswUrl">
