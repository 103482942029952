import classNames from "classnames/bind"
import * as React from "react"
import { useFormFloating, useFormLabelWidth, useFormSlim } from ".."
import { useFormDisplayMode } from "../form/Form"
import { InfoTip } from "../info-tip/InfoTip.component"
import styles from "./FormLabel.module.scss"

interface FormLabelProps {
    title: React.ReactNode
    description?: React.ReactNode
    children?: React.ReactNode
    htmlFor?: string
    tooltip?: string
    labelClassName?: string
    className?: string
    inline?: boolean
    width?: number
    slim?: boolean
    centerItems?: boolean
    valueClassName?: string
    ariaLabel?: string
}

/**
 *
 * @deprecated Use the Form Label component instead
 * src/components/form/FormLabel.component.tsx
 */
export function FormLabel({
    title,
    description,
    children,
    htmlFor,
    tooltip,
    className,
    inline,
    labelClassName,
    width,
    slim,
    centerItems,
    valueClassName,
    ariaLabel,
}: FormLabelProps) {
    // figure out if we should render as an inline label
    const displayMode = useFormDisplayMode()
    const inlineLabel = typeof inline === "undefined" ? displayMode === "grid" : inline

    // figure out if we have a width for the label
    const contextWidth = useFormLabelWidth()
    const labelWidth = inlineLabel ? width || contextWidth : "auto"

    // figure out if we have to show a slim label
    const contextSlim = useFormSlim()
    const slimLabel = typeof slim !== "undefined" ? slim : contextSlim

    // if the form isn't floating we need some space on the left
    const floating = useFormFloating()

    return (
        <FormLabelContext.Provider value={{ htmlFor }}>
            <section
                className={classNames(styles.section, className, {
                    [styles.inline]: inlineLabel,
                    [styles.sectionList]: !inlineLabel,
                    [styles.leftSpacing]: !floating && !slimLabel,
                    [styles.centerItems]: centerItems,
                })}
                aria-label={ariaLabel}
            >
                <div
                    className={classNames(
                        styles.formLabel,
                        styles.labelLeft,
                        styles.label,
                        {
                            [styles.inlineLabel]: inlineLabel,
                        },
                        labelClassName
                    )}
                    style={{ width: labelWidth }}
                >
                    <label htmlFor={htmlFor ?? ""}>{title}</label>
                    {tooltip && <InfoTip message={tooltip} />}
                </div>
                {description && <label className={styles.description}>{description}</label>}
                <div
                    className={classNames(valueClassName, {
                        [styles.inlineValue]: inlineLabel,
                        [styles.listValue]: !inlineLabel,
                    })}
                >
                    {children}
                </div>
            </section>
        </FormLabelContext.Provider>
    )
}

export const FormLabelContext = React.createContext<{ htmlFor?: string }>({ htmlFor: "" })

export const useHtmlFor = () => React.useContext(FormLabelContext).htmlFor
