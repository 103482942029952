import React from "react"

import { FormLabel } from "../../../pre-v3/components/form-label/FormLabel"
import { LanguageKey } from "../../../pre-v3/services/localization/languages/en-US.language"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { Edition } from "../../../v3/services/shared/Edition"
import {
    FeatureModelType,
    LicenseInformation,
    Org,
} from "../../../v3/services/MomOrgManagement.service"
import { EditionInput } from "./Edition.component"

interface FeatureModelRowProps {
    org: Org
    editionInputId: string
    editionInputDisabled?: boolean
    onEditionChange?(edition: Edition): void
}

export function FeatureModelRow(props: FeatureModelRowProps): JSX.Element {
    switch (props.org.featureModel.type) {
        case FeatureModelType.EDITION_BASED:
            return (
                <EditionInput
                    id={props.editionInputId}
                    value={props.org.featureModel.edition}
                    disabled={props.editionInputDisabled}
                    onChange={props.onEditionChange}
                />
            )

        case FeatureModelType.LICENSE_BASED:
            return (
                <LicenseInformationRow
                    spaLicenseInformation={props.org.featureModel.spa}
                    siaLicenseInformation={props.org.featureModel.sia}
                />
            )
    }
}

interface LicenseInformationRowProps {
    spaLicenseInformation: LicenseInformation
    siaLicenseInformation: LicenseInformation
}

function LicenseInformationRow(props: LicenseInformationRowProps): JSX.Element {
    const localization = useServiceLocalization()

    const getLicenseText = (
        licenseInformation: LicenseInformation,
        licenseType: LanguageKey
    ): string => {
        if (licenseInformation.entitlementCount === 0)
            return localization.getString(
                "licenseDataText_zero",
                localization.getString(licenseType)
            )

        return localization.getPluralString(
            "licenseDataText",
            licenseInformation.entitlementCount,
            localization.getString(licenseType),
            localization.getString(licenseInformation.level)
        )
    }

    return (
        <FormLabel inline title={localization.getString("licenses")}>
            <div>
                <p>{getLicenseText(props.spaLicenseInformation, "spa")}</p>
                <p>{getLicenseText(props.siaLicenseInformation, "sia")}</p>
            </div>
        </FormLabel>
    )
}
