export const findDuplicates = (list: string[]) => {
    const sortedArr = list.slice().sort()
    const duplicates = []

    for (let i = 0; i < sortedArr.length - 1; i++) {
        if (sortedArr[i + 1] === sortedArr[i]) {
            duplicates.push(sortedArr[i])
        }
    }

    return duplicates
}
