import React from "react"
import { useHistory } from "react-router-dom"

import { ActionBar, BreadcrumbProps } from "../../../components/action-bar/ActionBar.component"
import { Button, ButtonElement, ButtonType } from "../../../components/button/Button.component"
import { Input } from "../../../components/input/Input.component"
import { ErrorToast, SuccessToast, ToastApi } from "../../../components/toast/Toast.components"
import { Form } from "../../../pre-v3/components/form/Form"
import { FormLabel } from "../../../pre-v3/components/form-label/FormLabel"
import { FormSection } from "../../../pre-v3/components/form-section/FormSection"
import { PageBreak } from "../../../pre-v3/components/page-break/PageBreak.component"
import { useServiceLocalization } from "../../../pre-v3/services/localization/Localization.service"
import { encodeID } from "../../../pre-v3/utils/Url.util"
import { ROUTE, formatRoutePath } from "../../../routes"
import { Edition } from "../../../v3/services/shared/Edition"
import {
    KnownOrgType,
    NewOrg,
    OrgType,
    useAddOrg,
} from "../../../v3/services/MomOrgManagement.service"
import { AiAssistedAdminSearchCheckbox } from "../shared/AiAssistedAdminSearch.component"
import { ApplicationDiscoveryCheckbox } from "../shared/ApplicationDiscovery.component"
import { CustomerIdInput } from "../shared/CustomerId.component"
import { EditionInput } from "../shared/Edition.component"
import { InternalOwnerInput } from "../shared/InternalOwner.component"
import { MspOrgCheckbox } from "../shared/MspOrg.component"
import { NetworkConfigurationCheckbox } from "../shared/NetworkConfiguration.component"
import { OrgNameInput } from "../shared/OrgName.component"
import { OrgTypeInput } from "../shared/OrgType.component"
import { SonicWallCseIdpCheckbox } from "../shared/SonicWallCseIdp.component"
import styles from "./AddOrg.module.scss"

export function AddOrg(): JSX.Element {
    const localization = useServiceLocalization()
    const history = useHistory()

    const successToastRef = React.useRef<ToastApi>(null)
    const errorToastRef = React.useRef<ToastApi>(null)

    const [org, setOrg] = React.useState(initialEntries)

    const { mutate: addOrg, isLoading: isAddingOrg } = useAddOrg({
        onSuccess: (newOrg) => {
            successToastRef.current?.openToast(
                localization.getString(
                    "somethingHasBeenSuccessfullyAdded",
                    localization.getString("organization"),
                    newOrg.name
                )
            )
            history.push(
                formatRoutePath(ROUTE.ORG_MANAGEMENT_DETAILS, {
                    id: encodeID(newOrg.id),
                })
            )
        },
        onError: (error) =>
            typeof error === "string"
                ? errorToastRef.current?.openToast(error)
                : console.error(error),
    })

    const breadcrumbs: BreadcrumbProps[] = [
        {
            label: localization.getString("superAdmin"),
        },
        {
            label: localization.getString("orgManagement"),
            href: ROUTE.ORG_MANAGEMENT,
        },
    ]

    const onOrgNameChange = (name: string) => setOrg({ ...org, name })

    const onAdminFirstNameChange = (firstName: string) =>
        setOrg({ ...org, admin: { ...org.admin, firstName } })

    const onAdminLastNameChange = (lastName: string) =>
        setOrg({ ...org, admin: { ...org.admin, lastName } })

    const onAdminEmailAddressChange = (emailAddress: string) =>
        setOrg({ ...org, admin: { ...org.admin, emailAddress } })

    const onInternalOwnerChange = (internalOwnerEmailAddress: string) =>
        setOrg({ ...org, internalOwnerEmailAddress })

    const onToggleMspOrg = (isMspOrg: boolean) =>
        setOrg({
            ...org,
            featureFlags: { ...org.featureFlags, isMspOrg },
            edition: isMspOrg ? Edition.ENTERPRISE : org.edition,
        })

    const onEditionChange = (edition: Edition) =>
        setOrg({
            ...org,
            edition,
            networkConfiguration: {
                ...org.networkConfiguration,
                usesGlobalEdge:
                    edition === Edition.TEAM ? true : org.networkConfiguration.usesGlobalEdge,
            },
        })

    const onToggleSonicWallCseIdp = (usesSonicWallCseIdp: boolean) =>
        setOrg({ ...org, featureFlags: { ...org.featureFlags, usesSonicWallCseIdp } })

    const onToggleApplicationDiscovery = (enableApplicationDiscovery: boolean) =>
        setOrg({
            ...org,
            featureFlags: { ...org.featureFlags, enableApplicationDiscovery },
        })

    const onToggleGlobalEdge = (usesGlobalEdge: boolean) =>
        setOrg({ ...org, networkConfiguration: { ...org.networkConfiguration, usesGlobalEdge } })

    const onTogglePrivateEdge = (usesPrivateEdge: boolean) =>
        setOrg({ ...org, networkConfiguration: { ...org.networkConfiguration, usesPrivateEdge } })

    const onToggleAiAssistedAdminSearch = (enableAiAssistedAdminSearch: boolean) =>
        setOrg({
            ...org,
            featureFlags: { ...org.featureFlags, enableAiAssistedAdminSearch },
        })

    const onOrgTypeChange = (type: KnownOrgType) => setOrg({ ...org, type })

    const onCustomerIdChange = (customerId: string) => setOrg({ ...org, customerId })

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()
        addOrg(org)
    }

    return (
        <React.Fragment>
            <ActionBar title={localization.getString("addOrg")} breadcrumbs={breadcrumbs} />
            <Form className={styles.form} onSubmit={onSubmit} labelWidth={177} display="grid">
                <FormSection title={localization.getString("orgDetails")}>
                    <OrgNameInput id={Id.NAME} value={org.name} onChange={onOrgNameChange} />
                    <FormLabel
                        inline
                        title={localization.getString("firstName")}
                        htmlFor={Id.FIRST_NAME}
                    >
                        <Input
                            type="text"
                            id={Id.FIRST_NAME}
                            required
                            value={org.admin.firstName}
                            onChangeValue={onAdminFirstNameChange}
                        />
                    </FormLabel>
                    <FormLabel
                        inline
                        title={localization.getString("lastName")}
                        htmlFor={Id.LAST_NAME}
                    >
                        <Input
                            type="text"
                            id={Id.LAST_NAME}
                            required
                            value={org.admin.lastName}
                            onChangeValue={onAdminLastNameChange}
                        />
                    </FormLabel>
                    <FormLabel
                        inline
                        title={localization.getString("email")}
                        htmlFor={Id.EMAIL_ADDRESS}
                    >
                        <Input
                            type="email"
                            id={Id.EMAIL_ADDRESS}
                            required
                            value={org.admin.emailAddress}
                            onChangeValue={onAdminEmailAddressChange}
                        />
                    </FormLabel>
                    <InternalOwnerInput
                        id={Id.INTERNAL_OWNER}
                        value={org.internalOwnerEmailAddress}
                        onChange={onInternalOwnerChange}
                    />
                    <MspOrgCheckbox
                        id={Id.MSP_ORG}
                        value={org.featureFlags.isMspOrg}
                        onChange={onToggleMspOrg}
                    />
                    <EditionInput
                        id={Id.EDITION}
                        value={org.edition}
                        onChange={onEditionChange}
                        disabled={org.featureFlags.isMspOrg}
                    />
                    {org.edition !== Edition.TEAM && !org.featureFlags.isMspOrg && (
                        <React.Fragment>
                            <SonicWallCseIdpCheckbox
                                id={Id.SONIC_WALL_CSE_IDP}
                                value={org.featureFlags.usesSonicWallCseIdp}
                                onChange={onToggleSonicWallCseIdp}
                            />
                            <ApplicationDiscoveryCheckbox
                                id={Id.APPLICATION_DISCOVERY}
                                value={org.featureFlags.enableApplicationDiscovery}
                                onChange={onToggleApplicationDiscovery}
                            />
                        </React.Fragment>
                    )}
                    {!org.featureFlags.isMspOrg && (
                        <React.Fragment>
                            <NetworkConfigurationCheckbox
                                usesGlobalEdge={org.networkConfiguration.usesGlobalEdge}
                                usesPrivateEdge={org.networkConfiguration.usesPrivateEdge}
                                usesGlobalEdgeDisabled={org.edition === Edition.TEAM}
                                showPrivateEdge={org.edition !== Edition.TEAM}
                                onToggleGlobalEdge={onToggleGlobalEdge}
                                onTogglePrivateEdge={onTogglePrivateEdge}
                            />
                            <AiAssistedAdminSearchCheckbox
                                id={Id.AI_ASSISTED_ADMIN_SEARCH}
                                value={org.featureFlags.enableAiAssistedAdminSearch}
                                onChange={onToggleAiAssistedAdminSearch}
                            />
                        </React.Fragment>
                    )}
                    <OrgTypeInput id={Id.ORG_TYPE} value={org.type} onChange={onOrgTypeChange} />
                    <CustomerIdInput
                        id={Id.CUSTOMER_ID}
                        value={org.customerId}
                        onChange={onCustomerIdChange}
                    />
                </FormSection>
                <PageBreak />
                <section className={styles.formButtons}>
                    <Button
                        asElement={ButtonElement.LINK}
                        buttonType={ButtonType.SECONDARY}
                        to={ROUTE.ORG_MANAGEMENT}
                    >
                        {localization.getString("cancel")}
                    </Button>
                    <Button
                        asElement={ButtonElement.BUTTON}
                        buttonType={ButtonType.PRIMARY}
                        type="submit"
                        loading={isAddingOrg}
                    >
                        {localization.getString("addOrg")}
                    </Button>
                </section>
            </Form>
            <SuccessToast ref={successToastRef} />
            <ErrorToast ref={errorToastRef} />
        </React.Fragment>
    )
}

const initialEntries: NewOrg = {
    name: "",
    edition: Edition.TEAM,
    type: OrgType.PRODUCTION,
    internalOwnerEmailAddress: "",
    customerId: "",
    admin: {
        firstName: "",
        lastName: "",
        emailAddress: "",
    },
    networkConfiguration: {
        usesGlobalEdge: true,
        usesPrivateEdge: false,
    },
    featureFlags: {
        enableAiAssistedAdminSearch: false,
        enableApplicationDiscovery: false,
        isMspOrg: false,
        usesSonicWallCseIdp: false,
    },
}

enum Id {
    NAME = "name",
    FIRST_NAME = "firstName",
    LAST_NAME = "lastName",
    EMAIL_ADDRESS = "emailAddress",
    INTERNAL_OWNER = "internalOwner",
    MSP_ORG = "mspOrg",
    EDITION = "edition",
    SONIC_WALL_CSE_IDP = "sonicWallCseIdp",
    APPLICATION_DISCOVERY = "applicationDiscovery",
    AI_ASSISTED_ADMIN_SEARCH = "aiAssistedAdminSearch",
    ORG_TYPE = "orgType",
    CUSTOMER_ID = "customerId",
}
