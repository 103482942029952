import React from "react"

import { useServiceLocalization } from "../../pre-v3/services/localization/Localization.service"
import { Button, ButtonType } from "../button/Button.component"
import styles from "./ModalFooter.module.scss"

export interface Props {
    /**
     * @default "Confirm"
     */
    confirmButtonLabel?: string
    /**
     * @default "Cancel"
     */
    cancelButtonLabel?: string
    isDestructive?: boolean
    onCancel?(): void
    onConfirm?(): Promise<void>
}

export function ModalFooter(props: Props): JSX.Element {
    const localization = useServiceLocalization()

    const [isLoading, setIsLoading] = React.useState(false)

    const onCancel: React.MouseEventHandler = (event) => {
        event.preventDefault()
        props.onCancel?.()
    }

    const onConfirm: React.MouseEventHandler = async (event) => {
        event.preventDefault()

        setIsLoading(true)

        try {
            await props.onConfirm?.()
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={styles.buttons}>
            <Button buttonType={ButtonType.SECONDARY} onClick={onCancel}>
                {props.cancelButtonLabel ?? localization.getString("cancel")}
            </Button>
            <Button
                buttonType={props.isDestructive ? ButtonType.DESTRUCTIVE_SOLID : ButtonType.PRIMARY}
                onClick={onConfirm}
                loading={isLoading}
            >
                {props.confirmButtonLabel ?? localization.getString("confirm")}
            </Button>
        </div>
    )
}
