import React, { ReactNode, useState } from "react"
import { useParams } from "react-router-dom"

import {
    Grid,
    ColDef,
    ICellRendererParams,
} from "../../../../pre-v3/components/grid/Grid.component"
import { useServiceLocalization } from "../../../../pre-v3/services/localization/Localization.service"
import {
    ActionBarItem,
    IconType,
    useActionBar,
} from "../../../../pre-v3/services/ActionBar.service"
import { decodeID } from "../../../../pre-v3/utils/Url.util"
import orgManagementStyles from "../MspOrgManagement.module.scss"
import { DateUtil } from "../../../../pre-v3/utils/Date.util"
import { faPen, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { useServiceModal } from "../../../../pre-v3/services/Modal.service"
import { ErrorBanner, ErrorBanners } from "../../../components/banner/Banner.component"
import { Loader } from "../../../components/loader/Loader.component"
import { MspOrgForm } from "../form/MspOrgForm.component"
import {
    mspOrgLabelMap,
    MspAdminInfo,
    useGetMspAdminsByOrgId,
    useGetMspOrgById,
    orgStatusToStatusTypeMap,
} from "../../../services/MspOrgManagement.service"
import { Accordion } from "../../../components/accordion/Accordion.component"
import { Container } from "../../../components/container/Container.component"
import { OverviewTopContainer } from "../../../components/overview/OverviewTopContainer/OverviewTopContainer.component"
import { ROUTE } from "../../../../routes"
import AgGridUtil from "../../../../pre-v3/utils/AgGrid.util"
import { IconButton } from "../../../components/icon-button/IconButton.component"
import styles from "./MspOrgOverview.module.scss"
import { EditMspAdminModal } from "../modals/EditMspAdminModal.component"
import { RoundButton } from "../../../components/round-button/RoundButton.component"
import { AddMspAdminModal } from "../modals/AddMspAdminModal.component"

interface Props {
    canAssignAdmin: boolean
}

export function MspOrgOverview(props: Props): JSX.Element {
    const params = useParams<UrlParams>()
    const localization = useServiceLocalization()
    const modalService = useServiceModal()

    const {
        data: orgData,
        isFetching: isOrgLoading,
        refetch: refetchOrgData,
        error: orgError,
    } = useGetMspOrgById(decodeID(params.id))

    const {
        data: adminInfo,
        isFetching: isAdminLoading,
        refetch: refetchAdminData,
        error: adminError,
    } = useGetMspAdminsByOrgId(decodeID(params.id))

    const orgId = orgData?.id || ""
    const orgName = orgData?.orgName || ""
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const disableAllActions = isOrgLoading || !orgData || isEdit
    const isViewLoading = isOrgLoading || isAdminLoading
    const errors: ReactNode[] = [typeof orgError === "string" && orgError]

    const actions: ActionBarItem[] = [
        {
            icon: IconType.PEN,
            onClick: () => setIsEdit(true),
            tooltip: isEdit ? "" : localization.getString("edit"),
            disabled: disableAllActions,
        },
    ]

    useActionBar({
        title: orgName || "",
        items: [
            {
                label: localization.getString("orgManagement"),
                href: ROUTE.MSP_ORG_MANAGEMENT,
            },
        ],
        fetchData: () => {
            refetchOrgData()
            refetchAdminData()
        },
        actions,
    })

    function onCancel(): void {
        refetchOrgData()
        setIsEdit(false)
    }

    function onEdit(): void {
        refetchOrgData()
        setIsEdit(false)
    }

    const columns: ColDef<MspAdminInfo>[] = [
        {
            headerName: localization.getString("admin"),
            field: "userName",
            flex: 65,
            comparator: AgGridUtil.alphaBetComparator,
        },
        {
            headerName: localization.getString("role"),
            field: "profile",
            tooltipValueGetter: AgGridUtil.linkTooltipValueGetter,
            comparator: AgGridUtil.alphaBetComparator,
            flex: 65,
        },

        ...(props.canAssignAdmin
            ? [
                  {
                      headerName: localization.getString("manage"),
                      cellRenderer: (params: ICellRendererParams<MspAdminInfo>) =>
                          params.data && (
                              <IconButton
                                  action
                                  icon={faPen}
                                  aria-label={localization.getString(
                                      "editSomething",
                                      localization.getString(
                                          "somethingAssignment",
                                          params.data?.userName
                                      )
                                  )}
                              />
                          ),
                      cellClass: styles.buttonCell,
                      onCellClicked: (e) => {
                          modalService.open(localization.getString("editAnAdmin"), {
                              component: EditMspAdminModal,
                              props: {
                                  data: {
                                      ...e.data,
                                      orgId: decodeID(params.id),
                                  },
                              },
                              maxWidth: "lg",
                          })
                      },
                  } satisfies ColDef<MspAdminInfo>,
              ]
            : []),
    ]

    function addAdmin() {
        modalService.open(localization.getString("addAnAdmin"), {
            component: AddMspAdminModal,
            props: {
                orgId,
            },
            maxWidth: "lg",
        })
    }

    return (
        <Loader
            isLoading={isViewLoading}
            center
            medium
            title={localization.getString(
                "loadingSomething",
                localization.getString("orgManagement")
            )}
        >
            <ErrorBanners errors={errors} className={orgManagementStyles.errorContainer} />
            {orgData && (
                <Container>
                    <OverviewTopContainer
                        className={styles.overviewContainer}
                        statusType={orgStatusToStatusTypeMap[orgData.status]}
                        statusLabel={localization.getString(mspOrgLabelMap[orgData.status])}
                        listItems={[
                            {
                                label: localization.getString("createdAt"),
                                value: orgData.createdAt && DateUtil.format(orgData.createdAt),
                            },
                            {
                                label: localization.getString("licenseUsage"),
                                value: orgData.licenseCount,
                            },
                        ]}
                    />
                    <Accordion
                        label={localization.getString("generalInformation")}
                        type="plain"
                        open
                    >
                        <MspOrgForm
                            initialValue={orgData}
                            disabled={!isEdit}
                            isEdit={isEdit}
                            onCancel={onCancel}
                            afterSubmit={onEdit}
                        />
                    </Accordion>
                    {typeof adminError === "string" && adminError ? (
                        <ErrorBanner className={orgManagementStyles.errorBanner}>
                            {adminError}
                        </ErrorBanner>
                    ) : (
                        <div className={orgManagementStyles.adminGrid}>
                            {props.canAssignAdmin && (
                                <div className={styles.adminBar}>
                                    <RoundButton
                                        icon={faPlus}
                                        onClick={addAdmin}
                                        rightLabel={localization.getString("addAnAdmin")}
                                        brand
                                        className={styles.iconButton}
                                    />
                                </div>
                            )}
                            <Grid columnDefs={columns} rowData={adminInfo} pagination autoHeight />
                        </div>
                    )}
                </Container>
            )}
        </Loader>
    )
}

interface UrlParams {
    id: string
}
