import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { DateUtil } from "../../pre-v3/utils/Date.util"
import {
    EditMspAdminData,
    MspAdminInfoRes,
    MspOrgApi,
    MspOrgInfoPostBody,
    MspAdminOrgInfoRes,
    MspOrgInfoRes,
    TypeRes,
    StatusRes,
} from "../api/MspOrgManagement.api"
import { Edition } from "./shared/Edition"
import { StatusType } from "../components/status/Status.component"
import { LanguageKey } from "../../pre-v3/services/localization/languages/en-US.language"
import { Admin as UserListData, UserApi } from "../../pre-v3/api/User.api"
import { useAuthService } from "../../pre-v3/services/Auth.service"
import { UrlUtil } from "../../pre-v3/utils/Url.util"
import { ModalRef } from "../../pre-v3/services/Modal.service"

export function useGetMspOrgs() {
    const mspOrgApi = new MspOrgApi()

    return useQuery<MspOrgInfo[], string>({
        queryKey: ["mspOrgService.getOrgs"],
        queryFn: async () => {
            const res = await mspOrgApi.getMspOrgs()
            return res?.map(mapOrgResToOrg) || []
        },
    })
}

export function useGetMspOrgById(id: string) {
    const mspOrgApi = new MspOrgApi()

    return useQuery<MspOrgInfo, string>({
        queryKey: ["mspOrgService.getOrgData", id],
        queryFn: async () => {
            const res = await mspOrgApi.getMspOrgById(id)
            return mapOrgResToOrg(res)
        },
        enabled: Boolean(id),
    })
}

export function useGetMspAdminsByOrgId(id: string) {
    const mspOrgApi = new MspOrgApi()

    return useQuery<MspAdminInfo[], string>({
        queryKey: ["mspOrgService.getAdminsData", id],
        queryFn: async () => {
            const res = await mspOrgApi.getMspAdminsByOrgId(id)
            return res ? res.map(mapAdminResToAdmin) : []
        },
        enabled: Boolean(id),
    })
}

export function useCreateMspOrg(onSuccess?: () => void) {
    const mspOrgApi = new MspOrgApi()
    const client = useQueryClient()

    return useMutation<MspOrgInfoRes, string, MspOrgReqInfo>({
        mutationFn: (data: MspOrgReqInfo) => {
            const body: MspOrgInfoPostBody = {
                org_name: data.orgName,
                org_type: orgTypeReqDict[data.type as OrgType],
                description: data.description,
                banyan_idp: true,
            }
            return mspOrgApi.createMspOrg(body)
        },
        onSuccess: () => {
            client.invalidateQueries(["mspOrgService.getOrgs"])
            if (onSuccess) onSuccess()
        },
    })
}

export function useGetMspAdminOrgs(email: string) {
    const mspOrgApi = new MspOrgApi()
    const urlParams = UrlUtil.setURlParams("email", email)
    return useQuery<MspAdminOrgInfo[], string>({
        queryKey: ["mspOrgService.getAdminOrgs"],
        queryFn: async () => {
            const res = await mspOrgApi.getMspAdminOrgs(email)
            return res?.map(mapAdminOrgResToAdminOrg) || []
        },
    })
}

export function useEditMspAdmin(orgId: string, modalRef: ModalRef) {
    const mspOrgApi = new MspOrgApi()
    const client = useQueryClient()

    return useMutation<string, string, Omit<EditMspAdminData, "orgId">>({
        mutationFn: (data: Omit<EditMspAdminData, "orgId">) =>
            mspOrgApi.editMspAdmin({ ...data, orgId }),
        onSuccess: () => {
            client.invalidateQueries(["mspOrgService.getAdminsData", orgId])
            modalRef.close(null)
        },
    })
}

export function useDeleteMspAdmin(orgId: string, modalRef: ModalRef) {
    const mspOrgApi = new MspOrgApi()
    const client = useQueryClient()

    return useMutation<void, string, string>({
        mutationFn: (email: string) => mspOrgApi.deleteMspAdmin({ email, orgId }),
        onSuccess: () => {
            client.invalidateQueries(["mspOrgService.getAdminsData", orgId])
            modalRef.close(null)
        },
    })
}

export function useCreateMspAdmin(orgId: string) {
    const mspOrgApi = new MspOrgApi()
    const client = useQueryClient()

    return useMutation<void, string, Omit<CreateMspAdminHookData, "orgId">>({
        mutationFn: (data: Omit<CreateMspAdminHookData, "orgId">) =>
            mspOrgApi.crateMspAdmin({
                email: data.email,
                profile: data.profile,
                orgId,
            }),
        onSuccess: () => {
            client.invalidateQueries(["mspOrgService.getAdminsData", orgId])
        },
    })
}

export function useGetUsersList() {
    const userApi = new UserApi()

    return useQuery<AdminUser[], string>({
        queryKey: ["mspUsersList.getUsersList"],
        queryFn: async () => {
            return userApi.getAdmins().then((admins) => admins.map(mapAdminToAdminUser))
        },
    })
}

function mapAdminToAdminUser(a: UserListData): AdminUser {
    return {
        name: a.First + " " + a.Last,
        email: a.Email,
    }
}

function mapOrgResToOrg(org: MspOrgInfoRes): MspOrgInfo {
    return {
        createdAt: DateUtil.convertLargeTimestamp(org.created_at),
        id: org.org_id,
        orgName: org.org_name,
        type: orgTypeResDict[org.org_type],
        edition: editionMap[org.edition],
        description: org.description,
        status: mspOrgStatusMap[org.status],
        isGlobalEdge: org.global_edge,
        isPrivateEdge: org.private_edge,
        licenseCount: org.license_count || 0,
    }
}

function mapAdminOrgResToAdminOrg(org: MspAdminOrgInfoRes): MspAdminOrgInfo {
    return {
        lastLoginAt: DateUtil.convertLargeTimestamp(org.last_login),
        id: org.org_id,
        orgName: org.org_name,
        role: org.role,
    }
}

function mapAdminResToAdmin(admin: MspAdminInfoRes): MspAdminInfo {
    return {
        userName: admin.first_name + " " + admin.last_name,
        email: admin.email,
        lastLoginAt: DateUtil.convertLargeTimestamp(admin.last_login),
        profile: admin.profile,
    }
}

export function useUpdateMspOrg(orgId: string, onSuccess?: () => void) {
    const mspOrgApi = new MspOrgApi()
    const client = useQueryClient()

    return useMutation<MspOrgInfoRes, string, Omit<MspOrgReqInfo, "orgName">>({
        mutationFn: (data: Omit<MspOrgReqInfo, "orgName">) => {
            const body: Omit<MspOrgInfoPostBody, "org_name"> = {
                org_type: orgTypeReqDict[data.type as OrgType],
                description: data.description,
            }
            return mspOrgApi.updateMspOrg(orgId, body)
        },
        onSuccess: () => {
            client.invalidateQueries(["mspOrgService.getOrgData", orgId])
            if (onSuccess) onSuccess()
        },
    })
}

export function useMasqueradeMspAdmin() {
    const authService = useAuthService()

    return useMutation<void, string, string>({
        mutationFn: (orgId: string) => {
            return authService.masqueradeMspAdmin(orgId)
        },
    })
}

export function isLegacyOrgType(type: OrgType | LegacyOrgType): boolean {
    if (type === LegacyOrgType.INTERNAL || type === LegacyOrgType.TESTING) {
        return true
    } else {
        return false
    }
}

const editionMap: Record<MspOrgInfoRes["edition"], Edition> = {
    Team: Edition.TEAM,
    Enterprise: Edition.ENTERPRISE,
    Unlimited: Edition.UNLIMITED,
}

export enum Status {
    SUCCESS = "Success",
    PARTIAL_SUCCESS = "PartialSuccess",
    FAILED = "Failed",
    IN_PROGRESS = "InProgress",
    PENDING = "Pending",
}

const mspOrgStatusMap: Record<StatusRes, Status> = {
    Success: Status.SUCCESS,
    PartialSuccess: Status.PARTIAL_SUCCESS,
    Failed: Status.FAILED,
    InProgress: Status.IN_PROGRESS,
    Pending: Status.PENDING,
}

export const mspOrgLabelMap: Record<Status, LanguageKey> = {
    [Status.SUCCESS]: "active",
    [Status.PARTIAL_SUCCESS]: "partialSuccess",
    [Status.FAILED]: "error",
    [Status.IN_PROGRESS]: "inProgress",
    [Status.PENDING]: "pending",
}

export const orgStatusToStatusTypeMap: Record<Status, StatusType> = {
    [Status.SUCCESS]: "success",
    [Status.FAILED]: "error",
    [Status.PENDING]: "warning",
    [Status.PARTIAL_SUCCESS]: "warning",
    [Status.IN_PROGRESS]: "disabled",
}

export enum LegacyOrgType {
    INTERNAL = "Internal",
    TESTING = "Testing",
}

export enum OrgType {
    PRODUCTION = "Production",
    TRIAL = "Trial",
    STAGING = "Staging",
    INTERNAL_TEMPORARY = "Internal Temporary",
    INTERNAL_PERSISTENT = "Internal Persistent",
}

const orgTypeReqDict: Record<OrgType, TypeRes> = {
    [OrgType.PRODUCTION]: "Production",
    [OrgType.TRIAL]: "Trial",
    [OrgType.STAGING]: "Staging",
    [OrgType.INTERNAL_PERSISTENT]: "InternalPersistent",
    [OrgType.INTERNAL_TEMPORARY]: "InternalTemporary",
}

const orgTypeResDict: Record<TypeRes, OrgType | LegacyOrgType> = {
    Production: OrgType.PRODUCTION,
    Trial: OrgType.TRIAL,
    Staging: OrgType.STAGING,
    InternalPersistent: OrgType.INTERNAL_PERSISTENT,
    InternalTemporary: OrgType.INTERNAL_TEMPORARY,
    Internal: LegacyOrgType.INTERNAL,
    Testing: LegacyOrgType.TESTING,
}
export interface MspOrgInfo {
    id: string
    orgName: string
    status: Status
    type: OrgType | LegacyOrgType
    edition: Edition
    description: string
    createdAt?: number
    isGlobalEdge?: boolean
    isPrivateEdge?: boolean
    licenseCount?: number
}

export interface MspAdminInfo {
    userName: string
    profile: "Admin" | "ReadOnly"
    email: string
    lastLoginAt: number
}

export type MspOrgReqInfo = Pick<MspOrgInfo, "orgName" | "description" | "type">

export interface MspAdminOrgInfo {
    id: string
    orgName: string
    lastLoginAt: number
    role: "Admin" | "ReadOnly"
}

export interface CreateMspAdminHookData extends Omit<EditMspAdminData, "profile"> {
    profile: "Admin" | "ReadOnly"
}

export type UserListDataHook = AdminUser

export interface AdminUser {
    name: string
    email: string
}
